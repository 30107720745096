import React, { Component } from "react";
import {
  Row,
  Button,
  Col,
  Input,
  Label,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast, Flip } from "react-toastify";
import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uToken } from "../../useToken";
import { Link } from "react-router-dom";
import EmailSideBar from "./email-sidebar";
import swal from "sweetalert";

import EmailToolbar from "./email-toolbar";

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const removeUnwantedText = (text) => {
  const unwantedText = "Click here to unsubscribe";
  return text.replace(unwantedText, "").trim();
};

class EmailInbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      totalEmails: 0,
      currentPage: 1,
      pageSize: 10,
      selectedEmails: new Set(),
      selectAll: false,
      searchTerm: "",
      noRecordsFound: false,
      filterDropdownOpen: false,
      labelDropdownOpen: false,
      labels: [],
      selectedLabel: null,
    };
  }

  componentDidMount() {
    this.fetchEmails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.selectedLabel !== this.state.selectedLabel
    ) {
      this.fetchEmails();
    }
  }

  fetchEmails = (search = false) => {
    const reportId = new URLSearchParams().get("show")
      ? dcrypt(new URLSearchParams().get("show"))
      : "";
    const queryParams = new URLSearchParams({
      report_type: "email",
      report_id: reportId,
      page_number: this.state.currentPage,
      page_size: this.state.pageSize,
      direction: "in",
      status: "received",
      search: search ? this.state.searchTerm : undefined,
    });

    if (this.state.selectedLabel) {
      queryParams.append("label_id", this.state.selectedLabel);
    }

    fetch(
      `${ApiUrl}aio/report/email${
        search ? "/search" : ""
      }?${queryParams.toString()}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${uToken()}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          this.setState({
            emails: response.data.records,
            totalEmails: response.data.total,
            noRecordsFound: response.data.records.length === 0,
            selectedEmails: new Set(),
            selectAll: false,
          });
        } else {
          this.showError(response.message);
        }
      })
      .catch(() => this.showError("Error fetching emails"));
  };

  showError = (message) => {
    toast.error(message, {
      hideProgressBar: true,
      position: "top-center",
      transition: Flip,
    });
  };

  handleSelectEmail = (emailId) => {
    this.setState((prevState) => {
      const newSelected = new Set(prevState.selectedEmails);
      if (newSelected.has(emailId)) {
        newSelected.delete(emailId);
      } else {
        newSelected.add(emailId);
      }
      return { selectedEmails: newSelected };
    });
  };

  handleSelectAll = () => {
    this.setState((prevState) => {
      const newSelectAll = !prevState.selectAll;
      const newSelectedEmails = newSelectAll
        ? new Set(this.state.emails.map((email) => email.report_id))
        : new Set();

      return { selectAll: newSelectAll, selectedEmails: newSelectedEmails };
    });
  };
  handleDelete = () => {
    const { selectedEmails } = this.state;
    if (selectedEmails.size === 0) {
      swal({
        title: "No emails selected",
        text: "Please select at least one email to delete.",
        icon: "warning",
      });
      return;
    }
    swal({
      title: "Are you sure?",
      text: "You want to delete the selected emails.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        const reportIds = Array.from(selectedEmails);
        fetch(`${ApiUrl}aio/delete/email`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${uToken()}`,
          },
          body: JSON.stringify({ report_id: reportIds }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              swal(
                "Success",
                "Selected emails deleted successfully!",
                "success"
              );
              this.fetchEmails();
            } else {
              swal("Error", data.message, "error");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            swal("Error", "Error deleting emails", "error");
          });
      }
    });
  };

  handleLabelSelect = (labelId) => {
    if (this.state.selectedEmails.size === 0) {
      this.showError("No emails selected");
      return;
    }

    const reportIds = Array.from(this.state.selectedEmails).join(",");
    fetch(`${ApiUrl}assign/gmail/lebel`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ label_id: labelId, report_ids: reportIds }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          const successMessage = labelId
            ? "Label applied successfully"
            : "Label removed successfully";
          toast.success(successMessage, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
          });
          this.fetchEmails();
        } else {
          this.showError(response.message);
        }
      })
      .catch(() => this.showError("Error applying or removing label"));
  };

  handleSearchChange = (searchTerm) => {
    this.setState({ searchTerm, currentPage: 1 }, () => {
      this.fetchEmails(true);
    });
  };

  toggleFilterDropdown = () => {
    this.setState((prevState) => ({
      filterDropdownOpen: !prevState.filterDropdownOpen,
    }));
  };

  toggleLabelDropdown = () => {
    this.setState((prevState) => ({
      labelDropdownOpen: !prevState.labelDropdownOpen,
    }));
  };

  handleLabelFilter = (labelId) => {
    this.setState({ selectedLabel: labelId, currentPage: 1 }, () => {
      this.fetchEmails();
    });
  };

  render() {
    const {
      emails,
      totalEmails,
      currentPage,
      pageSize,
      selectedEmails,
      selectAll,
      noRecordsFound,
      filterDropdownOpen,
      labelDropdownOpen,
      labels,
    } = this.state;
    const isAnyEmailSelected = selectedEmails.size > 0;

    const styles = {
      dropdownToggle: {
        fontSize: "17px",
        padding: "5px 10px",
      },
      dropdownMenu: {
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        border: "1px solid #e0e0e0",
        padding: "10px",
        minWidth: "200px",
        fontSize: "14px",
      },
      dropdownItemHeader: {
        fontWeight: "500",
        fontSize: "13px",
        paddingBottom: "6px",
        paddingTop: "4px",
        paddingLeft: "10px",
        backgroundColor: "#fafafa",
        color: "#757575",
      },
      dropdownItem: {
        padding: "6px 12px",
        borderRadius: "4px",
        transition: "background-color 0.2s ease-in-out",
      },
      noRecords: {
        textAlign: "center",
        padding: "20px",
        fontSize: "18px",
        color: "#888",
        borderTop: "1px solid #ddd",
        backgroundColor: "#f9f9f9",
      },
      iconStyle: {
        fontSize: "24px",
        color: "#888",
        display: "block",
        marginBottom: "10px",
      },
      labelBadge: {
        backgroundColor: "#f0f0f0",
        color: "#000",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: "5px",
        fontSize: "12px",
      },
    };
    return (
      <Row>
        <Col xs="12">
          <EmailSideBar
            onLabelsFetched={(labels) => this.setState({ labels })}
          />
          <div className="email-rightbar mb-3">
            <Card>
              <EmailToolbar
                currentPage={currentPage}
                totalEmails={totalEmails}
                pageSize={pageSize}
                onPageChange={(page) => this.setState({ currentPage: page })}
                onPageSizeChange={(size) => this.setState({ pageSize: size })}
                hidePagination={false}
                selectedEmails={Array.from(selectedEmails)}
                refreshEmails={this.fetchEmails}
                toggleSelectAll={this.handleSelectAll}
                selectAll={selectAll}
                onSearchChange={this.handleSearchChange}
              />

              <div
                className="d-flex align-items-center"
                style={{ marginTop: "8px", marginLeft: "20px" }}
              >
                <Input
                  type="checkbox"
                  checked={selectAll}
                  onChange={this.handleSelectAll}
                  style={{
                    width: "16px",
                    height: "16px",
                    margin: "0 10px 0 0",
                    cursor: "pointer",
                  }}
                />
                <Dropdown
                  className="FilterDropDown"
                  isOpen={filterDropdownOpen}
                  toggle={this.toggleFilterDropdown}
                  style={{ margin: "0 10px" }}
                >
                  <DropdownToggle
                    style={styles.dropdownToggle}
                    tag="span"
                    className="mdi mdi-filter cursor-pointer"
                  />
                  <DropdownMenu end style={styles.dropdownMenu}>
                    <DropdownItem header style={styles.dropdownItemHeader}>
                      Filter Email
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => this.handleLabelFilter(null)}
                      style={styles.dropdownItem}
                    >
                      All
                    </DropdownItem>
                    {labels.length > 0 ? (
                      labels.map((label) => (
                        <DropdownItem
                          key={label.id}
                          onClick={() => this.handleLabelFilter(label.id)}
                          style={styles.dropdownItem}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#f0f0f0")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "transparent")
                          }
                        >
                          <span
                            className="mdi mdi-label"
                            style={{
                              marginRight: "8px",
                              color: label.color_code,
                              fontSize: "16px",
                            }}
                          ></span>
                          {label.name}
                        </DropdownItem>
                      ))
                    ) : (
                      <DropdownItem>No labels found</DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
                {isAnyEmailSelected && (
                  <>
                    <Button
                      type="button"
                      onClick={this.handleDelete}
                      style={{
                        background: "transparent",
                        border: "none",
                        padding: "5px 10px",
                        cursor: "pointer",
                        color: "#5b5757",
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        margin: "0 10px",
                      }}
                    >
                      <span
                        className="mdi mdi-delete"
                        style={{ fontSize: "18px" }}
                      ></span>
                    </Button>

                    <Dropdown
                      isOpen={labelDropdownOpen}
                      toggle={this.toggleLabelDropdown}
                      style={{ margin: "0 10px" }}
                    >
                      <DropdownToggle
                        style={styles.dropdownToggle}
                        tag="span"
                        className="mdi mdi-label cursor-pointer"
                      />
                      <DropdownMenu end style={styles.dropdownMenu}>
                        <DropdownItem header style={styles.dropdownItemHeader}>
                          Label as
                        </DropdownItem>
                        <DropdownItem
                          key="unassign"
                          onClick={() => this.handleLabelSelect(0)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 15px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            className="mdi mdi-label-off"
                            style={{ marginRight: "8px", fontSize: "16px" }}
                          ></span>
                          Unassign Label
                        </DropdownItem>
                        {labels.length > 0 ? (
                          labels.map((label) => (
                            <DropdownItem
                              key={label.id}
                              onClick={() => this.handleLabelSelect(label.id)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px 15px",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                className="mdi mdi-label"
                                style={{
                                  marginRight: "8px",
                                  color: label.color_code,
                                  fontSize: "16px",
                                }}
                              ></span>
                              {label.name}
                            </DropdownItem>
                          ))
                        ) : (
                          <DropdownItem>No labels found</DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </>
                )}
              </div>

              {noRecordsFound ? (
                <div style={styles.noRecords}>
                  <i
                    className="fas fa-folder-open"
                    style={styles.iconStyle}
                  ></i>
                  <p>No records found</p>
                </div>
              ) : (
                <EmailList
                  emails={emails}
                  selectedEmails={selectedEmails}
                  onEmailSelect={this.handleSelectEmail}
                  styles={styles}
                />
              )}
            </Card>
          </div>
        </Col>
      </Row>
    );
  }
}

const EmailList = ({ emails, selectedEmails, onEmailSelect, styles }) => (
  <ul className="message-list">
    {emails.length ? (
      emails.map((email) => (
        <li style={{ background: "none" }} key={email.report_id}>
          <div className="col-mail col-mail-1">
            <div className="checkbox-wrapper-mail">
              <Input
                type="checkbox"
                id={`checkbox-${email.report_id}`}
                name="report_id"
                checked={selectedEmails.has(email.report_id)}
                onChange={() => onEmailSelect(email.report_id)}
              />
              <Label
                htmlFor={`checkbox-${email.report_id}`}
                className="toggle"
              />
            </div>
            <Link
              to={`/email/read/${bcrypt(email.report_id)}`}
              className="title"
              style={{
                fontWeight: email.read_status === 0 ? "bold" : "normal",
              }}
            >
              {(email.sender_name || email.name || "Unknown Sender").length > 15
                ? (
                    email.sender_name ||
                    email.name ||
                    "Unknown Sender"
                  ).substring(0, 15) + "."
                : email.sender_name || email.name || "Unknown Sender"}

              {email.read_status === 0 && (
                <span
                  style={{
                    backgroundColor: "#1A73E8",
                    color: "white",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    marginLeft: "12px",
                    fontSize: "11px",
                  }}
                >
                  New
                </span>
              )}
            </Link>
          </div>
          <div className="col-mail col-mail-2">
            <Link
              to={`/email/read/${bcrypt(email.report_id)}`}
              className="subject"
            >
              {email.label_id && (
                <span
                  className="label"
                  style={{
                    ...styles.labelBadge,
                    backgroundColor: email.color_code || "#f0f0f0",
                    color: "#fff",
                  }}
                >
                  {email.label_name}
                </span>
              )}
              <span
                style={{
                  fontWeight: email.read_status === 0 ? "bold" : "normal",
                }}
              >
                {email.subject || "(no subject)"} –{" "}
              </span>
              <span className="teaser">
                {email.body
                  ? removeUnwantedText(stripHtmlTags(email.body)).substring(
                      0,
                      100
                    )
                  : "No preview available"}
              </span>
            </Link>
            <div style={{ paddingLeft: "0px" }} className="date">
              {email.created_at || "Date not available"}
            </div>
          </div>
        </li>
      ))
    ) : (
      <li>No received emails available</li>
    )}
  </ul>
);

export default EmailInbox;
