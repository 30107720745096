import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Helmet from "react-helmet";

import ReactDatatable from "@ashvin27/react-datatable";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import {
  dcrypt,
  bcrypt,
  isAllowed,
  uInfo,
  uToken,
  NumberWithCommas,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import Badge from "react-bootstrap/Badge";
import swal from "sweetalert";
import Select from "react-select";

export default function AddonsList() {
  const [pageName, setPageName] = useState("Add-ons List");
  const [includedAddonsList, setIncludedAddonsList] = useState([]);
  const [notIncludedAddonsList, setNotIncludedAddonsList] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addonDetail, setAddonDetail] = useState({});
  const [totalAddons, setTotalAddons] = useState(0);
  const [addonData, setAddonData] = useState({});
  const [packages, setPackages] = useState([]);
  const [addonPackages, setAddonPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [includedPlans, setIncludedPlans] = useState([]);
  const [availablePlans, setAvailablePlans] = useState([]);

  const routeHistory = useHistory();

  const getAddonsList = (packageId = null) => {
    const formData = JSON.stringify({
      available_for_plans: packageId || "",
      included_in_plans: packageId || "",
    });

    fetch(`${ApiUrl}addons/fetch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setIncludedAddonsList(data.data.records.included);
          if (data.data.records.not_included) {
            setNotIncludedAddonsList(data.data.records.not_included);
          }
          // setTotalAddons(data.data.total);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPackages = () => {
    fetch(`${ApiUrl}` + "packages/list/Coach", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ package_type: "default" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          const allOption = { package_id: 0, name: "All Plans" };
          setPackages([allOption, ...response.data.records]);
          // setPackages(response.data.records);
          setAddonPackages(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (packages.length > 0) {
      setSelectedPackage({ package_id: 0, name: "All Plans" });
    }
  }, [packages]);

  useEffect(() => {
    getAddonsList();
    getPackages();
  }, []);

  const saveAddonDetail = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();

    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    submitData.append("available_for_plans", availablePlans);
    submitData.append("included_in_plans", includedPlans);

    fetch(`${ApiUrl}addons/edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          const includedPlans = response.data.record.included_in_plans
            ? response.data.record.included_in_plans.split(",").map(Number)
            : [];

          if (includedPlans.includes(selectedPackage.package_id)) {
            if (includedAddonsList.some((item) => item.id === addonDetail.id)) {
              setIncludedAddonsList((prevAddons) =>
                prevAddons.map((addon) =>
                  addon.id === addonDetail.id ? response.data.record : addon
                )
              );
            } else {
              setNotIncludedAddonsList((prevAddons) =>
                prevAddons.filter((item) => item.id !== addonDetail.id)
              );

              setIncludedAddonsList((prevAddons) => [
                ...prevAddons,
                response.data.record,
              ]);
            }
          } else {
            if (
              notIncludedAddonsList.some((item) => item.id === addonDetail.id)
            ) {
              setNotIncludedAddonsList((prevAddons) =>
                prevAddons.map((addon) =>
                  addon.id === addonDetail.id ? response.data.record : addon
                )
              );
            } else {
              setIncludedAddonsList((prevAddons) =>
                prevAddons.filter((item) => item.id !== addonDetail.id)
              );

              setNotIncludedAddonsList((prevAddons) => [
                ...prevAddons,
                response.data.record,
              ]);
            }
          }

          swal({
            title: response.message,
            icon: "success",
          });
          setEditModal(false);
        } else {
          setAddonDetail([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          title: "An error occurred",
          icon: "error",
        });
      });
  };

  // const handleAvailablePackageChange = (selectedOptions) => {
  //   const selectedIds = selectedOptions.map((option) => option.package_id);
  //   setAddonDetail((prevState) => ({
  //     ...prevState,
  //     available_for_plans: selectedIds,
  //   }));
  // };

  // const handleIncludedPackageChange = (selectedOptions) => {
  //   const selectedIds = selectedOptions.map((option) => option.package_id);
  //   setAddonDetail((prevState) => ({
  //     ...prevState,
  //     included_in_plans: selectedIds,
  //   }));
  // };

  const handleIncludedPackageChange = (pkgId) => {
    setIncludedPlans((prevSelected) => {
      if (prevSelected.includes(pkgId)) {
        return prevSelected.filter((id) => id !== pkgId);
      } else {
        return [...prevSelected, pkgId];
      }
    });
  };

  const handleAvailablePackageChange = (pkgId) => {
    setAvailablePlans((prevSelected) => {
      if (prevSelected.includes(pkgId)) {
        return prevSelected.filter((id) => id !== pkgId);
      } else {
        return [...prevSelected, pkgId];
      }
    });
  };

  const AddonCards = ({ addons, type }) => {
    return (
      <>
        <div className="mt-3">
          <div className="ps-2 pb-1 pe-1">
            <Row className="my-3 mx-1">
              <Col lg="1">
                {notIncludedAddonsList &&
                  notIncludedAddonsList.length > 0 &&
                  (type == "included" ? (
                    <h5 className="fw-bold" style={{ color: "#00002a" }}>
                      Including:{" "}
                    </h5>
                  ) : (
                    <h5 className="fw-bold" style={{ color: "#00002a" }}>
                      Not Including:{" "}
                    </h5>
                  ))}
              </Col>
              <Col lg="11">
                <Row>
                  {addons.length > 0 ? (
                    addons.map((addon) => (
                      <Col lg="2" className="mb-3" key={addon.id}>
                        <div className="certification_sec AddonsPage">
                          <Card
                            className="h-100 d-flex flex-column cursor-pointer"
                            style={{
                              borderTop: `8px solid ${
                                type == "included" ? "#008037" : "#dc3545"
                              }`,
                              borderBottom: `8px solid ${
                                type == "included" ? "#008037" : "#dc3545"
                              }`,
                            }}
                            onClick={() => {
                              setEditModal(true);
                              setAddonDetail(addon);
                              setIncludedPlans(
                                addon.included_in_plans.split(",").map(Number)
                              );
                              setAvailablePlans(
                                addon.available_for_plans.split(",").map(Number)
                              );
                            }}
                          >
                            <div className="RecentlyViewed text-center d-flex py-3 justify-content-center">
                              <div className="mb-2 px-3">
                                <div className="">
                                  <h5 className="text-start color-dark fw-bold">
                                    {addon.title}
                                  </h5>
                                  <p className="text-start">
                                    {addon.description}
                                  </p>
                                </div>
                                <div className="text-start FixPosition ">
                                  <h6 className="color-dark">
                                    Price:{" "}
                                    {addon.price && addon.price != 0
                                      ? `$${NumberWithCommas(
                                          Math.round(addon.price)
                                        )}`
                                      : // ${
                                        //   addon.pricing_interval == "Monthly"
                                        //     ? "/month"
                                        //     : ""
                                        // }`
                                        "N/A"}
                                    <smal className="fw-light fst-italic">
                                      {addon.price &&
                                      addon.price != 0 &&
                                      addon.pricing_interval == "Monthly"
                                        ? "/month"
                                        : ""}
                                    </smal>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <h5 className="text-center">No Addons are available</h5>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  const handlePackageSelect = (selectedOption) => {
    setSelectedPackage(selectedOption);
    getAddonsList(selectedOption.package_id);
  };

  return (
    <>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>

      <Row>
        <Col sm={6}>
          <div className="page-title-box">
            <h4>{pageName}</h4>
            <ol className="breadcrumb m-0">
              <li key={0} className="breadcrumb-item active">
                {ProductName}
              </li>
              <li key={1} className="breadcrumb-item">
                <Link to="#">{pageName}</Link>
              </li>
            </ol>
          </div>
        </Col>
        <Col sm={6} className="d-none">
          <div className="page-title-box text-align-right">
            <Button
              type="button"
              color="secondary"
              onClick={() => routeHistory.goBack()}
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="4">
          <div className="ms-2 mb-3">
            <div className="CourseSelect d-flex align-items-center AddonsAdmin">
              <label className="mb-0">Selected Profile</label>
              <Select
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.package_id}
                options={packages}
                onChange={handlePackageSelect}
                value={selectedPackage}
                placeholder="Choose a Plan"
                className="basic-single"
                classNamePrefix="select"
              />
            </div>
          </div>
        </Col>

        <Col lg="12" className="DashboardCard mb-1">
          <AddonCards addons={includedAddonsList} type="included" />

          {notIncludedAddonsList && notIncludedAddonsList.length > 0 && (
            <AddonCards addons={notIncludedAddonsList} type="not_included" />
          )}
        </Col>
      </Row>

      <Modal
        isOpen={editModal}
        role="dialog"
        centered={true}
        className="exampleModal modal-lg UpdateInformationModal --"
        tabIndex="-1"
        toggle={() => {
          setEditModal(false);
        }}
      >
        <div className="modal-content">
          <ModalHeader className="text-transform-capitalize p-2 UpdateInformationUser">
            <div>Update Addon</div>
            <button
              onClick={() => setEditModal(false)}
              className="btn btn-link pull-right BtnCenterClose me-end"
            >
              <i className="fas fa-times"></i>
            </button>
          </ModalHeader>

          <form
            method="post"
            onSubmit={saveAddonDetail}
            encType="multipart/form-data"
            className="form-horizontal"
            id="selection-myform"
          >
            <ModalBody>
              <Row className="">
                <Col className="mb-1" md="12">
                  <div className="form-group mb-3">
                    <label>Title</label>
                    <input
                      name="title"
                      defaultValue={addonDetail.title}
                      className="form-control"
                      placeholder="Enter addon title"
                      required
                    />

                    <input
                      name="id"
                      value={addonDetail.id || ""}
                      defaultValue={addonDetail.id || ""}
                      type="hidden"
                    />
                  </div>
                </Col>

                <Col className="mb-1" md="12">
                  <div className="form-group mb-3">
                    <label>Description</label>
                    <textarea
                      name="description"
                      defaultValue={addonDetail.description}
                      className="form-control"
                      placeholder="Enter addon description"
                    />
                  </div>
                </Col>

                <Col className="mb-1" md="4">
                  <div className="form-group mb-3">
                    <label>Price</label>
                    <div className="group-addnon">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="validationTooltipUsernamePrepend"
                          >
                            {" "}
                            ${" "}
                          </span>
                        </div>
                        <input
                          name="price"
                          defaultValue={addonDetail.price}
                          className="form-control w-100"
                          placeholder="Enter addon price"
                          required
                          type={"number"}
                          step={"any"}
                          min={1}
                        />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="mb-1" md="4">
                  <div className="form-group mb-3">
                    <label>Interval</label>
                    <select
                      name="pricing_interval"
                      defaultValue={addonDetail.pricing_interval}
                      className="form-control "
                      required
                    >
                      <option value="Monthly">Monthly</option>
                      <option value="OneTime">One time</option>
                    </select>
                  </div>
                </Col>

                <Col className="mb-1" md="4">
                  <div className="form-group mb-3">
                    <label>Status</label>
                    <select
                      name="status"
                      defaultValue={addonDetail.status}
                      className="form-control "
                      required
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </Col>

                <Col className="mb-1" md="12">
                  <div className="form-group mb-3">
                    <label>Included by Default in Plan(s):</label>
                    <div className="ms-2 row checkbox-group">
                      {addonPackages.map((plan) => (
                        <div key={plan.package_id} className="col-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            // name="included_in_plans"
                            id={`package-${plan.package_id}`}
                            // value={includedPlans}
                            onClick={() =>
                              handleIncludedPackageChange(plan.package_id)
                            }
                            defaultChecked={includedPlans.includes(
                              plan.package_id
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`package-${plan.package_id}`}
                          >
                            {plan.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>

                <Col className="mb-1" md="12">
                  <div className="form-group mb-3">
                    <label>Available for Purchase in Plan(s):</label>
                    <div className="ms-2 row checkbox-group">
                      {addonPackages.map((plan) => (
                        <div key={plan.package_id} className="col-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            // name="available_for_plans"
                            id={`package-${plan.package_id}`}
                            // value={availablePlans}
                            onClick={() =>
                              handleAvailablePackageChange(plan.package_id)
                            }
                            defaultChecked={availablePlans.includes(
                              plan.package_id
                            )}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`package-${plan.package_id}`}
                          >
                            {plan.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="modal-footer d-flex justify-content-end">
              <div className="d-flex justify-content-end">
                <Button
                className="btn-sm"
                  color="secondary"
                  type="button"
                  onClick={() => {
                    setEditModal(false);
                    setIncludedPlans([]);
                    setAvailablePlans([]);
                  }}
                >
                  Cancel
                </Button>
                <Button className="ms-3 Green btn-sm" type="submit">
                  Submit
                </Button>
              </div>
            </ModalFooter>
          </form>
        </div>
      </Modal>
    </>
  );
}
