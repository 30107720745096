import React, { Component, Fragment } from "react";
// import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Tabs,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  CardText,
  Tablist,
  TabPanel,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import ProfileSettings from "../../Authentication/profile-sidebar";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
export default class EmailSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);

    this.state = {
      index: "",
      tab: "1",
      prevtab: "1",
      settingchangemodal: false,
      activeTabId: 0,
      id: obj.user_id,
      record: {},
      sendgrid: {},
      customstmp: {},
      gmail: {},
      amazon: {},

      page_name: this.props.match.params.id ? "Email " : "Email",
    };
  }
  componentDidMount() {
    this.getData();
    this.getcurrentsetingdata();
  }

  toggle(index) {
    this.setState({
      tab: this.state.prevtab,
      index: index,
      settingchangemodal: !this.state.settingchangemodal,
    });
  }
  changesetting() {
    this.setState({
      tab: this.state.index,
      prevtab: this.state.index,
      settingchangemodal: false,
    });
    this.updatesetting(this.state.index);
  }

  updatesetting = (id) => {
    fetch(`${ApiUrl}` + "update/user/emailsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/emailsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response, "Test");

        if (response.status === true) {
          this.setState({
            sendgrid: response.data.record.sendgrid,
            amazon: response.data.record.amazon,
            gmail: response.data.record.gmail,
            customstmp: response.data.record.custom,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getcurrentsetingdata = () => {
    fetch(`${ApiUrl}` + "get/user/emailsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            tab: response.data.record.email_setting,
            prevtab: response.data.record.email_setting,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitsendgrid = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/sendgrid", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          //toast.configure();
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmitamazon = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/amazon", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitgmail = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/gmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitcustom = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/customstmp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col lg={2} className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-12">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={this.state.tab == 1 ? "active" : ""}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      SendGrid
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={this.state.tab == 2 ? "active" : ""}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Amazon
                    </NavLink>
                  </NavItem> */}

                  {/* <NavItem>
                    <NavLink
                      className={this.state.tab == 4 ? "active" : ""}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      Custom STMP
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
              <TabContent activeTab={this.state.tab}>
                <TabPane tabId="1">
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitsendgrid}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <Row>
                              <Col lg={12}>
                              <h4> SendGrid Settings </h4>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  
                                  <AvField
                                    name="from_name"
                                    label="From Name"
                                    value={this.state.sendgrid.from_name}
                                    className="form-control"
                                    placeholder="From Name"
                                    required
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <AvField
                                    name="from_email"
                                    label="From Email"
                                    value={this.state.sendgrid.from_email}
                                    className="form-control"
                                    placeholder="From Email"
                                    required
                                  />
                                </div>
                              </Col>
                              <div className="mb-3">
                                <AvField
                                  name="sendgrid_api_key"
                                  type="password"
                                  label="SendGrid API Key"
                                  value={this.state.sendgrid.sendgrid_api_key}
                                  className="form-control"
                                  placeholder="SendGrid API Key"
                                  required
                                />
                              </div>
                              {/* <div className="mb-3">
                              <AvField
                                name="sendgrid_api"
                                label="SendGrid API"
                                value={this.state.sendgrid.sendgrid_api}
                                className="form-control"
                                placeholder="SendGrid API"
                                required
                              />
                            </div> */}
                              <div className="mb-3">
                                <AvField
                                  name="to_email"
                                  label="To Email"
                                  value={this.state.sendgrid.to_email}
                                  className="form-control"
                                  placeholder="To Email"
                                  required
                                />
                              </div>
                            </Row>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-sm"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light btn-sm"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tabId={"2"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitamazon}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <div className="mb-3">
                              <h4> Amazon Settings </h4>
                              <AvField
                                name="from_name"
                                label="From Name"
                                value={this.state.amazon.from_name}
                                className="form-control"
                                placeholder="From Name"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="from_email"
                                label="From Email"
                                value={this.state.amazon.from_email}
                                className="form-control"
                                placeholder="From Email"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="amazon_api_key"
                                label="Amazon API Key"
                                value={this.state.amazon.amazon_api_key}
                                className="form-control"
                                placeholder="Amazon API Key"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="amazon_secret_key"
                                label="Amazon Secret Key"
                                value={this.state.amazon.amazon_secret_key}
                                className="form-control"
                                placeholder="Amazon Secret Key"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="amazon_region"
                                label="Amazon Region"
                                value={this.state.amazon.amazon_region}
                                className="form-control"
                                placeholder="Amazon Region"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="to_email"
                                label="To Email"
                                value={this.state.amazon.to_email}
                                className="form-control"
                                placeholder="To Email"
                                required
                              />
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={"4"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitcustom}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody>
                            <div className="mb-3">
                              <h4> Custom STMP Settings </h4>
                              <AvField
                                name="server_name"
                                label="Server Name"
                                value={this.state.customstmp.server_name}
                                className="form-control"
                                placeholder="Server Name"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="server_port"
                                label="Server Port"
                                value={this.state.customstmp.server_port}
                                className="form-control"
                                placeholder="Server Port"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="server_protocol"
                                label="Server Protocol"
                                value={this.state.customstmp.server_protocol}
                                className="form-control"
                                placeholder="Server Protocol"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label> Authentication </label>
                              <AvRadioGroup
                                value={
                                  this.state.customstmp.server_authentication
                                }
                                inline
                                name="server_authentication"
                                required
                              >
                                <AvRadio label="Yes" value="yes" />
                                <AvRadio label="No" value="no" />
                              </AvRadioGroup>
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="user_email"
                                label="User Email/Name"
                                value={this.state.customstmp.user_email}
                                className="form-control"
                                placeholder="User Email"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="password"
                                label="Password"
                                value={this.state.customstmp.password}
                                className="form-control"
                                placeholder="Password"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="from_name"
                                label="From Name"
                                value={this.state.customstmp.from_name}
                                className="form-control"
                                placeholder="From Name"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="from_email"
                                label="From Email"
                                value={this.state.customstmp.from_email}
                                className="form-control"
                                placeholder="From Email"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="reply_to"
                                label="Reply To"
                                value={this.state.customstmp.reply_to}
                                className="form-control"
                                placeholder="Reply To"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="test_email"
                                label="Test Email"
                                value={this.state.customstmp.test_email}
                                className="form-control"
                                placeholder="Test Email"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="per_hour_limit"
                                label="Per Hour Limit"
                                value={this.state.customstmp.per_hour_limit}
                                className="form-control"
                                placeholder="Per Hour Limit"
                                required
                              />
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane> */}
              </TabContent>
              <Modal
                isOpen={this.state.settingchangemodal}
                fade={false}
                toggle={() => this.toggle()}
              >
                <ModalBody>
                  <div className="warning-box text-center">
                    <span className="warning">
                      <i className="mdi mdi-alert-circle"> </i>
                    </span>
                    <h3> Are you sure ? </h3>
                    <p> You want to change Email Setting ? </p>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="info"
                    onClick={() => this.changesetting(this.state.index)}
                  >
                    Yes, Change it
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => this.toggle(this.state.prevtab)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Row>
          </Col>
        </Row >
      </React.Fragment >
    );
  }
}
