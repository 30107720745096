import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import {
  bcrypt,
  dcrypt,
  uInfo,
  uToken,
  currentDate,
  NumberWithCommas,
  canPurchaseAddon,
} from "../useToken";
import { ApiUrl, AttachementsUrl } from "../config";
import { useHistory, Link } from "react-router-dom";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from "sweetalert";

const AddonPurchasePopup = ({ addonId, onClose }) => {
  const [addonData, setAddonData] = useState({});
  const routeHistory = useHistory();

  const getAddonDetail = () => {
    fetch(`${ApiUrl}addons/detail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id: addonId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setAddonData(data.data.record);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getAddonDetail();
  }, []);

  const createAddonInvoice = (addonData) => {
    // event.preventDefault();
    fetch(`${ApiUrl}` + "add/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        addon_id: addonData.id, 
        package_id: "0",
        product_id: "0",
        master_type: "addon",
        created_for_id: uInfo().user_id,
        invoice_currency_code: "USD",
        invoice_for: "Coach",
        items_data: [
          {
            items: addonData.title,
            price: addonData.price,
            quantity: "1",
            readOnly: "0",
            total: addonData.price,
          },
        ],
        payment_method: "stripe",
        renewal_interval:
          addonData.pricing_interval == "Monthly" ? "Monthly" : "None",
        start_date: currentDate(),
        total_price: addonData.price,
        type: addonData.pricing_interval == "Monthly" ? "recurring" : "one_time",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          routeHistory.push(data.data.payment_link);
        } else {
          swal({
            title: data.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  return (
    <Modal
      isOpen={true}
      fade={true}
      className="PopUpModal Purchese"
      centered={true}
      toggle={() => {
        onClose();
      }}
    >
      <ModalHeader className="text-white">{addonData.title} Addon</ModalHeader>
      <ModalBody>
        {/* <h5 className="text-white">{addonData.title}</h5> */}
        <p className="text-white">{addonData.description}</p>
        <h6 className="text-white mt-2">
          Price: ${addonData.price}{" "}
          {addonData.pricing_interval == "Monthly" ? "/month" : ""}
        </h6>
      </ModalBody>
      <ModalFooter>
        {canPurchaseAddon(addonData.id) ? (
          <p className="fw-light fst-italic text-white">
            To enable and get access to this feature you need to purchase this
            add-on.
          </p>
        ) : (
          <p className="fw-light fst-italic text-white">
            This add-on is not available for your current package. To get access
            to this feature you need to upgrade your package.
          </p>
        )}
        <Button color="secondary" type="button" onClick={() => onClose()}>
          Cancel
        </Button>
        {canPurchaseAddon(addonData.id) ? (
          // <Link className="" to={"/addons/purchase/" + bcrypt(addonData.id)}>
            <Button className="Green" type="button"
             onClick={(event) => {
              event.preventDefault();
              createAddonInvoice(addonData);
            }}
            >
              Purchase: ${addonData.price}
            </Button>
          // </Link>
        ) : (
          <Button className="btn btn-secondary" type="button" disabled>
            Purchase: ${addonData.price}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default AddonPurchasePopup;
