import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { bcrypt, uInfo, uToken, isAllowed } from "../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import { toast } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ReactDatatable from "@ashvin27/react-datatable";
import 'suneditor/dist/css/suneditor.min.css';
export default class ManageCmsBlogs extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Id#",
                key: "id",
                cell: (record, index) => {
                    return index + 1;
                }
            },
            {
                text: "Image",
                key: "image",
                cell: (record, index) => {
                    const imageUrl = `${AttachementsUrl}/user_${record.user_id}/posts/${record.image}`;

                    return (
                        <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                            <img
                                src={imageUrl}
                                alt="Post Image"
                                className="img img-thumbnail"
                                style={{ width: 50, height: 50 }}
                            />
                        </a>
                    )
                }
            },
            {
                text: "Title",
                key: "title",
            },
            {
                text: "Category",
                key: "category_name",
            },
            {
                text: "Status",
                key: "status",
                cell: (record, index) => {
                    if (record.status == 'Publish') {
                        return <p key={index} className="badge bg-success">{record.status}</p>
                    } else if (record.status == 'Unpublish') {
                        return <p key={index} className="badge bg-danger">{record.status}</p>
                    } else {
                        return <p key={index}>{record.status}</p>
                    }
                }
            },
            {
                text: "Created Date",
                key: "created_at",
                className: 'CreateDated'
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {isAllowed(uInfo(), ["can_edit"]) && (
                                <button onClick={() => this.props.history.push('/settings/edit/' + bcrypt(record.id))} className="btn btn-sm btn-secondary mr-5">
                                    <i className="mdi mdi-pencil"></i>
                                </button>
                            )}

                            <button onClick={() => { this.removeProduct(record.id) }} className="btn btn-sm btn-danger">
                                <i className="mdi mdi-trash-can-outline"></i>
                            </button>

                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            categories: [],
            loader: true,
            page_name: 'Blog listings',
            modal: false,
            modal2: false,
            row: {
                id: '',
                title: '',
                category_id: '',
                image: 0
            }
        }
    }


    componentDidMount() {
        this.getData();
        this.fetchAllCategories();
    }

    fetchAllCategories = () => {
        fetch(`${ApiUrl}get/cms/all/categories`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({ search: '' }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        categories: response.data.categories
                    });
                } else {
                    toast.error(response.message, { hideProgressBar: true, position: "top-center", transition: "flip", className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getData = () => {
        fetch(`${ApiUrl}` + 'get/cms/posts', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        records: response.data.records,
                    });

                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    removeProduct = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/post", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();

                                // toast.success(data.message, {
                                //   theme: "colored",
                                //   autoClose: ToastAutoClose,
                                // });
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box" style={{ display: 'flex', gap: '8px', float: 'inline-end' }}>
                                    <Link
                                        type="button"
                                        color="info"
                                        className="btn btn-info btn-sm"
                                        to={"/settings/add/blogs"}
                                        onClick={
                                            () => this.setState({
                                                modal: true, row: {
                                                    id: '',
                                                    title: '',
                                                    category_id: '',
                                                    image: 0
                                                }
                                            })}
                                    ><i className="mdi mdi-plus"> </i>
                                        Create Blog
                                    </Link>
                                    <Link
                                        type="button"
                                        color="info"
                                        className="btn btn-info btn-sm"
                                        to={"/settings/proglobal/tags"}

                                    >
                                        <i class="mdi mdi-format-list-bulleted"></i>
                                        Tag List
                                    </Link>
                                    <Link
                                        type="button"
                                        color="info"
                                        className="btn btn-info btn-sm"
                                        to={"/settings/categories"}

                                    >
                                        <i class="mdi mdi-format-list-bulleted"></i>
                                        Category List
                                    </Link>

                                </div>


                            </Col>

                        </Row>



                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}