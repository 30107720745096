import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    getPages,
    getPermission,
    getRoles,
    hasRole,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";

import Create from "./create.js";
import Update from "./update.js";
import swal from 'sweetalert';

export default class AssessmentList extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Assessment#",
                key: "assessment_id",
            },

            {
                text: "Client",
                key: "client_name",
                cell: (record, index) => {
                    return (
                        <Fragment>

                            <Link
                                to={
                                    "/client/dialer/" +
                                    bcrypt(record.client_id) +
                                    "/" +
                                    bcrypt(record.coach_id)
                                }
                            >
                                {record.client_name}

                            </Link>
                        </Fragment>
                    );
                }
            },

            {
                text: "Name",
                key: "name",
                // cell: (record, index) => {
                //     return (
                //         <Fragment>

                //             <Link
                //                 to={'#'}
                //             // to={
                //             //     "/assessment/" +
                //             //     bcrypt(record.assessment_id) +
                //             //     "/summary"
                //             // }
                //             >
                               

                //             </Link>
                //         </Fragment>
                //     );
                // }
            },
            {
                text: "Currency Code",
                key: "currency_code",
            },
            {
                text: "Module",
                key: "module",
                cell: (record, index) => {
                    return (
                        // record.module == '1' ?
                        //     'Jumpstart 12 - To close a sale ' :
                        //     record.module == '2' ?
                        //         'Jumpstart 40 - To close a sale or perform a faster full assessment' :
                        //         record.module == '3'
                        //             ? 'Deep Dive 40 - To perform a full assessment' :
                        //             record.module == '4' ?
                        //                 'Jumpstart - To close a sale with a digital marketing focus' :
                        record.module == '6' ?
                            'Pathway-to-Profit' : ''
                    );
                }
            },
            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {
                                this.state.type == 'all' ? (
                                    <>
                                    {hasRole(uInfo(), ["assessment_calculator"]) && (
                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-link btn-sm mb-1 mr-5"
                                            to={
                                                "/assessment/" + bcrypt(record.assessment_id) + "/summary"
                                            }
                                            title="Assessment Calculator"
                                        >
                                            <i className="fas fa-calculator"></i>
                                        </Link>
                                    )}
                                    {hasRole(uInfo(), ["profit_center"]) && (
                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-link btn-sm mb-1 mr-5"
                                            to={
                                                "/progress/tracker/" + bcrypt(record.assessment_id) + "/"+record.running_category
                                            }
                                            title="Profit Center"
                                        >
                                            <i className="far fa-money-bill-alt"></i>
                                        </Link>
                                    )}
                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-link btn-sm mb-1 mr-5"
                                            onClick={() => { this.setState({ show2Slider: true, page2Class: 'slide-in', editId: record.assessment_id }) }}
                                            title="Edit Assessment"
                                        >
                                            <i className="fas fa-edit"></i>
                                        </Link>

                                        <button
                                            className="btn btn-link btn-sm mb-1"
                                            onClick={this.deleteRecord.bind(this, record, index)}
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </>

                                )
                                    : this.state.type == 'center' ? (
                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-link btn-sm mb-1 mr-5"
                                            to={
                                                "/profit/center/" + bcrypt(record.assessment_id) + "/summary"
                                            }
                                            title="Profit Center"
                                        >
                                            <i className="far fa-money-bill-alt"></i>
                                        </Link>

                                    ) : (

                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-link btn-sm mb-1 mr-5"
                                            to={
                                                "/progress/tracker/" + bcrypt(record.assessment_id) + "/scheduling_freedom"
                                            }
                                            title="Progress Tracker"
                                        >
                                            <i className="fas fa-tasks"></i>
                                        </Link>

                                    )
                            }

                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Assessments",
            customDate: true,
            ClientSubcribeModal: false,
            page2Class: '',
            editId: '',
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
            type: props.match.params ? props.match.params.type : 'all'
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "assessments" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/assessment", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.assessment_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                //               toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };

    cancelSlider = async () => {
        this.getData("", "");
        this.setState({
            page2Class: 'slide-out2',
            editId: ''
            // showSlider: false,
        })
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        {/* <div className="page-title-box text-align-right">
                            {hasRole(uInfo(), ["Import Leads"]) && (
                                <Link to={"import/lead"} className="btn  btn-success">
                                    Import
                                </Link>
                            )}
                        </div> */}
                    </Col>
                    <Col sm={1}>
                        <div className="page-title-box text-align-right">
                            {isAllowed(uInfo(), ["can_create"]) && (
                                <button
                                    type="button"
                                    color="info"
                                    className="btn btn-info btn-sm"
                                    onClick={() => { this.setState({ showSlider: true, page2Class: 'slide-in' }) }}
                                >
                                    <i className="mdi mdi-plus"> </i>
                                    Create
                                </button>
                            )}
                        </div>
                    </Col>
                </Row>

                {
                    this.state.showSlider && this.state.editId == '' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div className="">
                                <div className="modal-head top-heading">
                                    <h2>Add Assessment</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2', editId: '' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <Create from={true} cancelSlider={this.cancelSlider} />
                            </div>
                        </div>
                    )
                }

                {
                    this.state.show2Slider && this.state.editId != '' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Edit Assessment</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2', editId: '' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <Update from={true} cancelSlider={this.cancelSlider} assessment_id={this.state.editId} />
                            </div>
                        </div>
                    )
                }
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody className="py-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        {/* <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="module"
                                                    label="Module"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="select">
                                                    <option value="">Select Module Set</option>
                                                    <optgroup label="Profit Acceleration">
                                                        <option value="1">Jumpstart 12 - To close a sale</option>
                                                        <option value="2">Jumpstart 40 - To close a sale or perform a faster full assessment</option>
                                                        <option value="3">Deep Dive 40 - To perform a full assessment</option>
                                                    </optgroup>
                                                    <optgroup label="Digital Acceleration">
                                                        <option value="4">Jumpstart - To close a sale with a digital marketing focus</option>
                                                        <option value="5">Deep Dive - To perform a full assessment with a digital marketing focus</option>
                                                    </optgroup>
                                                </AvField>

                                            </div>
                                        </Col> */}
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="date_range"
                                                    label="Date Filter"
                                                    onChange={(e) =>
                                                        e.target.value == "Custom Date"
                                                            ? this.setState({
                                                                customDate: false,
                                                            })
                                                            : this.setState({
                                                                customDate: true,
                                                            })
                                                    }
                                                >
                                                    <option value=""> All Time </option>
                                                    <option value="Current Week"> Current Week </option>
                                                    <option value="Last Week"> Last Week </option>
                                                    <option value="Current Month"> Current Month </option>
                                                    <option value="Last Month"> Last Month </option>
                                                    <option value="Last 3 Months"> Last 3 Months </option>
                                                    <option value="Last 6 Months"> Last 6 Months </option>
                                                    <option value="Current Year"> Current Year </option>
                                                    <option value="Last Year"> Last Year </option>
                                                    <option value="Custom Date"> Custom Date </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="start_date"
                                                    label="Start date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="end_date"
                                                    label="End date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <label className=""> {"Action"}</label>
                                                <div className="button-items">
                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="pt-0">
                                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
