import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import ProfileSettings from "./profile-sidebar";

import { uInfo, uToken, currentDateTime,convertPhoneFormat, hasRole } from "../../useToken";
import { ApiUrl, AttachementsUrl, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
const UserConstraintSetting = (props) => {
  const [tab, setTab] = useState("1");
  const [numbers, setNumber] = useState([]);
  const [holidays, setHolidays] = useState({});
  const [constraintSettings, setConstraintSettings] = useState({});
  const [activeTab, setActiveTab] = useState("0");
  const [recordSettings, setRecordSettings] = useState({});
  const [userTimeZone , setUserTimeZone] = useState();


  useEffect(() => {
    getPhoneNumbers();

  }, []);

  function toggle(index) {
    setTab(index);
  }

  const getPhoneNumbers = () => {
    fetch(`${ApiUrl}` + "user/form/data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.phones, 'phones')
          console.log(response.data.time_zone, 'timezone')
          setNumber(response.data.phones);
          setConstraintSettings(response.data.constraint_settings);
          setHolidays(response.data.holidays);
          setUserTimeZone(response.data.time_zone);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getRecordingSetting = () => {
    fetch(`${ApiUrl}` + "get/user/recording/setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'meta_key': 'recording_setting' }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecordSettings(response.data.record);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleSubmitConstraint = async (event, values) => {
    if (values.holidays && values.skip_weak_days) {
      values.holidays = values.holidays.toString();
      values.skip_weak_days = values.skip_weak_days.toString();
    }

    fetch(`${ApiUrl}` + "user/constraint/setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmitRecordSetting = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    // if(recordSettings.meta_value?.enable == 'on'){
    //   submitData.append('enable', 'on');
    // }else{
    //   submitData.append('enable', 'off');
    // }

    // submitData.append('meta_key', 'recording_setting');

    fetch(`${ApiUrl}` + "update/recording/setting", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          swal({
            title: response.message,
            icon: "success",
          });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Global Settings</title>
      </Helmet>
      <Row>
        <Col lg="2" className="pl-0">
          <ProfileSettings menuOpen={false} props={props} />
        </Col>

        <Col lg={10}>
          <Row>
            <Col sm={12}>
              <div className="page-title-box">
                <h4> Global  Settings</h4>
                <ol className="breadcrumb m-0">
                  <li key={0} className="breadcrumb-item active">
                    Settings
                  </li>
                  <li key={1} className="breadcrumb-item">
                    <Link to="#"> Global Setting</Link>
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">

              <Card className="mt-0 mb-2">
                <CardBody className="py-0">

                  <Nav tabs className="py-0">
                  {hasRole(uInfo(), ["constraints_settings"]) && (
                    <NavItem>
                      <NavLink
                        className={activeTab == 0 ? "active" : ""}
                        onClick={() => { setActiveTab("0") }}
                      >
                        Constraint Setting(s)
                      </NavLink>
                    </NavItem>
                  )}
                  {hasRole(uInfo(), ["call_recording"]) && (
                    <NavItem>
                      <NavLink
                        className={activeTab == 1 ? "active" : ""}
                        onClick={() => { setActiveTab("1"); getRecordingSetting() }}
                      >
                        ON/OFF Call Recording
                      </NavLink>
                    </NavItem>
                  )}
                  </Nav>
                </CardBody>
              </Card>

              <TabContent className="p-0" activeTab={activeTab}>
                <TabPane tabId={"0"}>
                  <AvForm
                    onValidSubmit={handleSubmitConstraint}
                    className="needs-validation"
                  >
                    <ModalBody className="row pt-0">
                      <p> Current Date / Time: {currentDateTime()} </p>
                      <div className="col-md-8 pl-25 mb-3">
                        <div className="row time-constraint">
                          <div className="col-md-12 mb-3">
                            <AvField
                              name="block_days"
                              value={constraintSettings.block_days}
                              label="Block Days"
                              className="form-control"
                              placeholder="For Example: 2022-04-16,2022-04-17"
                            />
                            <div className="note">
                              Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <AvField
                              name="from_time"
                              value={constraintSettings.from_time}
                              label="From Time"
                              className="form-control"
                              required
                              type="time"
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <AvField
                              name="to_time"
                              value={constraintSettings.to_time}
                              label="To Time"
                              className="form-control"
                              required
                              type="time"
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              name="skip_weak_days"
                              label="Skip Week Days"
                              multiple
                              value={constraintSettings.skip_weak_days}
                            >
                              <option value="Monday"> Monday </option>
                              <option value="Tuesday"> Tuesday </option>
                              <option value="Wednesday"> Wednesday </option>
                              <option value="Thursday"> Thursday </option>
                              <option value="Friday"> Friday </option>
                              <option value="Saturday"> Saturday </option>
                              <option value="Sunday"> Sunday </option>
                            </AvField>
                          </div>
                          {userTimeZone?.includes('America') ? (
                            <div className="col-md-6 mb-3">
                              <AvField
                                className="select form-control"
                                type="select"
                                value={constraintSettings.holidays}
                                name="holidays"
                                label="USA Holidays"
                                multiple
                              >
                                {Object.entries(holidays).map(([key, value]) => {
                                  return <option key={key} value={key}>{value}</option>;
                                })}
                              </AvField>
                            </div>
                          ) : ''}
                          
                         
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <button
                        className="btn btn-primary waves-effect waves-light btn-sm"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </AvForm>
                </TabPane>
                <TabPane tabId={"1"} className="card-body pt-0">
            
                  <form method="post" onSubmit={handleSubmitRecordSetting}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                    <div className="border py-3 px-3 mt-3">
                    
                      {
                        numbers.map((row, i) => {
                            return ( 
                            <div className="d-flex space-between my-3 px-3">
                              <div className="email">
                                <div className="d-flex align-items-center">
                                <h5 className="mb-0">{convertPhoneFormat(row.phone_number)}</h5>
                                  <div className="toggle form-check form-switch form-switch-lg ml-3">
                                    { recordSettings.meta_value && (
                                          <input
                                          type="checkbox"
                                          className="form-check-input"
                                          name={row.phone_number}
                                          value={row.phone_number}
                                          // onChange={(e) => {
                                          // console.log(e.target.value , e.target.checked ,'---');
                                          // let check = e.target.checked ? 'on' : 'off';
                                          // setRecordSettings({
                                          //     ...setRecordSettings,
                                          //     meta_value: {
                                          //       ...setRecordSettings.meta_value,
                                                
                                          //     }
                                          //   });
                                          // }}
                                          defaultChecked={recordSettings.meta_value?.includes(row.phone_number) ? true :  false}
                                          />
                                    )}
                                     
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                      
                      <button
                        className="btn btn-primary waves-effect waves-light mt-4 btn-sm"
                        type="submit"
                        style={{ float: "left" }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserConstraintSetting;
