import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    ModalBody,
    ModalFooter,
    CardBody,
} from "reactstrap";

import {
    dcrypt,
    bcrypt,
    uToken,
    isValidJSONString,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

import $ from "jquery"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";

export default class FilterAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_name: "Add Filter",
            customFields: [],
            metaData: {
                coachPartners: [],
                groups: [],
                tags: [],
                tokens: { default_fields: [], custom_fields: [] }
            },
            loader: true
        };
    }

    findById(array, type) {
        let exist = false;
        for (const item of array) {
            if (item.selected_fileds === type) return exist = true;
        }
        return exist;
    }


    addClick(type) {
        let exist = this.findById(this.state.customFields, type);

        if (!exist) {
            this.setState(prevState => ({
                customFields: [...prevState.customFields, { [`label`]: "Start", flowId: "", [`key`]: "Start", selected_fileds: type }]
            }))
        }
    }

    createUI() {
        return this.state.customFields.map((el, i) => (
            <div className={`border py-3 px-2 ${el.key ? el.key : 'd-none'}`} key={i} id={`element_${i}`}>
                <div className="row">
                    <div className="col-md-1 mb-3">
                        <div className="form-group">
                            <label className="visibilty-hidden">Group:</label>

                            <input placeholder="Enter label" name={`filters[${i}][key]`} required="" id="label" type="text"
                                className="form-control" value={`${i == 0 ? 'Start' : 'Next'}`} readOnly />

                            <input type={'hidden'} name={`filters[${i}][selected_fileds]`} value={this.state.customFields[i].selected_fileds} />
                        </div>
                    </div>

                    {
                        i != 0 && (
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label className="visibilty-hidden">Group:</label>
                                    <select className="select form-control"
                                        type="select"
                                        // name={`flowId`}
                                        name={`filters[${i}][operator]`}
                                        defaultValue={this.state.customFields[i].operator || ''}
                                        required
                                    >
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>

                                    </select>
                                </div>
                            </div>
                        )
                    }

                    {this.addContent(i, this.state.customFields[i].selected_fileds)}

                    {
                        i != 0 && (
                            <div className="col-md-1" >
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={this.removeClick.bind(this, i)}>
                                    <i className="ion ion-md-trash"> </i>
                                </button>
                            </div>
                        )
                    }

                </div>
            </div>
        ))
    }
    

    handleChange(i, e) {
        const { name, value } = e.target;
        let customFields = [...this.state.customFields];
        customFields[i] = { ...customFields[i], [name]: value };
        customFields[i] = { ...customFields[i], [`flowId`]: value };
        this.setState({ customFields });
    }


    addContent = (i, type) => {
        if (type == 'Group') {
            return (
                <>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>Type:</label>
                            <select defaultValue={this.state.customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                <option value="with">With Specified Group</option>
                                <option value="without">Without Specified Group</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <label>Group:</label>

                        <Select
                            getOptionLabel={option => option.title}
                            getOptionValue={option => option.group_id}
                            isMulti={true}
                            value={this.state.customFields[i].group_id}
                            options={this.state.metaData.groups}
                            classNamePrefix="select2-selection"
                            name={`filters[${i}][group_id][]`}
                        />

                        {/* <select required defaultValue={this.state.customFields[i].group_id} name={`filters[${i}][group_id]`} className="form-control">
                            <option value="">Select Group</option>
                            {
                                this.state.metaData.groups.map((row, i) => {
                                    return <option key={'group_' + i} value={row.group_id}>{row.title}</option>
                                })
                            }

                        </select> */}
                    </div>
                </>
            )
        } else if (type == 'Tag') {
            return (
                <>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>Type:</label>
                            <select defaultValue={this.state.customFields[i].field_type} name={`filters[${i}][field_type]`} className="form-control valid">
                                <option value="with">With Specified Tag</option>
                                <option value="without">Without Specified Tag</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <label>Tag:</label>
                        <select required defaultValue={this.state.customFields[i].tag_id} name={`filters[${i}][tag_id]`} className="form-control">
                            <option value="">Select Tag</option>
                            {
                                this.state.metaData.tags.map((row, i) => {
                                    return <option key={'tag' + i} value={row.group_id}>{row.title}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'having') {
            return (
                <>
                    <div className="col-md-3" >
                        <label>Contact Having:</label>
                        <select required defaultValue={this.state.customFields[i].having} name={`filters[${i}][having]`} className="form-control">
                            <option value="1">Select Option</option>
                            <option value="2">Just Phone , No Email</option>
                            <option value="3">Just Email , No Phone</option>
                            <option value="4">Both Email And Phone</option>
                            <option value="5">No Email , No Phone</option>
                            <option value="6">Just Mobile , No Phone</option>
                            <option value="7">Just Phone , No Mobile</option>
                            <option value="8">Both Phone and Mobile</option>
                        </select>
                    </div>
                </>
            )
        } else if (type == 'custom_fields') {
            return (
                <>
                    <div className="col-md-3" >
                        <label>Custom Fields:</label>
                        <select defaultValue={this.state.customFields[i].custom_fields} name={`filters[${i}][custom_fields]`} className="form-control">
                            {
                                this.state.metaData.tokens.custom_fields.map((row, i) => {
                                    return <option key={'custom_fields_' + i} value={row.key}>{row.label}</option>
                                })
                            }

                        </select>
                    </div>

                    <div className="col-md-3" >
                        <label>Value:</label>
                        <input required defaultValue={this.state.customFields[i].custom_fields_value} name={`filters[${i}][custom_fields_value]`} className="form-control" />

                    </div>
                </>
            )
        } else if (type == 'specified_text') {
            return (
                <>
                    <div className="col-md-6" >
                        <label>Specified Text:</label>
                        <input required defaultValue={this.state.customFields[i].specified_text} name={`filters[${i}][specified_text]`} className="form-control" />

                    </div>
                </>
            )
        } else if (type == 'added_by') {
            return (
                <>
                    <div className="col-md-3" >
                        <label>Added by:</label>

                        <Select
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.user_id}
                            isMulti={true}
                            value={this.state.customFields[i].added_by}
                            options={this.state.metaData.coachPartners}
                            classNamePrefix="select2-selection"
                            name={`filters[${i}][added_by][]`}
                        />

                        {/* <select defaultValue={this.state.customFields[i].added_by} name={`filters[${i}][added_by]`} className="form-control">
                            {
                                this.state.metaData.coachPartners.map((row, i) => {
                                    return <option key={'added_' + i} value={row.user_id}>{row.name}</option>
                                })
                            }

                        </select> */}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="col-md-3" >
                        <label>{this.state.customFields[i].selected_fileds}:</label>
                        <select required defaultValue={this.state.customFields[i].operation} name={`filters[${i}][operation]`} className="form-control">
                            <option value="eq">Equal</option>
                            <option value="neq">Not Equal</option>
                            <option value="sw">Start With</option>
                            <option value="ew">End With</option>
                            <option value="con">Containing</option>
                        </select>
                    </div>

                    <div className="col-md-3" >
                        <label className="visibilty-hidden">Specified Text:</label>
                        <input required  name={`filters[${i}][value]`} className="form-control" />

                    </div>
                </>
            )
        }
    }

    removeClick(i) {
        let customFields = [...this.state.customFields];
        customFields[i] = {};
        $('#element_' + i).html('');
        this.setState({ customFields });
    }

    componentDidMount() {
        this.getFormData();
        // this.getIvrMenu();
        // this.state.id != null && this.getData();
    }


    handleSubmit = async (event) => {
        this.setState({
            loader: true
        })

        event.preventDefault();
        const formData = new FormData(event.currentTarget);


        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/filter", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {

                    this.props.history.push('/filter/list');
                  
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    getFormData = () => {
        fetch(`${ApiUrl}` + "get/filter/meta/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: this.state.id,
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        metaData: response.data,
                        loader: false
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    OptionChange = () => {
        var options = document.getElementById('contact_fields').selectedOptions;
        var values = Array.from(options).map(({ value }) => value);

        values.forEach(element => {
            this.addClick(element)
        });
    }


    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.push('/filter/list')}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <form encType="multipart/form-data" className="form-horizontal" method="POST" onSubmit={this.handleSubmit}>

                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-2">

                                                <select style={{ minHeight: 300 }} className="form-control valid" id="contact_fields" name="contact_fields[]" multiple="multiple" onChange={(e) => this.OptionChange()}>
                                                    <optgroup label="Custom Filter">
                                                        <option value="Group">Group</option>
                                                        <option value="Tag">Tag</option>
                                                        <option value="having">Having Contact</option>
                                                        {/* <option value="specified_text">Specified Text</option>
                                                        <option value="custom_fields">Custom Fields</option>
                                                        <option value="added_by">Added By</option> */}
                                                    </optgroup>
                                                    <optgroup label="Filter by Attribute">
                                                        {this.state.metaData.tokens.default_fields.map((row, i) => {
                                                            return <option key={i} value={row.key}>{row.label}</option>
                                                        })}
                                                    </optgroup>
                                                </select>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="form-group mb-3">
                                                    <label>Filter Name:</label>
                                                    <input required type={'text'} className={'form-control'} name={'filter_name'} />
                                                </div>
                                                {this.createUI()}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 offset-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-info mt-4 btn-sm"
                                                    disabled={this.state.loader || this.state.customFields.length < 1}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>

                                    </CardBody>
                                </Card>
                            </form>
                        </Card>
                    </Col>
                </Row >
            </React.Fragment >
        );
    }
}
