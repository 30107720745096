import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ProfileSettings from "../../Authentication/profile-sidebar";
import "react-toastify/dist/ReactToastify.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  uToken,
 uInfo, 
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose, AttachementsUrl } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import UploadJsonFile from "../../../components/Common/UploadJsonFile";
import Loader from "react-loader";
import swal from 'sweetalert';
export default class TwillioSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      tab: "1",
      id: uInfo().user_id,
      record: {},
      recordrvm: {},
      record_fb: {},
      record_ol: {},
      page_name: this.props.match.params.id ? "Integrations" : "Integrations",
      files: "",
      loader: true,
      googleData: [],

    };
  }
  toggle(index) {
    this.setState({
      tab: index,
    });
  }
  componentDidMount() {
    this.getData();
    this.getDataRvm();
    this.getDataGoogleApp();
    this.getDataFacebook();
    this.getDataOutlook();
  }

  getDataGoogleApp = () => {
    fetch(`${ApiUrl}` + "get/googleapp/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            googleData: response.data.record,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getDataOutlook = () => {
    fetch(`${ApiUrl}` + "get/edit/integration/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id, type: "outlook-app"  }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record_ol: response.data.record,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getDataFacebook = () => {
    fetch(`${ApiUrl}` + "get/edit/integration/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id, type: "facebook-app" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record_fb: response.data.record,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitAction = async (event, values) => {
    // if (this.state.record_ol.id) {
    //   values.id = this.state.record_ol.id ? this.state.record.record_ol : -1;
    // }
    fetch(`${ApiUrl}` + "edit/integrationSetting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          if(values.type =='facebook-app'){
            this.getDataOutlook();
          }
          if(values.type =='outlook-app'){
            this.getDataFacebook();
          }
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
 
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/twilliosettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getDataRvm = () => {
    console.log(this.state.recordrvm, 'recordrvm11')
    fetch(`${ApiUrl}` + "get/edit/rvmsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.recordrvm.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            recordrvm: response.data.record,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmitrvm = async (event, values) => {
    console.log(this.state.recordrvm.id, 'ssss');
    if (this.state.recordrvm.id) {
      values.id = this.state.recordrvm.id;
    }
    fetch(`${ApiUrl}` + "edit/rvm_setting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data, 'responsedata')
          // this.setState({
          //   recordrvm: response.data,
          // });
          this.getDataRvm();
          //toast.configure();
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleSubmittwilio = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/twilio", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          //toast.configure();
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleChange = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0]);
    fileReader.onload = e => {
      console.log("e.target.result", e.target.result);
    };
    this.setState({
      files: e.target.files[0]
    })
  };
  handleSubmitGoogleApp = async (event, values) => {
    this.setState({
      loader: false
    });

    const data = new FormData();
    data.append("id", this.state.record.id ? this.state.record.id : -1);
    data.append("files", this.state.files);
    data.append("header", true);
    fetch(`${ApiUrl}` + "add/googleAppSettings", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.getDataGoogleApp();
          // this.getData();
          //toast.configure();
          this.setState({ loader: true });
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ loader: true });
      });
  };
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-12">
                <Card>
                  <CardBody className="py-0">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 1 ? "active" : ""}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Twilio
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 2 ? "active" : ""}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          RVM
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 3 ? "active" : ""}
                          onClick={() => {
                            this.toggle("3");
                          }}
                        >
                          Google App
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 4 ? "active" : ""}
                          onClick={() => {
                            this.toggle("4");
                          }}
                        >
                         Microsoft Outlook App
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={this.state.tab == 5 ? "active" : ""}
                          onClick={() => {
                            this.toggle("5");
                          }}
                        >
                          Facebook App
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </Card>
              </Col>
              <TabContent activeTab={this.state.tab}>
                <TabPane tabId="1">
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmittwilio}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody className="pt-0">
                            <div className="mb-3">
                              <h4>Twilio Credentials</h4>
                              <AvField
                                name="twilio_sid"
                                type="password"
                                label="Twilio SID"
                                placeholder="*********"
                                className="form-control"
                                value={this.state.record.twilio_sid}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="twilio_auth_token"
                                type="password"
                                label="Twilio Auth Token"
                                value={this.state.record.twilio_auth_token}
                                className="form-control"
                                placeholder="Twilio Auth Token"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="profile_sid"
                                type="password"
                                label="Primary Customer Profile SID"
                                value={this.state.record.profile_sid}
                                className="form-control"
                                placeholder="Primary Customer Profile SID"
                                required
                              />
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-sm"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light btn-sm"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={"2"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitrvm}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody className="pt-0">
                            <div className="mb-3">
                              <h4>RVM(Slybroadcast) Credentials</h4>
                              <AvField
                                name="rvm_user_name"
                                label="Email Address"
                                value={this.state.recordrvm.rvm_user_name}
                                className="form-control"
                                placeholder="Slybroadcast Account Email Address"
                            
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="rvm_password"
                                label="Password"
                                type="password"
                                value={this.state.recordrvm.rvm_password}
                                className="form-control"
                                placeholder="Slybroadcast Account Password"
                           
                              />
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-sm"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light btn-sm"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId={"3"}>
                  <Row>
                    <Col className="col-md-7">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitGoogleApp}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <ModalBody className="pt-0">
                            <div className="mb-3">
                              <h4>OAuth 2.0 Client IDs</h4>
                              <div className="mb-3 upload-file">
                                <label className="control-label">
                                  Upload Calendar Client
                                </label>
                                <input className="form-control" type="file" onChange={this.handleChange} />
                                <note>Note: Please upload Calendar Client JSON file that you have downloaded from google console.</note>
                              </div>
                            </div>

                          </ModalBody>
                          <ModalFooter className="pb-0">
                            <Button
                              type="button"
                              color="secondary"
                              className="btn-sm"
                              onClick={() => this.props.history.goBack()}
                            >
                              Cancel
                            </Button>
                            <button
                              className="btn btn-primary waves-effect waves-light btn-sm"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                    <Col className="col-md-5">
                      <Card>
                        <CardBody>
                          <h6>Help</h6>
                          <p>If you have not setup your google project, Please follow the steps mentioned in below guide file to ensure your google project has been created & running:</p>
                          Download <a href={AttachementsUrl + '/default/google-app.docx'} download >Help Guide</a>
                        </CardBody>
                      </Card>

                    </Col>

                    <Col className="col-md-12">
                      <Card>
                        <CardBody className="pt-0">
                          <h5>Configured Apps</h5>
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Project Id	</th>
                                <th>ClientId	</th>
                                <th>Accounts Used / Accounts Limit	</th>
                                <th>Status</th>
                                <th>Dated</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.googleData.map((row, i) => {
                                  return (
                                    <tr>
                                      <td>{row.id}</td>
                                      <td>{row.web_settings.project_id}</td>
                                      <td>{row.web_settings.client_id}</td>
                                      <td>{row.count} / 100</td>
                                      <td>{row.status  == 'Active' ? <p className="badge bg-success">{row.status}</p> : row.status == 'Inactive' ? <p className="badge bg-danger">{ row.status}</p> : <p>{ row.status}</p>}</td>
                                      <td style={{whiteSpace: 'nowrap'}}>{row.created_at}</td>

                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"4"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitAction}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                           <AvField
                            name="type"
                            value={"outlook-app"}
                            type={"hidden"}
                          />
                          <ModalBody className="pt-0">
                            <div className="mb-3">
                              <h4>Microsoft Outlook App Settings</h4>
                              <AvField
                                name="client_id"
                                label="Application (client) ID"
                                value={this.state.record_ol.client_id}
                                className="form-control"
                                placeholder="Client Id"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="tenant_id"
                                label="Tenant ID"
                                value={this.state.record_ol.tenant_id}
                                className="form-control"
                                placeholder="Tenant Id"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="client_secret"
                                label="Application (client) Secret"
                                type="password"
                                value={this.state.record_ol.client_secret}
                                className="form-control"
                                placeholder="Application (client) Secret"
                                required
                              />
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <button
                              className="btn btn-primary waves-effect waves-light btn-sm"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={"5"}>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <AvForm
                          onValidSubmit={this.handleSubmitAction}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <AvField
                            name="type"
                            value={"facebook-app"}
                            type={"hidden"}
                          />
                          <ModalBody className="pt-0">
                            <div className="mb-3">
                              <h4>Facebook App Settings</h4>
                              <AvField
                                name="app_id"
                                label="App ID"
                                value={this.state.record_fb.app_id}
                                className="form-control"
                                placeholder="Enter Facebook App ID"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="secret_key"
                                label="App Secret Key"
                                type="password"
                                value={this.state.record_fb.secret_key}
                                className="form-control"
                                placeholder="Enter App Secret Key"
                                required
                              />
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <button
                              className="btn btn-primary waves-effect waves-light btn-sm"
                              type="submit"
                            >
                              Submit
                            </button>
                          </ModalFooter>
                        </AvForm>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
