import { useState } from "react";

export const logEvent = (name) => (event) => {
  console.log(`[${name}]`, event);
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const convertPhoneWithCountryCodeFormat = (code, phone) => {
  let formattedPhoneNumber = phone;

  if (/^\d{10}$/.test(phone)) {
    // Regex to check if input is 10 digits
    return (formattedPhoneNumber = `${code} (${phone.slice(
      0,
      3
    )}) ${phone.slice(3, 6)}-${phone.slice(6)}`);
  } else {
    return code + "" + formattedPhoneNumber;
  }
};

export const convertPhoneFormat = (phone) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phone;
};

export const NumberWithCommas = (x) => {
  let y = Math.trunc(x);
  return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const CommasStringToNumber = (x) => {
  if (typeof str == "string") {
    let y = parseFloat(x.replace(/[^\d\.]/g, ""));
    console.log(x, y, "----");
    return y;
  } else {
    return x;
  }
};

export const inputRemoveSpecialCharacter = (string) => {
  return string.replace(/\D/g, "");
};

export const isObjectEmpty = (obj) => {
  var name;
  for (name in obj) {
    return false;
  }
  return true;
};

export const isValidJSONString = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};

export const truncateString = (input, count) =>
  input.length > count ? `${input.substring(0, count)}...` : input;

export const convertDateFormat = (date) => {
  const newdate = new Date(date);

  let d = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  })
    .format(newdate)
    .split(" ")
    .join(" ");

  return d;

  // const newDate = new Date(date);

  // if (isNaN(newDate)) {
  //   return "";
  // }

  // let d = new Intl.DateTimeFormat('en-GB', { year: "numeric", month: "short", day: "2-digit" }).format(newDate).split(" ").join(" ");

  // return d;
};

export const currentDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const currentDateTime = () => {
  // var now = new Date();
  // var datetime = now.getFullYear() + '-' + (("0" + (now.getMonth() + 1)).slice(-2)) + '-' + now.getDate();
  // datetime += ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
  // console.log('current da te' , datetime);
  // return datetime; // return "2024-11-20 17:38:4";

  var now = new Date();
  var datetime =
    now.getFullYear() +
    "-" +
    ("0" + (now.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + now.getDate()).slice(-2);
  datetime +=
    "T" +
    ("0" + now.getHours()).slice(-2) +
    ":" +
    ("0" + now.getMinutes()).slice(-2) +
    ":" +
    ("0" + now.getSeconds()).slice(-2);

  // console.log('current da te' , datetime);
  return datetime;
};

export const isAllowed = (user, rights) => {
  return rights.some((right) => user.rights.includes(right));
};

export function uTheme() {
  const theme = localStorage.getItem("theme");
  return theme;
}

export const hasRole = (user, roles) => {
  if (roles.length > 0 && user.permission.length > 0) {
    return roles.some((right) => user.permission.includes(right));
  } else {
    return true;
  }
  // return roles.includes(user.rights)
};

export function switchUserList() {
  let checkstorage = localStorage.getItem("switchUser");
  if (checkstorage) {
    const tokenString = decodeURIComponent(
      window.atob(localStorage.getItem("switchUser"))
    );
    let users = JSON.parse(tokenString);
    return users;
  } else {
    return [];
  }
}

export function useSwitchUser() {
  const getUsers = () => {
    const tokenString = localStorage.getItem("switchUser");
    const users = tokenString;
    return users?.users;
  };

  const [switchUser, setSwitchUser] = useState(getUsers());

  const saveUsers = (saveUsers) => {
    let encrpt = window.btoa(encodeURIComponent(saveUsers));
    localStorage.setItem("switchUser", encrpt);
    setSwitchUser(encrpt);
  };

  return {
    setSwitchUser: saveUsers,
    switchUser,
  };
}

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("authUser");
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("authUser", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}

export function uToken() {
  const tokenString = localStorage.getItem("authUser");
  let token = JSON.parse(tokenString);
  if (token) {
    return token.token;
  } else {
    return "";
  }
}

export function uInfo() {
  const tokenString = localStorage.getItem("authUser");
  let token = JSON.parse(tokenString);
  if (token) {
    return token;
  } else {
    return "";
  }
}

export function uRole() {
  const tokenString = localStorage.getItem("authUser");
  let token = JSON.parse(tokenString);
  if (token) {
    return token.role ? token.role : {};
  } else {
    return -1;
  }
}

export function bcrypt(id) {
  return window.btoa(id);
}

export function dcrypt(id) {
  try {
    return window.atob(id);
  } catch (e) {
    window.history.go(-1);
  }
}

export function logout() {
  localStorage.removeItem("switchUser");
  localStorage.removeItem("authUser");
}

export function getRoles() {
  return [
    {
      label: "Admin",
      value: "Admin",
    },
  ];
}
export const convert_cent_to_doller = (price) => {
  return price > 0 ? "$" + Number(price / 100, 2, ".", " ") : price;
};
export function getPages() {
  return [
    {
      label: "Users",
      value: "Users",
    },
    {
      label: "Roles",
      value: "Roles",
    },
    {
      label: "Logs",
      value: "Logs",
    },
    {
      label: "Packages",
      value: "Packages",
    },
    {
      label: "Reseller Packages",
      value: "Reseller Packages",
    },
    {
      label: "Reseller Management",
      value: "Reseller Management",
    },
    {
      label: "Managers Management",
      value: "Managers Management",
    },
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "Group Management",
      value: "Group Management",
    },
    {
      label: "Leads Management",
      value: "Leads Management",
    },
    {
      label: "Import Leads",
      value: "Import Leads",
    },
    {
      label: "Api Credentials",
      value: "Api Credentials",
    },
    {
      label: "Table Setting",
      value: "Table Setting",
    },
    {
      label: "Staff",
      value: "Staff",
    },
    ,
    {
      label: "Credits",
      value: "Credits",
    },

    {
      label: "Coaching Stuff",
      value: "Coaching Stuff",
    },

    {
      label: "Billing",
      value: "Billing",
    },
    {
      label: "Reports",
      value: "Reports",
    },
    {
      label: "CMS",
      value: "CMS",
    },
    {
      label: "Settings",
      value: "Settings",
    },
    {
      label: "Coaches Credit Management",
      value: "Coaches Credit Management",
    },
    {
      label: "Coaches Credit Criteria",
      value: "Coaches Credit Criteria",
    },
    {
      label: "WL Admin Credit Management",
      value: "WL Admin Credit Management",
    },
    {
      label: "Notification",
      value: "Notification",
    },
  ];
}

export function getPermission() {
  return [
    {
      label: "Can view",
      value: "can_view",
    },
    {
      label: "Can create",
      value: "can_create",
    },
    {
      label: "Can edit",
      value: "can_edit",
    },
    {
      label: "Can delete",
      value: "can_delete",
    },
  ];
}

export function purchasedAddon(addonId) {
  const tokenString = localStorage.getItem("authUser");
  let token = JSON.parse(tokenString);
  if (token) {
    const addons = token.purchased_addons ? String(token.purchased_addons).split(",").map(Number) : [];
    return addons.includes(addonId);
  } else {
    return false;
  }
}

export function hasPurchasedAddon(addonId) {
  const tokenString = localStorage.getItem("authUser");
  let token = JSON.parse(tokenString);
  if (token) {
    const purchasedAddons = token.purchased_addons ? String(token.purchased_addons).split(",").map(Number) : [];
    const activeAddons = token.active_purchased_addons ? String(token.active_purchased_addons).split(",").map(Number) : [];
    return purchasedAddons.includes(addonId) && activeAddons.includes(addonId);
  } else {
    return false;
  }
}


export function includedInPlanAddon(addonId) {
  const tokenString = localStorage.getItem("authUser");
  let token = JSON.parse(tokenString);
  if (token) {
    const addons = token.included_addons ? String(token.included_addons).split(",").map(Number) : [];
    return addons.includes(addonId);
  } else {
    return false;
  }
}

export function canPurchaseAddon(addonId) {
  const tokenString = localStorage.getItem("authUser");
  let token = JSON.parse(tokenString);
  if (token) {
    const addons = token.can_purchase_addons ? String(token.can_purchase_addons).split(",").map(Number) : [];
    return addons.includes(addonId);
  } else {
    return false;
  }
}

// export function userPackage() {
//   const tokenString = localStorage.getItem("authUser");
//   let token = JSON.parse(tokenString);
//   if (token) {
//     const packageInfo = token.latest_subscription ? token.latest_subscription.package_data : "";
//     let user_package = JSON.parse(packageInfo);
//     return user_package.name;
//   } else {
//     return false;
//   }
// }

// export function userPackage() {
//   const tokenString = localStorage.getItem("authUser");
//   let token = JSON.parse(tokenString);

//   if (token && token.latest_subscription && token.latest_subscription.package_data) {
//     try {
//       let packageInfo = token.latest_subscription.package_data;
//       let user_package = JSON.parse(packageInfo);

//       if (user_package && user_package.name) {
//         return user_package.name;
//       }
//     } catch (error) {
//       return false;
//     }
//   }

//   return false;
// }
