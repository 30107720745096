import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Progress
} from "reactstrap";

import Helmet from "react-helmet";
import { bcrypt, uRole, uToken, uInfo, NumberWithCommas } from "../../useToken";
import { ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import CalanderView from "./calanderView";
import moment from 'moment';
import Select from "react-select";
import swal from 'sweetalert';

import {
  Asia,
  Africa,
  America,
  Antartica,
  Atlantic,
  Australia,
  Europe,
  Indian,
  Pacific,
} from "../../common/data/timezones";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";
import CallWidget from "../Coaches/Clients/CallWidget";

const Coach = (props) => {
  const [record, setRecord] = useState({
    new_leads: [],
    revenue_goals: {
      revenue_goals: {}
    },
    revenue_goals_yearly: {
      revenue_goals: {}
    },
    report_records: {
      all_results: [],
      report_results: [],
      email_report: [],
      sms_report: [],
      call_report: [],
      voice_report: [],
      voice_message_listen: [],
    },
    pipeline_stats: [],
    activity_log: [],
  });
  const [clients, setClients] = useState([]);
  const [client_id, setClientId] = useState(0);
  const [client, setClient] = useState(0);

  const [taskClientLog, setTaskClientLog] = useState(0);
  const [pipeline_status_month, setPipelineStatusMonth] = useState("");
  const [pipeline_stats, setPipelineStats] = useState([]);

  const [task_status_month, setTaskStatusMonth] = useState("");
  const [taskModal, setTaskModal] = useState(false);
  const [taskData, setTaskData] = useState({});

  const [eventModal, setEventModal] = useState(false);
  const [eventData, setEventData] = useState({});


  const [goalsModal, setGoalsModal] = useState(false);
  const [goalsArray, setGoalsArray] = useState(false);
  const [timeZone_data, setnewzone] = useState(America);
  const [timezone, setTimeZone] = useState("");
  const [maintimezone, setMainTimeZone] = useState("");
  const [coachPartners, setCoachPartners] = useState([]);
  const [pipeline_list, setPipelineList] = useState([]);
  const [current_pipeline_id, setCurrentPipelineId] = useState([]);
  const [coachUser, setCoachUser] = useState({});
  const [calendarReload, setCalendarReload] = useState(true);

  const [activity, setActivity] = useState({
    report_results: [],
    sms_count: 0,
    email_count: 0,
    call_count: 0,
    voice_count: 0,
    voice_message_un_listen: 0
  });
  const [task_record, setTaskRecord] = useState({
    task_log: [],
    today: 0,
    weekly: 0,
    monthly: 0,
    unassigned: 0
  });
  const [schedule_record, setScheduleRecord] = useState({
    schedule_log: [],
    total_today: 0,
    total_week: 0,
    total_month: 0
  });
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const d = new Date();
  const [current_month] = useState(months[d.getMonth()]);
  let history = useHistory();
  useEffect(() => {
    let t1 = new moment();
    let month_year = t1.format("YYYY-MM");
    setPipelineStatusMonth(month_year);
    setTaskStatusMonth(month_year);
    getData();
    getActivity();
    getPipeline();
    getTaskStatistics(month_year, "all");
    getScheduleStatistics("all");
    getCoachUsers();
  }, []);

  const getData = () => {
    fetch(`${ApiUrl}` + "dashboard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecord(response.data.record);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getCoachUsers = () => {
    fetch(`${ApiUrl}` + "get/coach/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setCoachPartners(response.data.coachPartners);
          setCoachUser(response.data.coachUser);
          setClients(response.data.clients);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPipeline = () => {
    fetch(`${ApiUrl}` + "get/master/pipeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPipelineList(response.data.records);
          setCurrentPipelineId(response.data.current_pipeline.pipeline_id);
          let t1 = new moment();
          let month_year = t1.format("YYYY-MM");
          getPipelineStats(month_year, response.data.current_pipeline.pipeline_id);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getPipelineStats = (year_month, pipeline_id) => {
    setPipelineStatusMonth(year_month);
    fetch(`${ApiUrl}` + "dashboard/dealstatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'year_month': year_month, 'pipeline_id': pipeline_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPipelineStats(response.data.record);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getActivity = (type = 'all') => {
    fetch(`${ApiUrl}` + "get/business/inbox", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ day: 'today', 'type': type, 'client_id': 0 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setActivity(response.data.records);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 3) {
      fetch(`${ApiUrl}` + "search/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },

        body: JSON.stringify({ search: inputValue }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            setClients(response.data.clients);
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    console.log(inputValue.length);
  };
  const getTaskStatistics = (month_year, task_interval) => {
    setTaskStatusMonth(month_year);
    fetch(`${ApiUrl}` + "dashboard/task/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ month_year: month_year, task_interval: task_interval, client_id: 0 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setTaskRecord(response.data.record);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getScheduleStatistics = (task_interval) => {
    // console.log('scheudleee')
    // fetch(`${ApiUrl}` + "dashboard/schedule/statistics", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ` + uToken(),
    //   },
    //   body: JSON.stringify({ task_interval: task_interval }),
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (response.status === true) {
    //       //console.log(response.data.record.schedule_log, 'cccccccccccccc')
    //       // setScheduleRecord(response.data.record);
    //     } else {
    //                 

    //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  const maintimezones = [
    { id: "Asia", label: "Asia" },
    { id: "America", label: "America" },
    { id: "Australia", label: "Australia" },
    { id: "Europe", label: "Europe" },
    { id: "Indian", label: "Indian" },
  ];
  function settimezone(e) {
    setMainTimeZone(e);
    if (e.label == "Australia") {
      setnewzone(Australia);
    } else if (e.label == "America") {
      setnewzone(America);
    } else if (e.label == "Asia") {
      setnewzone(Asia);
    } else if (e.label == "Europe") {
      setnewzone(Europe);
    } else if (e.label == "Indian") {
      setnewzone(Indian);
    }
    setTimeZone({});
    // console.log(timeZone_data,'Zone Change');
  }

  const handleTaskSubmit = async (event, values) => {
    setCalendarReload(false);
    fetch(`${ApiUrl}` + "add/task/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setTaskModal(false);
          getTaskStatistics(task_status_month, "monthly");
          setCalendarReload(true);
          setTaskData({});
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const handleGoalsSubmit = async (event, values) => {

    fetch(`${ApiUrl}` + "edit/user/goals/" + uInfo().user_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setGoalsModal(false);
          getData();
          getPipelineStats(pipeline_status_month, current_pipeline_id);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  const deleteTaskAndEvents = (meeting_id, status = 'remove') => {
    swal({
      title: "Are you sure?",
      text: status == 'complete' ? "You want to mark complete this record" : "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          setCalendarReload(false);
          fetch(`${ApiUrl}` + "delete/meeting", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: meeting_id, status: status }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                setTaskModal(false);
                setTaskData({});
                getTaskStatistics(task_status_month, "monthly");

                // setEventData({});
                // setDynamicModal(false);
                // setDynamicModalName("");
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
              setCalendarReload(true);
            })
            //Then with the error genereted...
            .catch((error) => {
              setCalendarReload(true);
              console.error("Error:", error);
            });
        }
      });
  };

  const scheduleEvent = (event, custom_date = '', zone = '') => {

    if (event) {
      // setTimeZone(event.time_zone1);
      // setMainTimeZone(event.time_zone);
      // setEventData(event);
      // setEventModal(true);
      var obj = { meeting_id: event.meeting_id, calendar_id: event.calendar_id };

      if (custom_date) {
        obj.date = custom_date;
        obj.timezone = zone == '' ? timezone.id : zone;
      } else {
        setTimeZone(event.time_zone1);
        setMainTimeZone(event.time_zone);
        setEventData(event);
        setEventModal(true);
        obj.date = event.server_date;
        obj.timezone = event.client_time_zone;
      }

      fetch(`${ApiUrl}` + "get/meeting/slots", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(obj),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status === true) {
            setEventData((prevState) => ({
              ...prevState,
              server_date: obj.date,
              time_slots: data.data.slots
            }));
            // setTimeZone(event.time_zone1);
            // setMainTimeZone(event.time_zone);
            //setEventData(event);
            //setEventModal(true);

          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });


    }
  }

  const handleEventSubmit = async (event, values) => {
    setCalendarReload(false);
    values.client_id = eventData.client_id;
    values.calendar_id = eventData.calendar_id;
    values.type = "meeting";

    fetch(`${ApiUrl}` + "lead/activity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          setEventModal(false);
          // getTaskStatistics("monthly");
          setCalendarReload(true);
          setEventData({});
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }


  const refreshLog = () => {
    fetch(`${ApiUrl}` + "user/daily/logs/refresh", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          swal({
            text: data.message,
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <Helmet>
        <title></title>
      </Helmet>
      <Row className="DashboardPage">
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card>
            <CardBody>
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Outbound Calls</h4></div>
              <button
                onClick={() => {
                  setGoalsModal(true);
                  setGoalsArray([
                    ["outbound_call", "Phone Calls Goal", record.revenue_goals.revenue_goals.outbound_call],
                    ["outbound_email", "Email Goal", record.revenue_goals.revenue_goals.outbound_email],
                    ["outbound_sms", "SMS Goal", record.revenue_goals.revenue_goals.outbound_sms],
                  ]);
                }}
                title="Edit Goals"
                className="btn btn-link border-0 float-end pb-0"><i className="fas fa-edit"></i></button>
              <button onClick={() => refreshLog()} title="Refresh logs" className="btn btn-link border-0 float-end pb-0"><i class="fas fa-sync-alt"></i></button>
              <div className="ProgressDashboard OutboundGoals" style={{ width: "100%" }}>
                <div className="BarProgress">
                  <i className="ion ion-md-call"></i><div className="ProgressLabel"><label>Total Phone Calls</label><Progress color="primary" title={record.revenue_goals.out_call} value={record.revenue_goals.out_call_percentage}>
                    {record.revenue_goals.out_call}<div className="TotalProgress">{NumberWithCommas(record.revenue_goals.revenue_goals.outbound_call)}</div>
                  </Progress></div>
                </div>
              </div>
              <div className="ProgressDashboard OutboundGoals mt-2">
                <div className="BarProgress">
                  <i className="ion ion-md-mail"></i><div className="ProgressLabel"><label>Total Emails</label><Progress color="primary" title={record.revenue_goals.out_email} value={record.revenue_goals.out_email_percentage}>
                    {record.revenue_goals.out_email} <div className="TotalProgress">{NumberWithCommas(record.revenue_goals.revenue_goals.outbound_email)}</div>
                  </Progress>
                  </div>
                </div>
              </div>
              <div className="ProgressDashboard OutboundGoals mt-2">
                <div className="BarProgress">
                  <i className="mdi mdi-comment"></i><div className="ProgressLabel"><label>Total SMS Messages</label><Progress title={record.revenue_goals.out_sms} color="primary" value={record.revenue_goals.out_sms_percentage}>
                    {record.revenue_goals.out_sms} <div className="TotalProgress">{NumberWithCommas(record.revenue_goals.revenue_goals.outbound_sms)}</div>
                  </Progress>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card>
            <CardBody>
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Coaching Revenue Goals</h4></div>
              <button
                onClick={() => {
                  setGoalsModal(true);
                  setGoalsArray([
                    ["monthly_revenue", "Monthly Contracted Revenue Goal", record.revenue_goals.revenue_goals.monthly_revenue],
                    ["annual_revenue_earned", "YTD Revenue Goal", record.revenue_goals.revenue_goals.annual_revenue_earned],
                    ["total_clients", "Total Clients", record.revenue_goals.revenue_goals.total_clients],
                  ]);
                }}
                title="Edit Goals"
                className="btn btn-link border-0 float-end pb-0"><i className="fas fa-edit"></i></button>

              <div className="RevenueGoals">
                <div className="ProgressDashboardCoaching" style={{ width: "100%" }}>
                  <div className="BarProgress">
                    <i className="mdi mdi-currency-usd"></i><div className="ProgressLabel"><label>Monthly CONTRACTED Revenue</label>
                      <Progress title={`$${record.revenue_goals.coaching_revenue}`} color="primary" value={record.revenue_goals.coaching_revenue_percentage}>
                        ${record.revenue_goals.coaching_revenue} <div className="TotalProgress">${NumberWithCommas(record.revenue_goals.revenue_goals.monthly_revenue)}</div>
                      </Progress>
                    </div>
                  </div>
                </div>
              </div>
              <div className="RevenueGoals">
                <div className="ProgressDashboardCoaching mt-2">
                  <div className="BarProgress">
                    <i className="far fa-list-alt"></i><div className="ProgressLabel"><label>YTD Revenue</label>
                      <Progress title={`$${record.revenue_goals_yearly.coaching_revenue}`} color="primary" value={record.revenue_goals_yearly.coaching_revenue_percentage}>
                        ${record.revenue_goals_yearly.coaching_revenue} <div className="TotalProgress">${NumberWithCommas(record.revenue_goals.revenue_goals.annual_revenue_earned)}</div>
                      </Progress>
                    </div>
                  </div>
                </div>
              </div>
              <div className="RevenueGoals">
                <div className="ProgressDashboardCoaching mt-2">
                  <div className="BarProgress">
                    <i className="fas fa-user"></i><div className="ProgressLabel"><label>Total Clients</label>
                      <Progress title={`${record.revenue_goals.total_clients}`} color="primary" value={record.revenue_goals.total_clients_percentage}>
                        {record.revenue_goals.total_clients} <div className="TotalProgress">{NumberWithCommas(record.revenue_goals.revenue_goals.total_clients)}</div>
                      </Progress>
                    </div>
                  </div>
                </div>
              </div>

            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="4" sm="12" xs="12">
          <Card>
            <CardBody>
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Academy Revenue Goals</h4></div>
              <button
                onClick={() => {
                  setGoalsModal(true);
                  setGoalsArray([
                    ["lmc_monthly_revenue", "Monthly Academy Revenue Goal", record.revenue_goals.revenue_goals.lmc_monthly_revenue],
                    ["lmc_annual_revenue_earned", "YTD Revenue Goal", record.revenue_goals.revenue_goals.lmc_annual_revenue_earned],
                    ["lmc_total_subscriptions", "Total Subscribers", record.revenue_goals.revenue_goals.lmc_total_subscriptions],
                  ]);
                }}
                title="Edit Goals"
                className="btn btn-link border-0 float-end pb-0"><i className="fas fa-edit"></i></button>
              <div className="RevenueGoals">
                <div className="ProgressDashboardCoaching mt-2" style={{ width: "100%" }}>
                  <div className="BarProgress">
                    <i className="mdi mdi-currency-usd"></i><div className="ProgressLabel"><label>Monthly Academy Revenue</label>
                      <Progress title={`$${record.revenue_goals.academy_revenue}`} color="primary" value={record.revenue_goals.academy_revenue_percentage}>
                        ${record.revenue_goals.academy_revenue} <div className="TotalProgress">${NumberWithCommas(record.revenue_goals.revenue_goals.lmc_monthly_revenue)}</div>
                      </Progress>
                    </div>
                  </div>
                </div>
              </div>
              <div className="RevenueGoals">
                <div className="ProgressDashboardCoaching mt-2">
                  <div className="BarProgress">
                    <i className="far fa-list-alt"></i><div className="ProgressLabel"><label>YTD Revenue</label>
                      <Progress title={`$${record.revenue_goals_yearly.academy_revenue}`} color="primary" value={record.revenue_goals_yearly.lmc_annual_revenue_earned}>
                        ${record.revenue_goals_yearly.academy_revenue} <div className="TotalProgress">${NumberWithCommas(record.revenue_goals_yearly?.revenue_goals?.lmc_annual_revenue_earned)}</div>
                      </Progress>
                    </div>
                  </div>
                </div>
              </div>
              <div className="RevenueGoals">
                <div className="ProgressDashboardCoaching mt-2">
                  <div className="BarProgress ThumbsUp">
                    <i className="ion ion-md-thumbs-up"></i><div className="ProgressLabel"><label>Total Subscribers</label>
                      <Progress title={`${record.revenue_goals.academy_subscribers}`} color="primary" value={record.revenue_goals.academy_subscribers_percentage}>
                        {record.revenue_goals.academy_subscribers} <div className="TotalProgress"> {NumberWithCommas(record.revenue_goals.revenue_goals.lmc_total_subscriptions)}</div>
                      </Progress>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card className="VariousCalls BusinessInbox mt-5">
            <CardBody>
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Business Inbox</h4></div>
              <h4 className="card-title mt-2 mb-3">Today</h4>
              <div className="border-three">
                <Row>
                  <Col xl="6" lg="6" xs="6"><Card onClick={() => getActivity('sms')}
                    className="Message p-2 mb-3"><i className="mdi mdi-comment"></i>
                    <div className="NumberFunc">{activity.sms_count}</div>
                  </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card onClick={() => getActivity('email')} className="Email p-2 mb-3"><i className="ion ion-md-mail"></i>
                      <div className="NumberFunc">{activity.email_count}
                      </div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card onClick={() => getActivity('call')}
                      className="ReceivedCalls p-2 mb-2">
                      <i className="ion ion-md-call"></i>
                      <div className="NumberFunc" >{activity.call_count}</div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6"><Card onClick={() => getActivity('voice')} className="MissedCalls p-2 mb-2"><i className="ion ion-md-call"></i><div className="AlertBadge"><i className="fas fa-bookmark"></i><span>{activity.voice_message_un_listen}</span></div>
                    <div className="NumberFunc"  >{activity.voice_count}
                    </div>
                  </Card>
                  </Col>
                </Row>
              </div>
              <Card className="mt-1 mb-3">
                <CardBody className="RecentRecord">
                  {/* <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Messages</h4></div> */}

                  <h4 className="card-title mt-2 mb-3 text-center"> <button onClick={() =>
                    getActivity('all')
                  } className="StatusIcon Call btn-link pull-right" title="All Messages">
                    {/* <i className="fa fa-undo"></i> */}
                  </button></h4>

                  <ul>
                    {activity.report_results.length > 0 ? (
                      activity.report_results.map((row, i) => {
                        return (
                          <li className="mt-2 mb-3" key={"message" + i}>
                            <div className={`StatusIcon ${row.background}`}><i className={`${row.icon}`}></i></div>
                            <div className="w-100">
                              <div className="ContactDescription p-2">
                                <div className="NameUser"><h6 className="card-title">{row.client_name}</h6></div>
                                <div className="UserMessage"><p>{row.body}</p></div>
                              </div>
                              <div className="MessageTime d-flex "><div className="CreationDate">{row.created_at}&nbsp;<span className="roadmapDashboard">{row.master_type === 'Roadmap' ? '(Roadmap)' : ''}</span></div></div>
                            </div>
                            {/* <i className="fas fa-reply"></i> */}
                          </li>

                        );
                      })
                    ) : (
                      <li key={"message1"} className="mt-3">
                        <div className="ContactDescription p-2">
                          <div className="UserMessage text-center no-record"><p>No record found</p></div>
                        </div>
                      </li>
                    )}
                  </ul>
                </CardBody>
              </Card>
            </CardBody>
          </Card>

        </Col>
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card className="mt-5">
            <CardBody className="RecentRecord RecentLeads">
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">New Leads</h4></div>
              {/* <h4 className="card-title mt-2 mb-3">New Leads({record.new_leads.length}) <note style={{ fontSize: "10px" }}>(Today)</note></h4> */}
              <ul>
                {record.new_leads.length > 0 ? (
                  record.new_leads.map((row, i) => {
                    return (
                      <li key={"new_lead" + i} className="my-3">
                        <div className="StatusCall px-2 pr-0">
                          <div className="StatusIcon Call"><i className="ion ion-md-call"></i></div>
                          <div className="ContactDescription p-2">
                            <div className="LeadsSec">
                              <div className="NameUser"><h6 className="card-title">{row.client_name}</h6></div>
                              <div className="UserMessage"><p>{row.source}</p></div>
                            </div>
                            <div className="ButtonBox client-actions">

                              <CallWidget className="btn btn-primary btn-md dashboardLead" info={{
                                client_id: row.client_id,
                                coach_id: row.coach_id,
                                phoneno: row.phone ? row.phone : row.mobile,
                                phone: row.phone,
                                mobile: row.mobile,
                                record: "false"

                              }} onPopupTrigger={console.log('call')} />
                              {/* <button
                                key={i}
                                onClick={() => history.push(
                                  "/client/dialer/" +
                                  bcrypt(row.client_id) +
                                  "/" +
                                  bcrypt(row.coach_id)
                                )
                                }
                                className="btn btn-primary btn-sm"
                              >
                                Dial
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li key={"new_lead1"} className="mt-2 mb-2">
                    <div className="ContactDescription p-1">
                      <div className="LeadsSec" style={{ width: "100%" }}>
                        <div className="UserMessage no-record" style={{ textAlign: "center" }}><p>No record found</p></div>
                      </div>
                      <div className="ButtonBox"></div>
                    </div>
                  </li>
                )}
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="4" sm="12" xs="12" className="display-flex space-between">
          <Card className="VariousCalls TotalRecord MyScheduleMain mt-5">
            <CardBody>
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Calendar</h4></div>
              {
                calendarReload && (
                  <CalanderView scheduleEvent={scheduleEvent} />
                )
              }
            </CardBody>
          </Card>
        </Col>

      </Row>
      <Row className="Statuses">
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card className="mb-4 mt-5">
            <CardBody className="StatusListing">
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Pipeline Stats</h4></div>
              <div className="StatusHeading mt-3">
                <div className="MonthlyDropDown p-tracker mb-1">
                  <select value={current_pipeline_id} onChange={(e) => { setCurrentPipelineId(e.target.value); getPipelineStats(pipeline_status_month, e.target.value) }} className="form-control">
                    {pipeline_list.length > 0 &&
                      pipeline_list.map((row, i) => {
                        return (
                          <option key={i} value={row.pipeline_id}>{row.label}</option>
                        )
                      }
                      )
                    }

                  </select>

                </div>
                <h4 className="card-title"><input value={pipeline_status_month} onChange={(e) => getPipelineStats(e.target.value, current_pipeline_id)} className="form-control" type={'month'} name="deal_month" /></h4>
                <h4 className="card-title"> <button
                  onClick={() => {
                    setGoalsModal(true);
                    setGoalsArray([
                      ["pipeline_goal", "Pipeline Goal", record.revenue_goals.revenue_goals.pipeline_goal],
                    ]);
                  }}
                  title="Edit Goals"
                  className="btn btn-link border-0 float-end pb-0"><i className="fas fa-edit"></i></button></h4>
              </div>
              <div className="Campaigns px-3">
                {pipeline_stats.length > 0 ? (
                  pipeline_stats.map((row, i) => {
                    return (
                      <div className="RevenueGoals mb-2" key={"deal" + i}>
                        <div className="ProgressDashboardCoaching">
                          <div className="">
                            <label>{row.title}</label>
                            <Progress color={`primary ${row.random_keys}`} value={row.stats_count_percentage} title={"Total Count: " + row.stats_count}>
                              {row.stats_count} <div className="TotalProgress"> {row.goal}
                              </div>
                            </Progress>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="RevenueGoals no-record p-1" key={"deal1"}>
                    <div className="ProgressDashboardCoaching">
                      <div className="text-center">
                        <p>No record found</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="4" sm="6" xs="12">
          <Card className="mb-4 mt-5">
            <CardBody className="RecentRecord RecentLeads">
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">My Recent Activity</h4></div>
              {/* <div className="StatusHeading">
                <h4 className="card-title mb-3">Recent Activity</h4>
                <h4 className="card-title mb-3"></h4>
              </div> */}

              {record.activity_log.length > 0 && (
                <Link className={'text-align-right fs-4 d-block'} title={'View All'} to={'/report/activity'}><i class="fas fa-info-circle"></i></Link>
              )}
              <ul>
                {record.activity_log.length > 0 ? (
                  record.activity_log.map((row, i) => {
                    return (
                      <li className="mt-3" key={"activity" + i}>
                        <div className="StatusIcon Call"><i class="fas fa-history"></i></div>
                        <div className="ContactDescription p-2">
                          <div className="LeadsSec">
                            <div className="NameUser"><h6 className="card-title">{row.type}:</h6></div>
                            <div className="UserMessage"><p>{row.operation}</p></div>
                          </div>
                        </div>
                      </li>

                    );
                  })
                ) : (
                  <li key={"activity1"} className="mt-3">
                    <div className="StatusIcon Call d-none"><i class="fas fa-history"></i></div>
                    <div className="ContactDescription p-2">
                      <div className="LeadsSec">
                        <div className="UserMessage no-record"><p>No record found</p></div>
                      </div>
                      <div className="ButtonBox d-none"><button className="btn btn-success btn-sm"></button></div>
                    </div>
                  </li>
                )}
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4" lg="4" sm="12" xs="12" className="display-flex space-between mb-2">
          <Card className="VariousCalls TotalRecord MyScheduleMain TaskManager mb-4 mt-5">
            <CardBody>
              <div className="DashboardHeadingSection mb-4"><h4 className="card-title">Task Manager</h4></div>


              <div className="StatusHeading mt-3">
                <h4 className="card-title" style={{ margin: 0 }}><input value={task_status_month} onChange={(e) => getTaskStatistics(e.target.value, "monthly")} className="form-control" type={'month'} name="deal_month" /></h4>
                <h4 className="card-title">
                  <button
                    onClick={() => {
                      setTaskModal(true);
                    }}
                    className="btn btn-primary code-green border-0 float-end"><i className="fas fa-plus"></i></button>
                </h4>
              </div>





              {/* <div className="selectboxtop PlusSign"><h5 className="card-title mb-3">
                <button
                  onClick={() => {
                    setTaskModal(true);
                  }}
                  className="btn btn-primary code-green border-0 float-end"><i className="fas fa-plus"></i></button>
              </h5>
              </div> */}
              <div className="border-three">
                <Row>
                  <Col xl="6" lg="6" xs="6">
                    <Card onClick={() => getTaskStatistics(task_status_month, "today")} className="Message p-2 mb-3">
                      <div className="IconWithCount">
                        <div className="dot code-red"></div>
                        <span>Today</span>
                      </div>
                      <div className="NumberFunc">{task_record.today}</div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card onClick={() => getTaskStatistics(task_status_month, "weekly")} className="Email p-2 mb-3">
                      <div className="IconWithCount">
                        <div className="dot code-dark"></div>
                        <span>Week</span>
                      </div>
                      <div className="NumberFunc">{task_record.weekly}</div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card onClick={() => getTaskStatistics(task_status_month, "monthly")} className="ReceivedCalls p-2 m-0">
                      <div className="IconWithCount"><div className="dot code-green"></div>
                        <span>Month</span>
                      </div>
                      <div className="NumberFunc">{task_record.monthly}</div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="6" xs="6">
                    <Card onClick={() => getTaskStatistics(task_status_month, "unassigned")} className="MissedCalls p-2 m-0">
                      <div className="IconWithCount">
                        <div className="dot code-yellow"></div>
                        <span>Unassigned</span>
                      </div>
                      <div className="NumberFunc">{task_record.unassigned}</div>
                    </Card>
                  </Col>
                </Row>
              </div>
              <Card className="mb-3 MyScheduleMain">
                <CardBody className="RecentRecord MyTasks NewTasks">
                  <ul>
                    {task_record.task_log.length > 0 ? (
                      task_record.task_log.map((row, i) => {
                        return (
                          <li key={i} className="pt-2 pb-2" onClick={() => {
                            setClient(row.client);
                            setClientId(row.client_id);
                            setTimeZone(row.time_zone1);
                            setMainTimeZone(row.time_zone);
                            setTaskData(row);
                            setTaskModal(true);
                          }}>
                            <div className={`DotTask code-${row.color_code}`}></div>
                            <div className="TaskDescp">
                              <div className="research-date1 d-flex justify-content-between">
                                <p>{row.created_at}</p>
                                {row.status == 'complete' && (
                                  <i title="Task completed" class="far fa-check-circle"></i>
                                )}
                              </div>
                              <hr className="my-1"></hr>
                              <div className="desp-task">
                                <p><b>Client Name:</b> {row.client_name}</p>
                                <p><b>Title:</b> {row.title}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (

                      <li key={"task1"} className="mt-3 no-record">
                        <div className="ContactDescription p-2">
                          <div className="UserMessage text-center"><p>No record found</p></div>
                        </div>
                      </li>
                    )}
                  </ul>
                </CardBody>
              </Card>
            </CardBody>
          </Card>

        </Col>
      </Row>
      <Modal
        isOpen={taskModal}
        fade={true}
        size="md"
        toggle={() => {
          setTaskModal(false);
          setTaskData({});
        }}
      >
        <AvForm
          onValidSubmit={handleTaskSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Add Task </h2>
              <button
                type="button"
                onClick={() => {
                  setTaskModal(false);
                  setTaskData({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="event_title"
                    label="Task Title"
                    className="form-control"
                    required
                    type="text"
                    value={taskData.event_title}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_date"
                    label="Select Date"
                    className="form-control"
                    required
                    type="date"
                    value={taskData.server_date}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_time"
                    label="Select Time"
                    className="form-control"
                    required
                    type="time"
                    value={taskData.server_time}
                  />
                </div>
                <Col md={6} className="d-none">
                  <AvGroup>
                    <label> MainTime Zone </label>
                    <div className="MainTZone MainTime One">
                      <Select
                        value={maintimezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={maintimezones}
                        classNamePrefix="select2-selection"
                        className={`${maintimezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone"
                        onChange={(e) => {
                          settimezone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      name="time_zone_1"
                      value={maintimezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6} className="d-none">
                  <AvGroup>
                    <label> Time Zone </label>
                    <div className="MainTZone MainTime One Two">
                      <Select
                        value={timezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={timeZone_data}
                        classNamePrefix="select2-selection"
                        className={`${timezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone1"
                        onChange={(e) => {
                          setTimeZone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      name="time_zone"
                      value={timezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={12} className="mb-3">
                  <label className="control-label">Client</label>
                  {
                    taskData.meeting_id ? (
                      <select disabled className="form-control">
                        <option value={taskData.client_id}>{taskData.client_name}</option>

                      </select>

                    ) : (
                      <div className="Task One">
                        <Select
                          getOptionLabel={option => option.client_id + ' # ' + option.first_name + ' ' + option.last_name + ((option.email != '') ? ' - ' + option.email : ' - ' + option.country_code + option.mobile)}
                          getOptionValue={(option) => option.client_id}
                          isMulti={false}
                          value={clients.filter(
                            (row) => row.client_id === client_id
                          )}
                          options={clients}
                          classNamePrefix="select2-selection"
                          onInputChange={handleInputChange}
                          isDisabled={taskData.meeting_id ? true : false}
                          onChange={(e) => {
                            setClientId(e.client_id);
                            setClient(e);
                          }}
                        />

                      </div>
                    )
                  }
                  <AvField
                    required
                    type="hidden"
                    name="client_id"
                    value={client_id}
                  />
                </Col>
                <Col md={12}>
                  <div className="MainTZone Task Two">
                    <AvField
                      className="select form-control"
                      type="select"
                      name="assign_to"
                      label="Assign To"
                      value={taskData.assign_to}
                    >
                      <option value={""}> Un Assign </option>
                      {client.linked_user_id > 0 && (
                        <optgroup label="This Client/Lead">
                          <option key={1} value={client.linked_user_id}>{client.first_name + client.last_name + " - " + (client.email ? client.email : client.mobile)}</option>
                        </optgroup>
                      )}
                      <optgroup label="Coach">
                        <option key={1} value={coachUser.user_id}>{coachUser.name + " - " + coachUser.email}</option>
                      </optgroup>
                      <optgroup label="Coach Partner">
                        {coachPartners.map((row, i) => {
                          return (
                            <option key={i} value={row.user_id}>
                              {row.name + " - " + row.email}
                            </option>
                          );
                        })}
                      </optgroup>
                    </AvField>
                  </div>
                </Col>
                {
                  taskData.meeting_id && (
                    <AvField
                      type="hidden"
                      name="meeting_id"
                      value={taskData.meeting_id}
                    />
                  )
                }
              </div>
            </ModalBody>
            <ModalFooter>
              {
                taskData.meeting_id && (
                  <Button className="custom-btn-success btn-sm" color="success" onClick={() => { deleteTaskAndEvents(taskData.meeting_id, 'complete') }}>
                    Done
                  </Button>
                )
              }

              {
                taskData.meeting_id && (
                  <Button className="custom-btn-danger btn-sm" color="danger" onClick={() => { deleteTaskAndEvents(taskData.meeting_id, 'remove') }}>
                    Delete
                  </Button>
                )
              }
              <Button className="custom-btn-secondary btn-sm" color="secondary" type="submit">
                Save
              </Button>
              <Button className="custom-btn-muted btn-sm" color="muted" onClick={() => { setTaskModal(false); setTaskData({}); }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal >

      <Modal
        isOpen={eventModal}
        fade={true}
        size="md"
        toggle={() => {
          setEventModal(false);
          setEventData({});
        }}
      >
        <AvForm
          onValidSubmit={handleEventSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Schedule an event </h2>
              <button
                type="button"
                onClick={() => {
                  setEventModal(false);
                  setEventData({});
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="event_title"
                    label="Task Title"
                    className="form-control"
                    required
                    type="text"
                    value={eventData.event_title}
                  />
                </div>
                <Col md={6}>
                  <AvGroup>
                    <label> MainTime Zone </label>
                    <div className="MainTZone MainTime One">
                      <Select
                        value={maintimezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={maintimezones}
                        classNamePrefix="select2-selection"
                        className={`${maintimezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone"
                        onChange={(e) => {
                          settimezone(e);
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone_1"
                      value={maintimezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={6}>
                  <AvGroup>
                    <label> Time Zone </label>
                    <div className="MainTZone MainTime One Two">
                      <Select
                        value={timezone}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        options={timeZone_data}
                        classNamePrefix="select2-selection"
                        className={`${timezone != ""
                          ? "is-touched is-dirty av-valid"
                          : "is-touched is-pristine av-invalid is-invalid"
                          }`}
                        name="time_zone1"
                        onChange={(e) => {
                          setTimeZone(e);
                          if (eventData.meeting_id != undefined && eventData.meeting_id > 0) {
                            scheduleEvent(eventData, eventData.server_date, e.id)
                          }
                        }}
                      />
                    </div>
                    <AvInput
                      type="hidden"
                      required
                      name="time_zone"
                      value={timezone.id}
                    />
                    <AvFeedback> This is required * </AvFeedback>
                  </AvGroup>
                </Col>
                <div className="col-md-12 mb-3">
                  <AvField
                    name="server_date"
                    label="Select Date"
                    className="form-control"
                    required
                    type="date"
                    value={eventData.server_date}
                    onChange={(e) => scheduleEvent(eventData, e.target.value)}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="server_time"
                    label="Slots"
                    required
                    id="server_time"
                    value={eventData.from_time_convert}
                  >
                    <option value="">Time Slot</option>
                    {
                      eventData?.time_slots != undefined && eventData?.time_slots.map((slot, i) => {
                        return <option key={i} value={slot}>{slot}</option>
                      })
                    }


                  </AvField>

                  {/* <AvField
                    name="server_time"
                    label="Select Time"
                    className="form-control"
                    required
                    type="time"
                    value={eventData.from_time}
                  /> */}

                </div>

                {
                  eventData.meeting_id && (
                    <AvField
                      type="hidden"
                      name="meeting_id"
                      value={eventData.meeting_id}
                    />
                  )
                }
                {
                  eventData.meeting_id && (
                    <Col md={12}>
                      <AvField
                        className="select form-control"
                        type="select"
                        name="status"
                        label="Status"
                        required
                        id="event_status"
                        value={eventData.status}
                      >
                        <option value="pending">Pending</option>
                        <option value="confirm">Confirm</option>
                        <option value="expired">Expired</option>


                      </AvField>
                    </Col>
                  )
                }
              </div>
            </ModalBody>
            <ModalFooter>
              {
                eventData.meeting_id && (
                  <Button className="btn-sm" color="warning" onClick={() => { deleteTaskAndEvents(eventData.meeting_id) }}>
                    Delete this schedule
                  </Button>
                )
              }
              <Button className="btn-sm" color="info" type="submit">
                Save
              </Button>
              <Button className="btn-sm" color="danger" onClick={() => { setEventModal(false); setEventData({}); }}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
      <Modal
        isOpen={goalsModal}
        fade={true}
        size="md"
        toggle={() => {
          setGoalsModal(false);
        }}
      >
        <AvForm
          onValidSubmit={handleGoalsSubmit}
          className="needs-validation"
        >
          <div className="note">
            <div className="modal-head top-heading">
              <h2> Set Goals </h2>
              <button
                type="button"
                onClick={() => {
                  setGoalsModal(false);
                }}
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
            <ModalBody>
              <div className="mb-3 row">
                {goalsArray.length > 0 && (
                  goalsArray.map((row, i) => {
                    return (
                      <div className="col-md-12 mb-3">
                        <AvField
                          name={row[0]}
                          label={row[1]}
                          className="form-control"
                          required
                          type="text"
                          value={row[2]}
                        />
                      </div>
                    )
                  })
                )
                }
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-sm" color="info" type="submit">
                Save
              </Button>
              <Button className="btn-sm" type="button" color="danger" onClick={() => setGoalsModal(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment >
  );
};

export default Coach;
