import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  Table,
  Button,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import Helmet from "react-helmet"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

import { Link, withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-4.jpg"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Logs from "../Logs/Logs.js"
import { ApiUrl, ProductName } from "../../config"
import { toast, Flip } from "react-toastify"
import { dcrypt, uToken } from "../../useToken"
import LoginHistory from "../../components/tables/LoginHistory"
import Group from "../../pages/Coaches/Group/Group.js"
import swal from 'sweetalert';



// actions

//Import Action to copy breadcrumb items from local state to redux state

const UserProfile = props => {

  const [page_name] = useState("Profile");
  const [idx] = useState(dcrypt(props.match.params.id));
  const [info, setInfo] = useState({});
  const [managerscolumn, setManagersColumn] = useState([]);
  const [managersrow, setManagersRow] = useState([]);
  const [loginHistory, setLoginHistory] = useState([]);
  const [apiSetting, setApiSetting] = useState([]);
  const [loginHistoryColumn, setLoginHistoryColumn] = useState([]);
  const [historySubscriptionRows, setHistorySubscriptionRows] = useState([]);
  const [historySubscriptionColumn, setHistorySubscriptionColumn] = useState([]);
  const [clientAccountRows, setClientAccountRows] = useState([]);
  const [clientAccountColumn, setClientAccountColumn] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalOption, setModalOption] = useState({});

  useEffect(() => {
    console.log('UserProfile', props);
    getData();

  }, []);

  const getData = () => {

    fetch(`${ApiUrl}` + 'profile/user/' + idx, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, 'Data fetched by API');
          setInfo(response.data.info);
          setManagersColumn(response.data.managerscolumn);
          setManagersRow(response.data.managersrow);
          setLoginHistory(response.data.loginHistory);
          setLoginHistoryColumn(response.data.loginHistoryColumn);
          setHistorySubscriptionRows(response.data.historySubscriptionRows);
          setHistorySubscriptionColumn(response.data.historySubscriptionColumn);

          setClientAccountRows(response.data.clientAccountRows);
          setClientAccountColumn(response.data.clientAccountColumn);
          setApiSetting(response.data.api_settings);
          console.log(response, 'data')
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const openModal = (title, key) => {
    setModalOption({
      title: title,
      key: key
    });
    setModal(true);
  }

  const closeModal = () => {
    setModalOption({});
    setModal(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();

    submitData.append('user_id', info.user_id);
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }


    fetch(`${ApiUrl}` + "update/user/data", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          swal({
            title: response.message,
            icon: "success",
          });
          getData();
          closeModal();
          setModalOption({});

        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  

  return (
    <React.Fragment className={'custom-table'}>
      <div className="UserProfileTable">
        <Helmet>
          <title>Profile </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{page_name}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                className="btn-sm"
                onClick={() => props.history.goBack()}
              >
                Back{" "}
              </Button>
            </div>{" "}
          </Col>{" "}
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Basic Information</CardTitle>
                <div className="table-responsive user-info">
                  <Table className="table table-bordered mb-0 border-0">
                    <tbody>
                      <tr style={{ background: '#041934', color: 'white', backgroundPosition: 'right center' }}>
                        <th>Key</th>
                        <th>Value</th>
                      </tr>
                      <tr>
                        <td>UserId</td>
                        <td>{info.user_id}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{info.name}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{info.email}</td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>{info.type} {info.package_name != '' && `(${info.package_name})`}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{info.status}</td>
                      </tr>
                      <tr>
                        <td>Created At</td>
                        <td>{info.user_created_at}</td>
                      </tr>
                      <tr>
                        <td>Twilio Credentials</td>
                        <td>Account SID: {apiSetting.twilio_sid}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

          </Col>

          {
            loginHistoryColumn.length > 0 && (
              <Col md={6}>
                <LoginHistory columns={loginHistoryColumn} records={loginHistory} tableTitle={`Login History`} />
              </Col>

            )
          }

          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitle className="h4">User Actions</CardTitle>
                <div className="table-responsive user-info">
                  <Table className="table table-bordered mb-0 border-0">
                    <tbody>
                      <tr style={{ background: '#041934', color: 'white', backgroundPosition: 'right center' }}>
                        <th>Key</th>
                        <th>Value</th>
                        <th>Action</th>
                      </tr>

                      <tr>
                        <td>Allow Manual Payment </td>
                        <td>{info.allow_manual_payment == 1 ? <span class="badge bg-success">Yes</span> : <span class="badge bg-danger">No</span>}</td>
                        <td><button className="btn btn-sm btn-link py-0" onClick={() => openModal('Allow Manual Payment', 'allow_manual_payment')}><i className="fas fa-edit"></i></button></td>
                      </tr>

                      <tr>
                        <td>Bypass accounts for admin access</td>
                        <td>{info.bypass_admin_access == 1 ? <span class="badge bg-success">Yes</span> : <span class="badge bg-danger">No</span>}</td>
                        <td><button className="btn btn-sm btn-link py-0" onClick={() => openModal('Bypass accounts for admin access', 'bypass_admin_access')}><i className="fas fa-edit"></i></button></td>
                      </tr>

                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

          </Col>

        </Row>
        {clientAccountColumn.length > 0 && (
          <LoginHistory columns={clientAccountColumn} records={clientAccountRows} tableTitle={`Client Accounts`} />
        )}

        {historySubscriptionColumn.length > 0 && (
          <LoginHistory columns={historySubscriptionColumn} records={historySubscriptionRows} tableTitle={`Subscription History`} />
        )}

        {info.role_id == '3' && managerscolumn.length > 0 && (
          <LoginHistory columns={managerscolumn} records={managersrow} tableTitle={`Coach List`} />
        )}

        {/* {info.role_id == '3' && (
        <Row>
          <Col lg="12">
            <Group user_id={idx} />
          </Col>
        </Row>
      )} */}

        <Row>
          <Col lg="12">
            <Logs user_id={idx} />
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          fade={true}
          size="md"
          toggle={() => closeModal()}
        >
          <div className="modal-header p-1">
            <h5 className="modal-title">
              {modalOption?.title}
            </h5>
            <div className="pull-right">
              <button onClick={() => closeModal()} className="btn btn-sm" ><i className="fas fa-times"></i></button>
            </div>
          </div>

          <form onSubmit={handleSubmit} method="post"
            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
            <ModalBody>
              {
                modalOption?.key == 'allow_manual_payment' && (
                  <div className="form-group mb-3">
                    <label>{modalOption?.title} </label>
                    <select defaultValue={info.allow_manual_payment} name="allow_manual_payment" className="form-control" >
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </select>
                  </div>
                )
              }

              {
                modalOption?.key == 'bypass_admin_access' && (
                  <div className="form-group mb-3">
                    <label>{modalOption?.title} </label>
                    <select defaultValue={info.bypass_admin_access} name="bypass_admin_access" className="form-control" >
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </select>
                  </div>
                )
              }

              <input type="hidden" name="action_perform" value={modalOption?.key} required />

            </ModalBody>
            <ModalFooter className="py-1 px-2">

              <button type="submut" className="btn btn-success ml-5 btn-sm">Update</button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default UserProfile