// import React from "react"
// import PropTypes from "prop-types"
// import { Route, Redirect } from "react-router-dom"
// import Pages404 from "../../pages/Extra Pages/pages-404";
// import { dcrypt, hasRole, uInfo, uRole } from "../../useToken";

// const Authmiddleware = ({
//   component: Component,
//   layout: Layout,
//   isAuthProtected,
//   requiredRoles,
//   role,
//   ...rest
// }) => (
//   <Route
//     {...rest}
//     render={props => {
//       let userHasRequiredRole = false
//       // const userRole = uRole();

//       //console.log(props, 'auth');

//       const search = props.location.search;
//       // const query = queryString.parse(search);
//       const u = new URLSearchParams(search).get('u');

//       //console.log(u)

//       // if (requiredRoles) {
//       //   userHasRequiredRole = requiredRoles.includes(userRole)
//       // }

//       if (props.location.pathname == '/client/form/details') {
//         if (dcrypt(u) == 0) {
//           props.history.push(`/complete/profile${search}`, { from: props.location });
//         } else {
//           if (!localStorage.getItem("authUser")) {
//             return (
//               <Redirect
//                 to={{ pathname: "/login", state: { from: props.location } }}
//               />
//             )
//           } else {
//             return (
//               <Redirect
//                 to={{ pathname: "/client/profile" }}
//               />
//             )
//           }
//         }
//       } else {
//         if (isAuthProtected && !localStorage.getItem("authUser")) {
//           return (
//             <Redirect
//               to={{ pathname: "/login", state: { from: props.location } }}
//             />
//           )
//         }
//       }

//       // if (isAuthProtected && localStorage.getItem("authUser")) {
//       //   return (
//       //     !userHasRequiredRole && (
//       //       console.log('here'),
//       //       <Redirect
//       //         to={{ pathname: "/pages-404", state: { from: props.location } }}
//       //       />
//       //     )
//       //   )
//       // }

//       return (
//         <Layout>
//           {
//             isAuthProtected && !hasRole(uInfo(), requiredRoles) ?
//               <Pages404 />
//               :
//               <Component key={props.match.url} theme={2} role={uRole()} {...props} />
//           }
//         </Layout>
//       )
//     }}
//   />
// )

// Authmiddleware.propTypes = {
//   isAuthProtected: PropTypes.bool,
//   component: PropTypes.any,
//   location: PropTypes.object,
//   layout: PropTypes.any,
// }

// export default Authmiddleware

// commenting the above code snippet as backup, as it was in working before the implementation of Add-ons related functionality

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import Pages404 from "../../pages/Extra Pages/pages-404";
import {
  dcrypt,
  hasRole,
  uInfo,
  uRole,
  hasPurchasedAddon,
  includedInPlanAddon,
} from "../../useToken";
import AddonPurchasePopup from "../../pages/AddonPurchasePopup";

import { HostUrl, PusherKey } from "../../config";
import Pusher from "pusher-js";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  requiredRoles,
  requiredAddons,
  role,
  ...rest
}) => {
  const [showAddonPopup, setShowAddonPopup] = useState(false);
  const [missingAddonId, setMissingAddonId] = useState(null);
  const location = useLocation();
  const history = useHistory();

  // useEffect(() => {
  //   if (uInfo().type === "Coach") {
  //     const pusher = new Pusher(PusherKey, {
  //       cluster: "mt1",
  //     });
  //     const channel1 = pusher.subscribe('user.' + uInfo().user_id);
  //     channel1.bind("addon-removed", function (data) {
  //       if (data.data.user_id == uInfo().user_id && data.data.addon_id) {

  //        if (document.hidden) {
  //         console.log('active pusher is called looking for changes');
  //     } else {
  //       console.log('inactive pusher is called looking for changes');
  //     }

  //       }
  //     });

  //     return () => {
  //       pusher.unsubscribe('user.' + uInfo().user_id);
  //     };
  //   }
  // }, []);


  useEffect(() => {
    if (uInfo().type === "Coach") {
      const pusher = new Pusher(PusherKey, {
        cluster: "mt1",
      });
      const channel1 = pusher.subscribe("my-channel");
      channel1.bind("addon-removed", function (data) {
        if (data.data.user_id == uInfo().user_id && data.data.addon_id) {
         
          const tokenString = localStorage.getItem("authUser");
          let userToken = JSON.parse(tokenString);
          if (userToken) {
            const addonId = data.data.addon_id;

            const addons = userToken.active_purchased_addons
              ? String(userToken.active_purchased_addons).split(",").map(Number)
              : [];
            if (addons.includes(addonId)) {
              const updatedAddons = addons.filter((id) => id !== addonId);

              userToken.active_purchased_addons =
                updatedAddons.length > 0 ? updatedAddons.join(",") : "";

              localStorage.setItem("authUser", JSON.stringify(userToken));
            }
          }
        }
      });

      return () => {
        pusher.unsubscribe("my-channel");
      };
    }
  }, []);

  useEffect(() => {
    // if (uInfo().type === "Coach") {
    //   if (
    //     requiredAddons &&
    //     requiredAddons.some((addonId) => !purchased_Addon(addonId))
    //   ) {
    //     const missingAddon = requiredAddons.find(
    //       (addonId) => !purchased_Addon(addonId)
    //     );
    //     setMissingAddonId(missingAddon);
    //     setShowAddonPopup(true);
    //   }
    // }

    if (uInfo().type === "Coach") {
      if (
        requiredAddons &&
        (requiredAddons.some((addonId) => !includedInPlanAddon(addonId)) ||
          requiredAddons.some((addonId) => !hasPurchasedAddon(addonId)))
      ) {
        if (
          requiredAddons.find((addonId) => !includedInPlanAddon(addonId)) &&
          requiredAddons.find((addonId) => !hasPurchasedAddon(addonId))
        ) {
          var missingAddon;
          if (requiredAddons.find((addonId) => !includedInPlanAddon(addonId))) {
            missingAddon = requiredAddons.find(
              (addonId) => !includedInPlanAddon(addonId)
            );
          }
          if (requiredAddons.find((addonId) => !hasPurchasedAddon(addonId))) {
            missingAddon = requiredAddons.find(
              (addonId) => !hasPurchasedAddon(addonId)
            );
          }

          setMissingAddonId(missingAddon);
          setShowAddonPopup(true);
        }
      }
    }
  }, [requiredAddons]);

  return (
    <Route
      {...rest}
      render={(props) => {
        const search = location.search;
        const u = new URLSearchParams(search).get("u");

        // Handle specific route redirection logic
        if (props.location.pathname === "/client/form/details") {
          if (dcrypt(u) === 0) {
            history.push(`/complete/profile${search}`, { from: location });
          } else {
            if (!localStorage.getItem("authUser")) {
              return (
                <Redirect
                  to={{ pathname: "/login", state: { from: location } }}
                />
              );
            } else {
              return <Redirect to={{ pathname: "/client/profile" }} />;
            }
          }
        } else {
          if (isAuthProtected && !localStorage.getItem("authUser")) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: location } }}
              />
            );
          }
        }

        if (uInfo().type === "Coach" && showAddonPopup) {
          return (
            <Layout>
              <AddonPurchasePopup
                addonId={missingAddonId}
                onClose={() => {
                  setShowAddonPopup(false);
                  history.goBack();
                }}
              />
            </Layout>
          );
        }

        return (
          <Layout>
            {isAuthProtected && !hasRole(uInfo(), requiredRoles) ? (
              <Pages404 />
            ) : (
              <Component
                key={props.match.url}
                theme={2}
                role={uRole()}
                {...props}
              />
            )}
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  layout: PropTypes.any,
  requiredRoles: PropTypes.array,
  requiredAddons: PropTypes.array,
};

export default Authmiddleware;
