import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Table,
    Alert,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Knob from "./knob";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    hasRole,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import swal from 'sweetalert';
import Badge from "react-bootstrap/Badge";


export default class Campaign extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "campaign_id#",
                key: "campaign_id",
            },
            {
                text: "Title1",
                key: "title",
            },
            {
                text: "Group",
                key: "group_id",
            },
            // {
            //     text: "From Email",
            //     key: "from_email",
            // },
            {
                text: "Phone Number",
                key: "phone_number",
            },
            {
                text: "Status",
                key: "status",
                cell: (record, index) => {
                    return (

                        <Fragment>
                            {record.status == "Active" && (
                                <Badge bg="success"> {record.status} </Badge>
                            )}
                            {record.status == "Inactive" && (
                                <Badge bg="danger"> {record.status} </Badge>
                            )}
                        </Fragment>
                    );
                },
            },
            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <Link
                                type="button"
                                color="info"
                                className="btn btn-primary btn-sm mb-1 mr-5"
                                to={
                                    "/campaign/edit/" + bcrypt(record.campaign_id)

                                }

                            >
                                <i className="fas fa-edit"></i>
                                Edit
                            </Link>
                            {
                                record.can_delete != 0 && (
                                    <button
                                        className="btn btn-danger btn-sm mb-1"
                                        onClick={this.deleteRecord.bind(this, record, index)}
                                    >
                                        <i className="fas fa-trash"></i>
                                        Delete
                                    </button>
                                )
                            }
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            campaign_log: {},
            deal_status: {},
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Campaign",
            customDate: true,
            ClientSubcribeModal: false,
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
            expandedRows: '',
            expandState: {}
        };
    }
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/campaign", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.campaign_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                //               toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "campaigns" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getCampaignLog = (campaign_id) => {
        fetch(`${ApiUrl}` + "campaign/report/log", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: campaign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        campaign_log: response.data.campaign_log,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    resetDialAttempt = (campaign_id) => {
        fetch(`${ApiUrl}` + "campaign/reset/attempt", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: campaign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/campaign/list',
                        });
                    }, 2000);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "campaigns" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };



    handleEpandRow = (event, campaign_id) => {


        const currentExpandedRows = this.state.expandedRows;

        const isRowExpanded = currentExpandedRows == campaign_id ? true : false
        console.log('newExpandedRows', isRowExpanded)

        let obj = {};
        isRowExpanded ? (obj[campaign_id] = false) : (obj[campaign_id] = true);

        this.setState({
            expandState: obj
        })

        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ? currentExpandedRows !== campaign_id :
            campaign_id

        console.log(newExpandedRows, 'newExpandedRows', isRowExpanded)

        this.setState({ expandedRows: newExpandedRows })
        this.getCampaignLog(campaign_id);

    }

    render() {
        const queryParams = this.props.location.state ? this.props.location.state : '';
        const sessionMSg = queryParams.msg ? queryParams.msg : '';
        const sessionCampaignId = queryParams.campaign_id ? queryParams.campaign_id : 0;

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} List</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} List</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        {/* <div className="page-title-box text-align-right">
                            {hasRole(uInfo(), ["Import Leads"]) && (
                                <Link to={"import/lead"} className="btn  btn-success">
                                    Import
                                </Link>
                            )}
                        </div> */}
                    </Col>
                    <Col sm={1}>
                        <div className="page-title-box text-align-right">
                            {isAllowed(uInfo(), ["can_create"]) && (
                                <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-info btn-sm"
                                    to={"/campaign/add"}
                                >
                                    <i className="mdi mdi-plus"> </i>
                                    Create
                                </Link>
                            )}
                        </div>
                    </Col>
                </Row>

                {
                    sessionMSg && sessionMSg != '' && (
                        <>
                            <Alert color="info">{dcrypt(sessionMSg)} <button onClick={() => this.resetDialAttempt(sessionCampaignId)} className="btn btn-danger">Click to reset</button></Alert>
                        </>

                    )
                }

                <ToastContainer />

                <Row>
                    <Col xl="12">
                        <Card className="mb-0">
                            <CardBody className="py-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="date_range"
                                                    label="Date Filter"
                                                    onChange={(e) =>
                                                        e.target.value == "Custom Date"
                                                            ? this.setState({
                                                                customDate: false,
                                                            })
                                                            : this.setState({
                                                                customDate: true,
                                                            })
                                                    }
                                                >
                                                    <option value=""> All Time </option>
                                                    <option value="Current Week"> Current Week </option>
                                                    <option value="Last Week"> Last Week </option>
                                                    <option value="Current Month"> Current Month </option>
                                                    <option value="Last Month"> Last Month </option>
                                                    <option value="Last 3 Months"> Last 3 Months </option>
                                                    <option value="Last 6 Months"> Last 6 Months </option>
                                                    <option value="Current Year"> Current Year </option>
                                                    <option value="Last Year"> Last Year </option>
                                                    <option value="Custom Date"> Custom Date </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="start_date"
                                                    label="Start date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="end_date"
                                                    label="End date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <label className=""> {"Action"}</label>
                                                <div className="button-items">

                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="campagian pt-0">
                                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                {/* <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                /> */}

                                <Table responsive className="table-bordered table-striped" variant="light" >
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Title</th>
                                            <th>Group</th>
                                            {/* <th>From Email</th> */}
                                            <th>Phone Number</th>
                                            <th>Status</th>
                                            <th>Created Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.records.map((row, index) =>
                                                <>
                                                    <tr key={row.campaign_id}>
                                                        <td className="td-first">
                                                            {row.campaign_id}
                                                            <button className="btn-link"
                                                                onClick={event => this.handleEpandRow(event, row.campaign_id)}>
                                                                {
                                                                    this.state.expandState[row.campaign_id] ?
                                                                        <i className="fas fa-angle-down" /> : <i className="fas fa-angle-right" />
                                                                }
                                                            </button>
                                                        </td>
                                                        <td>{row.title}</td>
                                                        <td> {row.group_id}</td>
                                                        {/* <td>{row.from_email}</td> */}
                                                        <td>{row.phone_number}</td>
                                                        <td>
                                                            <Fragment>
                                                                {row.status == "Active" && (
                                                                    <Badge bg="success"> {row.status} </Badge>
                                                                )}
                                                                {row.status == "Inactive" && (
                                                                    <Badge bg="danger"> {row.status} </Badge>
                                                                )}
                                                            </Fragment>
                                                        </td>
                                                        <td>{row.created_at}</td>
                                                        <td className=""> {/* td-last add class name for display inline-block */}
                                                            <Fragment>
                                                                <Link
                                                                    type="button"
                                                                    color="info"
                                                                    className="btn btn-link btn-sm mb-1 mr-5"
                                                                    to={
                                                                        "/campaign/edit/" + bcrypt(row.campaign_id)

                                                                    }

                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>

                                                                <Link
                                                                    type="button"
                                                                    color="info"
                                                                    className="btn btn-link btn-sm mb-1 mr-5"
                                                                    to={
                                                                        `/campaign/outbounddialer/start?runId=${bcrypt(row.campaign_id)}&dialer_type=powerdialer`
                                                                    }

                                                                >
                                                                    <i className="fas fa-grip-horizontal"></i>
                                                                </Link>

                                                                {
                                                                    row.campaign_id!='124' && (
                                                                        <button
                                                                            className="btn btn-link btn-sm mb-1"
                                                                            onClick={this.deleteRecord.bind(this, row, index)}
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                    )
                                                                }


                                                                {/* )} */}
                                                            </Fragment>
                                                        </td>
                                                    </tr>
                                                    {
                                                        this.state.expandedRows == row.campaign_id ?
                                                            <tr>

                                                                <td colSpan={9} className="mid-td">
                                                                    <label
                                                                        className="main-label text-center w-100 mb-4">
                                                                        Reports Overview </label>
                                                                    <div className="row">
                                                                        <div className="col-md-2">
                                                                            <div className="progresss">
                                                                                <span className="labels mb-3">Total<br />Clients</span>
                                                                                <Knob
                                                                                    value={this.state.campaign_log.total_client}
                                                                                    height={100}
                                                                                    width={80}
                                                                                    fgColor="#4ac18e"
                                                                                    cursor={true}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2" >
                                                                            <div className="progresss">
                                                                                <span className="labels mb-3">Inbound<br />Calls</span>
                                                                                <Knob
                                                                                    value={this.state.campaign_log.inbound_call}
                                                                                    height={100}
                                                                                    width={80}
                                                                                    fgColor="#4ac18e"
                                                                                    cursor={true}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2" >
                                                                            <div className="progresss">
                                                                                <span className="labels mb-3">Inbound<br />Sms</span>
                                                                                <Knob
                                                                                    value={this.state.campaign_log.inbound_sms}
                                                                                    height={100}
                                                                                    width={80}
                                                                                    fgColor="#4ac18e"
                                                                                    cursor={true}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="progresss">
                                                                                <span className="labels mb-3">SMS Response<br />Rate</span>
                                                                                <Knob
                                                                                    value={this.state.campaign_log.sms_response_rate}
                                                                                    height={100}
                                                                                    width={80}
                                                                                    fgColor="#4ac18e"
                                                                                    cursor={true}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="progresss">
                                                                                <span className="labels mb-3">Email Open<br />Rate</span>
                                                                                <Knob
                                                                                    value={this.state.campaign_log.email_open_rate}
                                                                                    height={100}
                                                                                    width={80}
                                                                                    fgColor="#4ac18e"
                                                                                    cursor={true}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <div className="progresss">
                                                                                <span className="labels mb-3">RVM Success<br />Rate</span>
                                                                                <Knob
                                                                                    value={this.state.campaign_log.rvm_success_rate}
                                                                                    height={100}
                                                                                    width={80}
                                                                                    fgColor="#4ac18e"
                                                                                    cursor={true}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td colSpan={2} className="last-td d-none">
                                                                    <label className="text-center w-100 mb-4">Pipeline | Deal Status</label>
                                                                    <div className="row">
                                                                        <div className="col-md-6" >
                                                                            <div className="chart-data border-right">
                                                                                {
                                                                                    this.state.campaign_log.deal_status &&
                                                                                    this.state.campaign_log.deal_status.map((row, index) => {
                                                                                        return <p>{row.title}</p>

                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="chart">
                                                                                {
                                                                                    this.state.campaign_log.deal_status &&
                                                                                    this.state.campaign_log.deal_status.map((row, index) => {
                                                                                        return <div className={`common-tri ${row.random_keys}`}><span className="pipeline-count">{row.count}</span></div>

                                                                                    })
                                                                                }
                                                                                {/* <div className="common-tri unassigned"><span className="pipeline-count">0</span></div>
                                                                                <div className="common-tri proposal"><span className="pipeline-count">0</span></div>
                                                                                <div className="common-tri closed"><span className="pipeline-count">0</span></div>
                                                                                <div className="common-tri booked"><span className="pipeline-count">0</span></div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr> : null
                                                    }
                                                </>
                                            )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
