import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  // ModalTitle,
  Button,
} from "reactstrap";
import Carousel from "react-bootstrap/Carousel";

import Select from "react-select";
import Helmet from "react-helmet";
import "../../assets/css/courses_list.css";
import {
  ApiUrl,
  ProductName,
  AttachementsUrl,
  HostUrl,
  WebUrl,
} from "../../config";
import { uToken, uInfo } from "../../useToken";
import { Link, useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/courses_list.css";
import { toast, ToastContainer, Flip } from "react-toastify";
import RoadMap from "../../assets/images/roadmap.png";

export default function AdminTrainingPath() {
  const [badges, setBadges] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState([]);
  const [certificationModules, setCertificationModules] = useState([]);
  const [totalBadges, setTotalBadges] = useState(0);
  const [pageName, setPageName] = useState("Training Certifications");
  const [addModal, setAddModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);

  const [coursesModal, setCoursesModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const [cSelected, setCSelected] = useState([]);

  const [canEdit, setCanEdit] = useState(
    uInfo().user_id == 2 ? JSON.parse(localStorage.getItem("can_edit")) : false
  );

  const getAppreciationBadges = () => {
    fetch(`${ApiUrl}` + "training/certification/fetch", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      // body: JSON.stringify({ id: uInfo().user_id, status: badgeStatus }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records);
          setBadges(response.data.records);
          // setTotalBadges(response.data.total);
        } else {
          setBadges([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  // form submit from update modal
  const saveTrainingBadge = async (event) => {
    var updateURL;
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    updateURL = `${ApiUrl}` + "training/certification/save";

    fetch(`${updateURL}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          handleModalClose();
          if (!selectedBadge.id) {
            setBadges((prevBadges) => [...prevBadges, response.data.record]);
          } else {
            setBadges((prevBadges) =>
              prevBadges.map((badge) =>
                badge.id === selectedBadge.id ? response.data.record : badge
              )
            );
          }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  // SET MODULES BY CHECKBOXES FOR A TRAINING CERTIFICATE
  const saveCertificationModules = async (event) => {
    event.preventDefault();

    fetch(`${ApiUrl}` + "training/certification/modules", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: selectedBadge.id, module_ids: cSelected }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCoursesModal(false);
          setCSelected([]);
          if (!selectedBadge.id) {
            setBadges((prevBadges) => [...prevBadges, response.data.record]);
          } else {
            setBadges((prevBadges) =>
              prevBadges.map((badge) =>
                badge.id === selectedBadge.id ? response.data.record : badge
              )
            );
          }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  const getCourses = () => {
    fetch(`${ApiUrl}` + "get/waybook/category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        can_edit: "false",
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          setCourses(response.data.record);

          if (!canEdit) {
            // const modules = response.data.record.flatMap((course) =>
            //   course.sub_cat ? course.sub_cat : []
            // );
            // setModules(modules);

            const modules = response.data.record.flatMap((course) =>
              (course.sub_cat || []).map((subModule) => ({
                ...subModule,
                course_title: course.title,
              }))
            );
            setModules(modules);
          }
        } else {
          setCourses([]);
          setModules([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  const onCheckboxBtnClick = (catId) => {
    setCSelected((prevSelected) => {
      if (prevSelected.includes(catId)) {
        return prevSelected.filter((id) => id !== catId);
      } else {
        return [...prevSelected, catId];
      }
    });
  };

  useEffect(() => {
    getAppreciationBadges();
    getCourses();
  }, []);

  const handleModalClose = () => {
    setAddModal(false);
  };

  const editModalShow = (badge) => {
    setSelectedBadge(badge);
    setAddModal(true);
  };

  const changeHandler = (e) => {
    if (!e.target.files[0].type.match("image.*")) {
      swal({
        title: "Please upload a valid image file",
        icon: "warning",
      });
    }
  };

  const handleCardClick = (badge) => {
    const selectedIds = badge.module_ids
      ? badge.module_ids.split(",").map((id) => parseInt(id.trim(), 10))
      : [];

    setCSelected(selectedIds);
    setSelectedBadge(badge);
    if (canEdit) {
      setCoursesModal(true);
    } else {
      const filteredModules = modules.filter((module) =>
        selectedIds.includes(module.cat_id)
      );
      setCertificationModules(filteredModules);
      setDetailModal(true);
    }
  };

  // DRAG AND DROP FUNCTIONALITY

  const [draggedItemIndex, setDraggedItemIndex] = useState(null);

  const dragItem = useRef(null);

  const handleDragStart = (event, index) => {
    // setDraggedItemIndex(index);
    dragItem.current = index;
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();
    // if (draggedItemIndex === dropIndex) return;
    if (dragItem.current === dropIndex) return;
    const reorderedBadges = [...badges];
    // const [draggedBadge] = reorderedBadges.splice(draggedItemIndex, 1);
    const [draggedBadge] = reorderedBadges.splice(dragItem.current, 1);
    reorderedBadges.splice(dropIndex, 0, draggedBadge);
    dragItem.current = null;
    // setBadges(reorderedBadges);

    const updatedBadges = reorderedBadges.map((badge, index) => ({
      ...badge,
      rank_order: index + 1,
    }));
    setBadges(updatedBadges);
    updateBadgeOrder(updatedBadges);
  };

  const updateBadgeOrder = (reorderedBadges) => {
    // const orderedBadgeIds = reorderedBadges.map((badge) => badge.id);

    const orderedBadgeIds = reorderedBadges.map((badge) => badge.id);
    const rankOrder = reorderedBadges.map((badge) => badge.rank_order);

    fetch(`${ApiUrl}` + "training/certification/update/ranking", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        id: orderedBadgeIds,
        rank_order: rankOrder,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log(response.message);
        } else {
          console.log("Failed to update badge order");
        }
      })
      .catch((error) => {
        console.error("Error updating badge order:", error);
      });
  };
  // DRAG AND DROP FUNCTIONALITY

  const BadgeSection = ({ badges }) => {
    return (
      <>
        <Col lg="12" className="mb-1">
          <div className="mt-3">
            <div className="ps-2 pb-1 pe-1">
              <Form>
                <Row className="my-3 mx-1">
                  {badges.length > 0 ? (
                    badges.map((badge, index) => (
                      <Col lg="3" className="mb-3">
                        <div className="certification_sec">
                          <Card
                            className="d-flex flex-column cursor-pointer"
                            key={badge.id}
                            // draggable
                            // onDragStart={(event) => handleDragStart(event, index)}
                            // onDragOver={handleDragOver}
                            // onDrop={(event) => handleDrop(event, index)}

                            draggable={canEdit === true}
                            onDragStart={(event) =>
                              canEdit === true && handleDragStart(event, index)
                            }
                            onDragOver={(event) =>
                              canEdit === true && handleDragOver(event)
                            }
                            onDrop={(event) =>
                              canEdit === true && handleDrop(event, index)
                            }
                            style={{
                              borderTop: `6px solid ${badge.background_color}`,
                              borderBottom: `6px solid ${badge.background_color}`,
                            }}
                            onClick={() => handleCardClick(badge)}
                            // onMouseEnter={() => {setIsHovered(true) ; handleCardClick(badge)}}
                            // onMouseLeave={() => {setIsHovered(false) ; }}
                          >
                            <div className="RecentlyViewed text-center d-flex flex-grow-1 flex-column justify-content-center">
                              <div className="mb-2 py-2 px-2">
                                <div className="SliderContent text-center">
                                  <h5 className=" fw-bold pb-1 ">
                                    {badge.title}
                                  </h5>
                                </div>
                                <img
                                  src={`${AttachementsUrl}user_${badge.user_id}/training_badges/${badge.image}`}
                                  alt={badge.title}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                />
                              </div>
                            </div>

                            <div className="position-absolute top-0 end-0 p-1">
                              <button
                                type="button"
                                className="btn-danger btn-sm d-none"
                              >
                                <i className="mdi mdi-delete"></i>
                              </button>

                              {canEdit && (
                                // <button
                                //   type="button"
                                //   className="btn-primary btn-sm me-0 px-1 py-0 draggable drag-handle document-drag-handle has-tooltip"
                                //   draggable
                                //   onDragStart={(event) =>
                                //     handleDragStart(event, index)
                                //   }
                                //   style={{
                                //     cursor: "move",
                                //   }}
                                // >
                                <i
                                  className="fas fa-arrows-alt certificaion_icon draggable drag-handle document-drag-handle has-tooltip"
                                  draggable
                                  onDragStart={(event) =>
                                    handleDragStart(event, index)
                                  }
                                  style={{
                                    cursor: "move",
                                  }}
                                ></i>

                                // {/* </button> */}
                              )}

                              {canEdit && (
                                // <button
                                //   type="button"
                                //   className="ms-1 btn-primary btn-sm px-1 py-0"
                                //   onClick={(event) => {
                                //     event.stopPropagation();
                                //     editModalShow(badge);
                                //   }}
                                // >
                                <i
                                  className="fas fa-edit certificaion_icon"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    editModalShow(badge);
                                  }}
                                ></i>
                                // </button>
                              )}
                            </div>
                            <div className="position-absolute top-0 left-0">
                              <small>
                                <p class="m-1 fw-bolder fst-italic text-center">
                                  {badge.rank_order}{" "}
                                </p>
                              </small>
                            </div>
                          </Card>
                          {/* {isHovered && (selectedBadge.id === badge.id) && ( */}
                          <div
                            className="certification_div d-none"
                            // className={`${
                            //   coursesModal
                            //     ? "certification_div_show"
                            //     : "certification_div_hide"
                            // }`}
                            toggle={() => {
                              setCSelected([]);
                              setCoursesModal(false);
                            }}
                          >
                            <div className="form-action">
                              <form
                                method="post"
                                onSubmit={saveCertificationModules}
                                encType="multipart/form-data"
                                className="form-horizontal"
                                id="selection-myform"
                              >
                                <div className="modal-content">
                                  <div className="text-transform-capitalize p-2 UpdateInformationUser">
                                    <div>Certification Requirements</div>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setCSelected([]);
                                        setCoursesModal(false);
                                      }}
                                      className="btn btn-link pull-right BtnCenterClose me-end"
                                    >
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>

                                  <div>
                                    <div>{allCourses}</div>
                                  </div>

                                  <div className="modal-footer d-inline-block">
                                    <div className="d-flex justify-content-end">
                                      <button
                                        onClick={() => {
                                          setCSelected([]);
                                          setCoursesModal(false);
                                        }}
                                        type="button"
                                        className="bwaves-effect waves-light btn btn-outline-dark btn-sm"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-primary ms-2 btn-sm"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      </Col>
                    ))
                  ) : (
                    <h5 className="text-center">
                      No badges found in this section
                    </h5>
                  )}
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </>
    );
  };

  const generateCoursesTree = () => {
    if (!courses || courses.length === 0)
      return (
        <p className="text-center">
          No courses available. Please fetch or select a course.
        </p>
      );

    return (
      <>
        {courses.map((course) => (
          <>
            <ul className="tree Course">
              <li className="CourseName">
                <div className="CourseTag">
                  <button className="btn btn-success">C</button>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <span className="fw-bolder ">{course.title}</span>
                  </div>
                </div>

                <ul className="MOduleSec">
                  {course.sub_cat &&
                    course.sub_cat.map((module) => (
                      <li className="ModuleTitle" key={module.cat_id}>
                        <div className="CourseTag ModuleTag">
                          <button className="btn btn-info">M</button>
                        </div>
                        <div className="d-flex mt-3 ms-2 mb-2 flex-column">
                          <div className="d-flex">
                            <span className="fw-bold">{module.title}</span>

                            <div className="ms-3 d-flex">
                              <input
                                style={{ width: "15px", height: "15px" }}
                                className="form-check-input badge_check m-0"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value="option1"
                                onClick={() =>
                                  onCheckboxBtnClick(module.cat_id)
                                }
                                defaultChecked={cSelected.includes(
                                  module.cat_id
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
            <hr className="dotted" />
          </>
        ))}
      </>
    );
  };

  const allCourses = generateCoursesTree();

  // CERTIFICATES SECTION FOR COACHES
  const stepClasses = ["One", "Two", "Three", "Four", "Five"];

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="mb-0">
            <div className="TraningRightContent DashboardCard TraningCoachDash">
              <Row className="mx-1">
                <Col sm={6}>
                  <CardTitle className="mb-3 fw-bold ">
                    Training Certifications
                  </CardTitle>
                </Col>
                <Col sm={6}>
                  {canEdit && (
                    // <div className="page-title-box text-align-right">
                    //   <button
                    //     type="button"
                    //     className="btn btn-success"
                    //     // to={`/cms/${path}/add`}
                    //     onClick={() => {
                    //       setSelectedBadge([]);
                    //       setCSelected([]);
                    //       setAddModal(true);
                    //     }}
                    //   >
                    //     <i className="mdi mdi-plus"></i>
                    //     Create
                    //   </button>
                    // </div>

                    <div class="NextBackBtn edit-step-action text-align-right">
                      <button
                        class="BackBtn btn btn-secondary btn-sm"
                        onClick={() => {
                          setSelectedBadge([]);
                          setCSelected([]);
                          setAddModal(true);
                        }}
                      >
                        <i className="mdi mdi-plus me-1"></i>
                        Create{" "}
                      </button>
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <BadgeSection badges={badges} />
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      {/* ADD BADGE MODAL */}
      <Modal
        isOpen={addModal}
        role="dialog"
        centered={true}
        className="exampleModal modal-md UpdateInformationModal --"
        tabIndex="-1"
        toggle={() => {
          setAddModal(false);
        }}
      >
        <div className="modal-content">
          <ModalHeader className="text-transform-capitalize p-2 UpdateInformationUser text-center text-white m_header">
            <div className="text-center">
              {selectedBadge.id
                ? "Update Training Certification"
                : "New Training Certification"}
            </div>
            {/* <button
              onClick={() => handleModalClose()}
              className="btn btn-link pull-right BtnCenterClose me-end"
            >
              <i className="fas fa-times"></i>
            </button> */}
          </ModalHeader>

          <ModalBody>
            <div className="form-action">
              <form
                method="post"
                onSubmit={saveTrainingBadge}
                encType="multipart/form-data"
                className="form-horizontal"
                id="selection-myform"
              >
                <div className="form-group mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="form-label mb-0"
                  >
                    Name
                  </label>
                  <input
                    defaultValue={selectedBadge.title || ""}
                    placeholder={`Enter Title`}
                    required
                    className="form-control"
                    type={"text"}
                    name="title"
                  />

                  <input
                    type={"hidden"}
                    name="id"
                    value={selectedBadge.id || ""}
                  />
                </div>

                <div className="form-group mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="form-label mb-0"
                  >
                    Badge Image
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      accept="image/*"
                      className="form-control UploadImage me-2"
                      type={"file"}
                      onChange={(e) => changeHandler(e)}
                      name="image"
                    />
                    {selectedBadge.image && (
                      <img
                        height={40}
                        width={40}
                        src={`${AttachementsUrl}/user_${selectedBadge.user_id}/training_badges/${selectedBadge.image}`}
                        alt=""
                      />
                    )}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="description" className="form-label mb-0">
                    Description
                  </label>
                  <textarea
                    defaultValue={selectedBadge.description || ""}
                    placeholder="Enter description"
                    className="form-control"
                    name="description"
                    rows="3"
                  ></textarea>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="color-input" className="form-label mb-0">
                    Background Color
                  </label>
                  <input
                    type="color"
                    id="color-input"
                    name="background_color"
                    className="form-control"
                    defaultValue={selectedBadge.background_color || "#000000"}
                    required
                  />
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => handleModalClose()}
                    type="button"
                    className="btn-primary btn btn-sm"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="BackBtn btn btn-secondary ms-2 btn-sm "
                  >
                    {selectedBadge.id ? "Update" : "Create"}
                  </button>
                </div>
              </form>
            </div>
          </ModalBody>
        </div>
      </Modal>

      {/* COURSES MODAL */}

      <Modal
        isOpen={coursesModal}
        role="dialog"
        centered={true}
        className="exampleModal modal-md UpdateInformationModal --"
        tabIndex="-1"
        toggle={() => {
          setCSelected([]);
          setCoursesModal(false);
        }}
      >
        <div className="form-action ">
          <form
            method="post"
            onSubmit={saveCertificationModules}
            encType="multipart/form-data"
            className="form-horizontal"
            id="selection-myform"
          >
            <div className="modal-content">
              <ModalHeader className="text-transform-capitalize p-2 m_header text-white UpdateInformationUser">
                <div>Certification Requirements</div>
                {/* <button
                  type="button"
                  onClick={() => {
                    setCSelected([]);
                    setCoursesModal(false);
                  }}
                  className="btn btn-link pull-right BtnCenterClose me-end"
                >
                  <i className="fas fa-times"></i>
                </button> */}
              </ModalHeader>

              <ModalBody>
                <div>{allCourses}</div>
              </ModalBody>

              <ModalFooter className="modal-footer d-inline-block">
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => {
                      setCSelected([]);
                      setCoursesModal(false);
                    }}
                    type="button"
                    className="btn-secondary btn btn-sm"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary ms-2 btn-sm">
                    Add
                  </button>
                </div>
              </ModalFooter>
            </div>
          </form>
        </div>
      </Modal>

      {/* CERTIFICATION DETAIL MODAL */}

      <Modal
        isOpen={detailModal}
        role="dialog"
        centered={true}
        className="exampleModal modal-lg UpdateInformationModal --"
        tabIndex="-1"
        toggle={() => {
          setCSelected([]);
          setDetailModal(false);
        }}
      >
        <div className="form-action">
          <div className="modal-content">
            <ModalHeader className="text-transform-capitalize p-2 UpdateInformationUser">
              <div>Training Path View</div>
              <button
                type="button"
                onClick={() => {
                  setCSelected([]);
                  setDetailModal(false);
                }}
                className="btn btn-link pull-right BtnCenterClose me-end"
              >
                <i className="fas fa-times"></i>
              </button>
            </ModalHeader>

            <ModalBody className="ModalRoadMap">
              <Row>
                <Col lg={3}>
                  <Card
                    className=" d-flex flex-column"
                    key={selectedBadge.id}
                    style={{
                      borderTop: `6px solid ${selectedBadge.background_color}`,
                      borderBottom: `6px solid ${selectedBadge.background_color}`,
                    }}
                  >
                    <div className="RecentlyViewed text-center d-flex flex-grow-1 flex-column justify-content-center">
                      <div className="mb-2 py-2 px-2">
                        <div className="SliderContent text-center">
                          <h5 className=" fw-bold pb-1 ">
                            {selectedBadge.title}
                          </h5>
                        </div>
                        <img
                          src={`${AttachementsUrl}user_${selectedBadge.user_id}/training_badges/${selectedBadge.image}`}
                          alt={selectedBadge.title}
                          style={{
                            width: "70px",
                            height: "70px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="position-absolute top-0 left-0">
                      <small>
                        <p class="ms-1 fw-bold fst-italic text-center">
                           {selectedBadge.rank_order}{" "}
                        </p>
                      </small>
                    </div>
                  </Card>
                </Col>
                <Col lg={6}>
                  <p>{selectedBadge.description}</p>
                </Col>
                <Col lg={12}>
                  {/* {allModules} */}
                  <div className="RoadMapPoints">
                    <img src={RoadMap} />
                    <div className="RoadMapSteps">
                      {/* <div className="Step One">
                        <img src="http://192.168.5.55/profitcoach/crmdoc/user_2/training_badges/badge_1730452845.png" />
                        <div className="PointContent">
                          <h5>PROFITUNIVERSITY</h5>
                          <h3>Deep Dive Strategies</h3>
                        </div>
                      </div>
                      <div className="Step Two">
                        <img src="http://192.168.5.55/profitcoach/crmdoc/user_2/training_badges/badge_1730452845.png" />
                        <div className="PointContent">
                          <h5>PROFITUNIVERSITY</h5>
                          <h3>The Virtual MBA</h3>
                        </div>
                      </div>
                      <div className="Step Three">
                        <img src="http://192.168.5.55/profitcoach/crmdoc/user_2/training_badges/badge_1730452845.png" />
                        <div className="PointContent">
                          <h3>
                            Pathway To
                            <br />
                            Profit Strategies
                          </h3>
                          <h5>PROFITUNIVERSITY</h5>
                        </div>
                      </div>
                      <div className="Step Four">
                        <img src="http://192.168.5.55/profitcoach/crmdoc/user_2/training_badges/badge_1730452845.png" />
                        <div className="PointContent">
                          <h5>Diamond Partner</h5>
                          <h3>
                            Diamond Partner
                            <br />
                            Program
                          </h3>
                        </div>
                      </div>
                      <div className="Step Five">
                        <img src="http://192.168.5.55/profitcoach/crmdoc/user_2/training_badges/badge_1730452845.png" />
                        <div className="PointContent">
                          <h3>
                            Welcome to
                            <br />
                            PROFITCOACH
                            <br />
                            Business Center
                          </h3>
                          <h5>Welcome to PROFITCOACH</h5>
                        </div>
                      </div> */}

                      {certificationModules.slice(0, 5).map((module, index) => (
                        <div
                          className={`Step ${stepClasses[4 - index]}`}
                          key={index}
                        >
                          {module.badge_image ? (
                            <img
                              src={`${AttachementsUrl}/user_${module.user_id}/training/${module.badge_image}`}
                              alt=""
                            />
                          ) : (
                            module.image && (
                              <img
                                src={`${AttachementsUrl}/user_${module.user_id}/training/${module.image}`}
                                alt=""
                              />
                            )
                          )}

                          <div className="PointContent">
                            {4 - index == 1 || 2 || 4 ? (
                              <>
                                <h5>{module.course_title}</h5>
                                <h3>{module.title}</h3>
                              </>
                            ) : (
                              <>
                                <h3>{module.title}</h3>
                                <h5>{module.course_title}</h5>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>

            {/* <ModalFooter className="modal-footer d-inline-block">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setCSelected([]);
                    setDetailModal(false);
                  }}
                  type="button"
                  className="bwaves-effect waves-light btn btn-outline-dark"
                >
                  Close
                </button>
              </div>
            </ModalFooter> */}
          </div>
        </div>
      </Modal>
    </>
  );
}
