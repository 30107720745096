import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
//Import Action to copy breadcrumb items from local state to redux state
import UrlImageDownloader from "react-url-image-downloader";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Loader from "react-loader";

export default class smsTemplatesAddEdit extends Component {
  constructor(props) {
    super(props);

    const obj = JSON.parse(localStorage.getItem("authUser"));

    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      permissions: [],
      user_id: obj.user_id,
      record: {
        title: "",
        subject: "",
        body: "",
        type: "",
      },
      selected_file: "",
      page_name: this.props.match.params.id
        ? "Edit VoiceMail Template"
        : "Create VoiceMail Template",
      customfields: [],

      field: "",
      appendContents: "",
      editor: "",
      type: "",
      file_type: "",
      smstokens: [],
      default_fields: [],
      filetypemodal: false,
      path: '',
      loader: true,
      seconds: 0,
      isRunning: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getcoachdata();
    this.intervalId = setInterval(() => {
      if (this.state.isRunning) {
        this.setState((prevState) => ({
          seconds: prevState.seconds + 1,
        }));
      }
    }, 1000);
    //this.onChangeTypeAction(this.state.type);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }


  closemodal = () => {
    this.setState({
      filetypemodal: !this.state.filetypemodal,
    });
  };
  onChangeTypeAction = (value) => {
    var x = document.getElementById("text_body");
    var y = document.getElementById("mediafile_field");
    var z = document.getElementById("record");
    if (value == "text") {
      this.setState({
        type: "text",
      });
      x.style.display = "block";
      y.style.display = "none";
      z.style.display = "none";
    } else {
      this.setState({
        type: "file",
        file_type: "record",
      });
      x.style.display = "none";
      y.style.display = "block";
      z.style.display = "block";
    }
  };

  onChangefileTypeAction = (value) => {
    var x = document.getElementById("record");
    var y = document.getElementById("upload");
    if (value == "record") {
      this.setState({
        file_type: "record",
      });
      x.style.display = "block";
      y.style.display = "none";
    } else {
      this.setState({
        file_type: "upload",
      });
      x.style.display = "none";
      y.style.display = "block";
    }
  };
  changeHandler = (e) => {
    let selected_file = [];
    var reader = new FileReader();
    reader.onload = () => {
      selected_file.push(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({
      selected_file: selected_file,
    });
  };

  start = () => {
    // console.log(RecordState.START, 'DEBUGGINGG');
    this.setState({
      recordState: RecordState.START,
      isRunning: true,
    });
  };

  stop = () => {
    this.setState({
      recordState: RecordState.STOP,
      seconds: 0,
      isRunning: false,
    });
  };
  //audioData contains blob and blobUrl
  onStop = (audioData) => {
    this.setState({ audioData: audioData, path: audioData.url })
    let selected_file = [];
    var reader = new FileReader();
    reader.readAsDataURL(audioData.blob);
    reader.onloadend = function () {
      selected_file.push(reader.result);
      // base64data = reader.result;
      // console.log(base64data, 'base');
    }


    this.setState({
      selected_file: selected_file,
      file_type_validate: "wav",
    });
  };

  getcoachdata = () => {
    fetch(`${ApiUrl}` + "get/coachdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            default_fields: this.parsecustomfields(
              response.data.record.default_fields
            ),
            permissions: response.data.coachpartners,
          });

          var custom_fields = this.parsecustomfields(
            response.data.record.custom_fields
          );
          custom_fields.map((field) => {
            this.setState({
              customfields: [...this.state.customfields, field],
            });
          });
          this.setState({
            smstokens: [
              {
                label: "Default Fields",
                options: this.state.default_fields,
              },
              {
                label: "Custom Fields",
                options: this.state.customfields,
              },
            ],
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  parsecustomfields = (data) => {
    return data.map((customfield) => {
      return { label: customfield.label, value: customfield.key };
    });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/editvoicemailtemplate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedPermission: response.data.permissions,
            path: response.data.path
            //type: response.data.record.type,
            // inputList: JSON.parse(response.data.record.description)
          });



          if (this.state.record.type == "text") {
            this.setState({
              type: "text",
              editor: response.data.record.body,
            });
          }
          this.onChangeTypeAction(this.state.type);

          if (this.state.record.type == "mp3") {
            this.setState({
              type: "file",
              editor: "",
            });

            this.onChangefileTypeAction('upload')

          }

          if (this.state.record.type == "wav") {
            this.setState({
              type: "file",
              editor: "",
            });
            this.onChangefileTypeAction('record')

          }

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    values.body = this.state.editor;
    values.media_file = this.state.selected_file;
    values.file_type_validate = this.state.file_type_validate;
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }

    this.setState({ loader: false });


    fetch(`${ApiUrl}` + "edit/voicetemplate", {
      method: "POST",
      contentType: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      files: true,
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
            this.setState({
              editor: "",
            });
          }
          // this.getData();
          //  toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          this.props.history.goBack();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        this.setState({ loader: true });

      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });
        console.error("Error:", error);
      });
  };

  changeEditor = (field) => {
    this.setState({
      field: field,
    });

    this.appendcontent(field.value);
  };
  appendcontent = (value) => {
    this.setState({
      editor: this.state.editor + "{" + value + "}",
    });
  };

  handleChange = (e) => {
    this.setState({
      editor: e.target.value,
    });
  };

  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState({
      permission: filter.toString(),
      selectedPermission: selectedPermission,
    });
  };

  downloadimage = (e) => {
    e.preventDefault();
    fetch(
      "https://cors-anywhere.herokuapp.com/" +
      AttachementsUrl +
      "user_" +
      this.state.user_id +
      "/attachment/" +
      this.state.record.media_file,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/image",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // console.log(blob, "image data");
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.png`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });

    // let a = document.createElement("a");
    // a.href =  AttachementsUrl+"/user_"+this.state.user_id+"/attachment/"+this.state.record.media_file;
    // a.setAttribute("download", fileName);
    // a.click();
  };
  mediafilerecording = (e) => {
    const file = e.target.files[0];

    if (file.type == "audio/mpeg") {
      let selected_file = [];
      var reader = new FileReader();
      reader.onload = () => {
        selected_file.push(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      this.setState({
        selected_file: selected_file,
        file_type_validate: "mp3",
        path: URL.createObjectURL(e.target.files[0])
      });
    } else if (file.type == "audio/wav") {
      let selected_file = [];
      var reader = new FileReader();
      reader.onload = () => {
        selected_file.push(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      this.setState({
        selected_file: selected_file,
        file_type_validate: "wav",
        path: URL.createObjectURL(e.target.files[0])
      });
    } else if (file.type !== "audio/wav" || file.type !== "audio/mpeg") {
      this.setState({
        filetypemodal: !this.state.filetypemodal,
      });
    }
  };
  download = (e) => {
    fetch(
      "https://cors-anywhere.herokuapp.com/" +
      AttachementsUrl +
      "user_" +
      this.state.user_id +
      "/attachment/" +
      this.state.record.media_file,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "image.png"); //or any other extension
          // document.body.appendChild(link);
          // link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { recordState } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} Tables </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />
        <Loader loaded={this.state.loader}></Loader>
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
                enctype="multipart/form-data"
              >
                <ModalBody>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      value={this.state.record.name}
                      label="Name"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3 d-none">
                    <label> Type </label>
                    <AvRadioGroup
                      value={this.state.type}
                      inline
                      name="type"
                      onChange={(e) => this.onChangeTypeAction(e.target.value)}
                    >
                      <AvRadio label="Text" value="text" />
                      <AvRadio label="File" value="file" />
                    </AvRadioGroup>
                  </div>

                  <div id="text_body" style={{ display: "none" }}>
                    <div className="mb-3">
                      <AvGroup>
                        <label>Token </label>
                        <Select
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          isMulti={false}
                          options={this.state.smstokens}
                          classNamePrefix="select form-control"
                          style={{ position: "absolute" }}
                          className={"is-touched is-dirty av-valid"}
                          name="field"
                          onChange={(e) => {
                            this.changeEditor(e);
                          }}
                        />
                      </AvGroup>
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="body"
                        value={this.state.editor}
                        label="Body"
                        className="form-control"
                        type="textarea"
                        rows={5}
                        onChange={(e) => this.handleChange(e)}
                        cols={5}
                      />
                    </div>

                  </div>
                  <div id="mediafile_field" style={{ display: "none" }}>
                    <div className="mb-3">
                      <AvRadioGroup
                        value={this.state.file_type}
                        inline
                        name="file_type"
                        onChange={(e) =>
                          this.onChangefileTypeAction(e.target.value)
                        }
                      >
                        <AvRadio label="Record" value="record" />
                        <AvRadio label="Upload" value="upload" />
                      </AvRadioGroup>
                    </div>


                  </div>

                  <div id="record" style={{ display: "none" }}>
                    <div className="row">
                      <div className="col-3" >
                        <h6>Time: {this.state.seconds} seconds</h6>
                        <AudioReactRecorder state={recordState}
                          onStop={this.onStop}
                          backgroundColor="rgb(255,255,255)"
                        />
                        <audio id="audio"
                          controls src={
                            this.state.audioData ? this.state.audioData.url : this.state.path ? this.state.path : null
                          }>
                        </audio>
                      </div >
                      <div className="col-6 mt-2" >
                        <Button type="button"
                          color="secondary"
                          onClick={this.start} >
                          Start </Button>
                        <Button type="button" className="ml-3"
                          color="secondary"
                          onClick={this.stop} >
                          Stop </Button>
                      </div >
                    </div>
                  </div>

                  <div id="upload" style={{ display: "none" }}>
                    <label className="mr 5"> Media File</label>
                    <a
                      href={
                        this.state.path
                      }
                      download
                      className="ml 5 pull-right"
                      target="blank"
                    >
                      <i className="fa fa-download ml 5" />
                      download
                    </a>
                    <AvField
                      name="media_file"
                      value=""
                      className="form-control"
                      type="file"
                      accept="audio/*"
                      onChange={(e) => this.mediafilerecording(e)}
                    />
                  </div>
                  {/* {this.state.path && (
                      <div className="col-md-12 mb-3 pull-right">
                        <audio 
                        className="pull-right mt-3"
                          controls src={
                          this.state.path ? this.state.path :  null
                        }>
                        </audio>
                      </div>
                    )} */}
                  <div className="col-md-12 mb-3 d-none">
                    <label className="control-label"> Assign to </label>
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.user_id}
                      isMulti={true}
                      value={this.state.selectedPermission}
                      options={this.state.permissions}
                      classNamePrefix="select form-control"
                      style={{ position: "absolute" }}
                      className={"is-touched is-dirty av-valid"}
                      onChange={(e) => {
                        this.handlePermission(e);
                      }}
                    />
                    <AvField

                      type="hidden"
                      name="permission"
                      value={this.state.permission}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
              <Modal isOpen={this.state.filetypemodal} fade={false}>
                <ModalBody>
                  <div className="warning-box text-center">
                    <span className="warning">
                      <i className="mdi mdi-alert-circle"></i>
                    </span>
                    <h3>Please select file with valid type</h3>
                    <p> Format should be MP3 or Wav file.</p>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={() => this.closemodal()}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Card>
          </Col>
        </Row>
      </React.Fragment >
    );
  }
}
