import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";

import { currencies } from "../../common/Common-Currency";
import AsyncSelect from "react-select/async";
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.assessment_id != "" ? this.props.assessment_id : "",
      record: {},
      currency_arr: {},
      module_set_arr: {},
      currency_code_value: {},
      clients: [],
      client_id: this.props.client_id ? this.props.client_id : 0,
      permissions: [],
      permission: [],
    };
    console.log(this.props);
  }

  componentDidMount() {
    this.getFormData();
    if (this.state.id != "") {
      this.getData();
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.assessment_id != this.props.assessment_id) {
      this.setState({
        id: this.props.assessment_id != '' ? this.props.assessment_id : '',
        record: {},
      }, () => this.getData())
    }
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/assessment/data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        // client_id: this.state.client_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedPermission: response.data.record.permissions,
            currency_code: response.data.record.currency_code,
            client_id: response.data.record.client_id
          });
          this.getCurrency();
          this.changeformat(response.data.record.currency_code);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/assessment/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.status === true) {
          this.setState({
            clients: response.data.clients,
            permissions: response.data.coachPartners,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getCurrency = () => {
    var currency_arr = [];
    Object.keys(currencies).map((item) => {
      currency_arr.push({
        label:
          currencies[item].symbol +
          "- (" +
          currencies[item].name_plural +
          ") (" +
          currencies[item].code +
          ")",
        value: item,
      });
    });

    this.setState({ currency_arr: currency_arr });
  };
  changeformat = (currencycode) => {
    console.log(currencycode, 'dddddd');
    var currency_code_value = [];
    Object.keys(currencies).map((item) => {
      if (item == currencycode) {
        currency_code_value.push({
          label:
            currencies[item].symbol +
            "- (" +
            currencies[item].name_plural +
            ") (" +
            currencies[item].code +
            ")",
          value: item,
        });
        this.setState({
          currency_code: currencycode
        });
      }
    });

    this.setState({ currency_code_value: currency_code_value });
  };

  handleSubmit = async (event, values) => {
    console.log(values);
    if (this.state.client_id != "") {
      values.client_id = this.state.client_id;
    }
    if (this.state.id) {
      values.id = this.state.record.assessment_id ? this.state.record.assessment_id : -1;
    }
    fetch(`${ApiUrl}` + "edit/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          this.props.cancelSlider();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleCurrency = (selectedCurrency) => {

    this.setState({
      currency_code: selectedCurrency,
    });
  };




  handleInputChange = (inputValue) => {
    // const inputValue = newValue.replace(/\W/g, '');
    if (inputValue.length > 3) {
      fetch(`${ApiUrl}` + "search/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },

        body: JSON.stringify({ search: inputValue }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            this.setState({
              clients: response.data.clients
            });
          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    console.log(inputValue.length);
  };
  handlePermission = (selectedPermission) => {

    var filter = [];
    if (selectedPermission) {
      selectedPermission.map(fil => {
        filter.push(fil.user_id);
      })
    }

    this.setState({
      permission: filter.toString(),
      selectedPermission: selectedPermission
    })
  }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <AvForm
              onValidSubmit={this.handleSubmit}
              ref={(c) => (this.form = c)}
              className="needs-validation"
            >
              <ModalBody className="row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="name"
                    value={this.state.record.name}
                    label="Name"
                    className="form-control"
                    required
                  />
                </div>

                {
                  this.props.from === true && (
                    <div className="col-md-12 mb-3 d-none">
                      <label className="control-label">Lead/Client</label>
                      <div className="MainTZone ClientDetailsUpdate One">
                        <Select
                          // getOptionLabel={option => option.type == 'client' ? option.first_name + ' ' + option.last_name : option.company_name}
                          getOptionLabel={option => option.client_id + ' # ' + option.first_name + ' ' + option.last_name + ((option.email != '') ? ' - ' + option.email : ' - ' + option.country_code + option.mobile)}
                          getOptionValue={option => option.client_id}
                          isMulti={false}
                          value={this.state.clients.filter(({ client_id }) => client_id === this.state.client_id)}
                          options={this.state.clients}
                          classNamePrefix="select2-selection"
                          onInputChange={this.handleInputChange}
                          onChange={(e) => { this.setState({ client_id: e.client_id }); }}
                        />
                      </div>
                      <AvField required type="hidden" name="adc" value={this.state.client_id} />
                    </div>
                  )
                }

                <div className="col-md-12 mb-3">
                  <div className="MainTZone ClientDetailsUpdate Two">
                    <Select
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      isMulti={false}
                      options={this.state.currency_arr}
                      value={this.state.currency_code_value}
                      onChange={(e) => {
                        this.changeformat(e.value);
                      }}
                      classNamePrefix="select form-control"
                      className={"is-touched is-dirty av-valid"}
                    />
                  </div>
                  <AvField
                    type="hidden"
                    name="assessment_currency"
                    value={this.state.currency_code}
                  />
                </div>
                <div className="col-md-12 mb-3 d-none">
                  <AvField
                    className="select form-control"
                    type="select"
                    name="module"
                    label=""
                    value={this.state.record.module}

                    required
                  >
                    <option value="">Select Module Set</option>
                    <optgroup label="Profit Acceleration">
                      <option value="1">
                        Jumpstart 12 (To close a sale)
                      </option>
                      <option value="2">
                        Jumpstart 40 (To close a sale or perform a faster full
                        assessment)
                      </option>
                      <option value="3">
                        Deep Dive 40 (To perform a full assessment)
                      </option>
                    </optgroup>
                    <optgroup label="Digital Acceleration">
                      <option value="4">
                        Jumpstart (To close a sale with a digital marketing
                        focus)
                      </option>
                      <option value="5">
                        Deep Dive (To perform a full assessment with a digital
                        marketing focus)
                      </option>
                    </optgroup>
                  </AvField>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="control-label">Assign to</label>
                  <div className="MainTZone ZIndex">
                    <Select
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.user_id}
                      isMulti={true}
                      value={this.state.selectedPermission}
                      options={this.state.permissions}
                      classNamePrefix="select2-selection"
                      onChange={(e) => {
                        this.handlePermission(e)
                      }}
                    />
                    <AvField type="hidden" name="permission" value={this.state.permission} />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-primary waves-effect waves-light btn-sm"
                  type="submit"
                >
                  Submit
                </button>
              </ModalFooter>
            </AvForm>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
