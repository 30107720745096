import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import swal from 'sweetalert';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Loader from 'react-loader';
export default class UserOperation extends Component {
  constructor(props) {
    super(props);
    //console.log(dcrypt(this.props.match.params.id), 'debug');
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {
        payment_method: "manual",
        payment_type: "recurring",
      },
      page_name: this.props.match.params.id ? "Edit Coach" : "Create Coach",
      roles: [],
      packages: [],
      resellers: [],
      payment_fields_visible: 'hidden',
      isRequired: true,
      loaded: true,
    };
    // console.log(this.state.page_name, "debug");
  }

  componentDidMount() {
    if (this.state.id) {
      this.getData();
    }
    this.getPackages();
    this.getResellers();
    this.onChangePaymentAction('manual');
  }
  onChangePaymentAction = (value) => {

    this.setState({ record: { payment_method: value } });
    if (value == "stripe") {
      this.setState({ payment_fields_visible: 'visible' });
      this.setState({ isRequired: true });

    } else {
      this.setState({ payment_fields_visible: 'hidden' });
      this.setState({ isRequired: false });
    }
  };
  getPackages = (role) => {
    fetch(`${ApiUrl}` + "packages/list/Coach?u_type=coach", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            packages: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getResellers = () => {
    if (this.props.role.id == 1) {
      fetch(`${ApiUrl}` + "resellers", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            this.setState({
              resellers: response.data.records,
            });
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({
        resellers: [],
      });
    }
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            roles: response.data.roles,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.user_id ? this.state.record.user_id : -1;
    }

    values.action_by = 'Coach';




    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "edit/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }


          this.props.history.push("/users/coach");
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          let wrapper = document.createElement('div');
          wrapper.innerHTML = data.message;
          swal({
            title: "Error!",
            content: wrapper,
            icon: "error"
          });

          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    const paymentStyle = {
      visibility: this.state.payment_fields_visible,
      height: (this.state.payment_fields_visible == 'visible') ? 'auto' : '0',
      paddingTop: (this.state.payment_fields_visible == 'visible') ? 'auto' : '0',
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>

            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">

                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">

                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Loader loaded={this.state.loaded} className="spinner" />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="row">
                  <div className="mb-3">
                    <AvField
                      name="first_name"
                      label="First Name"
                      value={this.state.record.first_name}
                      className="form-control"
                      placeholder="Enter first Name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="last_name"
                      label="Last Name"
                      value={this.state.record.last_name}
                      className="form-control"
                      placeholder="Enter last Name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      value={this.state.record.email}
                      className="form-control"
                      placeholder="Enter Email Address"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="phone"
                      label="Phone"
                      value={this.state.record.phone}
                      className="form-control"
                      placeholder="Enter phone with country code"
                      required
                    />
                  </div>

                  {this.state.id == undefined && (
                    <>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value={this.state.record.password}
                          className="form-control"
                          placeholder="Enter Password"
                          minLength={8}
                          errorMessage="Password must be at least 8 characters"
                          required
                          type="password"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          minLength={8}
                          errorMessage="Confirm password must be at least 8 characters and same as password"
                          name="confirm_password"
                          label="Confirm Password"
                          type="password"
                          value={this.state.record.password}
                          className="form-control"
                          placeholder="Enter Confirm Password"
                          validate={{ match: { value: "password" } }}
                          required
                        />
                      </div>
                    </>
                  )}
                  {this.state.page_name == "Create Coach" && (
                    <>
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="package_id"
                          value={this.state.record.package_id}
                          label="Package"
                          required
                        >
                          <option value={""}> Select Package </option>
                          {/* <optgroup label="Monthly Packages"> */}

                          {this.state.packages.map((row) => {
                            if (row.package_interval == "Monthly" && row.status == "Active" ) {
                              return (
                                <option
                                  key={row.package_id}
                                  value={row.package_id}
                                >

                                  {`${row.name} (${row.type} - ${row.price}) ${row.package_interval} Subscription | Setup Fee: ${row.setup_price}  ( Monthly Installment:${row.setup_fee_installment})`}


                                  {/* {`${row.name} (${row.type} - ${row.price}) | ${row.package_interval}`} */}
                                </option>
                              );
                            }
                          })}
                          {/* </optgroup>
                          <optgroup label="Yearly Packages">
                            {this.state.packages.map((row) => {
                              if (row.package_interval == "Yearly" && row.status == "Active" && row.has_coach_license == "No") {
                                return (
                                  <option
                                    key={row.package_id}
                                    value={row.package_id}
                                  >

                                    {`${row.name} (${row.type} - ${row.price}) | ${row.package_interval}`}
                                  </option>
                                );
                              }
                            })}
                          </optgroup> */}
                        </AvField>
                      </div>

                      <div className="mb-3">
                        <label>Payment Method</label>
                        <AvRadioGroup
                          value={this.state.record.payment_method}
                          inline
                          name="payment_method"
                          onChange={(e) =>
                            this.onChangePaymentAction(e.target.value)
                          }
                        >
                          {/* <AvRadio label="Paid" value="stripe" /> */}
                          <AvRadio label="Manual(Skip	Payment	Option)" value="manual" />
                        </AvRadioGroup>
                      </div>

                      {
                        this.state.record.payment_method == 'stripe' && (
                          <ModalBody className="row" style={paymentStyle}>
                            <div className="col-md-12 mb-3">
                              <AvField
                                className="select form-control"
                                type="select"
                                name="payment_type"
                                label="Payment Type"
                                value={this.state.record.payment_type ?? 'recurring'}
                                required={this.state.isRequired}
                              >
                                {/* <option value={""}> Select Payment Type </option> */}
                                <option value={"recurring"}> Recurring </option>
                                {/* <option value={"one_time"}> One Time </option> */}

                              </AvField>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="control-label"> Card Number </label>
                              <AvField
                                name="cardnumber"
                                className="form-control"
                                type="text"
                                placeholder="Enter Card Number"
                                value={this.state.record.cardnumber}
                                required={this.state.isRequired}
                                maxLength={16}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}

                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="control-label"> CVC</label>
                              <AvField
                                name="cvc"
                                className="form-control"
                                type="text"
                                placeholder="CVC"
                                value={this.state.record.cvc}
                                required={this.state.isRequired}
                                maxLength={4}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <AvField
                                name="expirationdateMM"
                                label="MM"
                                className="form-control"
                                type="text"
                                placeholder="MM"
                                value={this.state.record.expirationdateMM}
                                required={this.state.isRequired}
                                maxLength={2}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="expirationdateYYYY"
                                label="YYYY"
                                className="form-control"
                                type="text"
                                placeholder="YYYY"
                                value={this.state.record.expirationdateYYYY}
                                required={this.state.isRequired}
                                maxLength={4}
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <AvField
                                name="address_line1"
                                label="Billing Address"
                                className="form-control"
                                type="text"
                                placeholder="Billing Addresss"
                                value={this.state.record.address_line1}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="address_city"
                                label="Billing City"
                                className="form-control"
                                type="text"
                                placeholder="Billing City"
                                value={this.state.record.address_city}

                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="address_state"
                                label="Billing State"
                                className="form-control"
                                type="text"
                                placeholder="Billing State"
                                value={this.state.record.address_state}

                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <AvField
                                name="address_zip"
                                label="Billing Zip"
                                className="form-control"
                                type="text"
                                placeholder="Billing Zip"
                                value={this.state.record.address_zip}

                              />
                            </div>
                          </ModalBody>
                        )
                      }


                      <div className="mb-3">
                        <AvGroup check>
                          <AvInput type="checkbox" name="twilio_sub_account" defaultValue={true} disabled />
                          <label className="control-label">
                            I agree to create twilio subaccount for this coach
                          </label>
                        </AvGroup>
                      </div>
                    </>
                  )}

                  <div className="mb-3">
                    <AvField
                      className="select form-control"
                      type="select"
                      id="s"
                      name="status"
                      label="Status"
                      required
                      value={this.state.record.status}
                    >
                      <option value={""}> Select Status </option>
                      <option value={"Active"}> Active </option>
                      <option value={"Inactive"}> Inactive </option>
                    </AvField>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    className="btn-sm"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >

                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
