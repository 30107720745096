import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ReactDatatable from "@ashvin27/react-datatable";
import 'suneditor/dist/css/suneditor.min.css';
export default class ManageCmsCategory extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Id#",
                key: "id",
                cell: (record, index) => {
                    return index + 1;
                }
            },

            {
                text: "Name",
                key: "name",
            },
            {
                text: "Slug",
                key: "slug",
            },
            {
                text: "Status",
                key: "status",
                cell: (record, index) => {
                    if (record.status == 'active') {
                        return <p key={index} className="badge bg-success">{record.status}</p>
                    } else if (record.status == 'inactive') {
                        return <p key={index} className="badge bg-danger">{record.status}</p>
                    } else {
                        return <p key={index}>{record.status}</p>
                    }
                }
            },
            {
                text: "Created Date",
                key: "created_at",
                cell: (record, index) => {
                    const formattedDate = new Date(record.created_at).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    });
                    return <p>{formattedDate}</p>;
                }
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button onClick={() => { this.setState({ row: record, modal: true }) }} className="btn btn-sm btn-secondary mr-5">
                                <i className="mdi mdi-pencil"></i>
                            </button>
                            <button onClick={() => { this.removeProduct(record.id) }} className="btn btn-sm btn-danger">
                                <i className="mdi mdi-trash-can-outline"></i>
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            loader: true,
            page_name: 'Categories listings',
            modal: false,
            modal2: false,
            row: {
                id: '',
                name: '',
            }
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/cms/categories', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        records: response.data.records,
                    });

                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/cms/categories", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            name: '',

                        },
                        modal: false
                    })
                    this.getData();
                } else {
                    swal({
                        title: data.message,
                        icon: "warning",
                    });
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }


    removeProduct = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/categories", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();

                                // toast.success(data.message, {
                                //   theme: "colored",
                                //   autoClose: ToastAutoClose,
                                // });
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box " style={{ display: 'flex', gap: '8px', float: 'inline-end' }}>
                                    <Button type="button"
                                        color="info"
                                        className="btn-sm"
                                        onClick={
                                            () => this.setState({
                                                modal: true, row: {
                                                    id: '',
                                                    name: '',

                                                }
                                            })}>
                                        <i className="mdi mdi-plus"></i> Create Category
                                    </Button>
                                    <Link
                                        type="button"
                                        color="info"
                                        className="btn btn-info btn-sm"
                                        to={"/settings/blogs"}

                                    >
                                        <i class="mdi mdi-format-list-bulleted"></i>
                                        Blog List
                                    </Link>
                                    <Link
                                        type="button"
                                        color="info"
                                        className="btn btn-info btn-sm"
                                        to={"/settings/proglobal/tags"}

                                    >
                                        <i class="mdi mdi-format-list-bulleted"></i>
                                        Tag List
                                    </Link>


                                </div>

                            </Col>
                        </Row>

                        <Modal
                            isOpen={this.state.modal}
                            fade={false}
                            toggle={() => this.setState({ modal: false })}
                        >
                            <form onSubmit={this.submitPage} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform-testimonial">
                                <ModalBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Name</label>
                                                <input
                                                    name="name"
                                                    defaultValue={this.state.row.name}
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                    required
                                                />
                                                <input
                                                    name="id"
                                                    defaultValue={this.state.row.id}
                                                    type='hidden'
                                                />
                                            </div>
                                        </Col>




                                        <input
                                            defaultValue={this.state.row.slug}
                                            name="slug"
                                            className="form-control"
                                            placeholder="Enter slug"
                                            type='hidden'

                                        />


                                        {
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <label>Status</label>
                                                    <select name="status" className="form-control" defaultValue={this.state.row.status}>
                                                        <option value={'active'}>Active</option>
                                                        <option value={'inactive'}>Inactive</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                    className="btn-sm"
                                        color="secondary"
                                        type="button"
                                        onClick={() => this.setState({ modal: false })}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" className="Green btn-sm">
                                        Submit
                                    </Button>

                                </ModalFooter>
                            </form>
                        </Modal>

                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}