import React, { useEffect, useRef, useState, useReducer, useCallback } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import WayBookContext from './WaybookContext';
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import { ApiUrl, ProductName, } from '../../config';
import Helmet from "react-helmet";
import { bcrypt, dcrypt, uInfo, uToken } from '../../useToken';
import ReactDatatable from "@ashvin27/react-datatable";
import Loader from "react-loader";
import { Link } from 'react-router-dom';

const TrainingQuizStatsView = (props) => {

	// console.log(props.details.cat_id)

	const [loaded, setLoaded] = useState(false);
	// console.log(props)
	const [cat_id, setCatId] = useState(null);
	const [step_id, setStepId] = useState(null);
	const [page_name, setPage_name] = useState('');

	const [records, setRecords] = useState([]);
	const [data, setData] = useState([]);
	const [total_pages, setTotal_pages] = useState(0);
	const [columns, setColumns] = useState([]);
	useEffect(() => {
		if (props.details.cat_id != 0 && props.details.step_id != 0) {
			if (props.details.cat_id) {
				setCatId(props.details.cat_id);
			}
			if (props.details.step_id) {
				setStepId(props.details.step_id);
			}
		}
	}, [props.details]);


	useEffect(() => {
		setLoaded(false);
		if (cat_id && step_id) {
			fetch(`${ApiUrl}` + "get/waybook/quiz/stats", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ` + uToken(),
				},
				body: JSON.stringify({ cat_id: cat_id, step_id: step_id }),
			})
				.then((response) => response.json())
				.then((response) => {
					if (response.status === true) {
						console.log(response.data.records)
						setRecords(response.data.records)
						setColumns(response.data.columns)
						setTotal_pages(response.data.total)
						setPage_name(response.data.step_title)
					}
					setLoaded(true);
				})
				//Then with the error genereted...
				.catch((error) => {
					setLoaded(true);

					console.error("Error:", error);
				});
		}
	}, [cat_id, step_id]);
	const columnConfig = columns.map((col, key) => ({
		key: col,
		text: col.charAt(0).toUpperCase() + col.slice(1),
		className: "Questions_" + col.slice(0, 5).toLowerCase(),
		TrOnlyClassName: "Questions_" + col.slice(0, 5).toLowerCase()
	}));
	const tableRecords = records.map(record => {
		const recordObj = {};
		columns.forEach((col, index) => {
			recordObj[col] = record[index];
		});
		return recordObj;
	});
	const config = {
		page_size: 10,
		length_menu: [10, 20, 50, 100],
		show_filter: false,
		show_pagination: false,
		button: {
			excel: false,
			print: false,
			csv: false,
		},
	};
	const fetchData = (queryString = "") => {

	};
	return (
		<>
			<Loader loaded={loaded}></Loader>

			<Row>
				<Col sm={12}>
					<div className='TraningRightContent'>
						<div className="page-title-box text-align-right" >
							<Button type="button"
								color="secondary"
								className="btn-sm"
								onClick={() => props.onBack()} >
								Back </Button>
						</div>
					
						<div className="PermissionLib DashboardDatatable">
							<ReactDatatable
								config={config}
								records={tableRecords}
								columns={columnConfig}
								tHeadClassName={'quizstats_thead'}
							/>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
export default TrainingQuizStatsView;