import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import swal from 'sweetalert';
import Helmet from "react-helmet";
import {
    currentDateTime,
    dcrypt,
    uInfo,
    uToken,
    convertDateFormat,
    bcrypt,
    NumberWithCommas
} from "../../useToken";
import { ApiUrl, logoLightPng } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { currencies } from "../../common/Common-Currency.js";
export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
            invoice_type: null,
            selectedProposaId: null,
            page_name: 'Edit Invoice',
            from: this.props.from ? this.props.from : '',
            record: {},
            clients: [],
            coachPartners: [],
            productProposals: [],
            customFields: [{ items: "", quantity: "0", price: "0", total: "0", readOnly: "0" }],
            formData: {
                start_date: currentDateTime(),
                expire_date: '',
                type: 'one_time',
                invoice_for: 'Client',
                renewal_interval: 'Monthly',
                items_data: [],
                email_subject: '',
                notes: '',
                confirmation_email: '',
                created_for_id: 0,
                activity: '0',
                master_type: 'coaching',
                product_id: '0',
                logo: '',
            },
            filter_value: '',
            revenueProducts: [],
            createdFor: {},
            currency: "",
        };
    }

    componentDidMount() {
        this.state.id != null && this.getData();
        this.getFormData("");
        window.onafterprint = function () {
            window.location.reload()
        }
    }
    getProposal = (id) => {
        fetch(`${ApiUrl}` + "getProduct/proposals/" + id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        productProposals: response.data.record,
                    })
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    getFormData = (data) => {
        fetch(`${ApiUrl}` + "get/invoice/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        clients: response.data.clients,
                        coachPartners: response.data.coachPartners,
                        revenueProducts: response.data.revenue_products
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    getData = () => {
        fetch(`${ApiUrl}` + "get/edit/invoice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id, coach_id: this.state.coach_id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.getInvoiceCurrency(response.data.record.currency_code)
                    this.setState({
                        formData: response.data.record,
                        customFields: JSON.parse(response.data.record.items_data),
                        createdFor: response.data.record.createdFor
                    });
                    if (response.data.record.product_id > 0) {
                        this.setState({
                            invoice_type: "product",
                            selectedProposaId: response.data.record.proposal_id
                        })
                        this.getProposal(response.data.record.product_id);

                    }
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getInvoiceCurrency = (code) => {
        let currencysymbol = currencies[code];
        if (currencysymbol) {
            this.setState({ currency: currencysymbol.symbol });
        }
    }
    Submit = () => {
        fetch(`${ApiUrl}` + "add/invoice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(this.state.formData),
        })
            .then((response) => response.json())
            .then((data) => {

                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });


                    setTimeout(
                        () => this.props.history.push('/invoice/list'),
                        1000
                    );

                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });

    }
    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.formData.created_for_id == '' || this.state.formData.created_for_id == 0) {
            swal({
                title: "Required Fields!",
                text: "Select To( Coach Partner or Client)",
                icon: "warning",
            });

            return;
        } else {
            let sum = this.state.customFields.reduce(function (prev, current) {
                return prev + +current.total
            }, 0);

            this.setState(prevState => {
                let formData = Object.assign({}, prevState.formData);
                formData.items_data = this.state.customFields;
                formData.total_price = sum;
                return { formData };
            }, () => this.Submit());
            return;
        }


        // this.setState(prevState => {
        //     let formData = Object.assign({}, prevState.formData);
        //     formData.items_data = this.state.customFields;
        //     formData.total_price = this.state.customFields.reduce((a, v) => a = a + v.total, 0);
        //     return { formData };
        // }, () => this.Submit());
        // return;
    };

    defaultUI() {
        return this.state.customFields.map((el, i) => (
            <div className="border px-2 mb-2" key={i}>
                <div className="row">
                    <div className="col-sm-8 col-md-8 mb-3">
                        <div className="form-group">
                            <input readOnly={el.readOnly == '1' ? true : false} value={el.items} name="items" required="required" type="text"
                                className="form-control" onChange={this.handleChange.bind(this, i)} />
                        </div>
                    </div>
                    <div className="col-sm-3 col-md-3 mb-3 d-none">
                        <div className="form-group">
                            <input value={el.quantity} readOnly={el.readOnly == '1' ? true : false} name="quantity" required="required" type="text"
                                className="form-control" onChange={this.handleChange.bind(this, i)} />

                        </div>
                    </div>
                    <div className="col-sm-3 col-md-3 mb-3 d-none">
                        <div className="form-group">
                            <input name="price" readOnly={el.readOnly == '1' && i < 1 ? true : false} value={el.price} required="required" type="text"
                                className="form-control" onChange={this.handleChange.bind(this, i)} />
                        </div>

                    </div>
                    <div className="col-sm-4 col-md-4">
                        <div className="invoice grid-style">

                            <div className="form-group">
                                <input name="total" readOnly={el.readOnly == '1' && i < 1 ? true : false} value={el.total} required="required" type="number" min={0}
                                    className="form-control" onChange={this.handleChange.bind(this, i)} />
                            </div>
                            {
                                i != 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-danger do-not-print btn-sm"
                                        onClick={this.removeClick.bind(this, i)}>
                                        <i className="ion ion-md-trash"> </i>
                                    </button>
                                )
                            }
                        </div>
                    </div>

                </div>
            </div>
        ))
    }






    addClick() {
        this.setState(prevState => ({
            customFields: [...prevState.customFields, { items: "", quantity: "0", price: "0", total: "0" }]
        }))
    }

    handleChange(i, e) {
        const { name, value } = e.target;
        let customFields = [...this.state.customFields];

        customFields[i][name] = value;
        if (name === 'quantity' || name === 'price') {
            customFields[i]['total'] = customFields[i].quantity * customFields[i].price;
        }

        this.setState({ customFields });
    }

    removeClick(i) {
        let customFields = [...this.state.customFields];
        console.log(customFields)
        customFields.splice(i, 1);
        this.setState({ customFields });
    }

    PrintElem() {
        var printContents = document.getElementById('print-data').innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        // document.body.innerHTML = originalContents;
    }

    render() {
        return (
            <React.Fragment>


                <Helmet>
                    <title> {this.state.page_name} </title>
                </Helmet>
                <ToastContainer />
                <form method="POST" onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm={8} className="">

                            <div className="pb-10 pull-right">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => this.props.history.goBack()}
                                    className="mr-3 btn-sm"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    color="info"
                                    onClick={() => this.PrintElem()}
                                    className="mr-3 btn-sm"
                                >
                                    Print
                                </Button>
                                <button
                                    className="btn btn-primary waves-effect waves-light btn-sm"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>



                        </Col>

                    </Row>



                    <Row>
                        <Col xs="8" id="print-data">
                            <Card>
                                <CardBody>
                                    <div className="invoice-title d-flex space-between">
                                        <h3 className="mt-0">
                                            <img src={this.state.formData.logo} alt="logo" height="50" />
                                        </h3>
                                        <div className="font-size-16">
                                            <p>{convertDateFormat(this.state.formData.start_date)}</p>
                                            <p>
                                                {this.state.formData.type.replace('_', ' ')} {' '}
                                                {this.state.formData.type == 'recurring' && (this.state.formData.renewal_interval)}

                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <Row>
                                        <Col xs="6" className="mt-4">
                                            <div className="invoice-meta">
                                                <p className="for">TO</p>
                                                {
                                                    this.state.formData.invoice_for == 'Client' ? (
                                                        <div className="invoice-data">
                                                            <h6>{this.state.createdFor.first_name} {this.state.createdFor.last_name}</h6>
                                                            <p className="address">
                                                                {this.state.createdFor.address}
                                                            </p>
                                                            <p className="mobile">{this.state.createdFor.mobile}</p>
                                                            <p className="email">{this.state.createdFor.email}</p>
                                                        </div>
                                                    ) :
                                                        (
                                                            <div className="invoice-data">
                                                                <h6>{this.state.createdFor.name}</h6>
                                                                <p className="address">
                                                                    {this.state.createdFor.address}
                                                                </p>
                                                                <p className="email">{this.state.createdFor.email}</p>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </Col>
                                        <Col xs="6" className="mt-4">
                                            <div className="invoice-meta">
                                                <p className="for">From</p>
                                                <div className="invoice-data">
                                                    <h6>{uInfo().name}</h6>
                                                    {/* <p className="address">
                                                        I-8 Markez Islamabad
                                                        Islamabad, 47000
                                                        4085027559
                                                    </p> */}
                                                    {/* <p className="mobile">+12515819043</p> */}
                                                    <p className="email">{uInfo().email}</p>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                    <div className="row mt-4">
                                        <div className="col-sm-8 col-md-8 mb-3">Product Description</div>
                                        {/* <div className="col-sm-3 col-md-3 mb-3">Quantity</div>
                                        <div className="col-sm-3 col-md-3 mb-3">Unit Price($)</div> */}
                                        <div className="col-sm-4 col-md-4 mb-3">Total</div>
                                    </div>
                                    {this.defaultUI()}
                                    <div className="row">
                                        <div className="col-sm-3 col-md-3 mb-3 do-not-print">
                                            <button type="button" className="btn btn-success d-none btn-sm" onClick={() => this.addClick()}>Add Item</button>
                                        </div>
                                        <div className="col-sm-3 col-md-3 mb-3"></div>
                                        <div className="col-sm-3 col-md-3 mb-3"></div>
                                        <div className="col-sm-3 col-md-3 mb-3">Total {this.state.currency}

                                            {NumberWithCommas(Math.round(
                                                this.state.customFields.reduce(function (prev, current) {
                                                    return prev + +current.total
                                                }, 0)
                                            ))}</div>
                                    </div>

                                    <div className="form-group d-none">
                                        <label>Note</label>
                                        <textarea className="form-control" name="notes" placeholder="Enter notes" onChange={(e) =>
                                            this.setState(prevState => {
                                                let formData = Object.assign({}, prevState.formData);
                                                formData.notes = e.target.value;
                                                return { formData };
                                            })
                                        }
                                            value={this.state.formData.notes}
                                        />
                                    </div>



                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="4">
                            <Card>
                                <CardBody>
                                    <div className="invoice-setting">
                                        <h6><i className="fas fa-cog"></i> Invoice Date</h6>
                                        <div className="form-group mb-4">
                                            <input readOnly required onChange={(e) => {
                                                this.setState(prevState => {
                                                    let formData = Object.assign({}, prevState.formData);
                                                    formData.start_date = e.target.value;
                                                    return { formData };
                                                })
                                            }} value={this.state.formData.start_date} name="start_date" className="form-control" type='date' />
                                        </div>

                                        <div className="form-group mb-4">
                                            <label>Invoice For</label>
                                            <br />
                                            {
                                                ['Client'].map((row, i) => {
                                                    return (
                                                        <div key={i} className="form-check form-check-inline">
                                                            <input disabled readOnly checked={this.state.formData.invoice_for == row ? 'checked' : ''}
                                                                onChange={(e) => {
                                                                    this.setState(prevState => {
                                                                        let formData = Object.assign({}, prevState.formData);
                                                                        formData.invoice_for = e.target.value;
                                                                        formData.created_for_id = 0;

                                                                        return { formData };
                                                                    });
                                                                    this.setState({
                                                                        createdFor: {},
                                                                    })
                                                                }} name={'invoice_for'} type="radio"
                                                                className="form-check-input" value={row} />
                                                            <label className="form-check-label">{`${row}`}</label>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>


                                        {
                                            this.state.formData.invoice_for == 'Client' ? (
                                                <>
                                                    <div className="form-group mb-4">
                                                        <input readOnly value={this.state.formData.created_for_name} onChange={(e) => {
                                                            this.getFormData({ 'filter_value': e.target.value });
                                                            this.setState(prevState => {
                                                                let formData = Object.assign({}, prevState.formData);
                                                                formData.created_for_name = e.target.value;
                                                                return { formData };
                                                            });
                                                        }} placeholder="Search clients" name="searh" type={'text'} className={'form-control'} />
                                                        <ul>
                                                            {
                                                                this.state.clients.map((row, i) => {
                                                                    return (
                                                                        <li onClick={() => {
                                                                            this.setState(prevState => {
                                                                                let formData = Object.assign({}, prevState.formData);
                                                                                formData.created_for_id = row.client_id;
                                                                                return { formData };
                                                                            });
                                                                            this.setState({
                                                                                createdFor: row
                                                                            });
                                                                            this.getFormData({
                                                                                'filter_value': ""
                                                                            });
                                                                        }} className="form-check pt-3" key={i}>
                                                                            <p className="form-check-label">{`${row.first_name} ${row.last_name}`}</p>
                                                                            <p className="form-check-label">{`${row.email}`}</p>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    </div>


                                                    {/* <div className="form-group mb-4">
                                                        <label>Confirmation Email</label>
                                                        <input value={this.state.formData.confirmation_email} onChange={(e) => {
                                                            this.setState(prevState => {
                                                                let formData = Object.assign({}, prevState.formData);
                                                                formData.confirmation_email = e.target.value;
                                                                return { formData };
                                                            })
                                                        }}
                                                            placeholder="Enter Email(s) Separated By Comma" name="confirmation_email" type={'text'} className={'form-control'} />
                                                    </div>

                                                    <div className="form-group mb-4">
                                                        <label>Confirmation Email Subject</label>
                                                        <input value={this.state.formData.email_subject} onChange={(e) => {
                                                            this.setState(prevState => {
                                                                let formData = Object.assign({}, prevState.formData);
                                                                formData.email_subject = e.target.value;
                                                                return { formData };
                                                            })
                                                        }}
                                                            placeholder="Confirmation Email Subject" name="email_subject" type={'text'} className={'form-control'} />
                                                    </div> */}
                                                </>

                                            ) :
                                                (
                                                    <div className="form-group mb-4">
                                                        <select className="form-control" name="invoice_for" value={this.state.formData.created_for_id}
                                                            onChange={(e) => {
                                                                let user_id = e.target.value;
                                                                if (user_id != '') {
                                                                    this.setState(prevState => {
                                                                        let formData = Object.assign({}, prevState.formData);
                                                                        formData.created_for_id = user_id;
                                                                        return { formData };
                                                                    });
                                                                    const row = this.state.coachPartners.filter(item => item.user_id == user_id);
                                                                    this.setState({
                                                                        createdFor: row[0]
                                                                    });
                                                                }
                                                            }} >
                                                            <option value=''>Select Coach Partner</option>
                                                            {
                                                                this.state.coachPartners.map((row, i) => {
                                                                    return (
                                                                        <option value={row.user_id}
                                                                            className="form-check pt-3" key={i}>
                                                                            {`${row.name} (${row.email})`}
                                                                        </option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                )
                                        }

                                        <div className="form-group mb-4">
                                            <label>Product Type</label>
                                            <br />
                                            {
                                                ['coaching', 'e-learning'].map((row, i) => {
                                                    return (
                                                        <div key={i} className="form-check form-check-inline">
                                                            <input disabled checked={this.state.formData.master_type == row ? 'checked' : ''}
                                                                onChange={(e) => {
                                                                    this.setState(prevState => {
                                                                        let formData = Object.assign({}, prevState.formData);
                                                                        formData.master_type = e.target.value;
                                                                        formData.product_id = '0';
                                                                        return { formData };
                                                                    });
                                                                    this.setState({
                                                                        customFields: [{ items: "", quantity: "0", price: "0", total: "0", readOnly: "0" }],
                                                                    })
                                                                }} name={'master_type'} type="radio"
                                                                className="form-check-input" value={row} />
                                                            <label className="form-check-label text-transform-capitalize">{`${i == 1 ? 'Academy' : row}`}</label>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div className="form-group mb-4">
                                            <select disabled value={this.state.formData.product_id} className="form-control" name="product_id"
                                                onChange={(e) => {
                                                    let product_id = e.target.value;
                                                    if (product_id != '0') {
                                                        const row = this.state.revenueProducts.filter(item => item.product_id == product_id);
                                                        let itemsArray = [
                                                            { items: `${row[0].name}`, quantity: "1", price: `${row[0].price}`, total: `${row[0].price}`, readOnly: '0' },
                                                            { items: `One time Setup Fee`, quantity: "1", price: `${row[0].setup_fee}`, total: `${row[0].setup_fee}`, readOnly: '0' },
                                                        ];

                                                        this.setState({
                                                            customFields: itemsArray
                                                        })

                                                        this.setState(prevState => {
                                                            let formData = Object.assign({}, prevState.formData);
                                                            formData.product_id = product_id;
                                                            formData.proposal_id = "0";
                                                            formData.type = row[0].payment_type;
                                                            formData.renewal_interval = row[0].product_interval;
                                                            formData.notes = row[0].description;
                                                            return { formData };
                                                        });
                                                        this.setState({
                                                            invoice_type: "product"
                                                        });
                                                        this.getProposal(product_id)
                                                    } else {
                                                        this.setState(prevState => {
                                                            let formData = Object.assign({}, prevState.formData);
                                                            formData.product_id = '0';
                                                            formData.notes = '';
                                                            return { formData };
                                                        });
                                                        this.setState({
                                                            customFields: [{ items: "", quantity: "0", price: "0", total: "0", readOnly: "0" }]
                                                        })
                                                    }
                                                }} >
                                                <option value='0'>Select Product</option>
                                                {
                                                    this.state.revenueProducts.map((row, i) => {
                                                        if (row.type == this.state.formData.master_type) {
                                                            return (
                                                                <option value={row.product_id}
                                                                    className="form-check pt-3" key={i}>
                                                                    {`${row.name} | ($${row.price})`}
                                                                </option>
                                                            );
                                                        }
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {this.state.productProposals && this.state.productProposals.length > 0 && (
                                            <div className="form-group mb-4">
                                                <label> Select Proposal </label>
                                                {this.state.selectedProposaId && (
                                                    <span className="pull-right">
                                                        <i title="Preview Proposal" onClick={() => this.props.history.push('/proposal/' + bcrypt(this.state.selectedProposaId))} className="fas fa-eye px-2 mr-2 cursor-pointer"></i>
                                                    </span>
                                                )}

                                                <select value={this.state.formData.proposal_id} className="form-control" name="proposal_id"
                                                    onChange={(e) => {
                                                        let proposal_id = e.target.value;
                                                        if (proposal_id != '0') {
                                                            const row = this.state.productProposals.filter(item => item.proposal_id == proposal_id);

                                                            let itemsArray = [
                                                                { items: `${row[0].name}`, quantity: "1", price: `${row[0].price}`, total: `${row[0].price}`, readOnly: '0' },
                                                                { items: `One time Setup Fee`, quantity: "1", price: `${row[0].setup_price}`, total: `${row[0].setup_price}`, readOnly: '0' },
                                                            ];

                                                            this.setState({
                                                                customFields: itemsArray,
                                                                selectedProposaId: proposal_id
                                                            })

                                                            this.setState(prevState => {
                                                                let formData = Object.assign({}, prevState.formData);
                                                                formData.proposal_id = proposal_id;
                                                                return { formData };
                                                            });
                                                        } else {
                                                            console.log('hereee')
                                                            this.setState(prevState => {
                                                                let formData = Object.assign({}, prevState.formData);
                                                                formData.proposal_id = '0';
                                                                return { formData };
                                                            });
                                                            this.setState({
                                                                selectedProposaId: null,
                                                                customFields: [{ items: "", quantity: "0", price: "0", total: "0", readOnly: "0" }],
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <option value='0'> Select Proposal</option>
                                                    {
                                                        this.state.productProposals && this.state.productProposals.map((row, i) => {
                                                            return (
                                                                <option value={row.proposal_id}
                                                                    className="form-check pt-3" key={i}>
                                                                    {`${row.name} | (${row.price})`}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        )}

                                        <div className="form-group mb-4">
                                            {
                                                [{ label: 'One Time', value: 'one_time' }, { label: 'Recurring', value: 'recurring' }].map((row, i) => {
                                                    return (
                                                        <div key={i} className="form-check form-check-inline">
                                                            <input checked={this.state.formData.type == row.value ? 'checked' : ''}
                                                                onChange={(e) => {
                                                                    this.setState(prevState => {
                                                                        let formData = Object.assign({}, prevState.formData);
                                                                        formData.type = e.target.value;
                                                                        return { formData };
                                                                    })
                                                                }} name={'type'} type="radio"
                                                                disabled={this.state.invoice_type == 'product' ? true : false}
                                                                className="form-check-input" value={row.value} />
                                                            <label className="form-check-label">{`${row.label}`}</label>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        {
                                            this.state.formData.type == 'recurring' && (
                                                [{ label: 'Monthly', value: 'Monthly' }, { label: 'Yearly', value: 'Yearly' }].map((row, i) => {
                                                    return (
                                                        <div key={i} className="form-check form-check-inline">
                                                            <input checked={this.state.formData.renewal_interval == row.value ? 'checked' : ''}
                                                                onChange={(e) => {
                                                                    this.setState(prevState => {
                                                                        let formData = Object.assign({}, prevState.formData);
                                                                        formData.renewal_interval = e.target.value;
                                                                        return { formData };
                                                                    })
                                                                }} name={'renewal_interval'} type="radio"
                                                                disabled={this.state.invoice_type == 'product' ? true : false}
                                                                className="form-check-input" value={row.value} />
                                                            <label className="form-check-label">{`${row.label}`}</label>
                                                        </div>
                                                    );
                                                })
                                            )
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form >

            </React.Fragment >
        );
    }
}
