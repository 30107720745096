import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import ProfileSettings from "../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class UserOperation extends Component {
  constructor(props) {
    super(props);
    //console.log(dcrypt(this.props.match.params.id), 'debug');
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      
      page_name: this.props.match.params.id
        ? "Edit Team"
        : "Create Team",
      roles: [],
      training_groups: [],
      record: {
        package_for: "Coach",
      }
    };
    // console.log(this.state.page_name, "debug");
  }

  componentDidMount() {
    this.getData();
    this.getTrainingGroups();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            roles: response.data.roles,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.user_id ? this.state.record.user_id : -1;
    }

    // if (this.state.id) {
    //   values.id = this.state.record.id ? this.state.record.id : -1;
    // }
    fetch(`${ApiUrl}` + "edit/coachpartner", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          this.props.history.goBack();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getTrainingGroups = () => {
    fetch(`${ApiUrl}` + "get/training/groups", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status == true) {
          this.setState({
            training_groups: response.data.record
          })
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col className="Col-10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <ModalBody>
                      <div className="mb-3">
                        <AvField
                          name="first_name"
                          label="First Name"
                          value={this.state.record.first_name}
                          className="form-control"
                          placeholder="Enter First Name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="last_name"
                          label="Last Name"
                          value={this.state.record.last_name}
                          className="form-control"
                          placeholder="Enter Last Name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={this.state.record.email}
                          className="form-control"
                          placeholder="Enter Email Address"
                          required
                        />
                      </div>

                      {this.state.id == undefined && (
                        <>
                          <div className="mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              id="s"
                              name="role_id"
                              label="Permission library (Optional)"
                            >
                              <option value={''}>Select Role</option>
                              {
                                this.state.roles.map((role, i) => {
                                  return <option value={role.id}>{role.name}</option>
                                })
                              }
                            </AvField>
                          </div>

                          <div className="mb-3">
                        
                          <AvField
                            className="select form-control"
                            type="select"
                            id="training_group"
                            name="training_group"
                            label="Training Group"
                          >
                            <option value={''}>Select</option>
                            {  this.state.training_groups &&
                              this.state.training_groups.map((row, i) => {
                                return <option value={row.id}>{row.name}</option>
                              })
                            }
                          </AvField>
                     
                        </div>
                          
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Password"
                              value={this.state.record.password}
                              className="form-control"
                              placeholder="Enter Password"
                              minLength={8}
                              errorMessage="Password must be at least 8 characters"
                              required
                              type="password"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              minLength={8}
                              errorMessage="Confirm password must be at least 8 characters and same as password"
                              name="confirm_password"
                              label="Confirm Password"
                              type="password"
                              value={this.state.record.password}
                              className="form-control"
                              placeholder="Enter Confirm Password"
                              validate={{ match: { value: "password" } }}
                              required
                            />
                          </div>
                        </>
                      )}

                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          id="s"
                          name="status"
                          label="Status"
                          required
                          value={this.state.record.status}
                        >
                          <option value={""}> Select Status </option>
                          <option value={"Active"}> Active </option>
                          <option value={"Inactive"}> Inactive </option>
                        </AvField>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                      className="btn-sm"
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light btn-sm"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </AvForm>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
