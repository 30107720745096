import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import ReactDatatable from '@ashvin27/react-datatable';
import Helmet from "react-helmet"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { bcrypt, getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import swal from 'sweetalert';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";

export default class Leads extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "Lead ID",
        key: "id",
        sortable: false
      },
      {
        text: "Name",
        key: "name",
        sortable: false,
      },
      {
        text: "Email",
        key: "email",
        sortable: false
      },
      {
        text: "Phone",
        key: "phone",
        sortable: false
      },
      {
        key: "link",
        text: "Coach Info",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                title="Coach Basic Info"
                type="button"
                color="info"
                className="btn btn-link btn-sm mr-5"
                onClick={() => { this.setState({ modal_link: true, coach_user: record.coach_user }) }}
              >
                <i className="fas fa-fw  fa-user"></i>
              </button>
            </Fragment>
          );
        },
      },
      {
        text: "Created At",
        key: "created_at",
      }
    ];
    this.config = {
      key_column: 'sr',
      page_size: 100,
      show_filter: true,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        csv: false
      },

    }
    this.state = {
      records: [],
      coach_user: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Global Lead Search",
      customDate: true,
      modal_link: false
    }
  }


  componentDidMount() {
    //this.getData();
  }

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + 'admin/leads' + queryString, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {

        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles
          })
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

  }

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values
    });
    this.getData("", values)
  }

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "")
  }

  tableChangeHandler = data => {
    let queryString = Object.keys(data).map((key) => {
      if (key === "sort_order" && data[key]) {
        return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      }

    }).join('&');
    this.getData('?' + queryString, this.state.filter);
  }



  render() {
    return (
      <React.Fragment >
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>

          </Col>
        </Row>

        <ToastContainer />


        <Row>
          <Col className="col-12">
          <Card>
            <CardBody>
              <div className="alert alert-info" role="alert">
                Enter an email address or phone number in the search box to perform a global lead search.
              </div>
              <ReactDatatable
                key={this.state.id}
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={this.state.total_pages}
                onChange={this.tableChangeHandler}
              />
            </CardBody>
          </Card>

          </Col>
        </Row>
        <Modal
          isOpen={this.state.modal_link}
          fade={false}
          toggle={() => this.setState({ modal_link: false })}
        >
          <ModalHeader>Coach Info</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-2 mb-2">
                Name:
              </div>
               <div className="col-md-9">
                {this.state.coach_user.name}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2">
                Email:
              </div>
               <div className="col-md-9">
                {this.state.coach_user.email}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-2">
                Phone:
              </div>
               <div className="col-md-9">
                {this.state.coach_user.phone}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
            className="btn-sm"
              color="info"
              onClick={() => this.setState({
                modal_link: false,
              })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment >
    )
  }
}

