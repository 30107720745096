import React, { Component } from "react";
import { Row, Col, Button, ModalBody, ModalFooter } from "reactstrap";
import { ApiUrl, ProductName } from "../../config";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { uToken } from "../../useToken";
import ProfileSettings from "../Authentication/profile-sidebar";
import Froala from "../Froala";
import { toast, Flip } from "react-toastify";

export default class AddCmsPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            loader: true,
            page_name: 'Proglobal Pages',
            row: {
                id: '',
                page_name: '',
                page_title: '',
                user_id: '',
                slug: '',
                body: '',
            }
        };
    }

    componentDidMount() {
        this.fetchRecords();
    }

    fetchRecords = () => {
        fetch(`${ApiUrl}get/cms/proglobal-pages`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uToken()}`
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                this.setState({ loader: false });
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ loader: false });
            });

    }



    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        fetch(`${ApiUrl}update/cms/proglobal-pages`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${uToken()}`
            },
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            page_name: '',
                            page_title: '',
                            user_id: '',
                            slug: '',
                            body: '',
                        },
                    });
                    this.fetchRecords();
                    this.props.history.push('/settings/proglobal/pages');
                } else {
                    toast.error(data.message || "Failed to update page", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: false });
            })
            .catch(error => {
                this.setState({ loader: false });
                console.error("Update error:", error);
                toast.error("Failed to update page. Please try again later.", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            });
    };
    handleBodyChange = (content) => {
        this.setState({
            row: {
                ...this.state.row,
                body: content,
            }
        });
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4>{this.state.page_name}</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                        <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name}</Link></li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>


                        <form onSubmit={this.submitPage} encType="multipart/form-data" className="form-horizontal" id="selection-myform-blog">
                            <ModalBody>
                                <div className="form-group mb-3">
                                    <label>Page Name</label>
                                    <input
                                        name="page_name"
                                        className="form-control"
                                        placeholder="Enter name"
                                        required
                                    />

                                </div>
                                <div className="form-group mb-3">
                                    <label>Page Title</label>
                                    <input
                                        name="page_title"
                                        className="form-control"
                                        placeholder="Enter title"
                                        required
                                    />

                                    <input
                                        name="id"
                                        type='hidden'
                                        value={this.state.row.id}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Slug</label>
                                    <input
                                        name="slug"
                                        className="form-control"
                                        placeholder="Enter slug"

                                    />
                                </div>
                                <fieldset>
                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Meta keywords</label>
                                            <input
                                                name="meta_keywords"
                                                className="form-control"
                                                placeholder="E.g blog, seo, etc"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="form-group mb-3">
                                            <label>Meta Description</label>
                                            <textarea
                                                name="meta_description"
                                                className="form-control"
                                                placeholder="Enter meta description"
                                                rows={4}
                                            />
                                        </div>
                                    </Col>
                                </fieldset>

                                {/* <div className="form-group mb-3">
                                    <label>Page Detail</label>
                                    <Froala
                                        nameKey={'body'}
                                        onChange={this.handleBodyChange}
                                        insideForm={true}
                                        btnId={'submit-blog'}
                                        use_token={'users'}
                                        type='hidden'
                                        style={{ display: 'none' }}
                                    />

                                </div> */}
                            </ModalBody>

                            <ModalFooter>
                                <Button color="secondary btn-sm" type="button" onClick={() => window.history.back()}>Cancel</Button>
                                <Button className="Green btn-sm" type="submit" id="submit-blog">Submit</Button>
                            </ModalFooter>
                        </form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
