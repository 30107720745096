import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../../common/data/countries";
import AsyncSelect from "react-select/async";

export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id != "" ? this.props.id : "",
      record: {},
      clients: [],
      clients_id: 0,
      permissions: [],
      permission: [],
      emails: [],
      phoneNumbers: [],
      email_reminder: false,
      sms_reminder: false,
      pipelines: [],
      runs: [
        {
          label: "Once",
          value: "once",
        },
        {
          label: "Multiple Times",
          value: "multiple",
        },
      ],
      selectedruntype: "",
      run: ''
    };

  }

  componentDidMount() {
    if (this.state.id != "") {
      this.getData();
    }

  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.id != this.props.id) {
      this.setState(
        {
          id: this.props.id != "" ? this.props.id : "",
          record: {},
        },
        () => this.getData()
      );
    }
  };



  setruntype = (selectedruntype) => {
    this.setState({
      selectedruntype: selectedruntype,
    });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/campaign/automation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        coach_id: this.state.coach_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log(response, 'DEBUGGING');
          this.setState({
            record: response.data.record,
            run: response.data.record.runs,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  onChangeTypeAction = (value) => {
    this.setState(prevState => {
      let record = Object.assign({}, prevState.record);
      record.event_type = value;
      return { record };
  })
  };
  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.record.automation_id
        ? this.state.record.automation_id
        : -1;
    }

    fetch(`${ApiUrl}` + "add/campaign/automation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

          this.props.cancelSlider();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <AvForm
              onValidSubmit={this.handleSubmit}
              // model={this.state.record}
              ref={(c) => (this.form = c)}
              className="needs-validation"
            >
              <ModalBody className="row">
                <div className="col-md-12 mb-3">
                  <AvField
                    name="title"
                    value={this.state.record.title}
                    label="Title"
                    className="form-control"
                    required
                  />
                </div>
                {this.state.record.type == 'Trigger' && (
                  <>
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="run_type"
                      value={this.state.record.runs}
                      label="Run"
                      className="form-control"
                      id="rs"
                      required
                      type="select"
                    >

                      <option value={"once"}>Once</option>
                      <option value={"multiple"}>Multiple Times</option>

                    </AvField>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label> Type </label>
                    <AvRadioGroup
                      value={this.state.record.event_type}
                      inline
                      name="event_type"
                      required
                      onChange={(e) => this.onChangeTypeAction(e.target.value)}
                    >
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow text-center"><div className={`status-radio ${this.state.record.event_type == '1' ? 'checked-radio' : ''}`}><i className="fas fa-phone-alt"></i><AvRadio value="1" /></div><label>Inbound Call</label></div>
                        <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow text-center"><div className={`status-radio ${this.state.record.event_type == '2' ? 'checked-radio' : ''}`}><i className="far fa-comment"></i><AvRadio value="2" /></div><label>Inbound SMS</label></div>
                        {/* <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '3' ? 'checked-radio' : ''}`}><i className="fas fa-envelope"></i><AvRadio value="3" /></div><label>Email Responder</label></div>
                      <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '4' ? 'checked-radio' : ''}`}><i className="fas fa-unlink"></i><AvRadio value="4" /></div><label>Click Link Email</label></div>
                      <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '5' ? 'checked-radio' : ''}`}><i className="far fa-envelope"></i><AvRadio value="5" /></div><label>Open Read Email</label></div> */}
                        <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow text-center"><div className={`status-radio ${this.state.record.event_type == '6' ? 'checked-radio' : ''}`}><i className="fas fa-mobile-alt"></i><AvRadio value="6" /></div><label>RVM Fail</label></div>
                        {/* <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '7' ? 'checked-radio' : ''}`}><i className="fas fa-users"></i><AvRadio value="7" /></div><label>Group Added</label></div>
                      <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '8' ? 'checked-radio' : ''}`}><i className="fas fa-user-md"></i><AvRadio value="8" /></div><label>Group Removed</label></div>
                      <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '9' ? 'checked-radio' : ''}`}><i className="fas fa-tag"></i><AvRadio value="9" /></div><label>Tag Added</label></div>
                      <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '10' ? 'checked-radio' : ''}`}><i className="fab fa-xing-square"></i><AvRadio value="10" /></div><label>Tag Removed</label></div>
                      <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '11' ? 'checked-radio' : ''}`}><i className="fas fa-microphone"></i><AvRadio value="11" /></div><label>VoiceMail Received</label></div>
                      <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.record.event_type == '12' ? 'checked-radio' : ''}`}><i className="fas fa-chart-bar"></i><AvRadio value="12" /></div><label>Deal Status Change</label></div> */}
                      </div>
                    </AvRadioGroup>
                  </div>
                </>
                )}
                <div className="col-md-12 mb-1">
                  <AvField
                    name="status"
                    value={this.state.record.status}
                    label="Status"
                    className="form-control"
                    id="rs"
                    required
                    type="select"
                  >
                    <option value={""}>Select Status</option>
                    <option value={"Active"}>Active</option>
                    <option value={"Inactive"}>Inactive</option>
                  </AvField>
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-start">
                <button
                  className="btn btn-primary waves-effect waves-light btn-sm"
                  type="submit"
                >
                  Submit
                </button>
              </ModalFooter>
            </AvForm>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
