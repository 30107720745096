import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { uToken } from "../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Froala from "../Froala";
import ReactDatatable from "@ashvin27/react-datatable";
import { AvForm, AvField } from "availity-reactstrap-validation";
import 'suneditor/dist/css/suneditor.min.css';
export default class ManageProGlobalSections extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Id#",
                key: "id",
                cell: (record, index) => {
                    return index + 1;
                }
            },
            {
                text: "Page Name",
                key: "page_name",

            },
            {
                text: "Title",
                key: "title",
            },
            {
                text: "Type",
                key: "type",
                cell: (record) => {
                    let badgeClass = "badge ";
                    if (record.type === 'image') {
                        badgeClass += "bg-primary";
                    } else if (record.type === 'content') {
                        badgeClass += "bg-info";
                    }
                    else if (record.type === 'slider') {
                        badgeClass += "bg-success";
                    }
                    return <p className={badgeClass}>{record.type}</p>;
                }
            },

            {
                text: "Created Date",
                key: "created_at",

            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button onClick={() => { this.setState({ row: record, modal: true }) }} className="btn btn-sm btn-secondary mr-5">
                                <i className="mdi mdi-pencil"></i>
                            </button>
                            <button onClick={() => { this.removeProduct(record.id) }} className="btn btn-sm btn-danger">
                                <i className="mdi mdi-trash-can-outline"></i>
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            loader: true,
            page_name: 'PropGlobal Sections listings',
            modal: false,
            modal2: false,
            row: {
                id: '',
                page_name: '',
                image: '',
                description: '',
                user_id: '',
                background: '',
                type: '',
                title: '',
            },
            pageNames: [],
            filter: {},
        }
    }

    componentDidMount() {
        this.getData();
        this.getPageNames();
    }

    getPageNames = () => {
        fetch(`${ApiUrl}get/global_cms_page_names`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uToken()}`
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        pageNames: response.data.global_cms.map(item => ({ label: item.page_name, value: item.page_name }))
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };
    getData = () => {
        fetch(`${ApiUrl}` + 'get/proglobal/sections', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    let filteredRecords = response.data.records;
                    if (this.state.filter.type && this.state.filter.type !== 'All') {
                        filteredRecords = filteredRecords.filter(record => record.type === this.state.filter.type);
                    }
                    if (this.state.filter.page_name && this.state.filter.page_name !== 'All') {
                        filteredRecords = filteredRecords.filter(record => record.page_name === this.state.filter.page_name);
                    }


                    this.setState({
                        records: filteredRecords,
                    });

                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    handlePageNameChange = (event) => {
        const page_name = event.target.value;
        this.setState(prevState => ({
            row: {
                ...prevState.row,
                page_name: page_name
            }
        }));
    };
    handleFilterSubmit = async (event, values) => {
        values.type = values.type === 'All' ? '' : values.type;
        values.page_name = values.page_name === 'All' ? '' : values.page_name;

        this.setState({
            filter: values,
        });

        this.getData();
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };


    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/proglobal/sections", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            page_name: '',
                            image: '',
                            description: '',
                            background: '',
                            user_id: '',
                            type: '',
                            title: ''

                        },
                        modal: false
                    })
                    this.getData();
                } else {
                    swal({
                        title: data.message,
                        icon: "warning",
                    });
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }

    handleTypeChange = (event) => {
        this.setState({
            row: {
                ...this.state.row,
                type: event.target.value
            }
        });
    };


    removeProduct = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/proglobal/sections", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right ">
                                    <Button type="button"
                                        color="info"
                                        className="btn-sm"
                                        onClick={
                                            () => this.setState({
                                                modal: true, row: {
                                                    id: '',
                                                    page_name: '',
                                                    image: '',
                                                    description: '',
                                                    background: '',
                                                    user_id: '',
                                                    type: '',
                                                    title: ''

                                                }
                                            })}>
                                        <i className="mdi mdi-plus"></i> Create
                                    </Button>
                                </div>

                            </Col>
                        </Row>


                        <Row>
                            <Col xl="12">
                                <Card className="mb-0">
                                    <CardBody className="py-0">
                                        <AvForm
                                            onValidSubmit={this.handleFilterSubmit}
                                            ref={(c) => (this.form = c)}
                                            className="needs-validation"
                                        >
                                            <Row>

                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            name="page_name"
                                                            label="Select Page Name"
                                                        >
                                                            <option value="">Select</option>
                                                            <option value={"All"}>All</option>
                                                            {this.state.pageNames.map((page, index) => (
                                                                <option key={index} value={page.value}>{page.label}</option>
                                                            ))}
                                                        </AvField>
                                                    </div>
                                                </Col>

                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <AvField className="select form-control" type="select" name="type" label="Type Status">
                                                            <option value={""}> Select Type </option>
                                                            <option value={"All"}>All</option>
                                                            <option value={"content"}> Content </option>
                                                            <option value={"image"}> Image </option>
                                                            <option value={"slider"}> Slider </option>
                                                        </AvField>
                                                    </div>
                                                </Col>




                                                <Col md="2" className="px-0">
                                                    <label className="">Action</label>
                                                    <div className="mb-3">
                                                        <div className="button-items">
                                                            <Button
                                                                className="btn-sm"
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                Filter
                                                            </Button>
                                                            <Button
                                                                onClick={() => this.cancelFilter()}
                                                                outline
                                                                color="secondary"
                                                                className="waves-effect btn-sm"
                                                                type="button"
                                                            >
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>



                        <Modal isOpen={this.state.modal} fade={false} toggle={() => this.setState({ modal: false })}>
                            <form onSubmit={this.submitPage} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform-testimonial">
                                <ModalBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Select Page Name</label>
                                                <select
                                                    name="page_name"
                                                    className="form-control"
                                                    onChange={this.handlePageNameChange}
                                                    value={this.state.row.page_name}
                                                >
                                                    <option value="">Select</option>
                                                    {this.state.pageNames.map((page, index) => (
                                                        <option key={index} value={page.value}>{page.label}</option>
                                                    ))}
                                                </select>
                                                <input
                                                    name="id"
                                                    defaultValue={this.state.row.id}
                                                    type='hidden'
                                                />
                                            </div>
                                        </Col>
                                        {
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <label>Type</label>
                                                    <select name="type" className="form-control" onChange={this.handleTypeChange} defaultValue={this.state.row.type}>
                                                        <option >Select Type</option>
                                                        <option value={'content'}>Content</option>
                                                        <option value={'image'}>Image</option>
                                                        <option value={'slider'}>Slider</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        }
                                        {this.state.row.type === 'content' && this.state.row.page_name !== 'privacy-policy' && this.state.row.page_name !== 'terms-and-condition' && (
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <label>Background Color</label>
                                                    <select name="background" className="form-control" defaultValue={this.state.row.background}>
                                                        <option>Select Background</option>
                                                        <option value={'white'}>White</option>
                                                        <option value={'black'}>Black</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        )}


                                        {(this.state.row.type === 'content' || this.state.row.type === 'slider') && (
                                            <>
                                                <Col md="12">
                                                    <div className="form-group mb-3">
                                                        <label>Title</label>
                                                        <input
                                                            name="title"
                                                            defaultValue={this.state.row.title}
                                                            className="form-control"
                                                            placeholder="Enter title"
                                                            required
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md="12">
                                                    <div className="form-group mb-3">
                                                        <label>Description</label>
                                                        {(this.state.row.page_name === 'technology' && this.state.row.type === 'slider') ||
                                                            (this.state.row.page_name === 'privacy-policy' && this.state.row.type === 'content') ? (
                                                            <Froala content={this.state.row.description ?? ''} nameKey={'description'} insideForm={true} btnId={'submit-blog'} handleSubmit={() => console.log('hi')} use_token={'users'} />
                                                        ) : (
                                                            <textarea
                                                                name="description"
                                                                className="form-control"
                                                                rows="5"
                                                                placeholder="Enter message"
                                                                defaultValue={this.state.row.description}
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                            </>
                                        )}

                                        {(this.state.row.type === 'image' || this.state.row.type === 'slider') && (

                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <label>Image</label>
                                                    <input
                                                        name="image"
                                                        className="form-control"
                                                        placeholder="Enter title"
                                                        type='file'
                                                    />
                                                    {this.state.row.image != 0 && (
                                                        <img width={"200px"} src={`${AttachementsUrl}/user_${this.state.row.user_id}/sections/${this.state.row.image}`} className="img img-thumbnail" />
                                                    )}
                                                </div>
                                            </Col>
                                        )}


                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="btn-sm" color="secondary" type="button" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                                    <Button className="Green btn-sm" type="submit">Submit</Button>
                                </ModalFooter>
                            </form>
                        </Modal>

                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}