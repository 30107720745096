import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody } from "reactstrap";
import { ApiUrl, ProductName, WebUrl } from "../../config";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { bcrypt, uToken } from "../../useToken";
import ProfileSettings from "../Authentication/profile-sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import SweetAlert from 'react-bootstrap-sweetalert';
export default class ManageProGlobalPages extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Id#",
                key: "id",
                cell: (record, index) => {
                    return index + 1;
                }
            },
            {
                text: "Page Name",
                key: "page_name",
            },
            {
                text: "Page Title",
                key: "page_title",
            },

            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button
                                onClick={() => {
                                    if (record.slug) {
                                        window.open(`${WebUrl}/edit_widget/${record.slug}`, '_blank');
                                    } else {
                                        this.setState({ showErrorAlert: true }); 
                                    }
                                }}
                                className="btn btn-sm btn-secondary mr-5"
                                title="Edit Widget"
                            >
                                <i className="mdi mdi-pencil-box-outline"></i>
                            </button>

                            <button onClick={() => this.props.history.push('/settings/page/edit/' + bcrypt(record.id))} className="btn btn-sm btn-secondary mr-5" title="Default Edit">
                                <i className="mdi mdi-pencil"></i>
                            </button>


                            <button onClick={() => { this.removePage(record.id) }} className="btn btn-sm btn-danger" title="Delete">
                                <i className="mdi mdi-trash-can-outline"></i>
                            </button>

                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            loader: true,
            page_name: 'Proglobal Pages',
            row: {
                id: '',
                page_name: '',
                page_title: '',
                slug: '',
                body: '',
            },
            showErrorAlert: false,
        };
    }

    componentDidMount() {
        this.fetchRecords();
    }

    fetchRecords = () => {
        fetch(`${ApiUrl}get/cms/proglobal-pages`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uToken()}`
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                this.setState({ loader: false });
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ loader: false });
            });
    }



    fetchSlug = (slug) => {
        fetch(`http://192.168.5.55/profitcoach/api/edit_widget/${slug}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uToken()}`
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        row: {
                            fileName: response.data.fileName,
                            getPageContent: response.data.getPageContent,
                            getPageBuilder: response.data.getPageBuilder
                        }
                    });
                } else {
                    swal({
                        title: response.message || "Error fetching page body",
                        icon: "warning",
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                swal({
                    title: "Error",
                    text: "Failed to fetch page body",
                    icon: "error",
                });
            });
    };


    fetchBodyContent = (pageId) => {
        fetch(`http://192.168.5.55/profitcoach/api/get/cms/proglobal-page-body/${pageId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${uToken()}`
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === true) {
                    this.setState({
                        row: {
                            ...this.state.row,
                            id: response.data.id,
                            body: response.data.body,
                        }
                    });
                } else {
                    swal({
                        title: response.message || "Error fetching page body",
                        icon: "warning",
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
                swal({
                    title: "Error",
                    text: "Failed to fetch page body",
                    icon: "error",
                });
            });
    }

    hideAlert = () => {
        this.setState({ showErrorAlert: false });
    };
    removePage = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/proglobal/page", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.fetchRecords();

                                // toast.success(data.message, {
                                //   theme: "colored",
                                //   autoClose: ToastAutoClose,
                                // });
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    
                    <Col lg="10">
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4>{this.state.page_name}</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                        <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name}</Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right ">
                                    <Link
                                        type="button"
                                        color="info"
                                        className="btn btn-info btn-sm"
                                        to={"/settings/proglobal/create-page"}
                                        onClick={
                                            () => this.setState({
                                                modal: true, row: {
                                                    id: '',
                                                    page_name: '',
                                                    page_title: '',
                                                    slug: '',
                                                    body: '',
                                                }
                                            })}
                                    >
                                        <i className="mdi mdi-plus"> </i>
                                        Create
                                    </Link>
                                </div>

                            </Col>
                        </Row>
                        {this.state.showErrorAlert && (
                        <SweetAlert
                            error
                            title="Error"
                            onConfirm={this.hideAlert}
                        >
                            Page not found.
                        </SweetAlert>
                        )}
                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
