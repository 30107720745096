import React, { useState, useEffect } from "react";
import WaySideBar from "./WaySideBar";
import WayCoachTraining from "./WayCoachTraining";
import WayBookContext from "./WaybookContext";
import SubjectLists from "./SubjectLists";
import CategoryLists from "./CategoryLists";
import DocumentContents from "./DocumentContents";
import TrainingCoachDashboard from "./TrainingCoachDashboard";
import TrainingAdminDashboard from "./TrainingAdminDashboard";
import QuizDetailsPage from "./trainingQuizStats";
import Dropdown from "react-bootstrap/Dropdown";
import { ApiUrl, AttachementsUrl } from "../../config";
import swal from "sweetalert";
import swal2 from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
//import SweetAlert from 'sweetalert2-react';
import {
  bcrypt,
  dcrypt,
  uInfo,
  uToken,
  canPurchaseAddon,
  hasPurchasedAddon , includedInPlanAddon
} from "../../useToken";
import Loader from "react-loader";
import Select from "react-select";
import SmallIcon from "../../assets/images/small/collaps-icon.png";
import { Modal, ModalBody, Label } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Froala from "../Froala";
import SearchBar from "./SearchBar";
// import CoachTrainingPath from "./CoachTrainingPath";
import TrainingPaths from "./TrainingPaths";

function App(props) {
  const userType = uInfo().type;
  const history = useHistory();
  const [id] = useState(
    props.match.params.id ? props.match.params.id.split("/") : []
  );
  const [setupreview] = useState(
    history.location.pathname.includes("trainingsreview") ? true : false
  );
  const { search } = useLocation();
  const url_params = new URLSearchParams(search);

  const is_coach_section = url_params.get("sec") ? "Yes" : "No";
  const [canEdit, setCanEdit] = useState(
    uInfo().user_id == 2 ? JSON.parse(localStorage.getItem("can_edit")) : false
  );

  const [new_training_tags, setNewTrainingTags] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loaded_coach_training, setLoadedCoachTraining] = useState(false);
  const [waybookList, setWayBookList] = useState([]);
  const [waybookCoachList, setWayBookCoachList] = useState([]);
  const [list, setList] = useState({});
  const [action, setAction] = useState(0);
  const [first_cat_id, setFirstCatId] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);

  const [coach_sidebar, setCoachSideBar] = useState(false);

  const [subAction, setSubAction] = useState({});
  const [subActionModal, setSubActionModal] = useState(false);

  const [stepAction, setStepAction] = useState({});
  const [stepActionModal, setStepActionModal] = useState(false);
  const [welcomeNotes, setWelcomeNotes] = useState({});

  const [onboard, setOnBoard] = useState({});

  const [url_cat_id] = useState(id[1] ? dcrypt(id[1]) : "0");
  const [list_tags, setListTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [searchWordFinal, setSearchWordFinal] = useState("");
  const [numbers, setNumbers] = useState([]);

  const [searchResult, setSearchResult] = useState("");
  const [countRecord, setCountRecord] = useState("");
  const [quizValue, setQuizValue] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    label: "Training",
    value: "content",
  });
  const [defualtType, setDefualtType] = useState({
    label: "Training",
    value: "content",
  });
  const [quizOrcontent, setQuizOrcontent] = useState("");
  const [noOptions, setNoOptions] = useState(false);
  const [isViewingQuiz, setIsViewingQuiz] = useState(false);
  const [catIdAndStepId, setCatIdAndStepId] = useState({
    cat_id: 0,
    step_id: 0,
  });

  const [showTrainingPath, setShowTrainingPath] = useState(false);
  const typeMap = {
    Category: "Course",
    Subject: "Module",
    Document: "Lesson",
  };
  const [checkedItems, setCheckedItems] = useState({});
  const [certifications, setCertifications] = useState([]);

  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [index]: checked,
    }));
  };
  useEffect(() => {
    // setTagEnable("No");
    const fetchData = async () => {
      await getTagLists("category", 0);
      getWayBookLists();
      getTrainingCertifications();
    };
    fetchData();
    numberSelect();
    setNoOptions(false);
    console.log(canEdit);
  }, []);

  const getTrainingCertifications = () => {
    fetch(`${ApiUrl}` + "training/certification/fetch", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      // body: JSON.stringify({ id: uInfo().user_id, status: badgeStatus }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          console.log("setCertifications", response.data.records);
          setCertifications(response.data.records);
        } else {
          setCertifications([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  // useEffect(() => {
  // 	const matchingOptions = list_tags.filter(tag => tag.name.toLowerCase().includes(inputValue.toLowerCase()));
  // 	setNoOptions(inputValue && matchingOptions.length === 0);
  // }, [inputValue, list_tags]);

  useEffect(() => {}, [numbers]);

  const numberSelect = () => {
    const options = Array.from({ length: 100 }, (v, k) => ({
      value: 100 - k,
      label: `${100 - k}%`,
    }));
    setNumbers(options);
  };

  const renderTrainingTag = () => {
    switch (action) {
      case 1:
        return "TrainingTagCategory";
      case 2:
        return "TrainingTagSubject";
      case 3:
        return "TrainingTagDocument";
      case 4:
        return "TrainingTagSubject";
      default:
        return "TrainingTagCategory";
    }
  };
  const getsearch = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/search", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ search_word: searchWord, can_edit: canEdit }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setSearchResult(response.data.records);
          console.log(response.data.records, "record111");
          setCountRecord(response.data.records.length);
          setSearchWordFinal(searchWord);
        } else {
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const customNoOptionsMessage = (record) => {
    setInputValue(record.inputValue);
    const matchingOptions = list_tags.filter((tag) =>
      tag.name.toLowerCase().includes(record.inputValue.toLowerCase())
    );
    setNoOptions(record.inputValue && matchingOptions.length === 0);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };
  const handleCoachSideBarChange = (newValue) => {
    setCoachSideBar(newValue);
  };
  const handleQuizstepsChanages = (option, catid) => {
    // if (option.value === 'quiz') {
    // 	let bodyParamsforResult = {
    // 		cat_id: catid,
    // 	};
    // 	fetch(`${ApiUrl}` + "training/quiz/checkquiztype", {
    // 		method: "POST",
    // 		headers: {
    // 			Accept: "application/json",
    // 			"Content-Type": "application/json",
    // 			Authorization: `Bearer ` + uToken(),
    // 		},
    // 		body: JSON.stringify(bodyParamsforResult),
    // 	})
    // 		.then((response) => response.json())
    // 		.then((response) => {
    // 			if (response.status === true) {
    // 				console.log(response)
    // 				if (response.data.exists) {
    // 					setDefualtType({ label: "Step", value: "content" });
    // 					toast("You have already added a Review step for this category. You can't add another quiz for this category.", { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    // 				} else {
    // 					setDefualtType(option);
    // 					setSelectedOption(option);
    // 				}
    // 			} else {
    // 				//toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    // 			}
    // 		})
    // } else {
    // }
    setDefualtType(option);
    setSelectedOption(option);
  };

  const handleCreateTag = (event) => {
    event.preventDefault();
    console.log(inputValue, "inputValue");
    fetch(`${ApiUrl}` + "add/training/tag", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ name: inputValue }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          const newTag = response.data.record;

          const updatedTags = [...list_tags, newTag];
          setListTags(updatedTags);
          //console.log(setNewTrainingTags, 'setNewTrainingTags')
          setNewTrainingTags((prevTags) => {
            return [...prevTags, newTag];
          });
          setStepAction((prevState) => ({
            ...prevState,
            tag_training: Array.isArray(prevState.tag_training)
              ? [...prevState.tag_training, newTag.id]
              : [newTag.id],
          }));
          setSubAction((prevState) => ({
            ...prevState,
            tag_training: Array.isArray(prevState.tag_training)
              ? [...prevState.tag_training, newTag.id]
              : [newTag.id],
          }));
          setInputValue("");
          swal({
            title: "You created a new training tag",
            text: "Do you want to manage users for it?",
            icon: "success",
            buttons: {
              cancel: "No",
              confirm: "Yes", // Change "Yes" text
            },
            dangerMode: true,
          }).then((confirmation) => {
            if (confirmation) {
              history.push(`/training/tags?id=${bcrypt(newTag.id)}`);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleTrainingForChange = (e) => {
    const trainingForArray =
      typeof subAction.training_for === "string"
        ? subAction.training_for.split(",").map((item) => item.trim())
        : [];
    //console.log(trainingForArray, 'trainingForArraytrainingForArray')
    let status = e.some((obj) => !trainingForArray.includes(obj.value));
    if (status) {
      var res = e.map((s) => s.value);
      setSubAction((prevState) => ({
        ...prevState,
        training_for: res.toString(),
      }));
      return false;
    }
    swal({
      title: "After the remove and update action",
      text: "the sublevel of this section will also be hidden for the selected training-for option. Are you sure you want to proceed?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        var res = e.map((s) => s.value);
        setSubAction((prevState) => ({
          ...prevState,
          training_for: res.toString(),
        }));
      } else {
        return false;
      }
    });
  };

  const handleChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    const isStatusRequired =
      subAction.tag_training &&
      Array.isArray(subAction.tag_training) &&
      subAction.tag_training.some(
        (id) => selectedIds && !selectedIds.includes(id)
      );
    const newTrainingTags = selectedOptions.filter(
      (tag) =>
        Array.isArray(subAction.tag_training) &&
        !subAction.tag_training.includes(tag.id)
    );

    if (isStatusRequired) {
      // Only show SweetAlert if required
      swal({
        title: "After the remove and update action",
        text: "The sublevel of this section will also be hidden for the selected training option. Are you sure you want to proceed?",
        icon: "warning",
        buttons: {
          cancel: {
            text: "No",
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Yes",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      }).then((confirmation) => {
        if (confirmation) {
          setNewTrainingTags((prevTags) => {
            const filteredExistingTags = prevTags.filter((tag) =>
              selectedIds.includes(tag.id)
            );
            const combinedTags = [...filteredExistingTags, ...newTrainingTags];
            return combinedTags.filter((tag) => selectedIds.includes(tag.id));
          });

          setSubAction((prevState) => ({
            ...prevState,
            tag_training: selectedIds,
          }));
        }
      });
    } else {
      setNewTrainingTags((prevTags) => {
        const filteredExistingTags = prevTags.filter((tag) =>
          selectedIds.includes(tag.id)
        );
        const combinedTags = [...filteredExistingTags, ...newTrainingTags];
        return combinedTags.filter((tag) => selectedIds.includes(tag.id));
      });
      setSubAction((prevState) => ({
        ...prevState,
        tag_training: selectedIds,
      }));
    }
  };

  const handleStepChange = (selectedOptions) => {
    //console.log(selectedOptions, 'selectedOptions')
    const selectedIds = selectedOptions.map((option) => option.id);
    setStepAction((prevState) => ({
      ...prevState,
      tag_training: selectedIds,
    }));
    //setInputValue(selectedOptions);
  };

  const handleCertificationChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    console.log("selectedIds", selectedIds);
    console.log("certifications", certifications);
    console.log("subAction", subAction.training_certifications);
    setSubAction((prevState) => ({
      ...prevState,
      training_certifications: selectedIds,
    }));
  };

  const getWayBookLists = () => {
    setLoaded(false);
    setLoadedCoachTraining(false);
    fetch(`${ApiUrl}` + "get/waybook/category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ can_edit: canEdit }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("categories", response.data.record);
        if (response.status === true) {
          setWayBookList(response.data.record);
          setList(response.data.summary);
          setWelcomeNotes(response.data.welcome_notes);
          setOnBoard(response.data.onBoardTraining);
          setShowTrainingPath(props.match.params.id === "path" ? true : false);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
        setLoaded(true);
        setLoadedCoachTraining(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const handleWelcomeSubmit = async (event, meta_value) => {
    fetch(`${ApiUrl}` + "upate/waybook/notes", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ meta_value: meta_value }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // props.history.push('/waybook');
          setAction(0);
          getWayBookLists();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getTagLists = async (section, record_id) => {
    // fetch(`${ApiUrl}` + "get/user/trainingtags", {
    fetch(`${ApiUrl}` + "get/section/trainingtags", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        can_edit: "yes",
        user_id: uInfo().user_id,
        section: section,
        record_id: record_id,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log("tags list", response.data.records);
        if (response.status === true) {
          setListTags(response.data.records);
          setNewTrainingTags([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleOnboardTraining = async (cat_id) => {
    // event.preventDefault();
    // const formData = new FormData(event.currentTarget);
    // const submitData = new FormData();
    // for (let [key, value] of formData.entries()) {
    // submitData.append(key, value);
    // }

    fetch(`${ApiUrl}` + "set/waybook/category/onboarding", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ cat_id: cat_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // props.history.push('/waybook');
          setAction(0);
          getWayBookLists();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    const currentUrl = window.location.href;
    submitData.append("is_coach_section", is_coach_section);

    fetch(`${ApiUrl}` + "submit/waybook/category", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (response.data.redirect_url) {
            console.log(response.data.redirect_url, "redirect_url11");
            props.history.push(response.data.redirect_url);
          } else {
            setAction(0);
            getWayBookLists();
          }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match("image.*")) {
      swal({
        title: "Olny image allowed",
        icon: "warning",
      });
    }
    setImagePreview(URL.createObjectURL(file));
  };

  const closeSubActionModal = () => {
    setSubAction({});
    setSubActionModal(false);
    setStepAction({});
    setStepActionModal(false);
    setSelectedOption({ label: "Step", value: "content" });
  };

  const changeStatusTraining = (cat_id, action) => {
    fetch(`${ApiUrl}` + "status/update/waybook/category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ cat_id: cat_id, status: action }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          getWayBookLists();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlMoveAction = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();

    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "move/waybook/category", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setDefualtType({ label: "Training", value: "content" });
          const laststepid = response.data.step_id;
          closeSubActionModal();
          //getWayBookLists();
          let section = "";
          console.log(is_coach_section, "is_coach_section");
          if (is_coach_section === "Yes") {
            section = "&sec=Y29hY2g=";
          }
          const currenturl = window.location.pathname;
          if (response.data.type === "quiz") {
            history.push(
              `/trainingsreview/${response.data.encoded_link + section}`
            );
          } else {
            history.push(`/trainings/${response.data.encoded_link + section}`);
          }

          // const arr = currenturl.split("/").filter(Boolean);
          // if (arr.length > 3) {
          // 	arr.splice(-2);
          // }
          // if (arr[arr.length - 1] === '') {
          // 	arr[arr.length - 2] = bcrypt(laststepid);
          // 	const index = arr.indexOf('trainings');
          // 	if (index !== -1) {
          // 		if (response.data.type === 'quiz') {
          // 			arr[index] = 'trainingsreview';
          // 		} else {
          // 			arr[index] = 'trainings';
          // 		}
          // 	}
          // 	const joinedString = arr.join('/');

          // 	history.push(`${joinedString}?step=${bcrypt(laststepid)}`);
          // } else {
          // 	console.log('else ---')
          // 	const index = arr.indexOf('trainings') !== -1 ? arr.indexOf('trainings') : arr.indexOf('trainingsreview');
          // 	if (index !== -1) {
          // 		arr[index] = response.data.type === 'quiz' ? 'trainingsreview' : 'trainings';
          // 	}
          // 	const joinedString = arr.join('/');
          // 	const iscoachsection = (is_coach_section === "Yes") ? `/?step=${bcrypt(laststepid)}&sec=Y29hY2g=` : `/?step=${bcrypt(laststepid)}`;
          // 	console.log(`/${joinedString}/${bcrypt(laststepid)}${iscoachsection}`)
          // 	history.push(`/${joinedString}/${bcrypt(laststepid)}${iscoachsection}`);
          // }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlStepMoveAction = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }
    fetch(`${ApiUrl}` + "update/waybook/category/step", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          //getWayBookLists();
          closeSubActionModal();
          history.push(`/trainingsreview/${response.data.encoded_link}`);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const subjectOrDocumentAction = (
    type,
    cat_id,
    title,
    action,
    training_for,
    tag_training,
    image = "",
    user_id = 2,
    section,
    master_id,
    badge_image,
    badge_description = "",
    coaching_points = 0,
    course_description = "",
    training_certifications = ""
  ) => {
    console.log("inside", action);
    setShowTrainingPath(false);
    setNoOptions(false);
    setAction(0);
    setSubAction({});
    setTimeout(() => {
      if (action == "Move") {
        setSubAction({
          type: type,
          cat_id: cat_id,
          title: title,
        });
        setSubActionModal(true);
      } else if (
        action == "Delete" ||
        action == "Draft" ||
        action == "Publish"
      ) {
        changeStatusTraining(cat_id, action);
      } else if (action == "AddSubject") {
        setSubAction({
          type: type,
          add_category_id: cat_id,
          title: title,
        });
        getTagLists("subject", cat_id);
        setAction(2);
      } else if (action == "AddDocument") {
        setSubAction({
          type: type,
          add_subject_id: cat_id,
          title: title,
        });
        console.log(cat_id, "actionactionaction");
        setSubAction("document");
        getTagLists("document", cat_id);
        //setSubActionModal(true);
        setAction(3);
      } else if (action == "AddStep") {
        console.log(type);
        setSubAction({
          type: type,
          document_id: cat_id,
          title: title,
          section: section ? section : "left",
        });
        getTagLists("step", cat_id);
        setSubActionModal(true);
      } else if (action == "AddStepquiz") {
        setSubAction({
          type: type,
          document_id: cat_id,
          title: title,
          section: section ? section : "left",
        });

        setDefualtType({ label: "Quiz", value: "quiz" });
        setSelectedOption({ label: "Quiz", value: "quiz" });
        setSubActionModal(true);
        getTagLists("quiz", cat_id);
      } else if (action == "Update") {
        setSubAction({
          type: type,
          cat_id: cat_id,
          title: title,
          image: image,
          user_id: user_id,
          training_for: training_for,
          tag_training: tag_training,
          add_category_id: "",
          add_subject_id: "",
          badge_image: badge_image,
          badge_description: badge_description,
          course_description: course_description,
          coaching_points: coaching_points,
          training_certifications: training_certifications,
        });
        setAction(5);
        console.log("course_description", course_description);
        console.log("title", title);
        console.log(" --- type -- ", type);
        // if(type === 'Category'){
        // 	setTagEnable("Yes");
        // }
        getTagLists(type.toLowerCase(), master_id);
      } else if (action == "OnBoarding") {
        handleOnboardTraining(cat_id);
      }
    }, 100);
  };

  const trigger_action = (step_id, action) => {
    fetch(`${ApiUrl}` + "status/update/waybook/category/step", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        step_id: step_id,
        status: action,
        is_coach_section: is_coach_section,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (action === "Retake" || action === "Notification") {
            swal({
              title: response.message,
              icon: "success",
            });
          } else if (action === "Delete") {
            const path = `/trainings/${bcrypt(data.category)}/${bcrypt(
              data.subject
            )}/${bcrypt(data.documents)}`;
            console.log(data, "pathpathpath");
            history.push(path);
          } else {
            getWayBookLists();
          }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const stepMethodAction = (
    type,
    step_id,
    title,
    action,
    training_for,
    tag_training,
    cat_id
  ) => {
    setNoOptions(false);
    if (action == "Move" || action == "Update") {
      setStepAction({
        type: type,
        step_id: step_id,
        title: title,
        action: action,
        training_for: training_for,
        tag_training: tag_training,
      });

      setStepActionModal(true);
      getTagLists("document", cat_id);
    } else if (
      action == "Delete" ||
      action == "Draft" ||
      action == "Publish" ||
      action == "Retake" ||
      action == "Notification"
    ) {
      trigger_action(step_id, action);
    }
  };
  let templates_group = [];
  let templates_documents_group = [];

  if (uInfo().type === "Coach") {
    // Process waybookCoachList
    templates_group = waybookCoachList.map((row) => {
      let sub_cat = row.sub_cat.map((type) => ({
        cat_id: type.cat_id,
        title: type.title,
        onboard_training: type.onboard_training,
      }));
      return { label: row.title, options: sub_cat };
    });

    templates_documents_group = waybookCoachList.flatMap((row) =>
      row.sub_cat.map((sub) => {
        let subs = sub.sub_cat.map((doc) => ({
          cat_id: doc.cat_id,
          title: doc.title,
        }));
        return { label: `${sub.title} - ${row.title}`, options: subs };
      })
    );
  } else {
    // Process waybookList
    templates_group = waybookList.map((row) => {
      let sub_cat = row.sub_cat.map((type) => ({
        cat_id: type.cat_id,
        title: type.title,
        onboard_training: type.onboard_training,
      }));
      return { label: row.title, options: sub_cat };
    });

    templates_documents_group = waybookList.flatMap((row) =>
      row.sub_cat.map((sub) => {
        let subs = sub.sub_cat.map((doc) => ({
          cat_id: doc.cat_id,
          title: doc.title,
        }));
        return { label: `${sub.title} - ${row.title}`, options: subs };
      })
    );
  }

  const Action = (action, cat_id) => {
    console.log(action, cat_id);
    setAction(action);
    if (action == 2) {
      setSubAction({
        type: "Subject",
        add_category_id: cat_id,
        title: "Course",
      });
    }
    if (action == 3) {
      setSubAction({
        type: "Document",
        add_subject_id: cat_id,
        title: "Module",
      });
    }
  };

  const documentLogsReset = (document_id, step_id = "") => {
    fetch(`${ApiUrl}` + "waybook/category/logs/reset", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ cat_id: document_id, step_id: step_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          getWayBookLists();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const markAsComplete = (cat_id, step_id) => {
    let data = {
      cat_id: cat_id,
      step_id: step_id,
    };

    fetch(`${ApiUrl}` + "step/mark/complete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          let next_training_type = "trainings";
          if (response.data.record.next_step_type === "quiz") {
            next_training_type = "trainingsreview";
          }

          if (
            response.data.record.completed_certifications &&
            Object.keys(response.data.record.completed_certifications).length >
              0
          ) {
            var confirm_text = "";
            const badge = response.data.record;
            if (badge.course_completed === "Yes") {
              confirm_text = "Go to Dashboard";
            } else if (
              badge.module_completed === "Yes" &&
              badge.course_completed === "No"
            ) {
              confirm_text = "Go to Next";
            }

            const certification = response.data.record.completed_certifications;
            swal2
              .fire({
                title: `<span style="color: green;margin-right: 2px;">${certification.title}</span>`,
                html: `Congratulations On Completing <span style="font-weight: bold">${response.data.record.title}</span>`,

                imageUrl: `${AttachementsUrl}/user_${certification.user_id}/training_badges/${certification.image}`,
                showConfirmButton: true,
                confirmButtonText: confirm_text,
                showCancelButton: true,
                cancelButtonText: `Close`,
                confirmButtonColor: "#28a745",
              })
              .then((result) => {
                if (result.value) {
                  if (badge.course_completed === "Yes") {
                    history.push(`/${next_training_type}`);
                  } else if (
                    badge.module_completed === "Yes" &&
                    badge.course_completed === "No"
                  ) {
                    history.push(
                      `/${next_training_type}${
                        badge.next_module_link
                          ? "/" + badge.next_module_link
                          : ""
                      }`
                    );
                  }
                } else {
                  getWayBookLists();
                }
              });
          } else if (response.data.record.course_completed === "Yes") {
            swal2
              .fire({
                title: `<span style="color: green;margin-right: 2px;">${response.data.record.course_title}</span>`,
                html: `Congratulations On Completing <span style="font-weight: bold">${response.data.record.title}</span>`,

                imageUrl: `${AttachementsUrl}/user_${response.data.record.user_id}/training/${response.data.record.badge_image}`,
                showConfirmButton: true,
                confirmButtonText: `Go to Dashboard`,
                showCancelButton: true,
                cancelButtonText: `Close`,
                confirmButtonColor: "#28a745",
              })
              .then((result) => {
                // console.log(result.isConfirmed, 'course')

                if (result.value) {
                  history.push(`/${next_training_type}`);
                } else {
                  getWayBookLists();
                }
              });
          } else if (
            response.data.record.module_completed === "Yes" &&
            response.data.record.course_completed === "No"
          ) {
            swal2
              .fire({
                title: "Module Completed",
                html: `Congratulations On Completing <span style="font-weight: bold">${response.data.record.title}</span>`,

                imageUrl: `${AttachementsUrl}/user_${response.data.record.user_id}/training/${response.data.record.badge_image}`,
                showConfirmButton: true,
                confirmButtonText: `Go to Next`,
                showCancelButton: true,
                cancelButtonText: `Close`,
                confirmButtonColor: "#28a745",
              })
              .then((result) => {
                // console.log(result.isConfirmed, 'module')
                if (result.value) {
                  history.push(
                    `/${next_training_type}${
                      response.data.record.next_module_link
                        ? "/" + response.data.record.next_module_link
                        : ""
                    }`
                  );
                } else {
                  getWayBookLists();
                }
              });
          } else if (response.data.record.lesson_completed === "Yes") {
            history.push(
              `/${next_training_type}${
                response.data.record.next_lesson_link
                  ? "/" + response.data.record.next_lesson_link
                  : ""
              }`
            );
          } else {
            history.push(
              `/${next_training_type}${
                response.data.record.next_step_link
                  ? "/" + response.data.record.next_step_link
                  : ""
              }`
            );
          }
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const data = {
    category: typeof id[0] != "undefined" ? dcrypt(id[0]) : "",
    subject: typeof id[1] != "undefined" ? dcrypt(id[1]) : "",
    documents: typeof id[2] != "undefined" ? dcrypt(id[2]) : "",
    step: typeof id[3] != "undefined" ? dcrypt(id[3]) : "",
    can_edit: canEdit,
    can_edit_coach:
      uInfo().type === "Coach"
        ? JSON.parse(localStorage.getItem("can_edit_coach"))
        : false,
    lists: waybookList,
    setWayBookCoachList: setWayBookCoachList,
    subjectOrDocumentAction: subjectOrDocumentAction,
    Action: Action,
    getTagLists: getTagLists,
    stepMethodAction: stepMethodAction,
    documentLogsReset: documentLogsReset,
    markAsComplete: markAsComplete,
    updatetWayBookLists: getWayBookLists,
    setAction: setAction,
    coach_sidebar: setCoachSideBar,
  };
  const user_types = [
    { value: "1", label: "Coach" },
    { value: "2", label: "Diamond Partner" },
    { value: "3", label: "Client" },
    { value: "4", label: "Team Admin" },
    { value: "6", label: "Team Coach" },
  ].filter((type) => {
    if (uInfo().type === "Coach") {
      return type.value === "3" || type.value === "6";
    }
    return true;
  });
  const handleQuizdetailsView = (cat_id, step_id) => {
    console.log("buttonClicked!", cat_id, step_id);
    setCatIdAndStepId({ cat_id, step_id });
    setIsViewingQuiz(true);
  };

  const handleBackButtonClickBacktn = () => {
    //setCatIdAndStepId({ 0, 0 });
    setIsViewingQuiz(false);
  };

  const handleTrainingPathShow = () => {
    console.log("show path");
    // setAction(0)
    // setLoaded(true);
    // setSearchResult(false);
    setShowTrainingPath(true);
  };

  const showDocuments = () => {
    setShowTrainingPath(false);
  };
  // function tToggle() {
  // 	var body = document.body;
  // 	body.classList.toggle("vertical-collpsed");
  // 	body.classList.toggle("sidebar-enable");
  // }
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");

    var pageContent = document.querySelector(".way-content DashboardTraning1");
    if (body.classList.contains("vertical-collpsed")) {
      pageContent.style.padding = "";
    } else {
      pageContent.style.padding =
        "calc(0px + 0px) calc(24px / 2) 60px calc(690px / 2)";
      // Restore default or other padding styles when the class is not present
    }
  }

  return (
    <WayBookContext.Provider value={data}>
      <Loader loaded={loaded}></Loader>
      <div className="main-way">
        <div className="row">
          <React.Fragment>
            <div className="vertical-menu TraningDashboard">
              <div data-simplebar className="h-100">
                <div
                  className={`WayBookBar ${
                    uInfo().type != "WlAdmin" ? "AdminEdit" : ""
                  }`}
                >
                  <div className="d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      onClick={() => {
                        tToggle();
                      }}
                      className="btn btn-sm header-item waves-effect vertical-menu-btn "
                      id="vertical-menu-btn"
                    >
                      {/* <i className="mdi mdi-menu"></i> */}
                      <img src={SmallIcon}></img>
                    </button>
                  </div>
                  <div className="HideCollaps mt-2">
                    <ul className="TabsSideBar mb-3">
                      <li className="nav-item">
                        <Link
                          className={`${
                            props.match.params.id != "path" ? "active" : ""
                          } nav-link`}
                          onClick={() => {
                            history.push(`/trainings`);
                          }}
                        >
                          Home
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          onClick={() => {
                            history.push(`/ask-ai`);
                            setShowTrainingPath(false);
                          }}
                        >
                          Ask Ai
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${
                            props.match.params.id === "path" ? "active" : ""
                          } nav-link`}
                          onClick={() => {
                            setShowTrainingPath(true);
                            history.push(`/trainings/path`);
                          }}
                        >
                          Paths
                        </Link>
                      </li>
                    </ul>
                    <div className="d-flex px-2">
                      <div
                        className={
                          canEdit
                            ? "col-lg-12 px-0 SearchSection edit-adjust"
                            : "col-lg-12 px-0 SearchSection"
                        }
                      >
                        <div className="SearchTraningBar">
                          <div className="InputSearch">
                            <input
                              type="search"
                              className="form-control"
                              id="searchInput"
                              placeholder="Search lessons"
                              onKeyUp={(e) => {
                                setSearchWord(e.target.value);
                                if (e.key === "Enter") {
                                  getsearch();
                                }

                                document
                                  .getElementById("searchInput")
                                  .addEventListener("input", (event) => {
                                    if (!event.currentTarget.value) {
                                      window.location.reload();
                                    }
                                  });
                              }}
                            ></input>
                            <i className="mdi mdi-magnify"></i>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        {uInfo().user_id == 2 && (
                          <div className="form-check form-switch py-0 d-flex px-0">
                            <label className="mb-0 me-2">Edit</label>
                            <input
                              type="checkbox"
                              className="form-check-input ms-0"
                              id="customSwitch2"
                              checked={JSON.parse(canEdit)}
                              onChange={(e) => {
                                setCanEdit(e.target.checked);
                                localStorage.setItem(
                                  "can_edit",
                                  e.target.checked
                                );
                                window.location.reload(); // This will reload the page
                              }}
                            />
                          </div>
                        )}
                        {canEdit && (
                          <Dropdown
                            className="align-self-center"
                            onSelect={(e) => {
                              const section_type =
                                e == 1 ? "category" : "subject";
                              getTagLists(section_type, 0);
                              setAction(e);
                              setNoOptions(false);
                              setSubAction((prevState) => ({
                                ...prevState,
                                tag_training: [],
                              }));
                            }}
                          >
                            <Dropdown.Toggle
                              title="Add New"
                              variant="link"
                              id="dropdown-basic"
                            >
                              <i className="fas fa-plus"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="1">Course</Dropdown.Item>
                              <Dropdown.Item eventKey="2">Module</Dropdown.Item>
                              <Dropdown.Item eventKey="3">Lesson</Dropdown.Item>
                              {/* <Dropdown.Item eventKey="7">Set Onboadrd Training</Dropdown.Item>  */}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                    <div className="d-flex  w-100 WayBookDropDown pt-0 justify-content-between mt-2">
                      {canEdit ? (
                        <>
                          <Link
                            to={"/trainings"}
                            className="p-1"
                            onClick={() => showDocuments()}
                          >
                            <i className="fas fa-home"></i> Dashboard
                          </Link>
                        </>
                      ) : (
                        <>
                          {uInfo().type == "Client" ? (
                            <>
                              <Link
                                to={"/trainings"}
                                className="p-1"
                                onClick={() => showDocuments()}
                              >
                                <i className="fas fa-home"></i> Dashboard{" "}
                              </Link>

                              <Link to={"/client/profile"} className="p-1">
                                <i className="fas fa-angle-left"></i> Back
                              </Link>
                            </>
                          ) : (
                            <Link
                              to={"/trainings"}
                              className="p-1"
                              onClick={() => showDocuments()}
                            >
                              <i className="fas fa-home"></i> Dashboard{" "}
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                    <div className="way-sidebar">
                      {loaded && <WaySideBar lists={waybookList} />}

                      {/* {uInfo().type === "Coach" &&
                        uInfo().coach_can_sell_trainings != "inactive" &&
                        loaded_coach_training && (
                          <WayCoachTraining cat_id={first_cat_id} />
                        )} */}
                      {uInfo().type === "Coach" &&
                        (hasPurchasedAddon(4) || includedInPlanAddon(4) || canPurchaseAddon(4)) &&
                        loaded_coach_training && (
                          <WayCoachTraining cat_id={first_cat_id} />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
          {showTrainingPath ? (
            <div className="way-content DashboardTraning">
              <TrainingPaths courses={waybookList} />
            </div>
          ) : !searchResult && loaded ? (
            <div className="way-content DashboardTraning">
              {action == 0 ? (
                data.category != "" && data.subject == "" ? (
                  <CategoryLists />
                ) : data.subject != "" && data.documents == "" ? (
                  <SubjectLists />
                ) : data.subject != "" && data.documents != "" ? (
                  // <DocumentContents id={id} setupreview={setupreview} lists={uInfo().type === 'Coach'? waybookCoachList:waybookList} />
                  <DocumentContents
                    id={id}
                    setupreview={setupreview}
                    lists={waybookList}
                  />
                ) : isViewingQuiz ? (
                  <QuizDetailsPage
                    onBack={handleBackButtonClickBacktn}
                    details={catIdAndStepId}
                  />
                ) : uInfo().user_id === 2 ? (
                  <TrainingAdminDashboard
                    handleQuizdetailsView={handleQuizdetailsView}
                  />
                ) : (
                  <TrainingCoachDashboard />
                )
              ) : (
                // uInfo().user_id == 2? <TrainingAdminDashboard /> :<TrainingCoachDashboard />
                <>
                  <div className="TraningRightContent RenamePages">
                    <div
                      className={`action-way d-flex m-auto justify-content-center current_${action}`}
                    >
                      <div className="widthWise">
                        <div className="title text-center mb-1">
                          {action == 1 ? (
                            <h4>Create a new Course</h4>
                          ) : action == 2 ? (
                            <h4>Create a new Module</h4>
                          ) : action == 3 ? (
                            <h4>Create a new Lesson</h4>
                          ) : action == 4 ? (
                            <h4>Create a new Module</h4>
                          ) : action == 5 ? (
                            <h4>Update</h4>
                          ) : action == 6 ? (
                            <h4>Welcome Message</h4>
                          ) : action == 7 ? (
                            <h4>Set Onboard Training</h4>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="form-action">
                          {action == 6 ? (
                            <>
                              <Froala
                                inlineEditior={true}
                                btnId={"handle-welcome-submit"}
                                content={welcomeNotes.meta_value}
                                handleSubmit={handleWelcomeSubmit}
                                use_token={"users"}
                              />
                              <div className="row mt-3">
                                <div className="col-md-12 text-center">
                                  <button
                                    type="button"
                                    id="handle-welcome-submit"
                                    className="btn btn-primary w-100"
                                  >
                                    Update Note
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : action == 7 ? (
                            <>
                              <h6>
                                Current Onboard Training : {onboard.title} (
                                {onboard.master.title ?? ""})
                              </h6>
                              <form
                                method="post"
                                onSubmit={handleOnboardTraining}
                                encType="multipart/form-data"
                                className="form-horizontal"
                                id="selection-myform"
                              >
                                <div className="form-group mt-3">
                                  <label
                                    htmlFor="example-text-input"
                                    className="form-label"
                                  >
                                    Course
                                  </label>
                                  <Select
                                    isClearable
                                    getOptionLabel={(option) => option.title}
                                    getOptionValue={(option) => option.cat_id}
                                    isMulti={false}
                                    menuIsOpen={true}
                                    placeholder={
                                      "Select Course which you want to show as onboard trianing"
                                    }
                                    options={templates_group}
                                    classNamePrefix="select2-selection"
                                    name="cat_id"
                                  />
                                </div>
                                <p className="text-danger my-1">
                                  The Course you are applying for should be
                                  Publish Lessons and Steps.
                                </p>

                                <div className="row">
                                  <div className="col-md-12 text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-100"
                                    >
                                      Set Training
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </>
                          ) : (
                            <form
                              method="post"
                              onSubmit={handleSubmit}
                              encType="multipart/form-data"
                              className="form-horizontal"
                              id="selection-myform"
                            >
                              {action == 1 && (
                                <>
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      placeholder="The name of this Course"
                                      required
                                      className="form-control"
                                      type={"text"}
                                      name="title"
                                    />

                                    <input
                                      type={"hidden"}
                                      name="type"
                                      value={1}
                                    />
                                  </div>
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="course_description"
                                      className="form-label"
                                    >
                                      Course Objective
                                    </label>
                                    <textarea
                                      placeholder="Enter Course description"
                                      className="form-control"
                                      name="course_description"
                                      rows="3"
                                    ></textarea>
                                  </div>
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="training_for-text-label"
                                      className="form-label"
                                    >
                                      Training for
                                    </label>
                                    <div className="LowIndexothers ActionOne">
                                      <Select
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.value
                                        }
                                        isMulti={true}
                                        options={user_types}
                                        classNamePrefix="select2-selection position-releative"
                                        name="training_for[]"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}

                              {action == 2 && (
                                <>
                                  {subAction.add_category_id ? (
                                    <input
                                      type={"hidden"}
                                      name="master_id"
                                      value={subAction.add_category_id}
                                    />
                                  ) : (
                                    <div className="form-group mb-3">
                                      <label
                                        htmlFor="example-text-input"
                                        className="form-label"
                                      >
                                        Course{" "}
                                      </label>
                                      <div className="CategoryListTarnings ActionTwoSub">
                                        <Select
                                          getOptionLabel={(option) =>
                                            option.title
                                          }
                                          getOptionValue={(option) =>
                                            option.cat_id
                                          }
                                          isMulti={false}
                                          onChange={(e) =>
                                            getTagLists("subject", e.cat_id)
                                          }
                                          options={
                                            uInfo().type === "Coach"
                                              ? waybookCoachList
                                              : waybookList
                                          }
                                          classNamePrefix="select2-selection"
                                          name="master_id"
                                        />
                                      </div>
                                    </div>
                                  )}

                                  <div className="form-group mb-3 mt-5">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      placeholder="The name of this Module"
                                      required
                                      className="form-control"
                                      type={"text"}
                                      name="title"
                                    />
                                    <input
                                      type={"hidden"}
                                      name="type"
                                      value={2}
                                    />
                                  </div>
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Module Image
                                    </label>
                                    <input
                                      accept="image/*"
                                      required
                                      className="form-control"
                                      type={"file"}
                                      onChange={(e) => changeHandler(e)}
                                      name="image"
                                    />
                                  </div>

                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Badge Image
                                    </label>
                                    <input
                                      accept="image/*"
                                      className="form-control"
                                      type={"file"}
                                      onChange={(e) => changeHandler(e)}
                                      name="badge_image"
                                    />
                                  </div>

                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="badge_description"
                                      className="form-label"
                                    >
                                      Module Objective
                                    </label>
                                    <textarea
                                      placeholder="Enter description"
                                      className="form-control"
                                      name="badge_description"
                                      rows="3"
                                    ></textarea>
                                  </div>

                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Coaching Points (CP){" "}
                                    </label>
                                    <input
                                      placeholder="Enter coaching points"
                                      required
                                      className="form-control"
                                      type={"number"}
                                      name="coaching_points"
                                    />
                                  </div>

                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="training_for-text-label"
                                      className="form-label"
                                    >
                                      Training for
                                    </label>
                                    <div className="LowIndexothers CreateSubject">
                                      <Select
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.value
                                        }
                                        isMulti={true}
                                        options={user_types}
                                        classNamePrefix="select2-selection position-releative"
                                        name="training_for[]"
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group mb-3 d-none">
                                    <label
                                      htmlFor="training_for-text-label"
                                      className="form-label"
                                    >
                                      Who are tagged as
                                    </label>
                                    <div className="position-relative">
                                      <div
                                        className="CommonSelectTwo ComMain TraningSubject TraningUpdate"
                                        style={{
                                          minHeight: "38px",
                                          height: "auto",
                                        }}
                                      >
                                        <Select
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) => option.id}
                                          isMulti={true}
                                          options={list_tags || []} // Simplified condition
                                          // onInputChange={handleInputChange}
                                          // inputValue={inputValue}
                                          value={
                                            list_tags && list_tags.length > 0
                                              ? list_tags.filter(
                                                  (obj) =>
                                                    subAction.tag_training &&
                                                    subAction.tag_training.includes(
                                                      obj.id
                                                    )
                                                )
                                              : []
                                          }
                                          onChange={handleChange}
                                          classNamePrefix="select2-selection position-relative"
                                          name="tags[]"
                                          noOptionsMessage={
                                            customNoOptionsMessage
                                          }
                                          isDisabled={
                                            !list_tags || list_tags.length === 0
                                          }
                                        />

                                        {noOptions && action == 1 && (
                                          <button
                                            className={`btn btn-sm btn-success ${renderTrainingTag(
                                              action
                                            )}`}
                                            onClick={handleCreateTag}
                                          >
                                            <i className="fas fa-plus"></i>{" "}
                                            Training Tag
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {action == 3 && (
                                <>
                                  {subAction.add_subject_id ? (
                                    <input
                                      type={"hidden"}
                                      name="master_id"
                                      value={subAction.add_subject_id}
                                    />
                                  ) : (
                                    <div className="form-group mb-3">
                                      <label
                                        htmlFor="example-text-input"
                                        className="form-label"
                                      >
                                        Module
                                      </label>
                                      <div className="CategoryListTarnings ActionTwoSub">
                                        <Select
                                          getOptionLabel={(option) =>
                                            option.title
                                          }
                                          getOptionValue={(option) =>
                                            option.cat_id
                                          }
                                          isMulti={false}
                                          onChange={(e) =>
                                            getTagLists("subject", e.cat_id)
                                          }
                                          options={templates_group}
                                          classNamePrefix="select2-selection"
                                          name="master_id"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="form-group mb-3 mt-5">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      placeholder="The name of this Lesson"
                                      required
                                      className="form-control"
                                      type={"text"}
                                      name="title"
                                    />

                                    <input
                                      type={"hidden"}
                                      name="type"
                                      value={3}
                                    />
                                  </div>

                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="training_for-text-label"
                                      className="form-label"
                                    >
                                      Training for
                                    </label>
                                    <div className="LowIndexothers ActionThree">
                                      <Select
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.value
                                        }
                                        isMulti={true}
                                        options={user_types}
                                        classNamePrefix="select2-selection position-releative"
                                        name="training_for[]"
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group mb-3 d-none">
                                    <label
                                      htmlFor="training_for-text-label"
                                      className="form-label"
                                    >
                                      Who are tagged as
                                    </label>
                                    <div className="position-relative">
                                      <div
                                        className="CommonSelectTwo ComMain TraningSubject TraningUpdate"
                                        style={{
                                          minHeight: "38px",
                                          height: "auto",
                                        }}
                                      >
                                        <Select
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) => option.id}
                                          isMulti={true}
                                          options={list_tags || []} // Simplified condition
                                          // onInputChange={handleInputChange}
                                          // inputValue={inputValue}
                                          value={
                                            list_tags && list_tags.length > 0
                                              ? list_tags.filter(
                                                  (obj) =>
                                                    subAction.tag_training &&
                                                    subAction.tag_training.includes(
                                                      obj.id
                                                    )
                                                )
                                              : []
                                          }
                                          onChange={handleChange}
                                          classNamePrefix="select2-selection position-relative"
                                          name="tags[]"
                                          noOptionsMessage={
                                            customNoOptionsMessage
                                          }
                                          isDisabled={
                                            !list_tags || list_tags.length === 0
                                          }
                                        />
                                        {noOptions &&
                                          (action == 1 || action == 5) && (
                                            <button
                                              className={`btn btn-sm btn-success ${renderTrainingTag(
                                                action
                                              )}`}
                                              onClick={handleCreateTag}
                                            >
                                              <i className="fas fa-plus"></i>{" "}
                                              Training Tag
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {action == 4 && (
                                <>
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Select a Lesson
                                    </label>
                                    <Select
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option.cat_id}
                                      isMulti={false}
                                      options={templates_group}
                                      classNamePrefix="select2-selection"
                                      name="master_id"
                                    />
                                  </div>
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="example-text-input"
                                      className="form-label"
                                    >
                                      Name 1
                                    </label>
                                    <input
                                      placeholder="The name of this Module"
                                      required
                                      className="form-control"
                                      type={"text"}
                                      name="title"
                                    />

                                    <input
                                      type={"hidden"}
                                      name="type"
                                      value={3}
                                    />
                                  </div>
                                </>
                              )}
                              {[1, 2, 3, 4].includes(Number(action)) && (
                                <div className="form-group mb-3">
                                  <label
                                    htmlFor="training_for-text-label"
                                    className="form-label"
                                  >
                                    Who are tagged as
                                  </label>
                                  <div className="position-relative">
                                    <div
                                      className="CommonSelectTwo ComMain TraningSubject TraningUpdate"
                                      style={{
                                        minHeight: "38px",
                                        height: "auto",
                                      }}
                                    >
                                      <Select
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isMulti={true}
                                        options={list_tags || []} // Simplified condition
                                        // onInputChange={handleInputChange}
                                        // inputValue={inputValue}
                                        value={
                                          list_tags && list_tags.length > 0
                                            ? list_tags.filter(
                                                (obj) =>
                                                  subAction.tag_training &&
                                                  subAction.tag_training.includes(
                                                    obj.id
                                                  )
                                              )
                                            : []
                                        }
                                        onChange={handleChange}
                                        classNamePrefix="select2-selection position-relative"
                                        name="tags[]"
                                        noOptionsMessage={
                                          customNoOptionsMessage
                                        }
                                        isDisabled={
                                          action != 1 &&
                                          (!list_tags || list_tags.length === 0)
                                        }
                                      />
                                      {noOptions &&
                                        (action == 1 || action == 5) && (
                                          <button
                                            className={`btn btn-sm btn-success ${renderTrainingTag(
                                              action
                                            )}`}
                                            onClick={handleCreateTag}
                                          >
                                            <i className="fas fa-plus"></i>{" "}
                                            Training Tag
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {action == 2 && userType == "WlAdmin" && (
                                <div className="form-group mb-3 d-none">
                                  <label
                                    htmlFor="training_for-text-label"
                                    className="form-label"
                                  >
                                    Training Certification
                                  </label>
                                  <div className="">
                                    <Select
                                      getOptionLabel={(option) => option.title}
                                      getOptionValue={(option) => option.id}
                                      isMulti={true}
                                      // value={certifications.filter(
                                      //   (obj) =>
                                      //     subAction.training_certifications &&
                                      //     subAction.training_certifications.includes(
                                      //       obj.id
                                      //     )
                                      // )}
                                      options={certifications}
                                      classNamePrefix="select2-selection position-releative"
                                      name="training_certifications[]"
                                    />
                                  </div>
                                </div>
                              )}

                              {action == 5 && (
                                <>
                                  <div className="UpdatePageUsr">
                                    {subAction.type == "Subject" && (
                                      <div className="ClientAwardBadges d-none">
                                        <div className="AwardImage">
                                          <div className="image-preview">
                                            {imagePreview ? (
                                              <img
                                                src={imagePreview}
                                                alt="Module Badge Preview"
                                                style={{
                                                  maxWidth: "200px",
                                                  maxHeight: "200px",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                style={{
                                                  maxWidth: "200px",
                                                  maxHeight: "200px",
                                                }}
                                                src={`${AttachementsUrl}/user_${subAction.user_id}/training/${subAction.badge_image}`}
                                                alt="Module Badge Preview"
                                              />
                                            )}
                                          </div>
                                          {/* <p>{subAction.badge_description}</p> */}
                                        </div>
                                      </div>
                                    )}

                                    <div className="form-group mb-3">
                                      <label
                                        htmlFor={`example-text-input -- ${subAction.type}`}
                                        className="form-label"
                                      >
                                        Name
                                      </label>
                                      <input
                                        defaultValue={subAction.title}
                                        placeholder={`The name of this ${
                                          typeMap[subAction.type] ||
                                          subAction.type
                                        }`}
                                        required
                                        className="form-control"
                                        type={"text"}
                                        name="title"
                                      />
                                      {subAction.type === "Category" && (
                                        <div className="form-group mb-3">
                                          <label
                                            htmlFor="course_description"
                                            className="form-label"
                                          >
                                            Course Objective
                                          </label>
                                          <textarea
                                            defaultValue={
                                              subAction.course_description
                                            }
                                            onChange={(e) => {
                                              setSubAction((prevState) => ({
                                                ...prevState,
                                                course_description:
                                                  e.target.value, // Use e.target.value to get the updated text
                                              }));
                                            }}
                                            placeholder="Enter Course description"
                                            className="form-control"
                                            name="course_description"
                                            rows="3"
                                          ></textarea>
                                        </div>
                                      )}

                                      {subAction.type == "Subject" && (
                                        <div className="form-group mt-3 ">
                                          <label
                                            htmlFor="example-text-input"
                                            className="form-label "
                                          >
                                            Module Image
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <input
                                              accept="image/*"
                                              className="form-control UploadImage me-2"
                                              type={"file"}
                                              onChange={(e) => changeHandler(e)}
                                              name="image"
                                            />
                                            {subAction.image && (
                                              <img
                                                height={40}
                                                width={40}
                                                src={`${AttachementsUrl}/user_${subAction.user_id}/training/${subAction.image}`}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      <input
                                        type={"hidden"}
                                        name="id"
                                        value={subAction.cat_id}
                                      />
                                      <input
                                        type={"hidden"}
                                        name="master_type"
                                        value={subAction.type}
                                      />

                                      <input
                                        type={"hidden"}
                                        name="type"
                                        value={1}
                                      />
                                    </div>

                                    {subAction.type == "Subject" && (
                                      <>
                                        <div className="form-group mb-3">
                                          <label
                                            htmlFor="example-text-input"
                                            className="form-label"
                                          >
                                            Badge Image
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <input
                                              accept="image/*"
                                              className="form-control UploadImage me-2"
                                              type={"file"}
                                              onChange={(e) => changeHandler(e)}
                                              name="badge_image"
                                            />
                                            {subAction.badge_image && (
                                              <img
                                                height={40}
                                                width={40}
                                                src={`${AttachementsUrl}/user_${subAction.user_id}/training/${subAction.badge_image}`}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="form-group mb-3">
                                          <label
                                            htmlFor="badge_description"
                                            className="form-label"
                                          >
                                            Module Objective
                                          </label>
                                          <textarea
                                            defaultValue={
                                              subAction.badge_description
                                            }
                                            onChange={(e) => {
                                              setSubAction((prevState) => ({
                                                ...prevState,
                                                badge_description:
                                                  e.target.value, // Use e.target.value to get the updated text
                                              }));
                                            }}
                                            placeholder="Enter description"
                                            className="form-control"
                                            name="badge_description"
                                            rows="3"
                                          ></textarea>
                                        </div>
                                        <div className="form-group mb-3">
                                          <label
                                            htmlFor="example-text-input"
                                            className="form-label"
                                          >
                                            Coaching Points (CP){" "}
                                          </label>
                                          <input
                                            placeholder="Enter coaching points"
                                            required
                                            className="form-control"
                                            type={"number"}
                                            name="coaching_points"
                                            defaultValue={
                                              subAction.coaching_points
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div className="form-group mb-3">
                                      <label
                                        htmlFor="training_for-text-label"
                                        className="form-label"
                                      >
                                        Training for
                                      </label>
                                      <div className="LowIndexothers UpdateStep">
                                        <Select
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          getOptionValue={(option) =>
                                            option.value
                                          }
                                          isMulti={true}
                                          value={user_types.filter(
                                            (obj) =>
                                              subAction.training_for &&
                                              subAction.training_for.includes(
                                                obj.value
                                              )
                                          )}
                                          options={user_types}
                                          onChange={handleTrainingForChange}
                                          classNamePrefix="select2-selection position-releative"
                                          name="training_for[]"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group mb-3">
                                      <label
                                        htmlFor="training_for-text-label"
                                        className="form-label"
                                      >
                                        Who are tagged as
                                      </label>
                                      <div className="position-relative">
                                        <div
                                          className="CommonSelectTwo ComMain TraningSubject TraningUpdate"
                                          style={{
                                            minHeight: "38px",
                                            height: "auto",
                                          }}
                                        >
                                          <Select
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            getOptionValue={(option) =>
                                              option.id
                                            }
                                            isMulti={true}
                                            value={
                                              list_tags && list_tags.length > 0
                                                ? list_tags.filter(
                                                    (obj) =>
                                                      subAction.training_for &&
                                                      subAction.tag_training.includes(
                                                        obj.id
                                                      )
                                                  )
                                                : []
                                            }
                                            options={
                                              list_tags && list_tags.length > 0
                                                ? list_tags
                                                : []
                                            }
                                            // onInputChange={handleInputChange}
                                            // inputValue={inputValue}
                                            onChange={handleChange}
                                            classNamePrefix="select2-selection position-relative"
                                            name="tags[]"
                                            noOptionsMessage={
                                              customNoOptionsMessage
                                            }
                                            isDisabled={
                                              subAction.type !== "Category" &&
                                              (!list_tags ||
                                                list_tags.length === 0)
                                            }
                                          />
                                          {noOptions &&
                                            subAction.type === "Category" && (
                                              <button
                                                className={`btn btn-sm btn-success ${renderTrainingTag(
                                                  action
                                                )}`}
                                                onClick={handleCreateTag}
                                              >
                                                <i className="fas fa-plus"></i>{" "}
                                                Training Tag
                                              </button>
                                            )}
                                        </div>
                                        {new_training_tags &&
                                          new_training_tags.length > 0 && (
                                            <div className="col-md-12 mt-3">
                                              <label
                                                htmlFor="training_for-text-label"
                                                className="form-label"
                                              >
                                                Apply selected tags at the
                                                sub-level
                                              </label>
                                              {new_training_tags.map((row) => (
                                                <div
                                                  className="col-md-3 mb-3 mt-3"
                                                  key={row.id}
                                                >
                                                  <div className="form-check">
                                                    <input
                                                      name={`sub_level_tags[]`}
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      checked={
                                                        checkedItems[row.id] ||
                                                        false
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxChange(
                                                          e,
                                                          row.id
                                                        )
                                                      }
                                                      value={row.id}
                                                    />
                                                    <label
                                                      htmlFor={`sub-level-tags-${row.id}`}
                                                      className="form-check-label"
                                                    >
                                                      {row.name}
                                                    </label>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                      </div>
                                    </div>

                                    {subAction.type == "Subject" &&
                                      userType == "WlAdmin" && (
                                        <div className="form-group mb-3">
                                          <label className="form-label">
                                            Training Certification
                                          </label>
                                          <div className="">
                                            <Select
                                              getOptionLabel={(option) =>
                                                option.title
                                              }
                                              getOptionValue={(option) =>
                                                option.id
                                              }
                                              isMulti={true}
                                              value={certifications.filter(
                                                (obj) =>
                                                  subAction.training_certifications &&
                                                  subAction.training_certifications.includes(
                                                    obj.id
                                                  )
                                              )}
                                              options={certifications}
                                              onChange={
                                                handleCertificationChange
                                              }
                                              classNamePrefix="select2-selection position-releative"
                                              name="training_certifications[]"
                                            />
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </>
                              )}

                              {action == 5 ? (
                                <div className="row">
                                  <div className="col-md-12 text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-100"
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-12 text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-100"
                                    >
                                      Create
                                    </button>
                                  </div>
                                </div>
                              )}
                            </form>
                          )}
                          <div className="cancel-button TraningBook text-center mt-2">
                            <button
                              onClick={() => setAction(0)}
                              type="button"
                              className="bwaves-effect waves-light btn btn-outline-dark w-100 "
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            searchResult && (
              <div className="way-content">
                <div className="default-layout">
                  <div className="dash-welcome-left">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="TraningRightContent">
                          <SearchBar
                            count_record={countRecord}
                            search_result={searchResult}
                            search_word={searchWordFinal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <Modal
          isOpen={subActionModal}
          fade={true}
          size="md"
          className="subActionTraining"
          toggle={() => closeSubActionModal()}
          centered={true}
        >
          <div className="card">
            <div className="">
              <div className="modal-header px-2 py-1">
                <div className="modal-title">
                  {subAction.document_id ? (
                    <h5 className="mb-0">Add training to {subAction.title}.</h5>
                  ) : (
                    <h5 className="mb-0">
                      Where do you want to move the {subAction.title} to?
                    </h5>
                  )}
                </div>
                <button
                  onClick={() => closeSubActionModal()}
                  className="btn btn-link pull-right BtnCenterClose"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <ModalBody>
                <form
                  method="post"
                  onSubmit={handlMoveAction}
                  encType="multipart/form-data"
                  className="form-horizontal"
                  id="selection-myform"
                >
                  {subAction.type == "Subject" && (
                    <div className="form-group mt-3">
                      <label
                        htmlFor="example-text-input"
                        className="form-label"
                      >
                        Course
                      </label>
                      <Select
                        isClearable
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.cat_id}
                        isMulti={false}
                        menuIsOpen={true}
                        placeholder={"Select Course which you want to move"}
                        options={
                          uInfo().type === "Coach"
                            ? waybookCoachList
                            : waybookList
                        }
                        classNamePrefix="select2-selection"
                        name="master_id"
                      />
                    </div>
                  )}

                  {subAction.type == "Document" && (
                    <div className="form-group mt-3">
                      <label
                        htmlFor="example-text-input"
                        className="form-label"
                      >
                        Module
                      </label>
                      <Select
                        isClearable
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.cat_id}
                        isMulti={false}
                        menuIsOpen={true}
                        placeholder={"Select module which you want to move"}
                        options={templates_group}
                        classNamePrefix="select2-selection"
                        name="master_id"
                      />
                    </div>
                  )}

                  {subAction.type == "Step" && (
                    <>
                      <div className="form-group mb-3">
                        <label
                          htmlFor="training_for-text-label"
                          className="form-label"
                        >
                          Select Type
                        </label>
                        <div className="LowIndexothers AddStepPopup MoreZindex Zfive">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            value={defualtType}
                            isMulti={false}
                            options={[
                              { label: "Training", value: "content" },
                              { label: "Quiz", value: "quiz" },
                            ]}
                            classNamePrefix="select2-selection position-releative"
                            name="content_or_quiz"
                            onChange={(e) =>
                              handleQuizstepsChanages(e, subAction.document_id)
                            }
                          />
                        </div>
                      </div>
                      {selectedOption.value === "quiz" && (
                        <div className="form-group mb-3">
                          <label
                            htmlFor="training_for-text-label"
                            className="form-label"
                          >
                            Enter Passing Percentage
                          </label>
                          <div className="LowIndexothers AddStepPopup MoreZindex">
                            <Select
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              defaultValue={{ label: "100%", value: 100 }}
                              isMulti={false}
                              options={numbers}
                              classNamePrefix="select2-selection position-releative"
                              name="passingpercentage"
                            />
                          </div>
                        </div>
                      )}

                      <div className="form-group mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="form-label"
                        >
                          Add title to training
                        </label>
                        <input
                          placeholder="Add title"
                          required
                          className="form-control"
                          type={"text"}
                          name="title"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label
                          htmlFor="training_for-text-label"
                          className="form-label"
                        >
                          Training for
                        </label>
                        <div className="LowIndexothers AddStepPopup">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            isMulti={true}
                            options={user_types}
                            classNamePrefix="select2-selection position-releative"
                            name="training_for[]"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <label
                          htmlFor="training_for-text-label"
                          className="form-label"
                        >
                          Who are tagged as
                        </label>
                        <div className="position-relative">
                          <div
                            className="CommonSelectTwo ComMain TraningSubject"
                            style={{ minHeight: "38px", height: "auto" }}
                          >
                            <Select
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isMulti={true}
                              options={list_tags || []}
                              value={
                                list_tags && list_tags.length > 0
                                  ? list_tags.filter(
                                      (obj) =>
                                        stepAction.tag_training &&
                                        stepAction.tag_training.includes(obj.id)
                                    )
                                  : []
                              }
                              onChange={handleStepChange}
                              classNamePrefix="select2-selection position-relative"
                              name="tags[]"
                              noOptionsMessage={customNoOptionsMessage}
                            />
                          </div>
                          {noOptions && (action == 1 || action == 5) && (
                            <button
                              className={`btn btn-sm btn-success ${
                                defualtType.value === "content"
                                  ? "TrainingTgsStep"
                                  : "TrainingTgsQuiz"
                              }`}
                              onClick={handleCreateTag}
                            >
                              <i className="fas fa-plus"></i> Training Tag
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {subAction.document_id ? (
                    <>
                      <input
                        type={"hidden"}
                        name="document_id"
                        value={subAction.document_id}
                      />

                      <div className="row mt-3">
                        <div className="col-md-8">
                          <button
                            type="submit"
                            className="btn btn-primary pull-right w-50"
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <input
                        type={"hidden"}
                        name="cat_id"
                        value={subAction.cat_id}
                      />

                      <div className="row mt-3">
                        <div className="col-md-8">
                          <button
                            type="submit"
                            className="btn btn-primary pull-right w-50"
                          >
                            Move
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </ModalBody>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={stepActionModal}
          fade={true}
          size="md"
          className="subActionTraining"
          toggle={() => closeSubActionModal()}
          centered={true}
        >
          <div className="card">
            <div className="">
              <div className="modal-header p-2">
                <div className="modal-title">
                  {stepAction.action == "Move" ? (
                    <h5>
                      Where do you want to move the {stepAction.title} to?
                    </h5>
                  ) : (
                    "Update Training Title"
                  )}
                </div>
                <button
                  onClick={() => closeSubActionModal()}
                  className="py-0 btn btn-link pull-right BtnCenterClose"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <ModalBody>
                <form
                  method="post"
                  onSubmit={handlStepMoveAction}
                  encType="multipart/form-data"
                  className="form-horizontal"
                  id="selection-myform"
                >
                  {stepAction.action == "Move" && (
                    <div className="form-group mt-2">
                      <label
                        htmlFor="example-text-input"
                        className="form-label"
                      >
                        Lesson
                      </label>
                      <Select
                        isClearable
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.cat_id}
                        isMulti={false}
                        menuIsOpen={true}
                        placeholder={"Select Lesson which you want to move"}
                        options={templates_documents_group}
                        classNamePrefix="select2-selection"
                        name="document_id"
                      />
                    </div>
                  )}

                  {stepAction.action == "Update" && (
                    <>
                      <div className="form-group mb-2">
                        <label
                          htmlFor="example-text-input"
                          className="form-label"
                        >
                          Name
                        </label>
                        <input
                          defaultValue={stepAction.title}
                          placeholder="The name of this training"
                          required
                          className="form-control"
                          type={"text"}
                          name="title"
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label
                          htmlFor="training_for-text-label"
                          className="form-label"
                        >
                          Training for
                        </label>
                        <div className="LowIndexothers AddStepPopup">
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            isMulti={true}
                            value={user_types.filter(
                              (obj) =>
                                stepAction.training_for &&
                                stepAction.training_for.includes(obj.value)
                            )}
                            options={user_types}
                            onChange={(e) => {
                              var res = e.map((s) => s.value);
                              setStepAction((prevState) => ({
                                ...prevState,
                                training_for: res.toString(),
                              }));
                            }}
                            classNamePrefix="select2-selection position-releative"
                            name="training_for[]"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-2">
                        <label
                          htmlFor="training_for-text-label"
                          className="form-label"
                        >
                          Who are tagged as
                        </label>
                        <div className="position-relative">
                          <div
                            className="CommonSelectTwo ComMain TraningSubject"
                            style={{ minHeight: "38px", height: "auto" }}
                          >
                            {/* Step edit section */}
                            <Select
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isMulti={true}
                              value={
                                list_tags && list_tags.length > 0
                                  ? list_tags.filter(
                                      (obj) =>
                                        stepAction.tag_training &&
                                        stepAction.tag_training.includes(obj.id)
                                    )
                                  : []
                              }
                              options={
                                list_tags && list_tags.length > 0
                                  ? list_tags
                                  : []
                              }
                              onChange={handleStepChange}
                              classNamePrefix="select2-selection position-relative"
                              name="tags[]"
                              noOptionsMessage={customNoOptionsMessage}
                            />
                            {noOptions && (action == 1 || action == 5) && (
                              <button
                                className={`btn btn-sm btn-success TrainingTgsStepUpdate`}
                                onClick={handleCreateTag}
                              >
                                <i className="fas fa-plus"></i> Training Tag
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <input
                    type={"hidden"}
                    name="action"
                    value={stepAction.action}
                  />

                  <input
                    type={"hidden"}
                    name="step_id"
                    value={stepAction.step_id}
                  />

                  <div className="row mt-3">
                    <div className="col-md-8">
                      <button
                        type="submit"
                        className="btn btn-primary pull-right w-50"
                      >
                        {stepAction.type == "Move" ? "Move" : "Update"}
                      </button>
                    </div>
                  </div>
                </form>
              </ModalBody>
            </div>
          </div>
        </Modal>
      </div>
    </WayBookContext.Provider>
  );
}

export default App;
