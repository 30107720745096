import React from "react";
import { Redirect, useLocation } from "react-router-dom";

export default function EmailRedirection() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const purpose = params.get("purpose");

  if (purpose === "profile_settings") {
    return <Redirect to="/settings/profile?tab=4" />;
  }

  if (purpose) {
    return <Redirect to={`/trainings/${purpose}`} />;
  }

  return (
    <Redirect to={{ pathname: "/dashboard", state: { fromRedirect: true } }} />
  );

  // if (purpose && purpose === "profile_settings") {
  //   return <Redirect to="/settings/profile?tab=4" />;
  // } else if (purpose && purpose != "profile_settings") {
  //   return <Redirect to={`/trainings/${purpose}`} />;
  // } else if (!purpose) {
  //   return (
  //     <Redirect
  //       to={{ pathname: "/dashboard", state: { fromRedirect: true } }}
  //     />
  //   );
  // }
  // return (
  //   <Redirect to={{ pathname: "/dashboard", state: { fromRedirect: true } }} />
  // );
}
