import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  TabContent,
  TabPane,
} from "reactstrap";
import "../../assets/css/courses_list.css";
import { ApiUrl } from "../../config";
import { uToken, uInfo, bcrypt, dcrypt } from "../../useToken";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";

function generateShortId() {
  return Number(String(Date.now()).slice(-5) + Math.floor(Math.random() * 1000));
}
export default function AskAI(props) {
  const id = props.match.params.id ? dcrypt(props.match.params.id) : null;
  const [thread_id] = useState(generateShortId());
  const [chatMode, setChatMode] = useState('help');
  const [message, setMessage] = useState('');
  const [lastId, setLastId] = useState(0);
  const [loadData, setLoadData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);
  const history = useHistory();
  useEffect(() => {
    fetchData();
    id && fetchThreadData(id);
  }, []);
  const fetchData = () => {
    fetch(`${ApiUrl}` + "get/waybook/askai/questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records);
        } 
      })
      .catch((error) => {
      });
  };
  const fetchThreadData = (thread_id) => {
    fetch(`${ApiUrl}` + "get/waybook/askai/threadData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: thread_id}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          const recordsData = response.data.records;
          setData(recordsData);
          if (recordsData.length > 0) {
            setLastId(recordsData[0].id);
          }
        } 
      })
      .catch((error) => {
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoadData(false);
    const formatTime = (date) => {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12; // Convert to 12-hour format
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const strMinutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes
      return `${hours}:${strMinutes} ${ampm}`;
    };
  
    if(message ===''){
        swal({
          title: 'Please add your message',
          icon: "warning",
        });
        return false;
    }
    const currentTime = formatTime(new Date());
    setLastId(lastId + 1);
    const newMessage = { id: lastId + 1, message: message, creatd_at: currentTime, type: 'user' };
    setData((prevData) => [...prevData, newMessage]);
    setLoadData(true);
    setMessage('');
    fetch(`${ApiUrl}` + "send/askai/message", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        message: message,
        type: chatMode,
        thread_id: id ? id : thread_id,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setLoadData(false);
          setLastId(response.data.record.id);
          const resMessage = { id: response.data.record.id, message: response.data.record.message, creatd_at: response.data.record.creatd_at, type: 'bot' };
          setData((prevData) => [...prevData, resMessage]);
       
          setLoadData(true);
          //fetchThreadData(response.data.thread_id);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <>
      
          <TabContent activeTab={true} className="d-block">
            <TabPane tabId="1" className="d-block">
              <main className="chat-box">
                <div className="card">
                  <div className="row">
                      <div className="d-none d-md-block border-bottom vertical-menu TraningDashboard">
                        <div className="WayBookBar AskAiTab">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <ul className="TabsSideBar">
                              <li className="nav-item"><Link className="nav-link" onClick={() => {history.push(`/trainings`);}}>Home</Link></li>
                              <li className="nav-item"><Link className="active nav-link"  onClick={() => {history.push(`/ask-ai`);}}>Ask Ai</Link></li>
                              <li className="nav-item"><Link className="nav-link" onClick={() => {history.push(`/trainings/path`);}}>Paths</Link></li>
                            </ul>
                          </div>
                        </div>

                      <div className="chat-user-list">
                        {records && records.map((row, i) => {
                        return (
                        <div
                          className="parent"
                           key={i}
                        >
                          <div id={row.id}>
                            <Link to={`/ask-ai/${bcrypt(row.thread_id)}`} key={`${row.id}-${i}`} className="list-group-item list-group-item-action border-0">
                            {row.message}</Link>
                          </div>
                        </div>
                        );
                      })} 
                      </div>
                      <div className="text-center">
                      <button 
                      class="bwaves-effect waves-light btn btn-secondary btn-sm" 
                      onClick={() => {history.push(`/ask-ai`);}}><i className="mdi mdi-plus"></i> New Question</button>
                      </div>
                      <hr className="d-block d-lg-none mt-1 mb-0" />
                    </div>
                    </div>


                    {/* {this.state.id ? ( */}
                    <div className="way-content DashboardTraning">

                      
                      <Card 
                      // className="askai_card"
                       className={`${loadData && data.length > 0 ? "askai_chat_card" : "askai_card" }`}
                      >
                        <CardBody className="p-0">
                          <div 
                          // className="askai_content"
                          className={`${loadData && data.length > 0 ? "askai_chat_content" : "askai_content" }`}
                          >
                            

                            {loadData && data.length > 0 ? (
                              <div className="chat-messages p-4 custom-scroll">
                                {data.slice().sort((a, b) => b.id - a.id).map((row, i) => (
                                  <div key={i} className={`chat-message-${row.type === 'user' ? 'right' : 'left'} pb-3`}>
                                    <div className="mr-3 text-center">
                                      <div
                                        className="sb-avatar sb-avatar--text"
                                        style={{
                                          display: 'inline-block',
                                          width: '30px',
                                          height: '30px',
                                          borderRadius: '50%',
                                          fontFamily: 'Helvetica, Arial, sans-serif',
                                          backgroundColor: row.type === 'user' ? 'rgb(126, 55, 148)' : 'rgb(215, 61, 50)',
                                        }}
                                      >
                                        <div
                                          className="sb-avatar__text"
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'table',
                                            textAlign: 'center',
                                            color: '#fff',
                                            fontSize: '10px',
                                          }}
                                        >
                                          <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                            {row.type === 'user' ? 'You' : 'SA'}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="text-muted small text-nowrap mt-2">
                                        {row.created_at}
                                      </div>
                                    </div>
                                    <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                        <p
                                            className="text-break"
                                            dangerouslySetInnerHTML={{ __html: row.message }}
                                        ></p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <CardTitle tag="h4" className="mb-2 text-center">What would you like to know?</CardTitle>
                            )}



                            <form
                             onSubmit={handleSearch}
                            >
                              <div 
                                className="courses_list"
                                style={{ border: "none" }}
                              >
                                <div className="SearchTraningBar position-relative">
                                  <div className="InputSearch">
                                    <input
                                      type="text"
                                      className="form-control px-2 py-2"
                                      value={message}
                                      onChange={(e) => setMessage(e.target.value)}
                                      placeholder="Get answers from your PROFITCOACH by asking any question..."
                                      style={{                                        
                                        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                                      }}
                                    />
                                    <Button
                                      color="primary"
                                      className="position-absolute px-3"
                                      style={{
                                        right: "4px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        zIndex: 2,
                                      }}
                                      disabled={isLoading}
                                      //onClick={() => sendMessage()}
                                      type="submit"
                                    >
                                      {isLoading ? (
                                        <span>
                                          <i className="mdi mdi-loading mdi-spin me-1"></i>
                                          Sending...
                                        </span>
                                      ) : (
                                        <span>
                                          Ask
                                        </span>
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <div className="mt-1 d-flex justify-content-between align-items-center text-muted small">
                              <div>
                              <p><i className="mdi mdi-information-outline me-1"></i>
                                AI tools can make mistakes. Consider checking
                                important information.</p>
                              </div>
                              <div className="d-flex align-items-center text-muted small gap-2 d-none">
                                <span  className={chatMode === 'help' ? 'text-primary' : 'text-muted'}>PROFITCOACH™ Help Center</span>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={chatMode ==='document' ? true:false}
                                    onChange={(e) => setChatMode(e.target.checked ? 'document':"help")}
                                  />
                                </div>
                                <span className={chatMode === 'document' ? 'text-primary' : 'text-muted'}>Your Documents</span>
                              </div>
                            </div>

                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    {/* ) : (
                    <div className="col-12 col-lg-7 col-xl-9"></div>
                  )} */}
                  </div>
                </div>
              </main>
            </TabPane>
       
          </TabContent>

        
    </>
  );
}
