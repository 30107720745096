import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, Media, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import avatar1 from "../../assets/images/profile-icons/usergrren.png";
import { ApiUrl, WebUrl } from "../../config";
import { dcrypt, uToken } from "../../useToken";
import { toast, Flip } from "react-toastify";
import swal from "sweetalert";

import EmailSideBar from "./email-sidebar";
import EmailToolbar from "./email-toolbar";

class SentEmailRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      loading: true,
      error: null,
      replyContent: '',
      showDetails: false,
      isReplying: false,
      labels: [],
      emails: [],
      selectedEmails: new Set(),
    };
  }

  componentDidMount() {
    this.getEmailRead();
  }
  toggleDetails = () => {
    this.setState(prevState => ({ showDetails: !prevState.showDetails }));
  };
  getEmailRead = () => {
    const { report_id } = this.props.match.params;
    const decryptedReportId = dcrypt(report_id);
    fetch(`${ApiUrl}aio/report/read/email?report_id=${decryptedReportId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response Data: ", data);
        if (data.status === true) {
          const labels = data.data.label_name
            ? [{
              name: data.data.label_name,
              color_code: data.data.color_code,
            }]
            : [];

          this.setState({
            email: {
              ...data.data,
              labels,
            },
            loading: false,
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error'
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Error fetching email", {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls error'
        });
      });
  };
  toggleLabelDropdown = () => {
    this.setState((prevState) => ({
      labelDropdownOpen: !prevState.labelDropdownOpen,
    }));
  };

  handleDelete = () => {
    const { report_id } = this.props.match.params;
    const decryptedReportId = dcrypt(report_id);

    swal({
      title: "Are you sure?",
      text: "You want to delete this email.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}aio/delete/email`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${uToken()}`,
          },
          body: JSON.stringify({ report_id: decryptedReportId }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok.");
            }
            return response.json();
          })
          .then((data) => {
            if (data.status === true) {
              swal("Success", "Email deleted successfully!", "success")
                .then(() => {
                  this.props.history.push('/email/sent');
                });
            } else {
              swal("Error", data.message, "error");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            swal("Error", "Error deleting email", "error");
          });
      }
    });
  };

  handleLabelSelect = (labelId) => {
    const { report_id } = this.props.match.params;
    const reportIds = dcrypt(report_id);

    fetch(`${ApiUrl}assign/gmail/lebel`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ label_id: labelId, report_ids: reportIds }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          const successMessage = labelId ? "Label applied successfully" : "Label removed successfully";
          toast.success(successMessage, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
          });
          this.getEmailRead();
        } else {
          this.showError(response.message);
        }
      })
      .catch(() => this.showError("Error applying or removing label"));
  };

  handleReplyChange = (e) => {
    this.setState({ replyContent: e.target.value });
  };

  render() {
    const { showDetails, labels, labelDropdownOpen, email, loading, error, replyContent, isReplying } = this.state;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!email) return <div>No email found</div>;
    const styles = {
      dropdownToggle: {
        fontSize: "17px",
        padding: "5px 10px",
      },
      dropdownMenu: {
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        border: "1px solid #e0e0e0",
        padding: "10px",
        minWidth: "200px",
        fontSize: "14px",
        left: "-170px !important",
      },
      dropdownItemHeader: {
        fontWeight: "500",
        fontSize: "13px",
        paddingBottom: "6px",
        paddingTop: "4px",
        paddingLeft: "10px",
        backgroundColor: "#fafafa",
        color: "#757575",
      },
      dropdownItem: {
        padding: "6px 12px",
        borderRadius: "4px",
        transition: "background-color 0.2s ease-in-out",
      },
      noRecords: {
        textAlign: "center",
        padding: "20px",
        fontSize: "18px",
        color: "#888",
        borderTop: "1px solid #ddd",
        backgroundColor: "#f9f9f9",
      },
      iconStyle: {
        fontSize: "24px",
        color: "#888",
        display: "block",
        marginBottom: "10px",
      },
      labelBadge: {
        backgroundColor: "#f0f0f0",
        color: "#000",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: "5px",
        fontSize: "12px",
      },
    };
    return (
      <React.Fragment>
        <MetaTags>
          <title>Sent Email Read</title>
        </MetaTags>
        <Row>
          <Col xs="12">
            <EmailSideBar onLabelsFetched={(labels) => this.setState({ labels })} />
            <div className="email-rightbar mb-3">
              <Card>
                <CardBody className="pt-0">
                  <div className="d-flex align-items-center EmailMedia">
                    <Media className="align-items-center" style={{ position: 'relative', display: 'flex' }}>
                      <span style={{ marginTop: '-8px' }} className="mdi-48px mdi mdi-account-circle d-flex me-2 rounded-circle"></span>
                      <div style={{ flex: 1 }}>
                        <h4 style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          margin: 0,
                          color: '#202124',
                        }}>
                          {email.name || 'Unknown Sender'}
                        </h4>
                        <div style={{ position: 'relative' }}>
                          <p style={{
                            fontSize: '13px',
                            color: '#5f6368',
                            margin: 0,
                            cursor: 'pointer',
                          }} onClick={this.toggleDetails}>
                            To: {email.tos ? `${email.tos.split('@')[0]}` : 'No email address'}
                            <span style={{ fontSize: '12px', marginLeft: '3px' }}>▼</span>
                          </p>
                        </div>

                      </div>

                      {showDetails && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: '0',
                            backgroundColor: '#ffffff',
                            border: '1px solid #d0d0d0',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            padding: '20px',
                            zIndex: 1000,
                            fontSize: '14px',
                            color: '#333',
                            width: '320px',
                            marginTop: '8px',
                            transition: 'box-shadow 0.3s ease',
                          }}
                          onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 4px 16px rgba(0, 0, 0, 0.2)')}
                          onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 2px 12px rgba(0, 0, 0, 0.1)')}
                        >
            
                          <p style={{ margin: '6px 0', color: '#333' }}>
                            <span style={{ fontWeight: 'bold', color: '#555' }}>From:</span> {email.froms}
                          </p>
                          <p style={{ margin: '6px 0', color: '#333' }}>
                            <span style={{ fontWeight: 'bold', color: '#555' }}>To:</span> {email.tos}
                          </p>
                          {email.cc_emails && (
                            <p style={{ margin: '6px 0', color: '#333' }}>
                              <span style={{ fontWeight: 'bold', color: '#555' }}>CC:</span>
                              {email.cc_emails.split(',').map((ccEmail, index, array) => (
                                <span key={index}>
                                  {ccEmail.trim()}{index < array.length - 1 ? ', ' : ''}
                                  {index % 3 === 2 && <br />}
                                </span>
                              ))}
                            </p>
                          )}
                          {email.bcc_emails && (
                            <p style={{ margin: '6px 0', color: '#333' }}>
                              <span style={{ fontWeight: 'bold', color: '#555' }}>BCC:</span>
                              {email.bcc_emails.split(',').map((bccEmail, index, array) => (
                                <span key={index}>
                                  {bccEmail.trim()}{index < array.length - 1 ? ', ' : ''}
                                  {index % 3 === 2 && <br />}
                                </span>
                              ))}
                            </p>
                          )}
                          <p style={{ margin: '6px 0', color: '#333' }}>
                            <span style={{ fontWeight: 'bold', color: '#555' }}>Date:</span> {email.created_at || "Date not available"}
                          </p>
                          <p style={{ margin: '6px 0', color: '#333' }}>
                            <span style={{ fontWeight: 'bold', color: '#555' }}>Subject:</span> {email.subject || 'No Subject'}
                          </p>
                          <p style={{ margin: '6px 0', color: '#333' }}>
                            <span style={{ fontWeight: 'bold', color: '#555' }}>Mailed by:</span> {email.froms.split('@')[1]}
                          </p>
                        </div>
                      )}

                    </Media>

                    <div className="d-flex align-items-center ms-auto">
                      <p style={{
                        fontSize: '12px',
                        color: '#5f6368',
                        margin: 0,
                        marginRight: '10px',
                      }}>
                        ( Date: {email.created_at || "Date not available"} )
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center py-2">
                    <div className="d-flex align-items-center">
                      <h4 className="mt-0 font-size-18 text-dark mb-0">
                        {email.subject || "(no subject)"}
                      </h4>

                      {email.labels && email.labels.length > 0 ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            fontSize: '14px',
                            color: '#5f6368',
                          }}
                        >
                          {email.labels.map((label, index) => (
                            <span
                              key={index}
                              style={{
                                backgroundColor: label.color_code,
                                color: '#fff',
                                padding: '5px 10px',
                                borderRadius: '12px',
                                margin: '0 5px',
                                fontSize: '12px',
                                display: 'inline-block',
                              }}
                            >
                              {label.name}
                            </span>
                          )).reduce((prev, curr) => [prev, ', ', curr])}
                        </span>
                      ) : null}
                    </div>

                    <div className="d-flex align-items-center ml-auto">
                      <Dropdown isOpen={labelDropdownOpen} toggle={this.toggleLabelDropdown} style={{ marginLeft: '10px' }}>
                        <DropdownToggle
                          style={styles.dropdownToggle}
                          tag="span"
                          className="mdi mdi-label cursor-pointer"
                        />
                        <DropdownMenu end style={styles.dropdownMenu}>
                          <DropdownItem header style={styles.dropdownItemHeader}>Label as</DropdownItem>
                          <DropdownItem
                            key="unassign"
                            onClick={() => this.handleLabelSelect(0)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '10px 15px',
                              cursor: 'pointer',
                            }}
                          >
                            <span
                              className="mdi mdi-label-off"
                              style={{ marginRight: '8px', fontSize: '16px' }}
                            ></span>
                            Unassign Label
                          </DropdownItem>
                          {labels.length > 0 ? (
                            labels.map((label) => (
                              <DropdownItem
                                key={label.id}
                                onClick={() => this.handleLabelSelect(label.id)}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '10px 15px',
                                  cursor: 'pointer',
                                }}
                              >
                                <span
                                  className="mdi mdi-label"
                                  style={{
                                    marginRight: '8px',
                                    color: label.color_code,
                                    fontSize: '16px',
                                  }}
                                ></span>
                                {label.name}
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem>No labels found</DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        type="button"
                        onClick={this.handleDelete}
                        style={{
                          background: 'transparent',
                          border: 'none',
                          padding: '5px 10px',
                          cursor: 'pointer',
                          color: '#5b5757',
                          fontSize: '18px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className="mdi mdi-delete" style={{ fontSize: '18px' }}></span>
                      </Button>


                    </div>
                  </div>
                  {/* <EmailToolbar hidePagination={true} hideSearch={true} hideDeleteButton={true} /> */}

                  <div className="EmailContent" style={{ background: "#E7E7E7" }}>
                    <div dangerouslySetInnerHTML={{ __html: email.body }}></div>
                    {email.attachments && email.attachments.length > 0 && (
                      <div className="mt-3">
                        <h5 style={{ fontWeight: 'bold' }}>Attachments:</h5>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}>
                          {email.attachments.map((attachment, index) => {
                            const fileExtension = attachment.path.split('.').pop().toLowerCase();
                            const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
                            const isWord = fileExtension === 'doc' || fileExtension === 'docx';
                            const isPdf = fileExtension === 'pdf';
                            const isText = fileExtension === 'txt';
                            const isCsvOrExcel = ['csv', 'xls', 'xlsx'].includes(fileExtension);

                            const attachmentUrl = attachment.path.startsWith('http')
                              ? attachment.path
                              : `${WebUrl}/storage/app/public/attachments/${attachment.path.split('/').pop()}`;

                            return (
                              <div
                                key={index}
                                style={{
                                  width: '150px',
                                  height: '130px',
                                  border: '1px solid #ddd',
                                  borderRadius: '5px',
                                  overflow: 'hidden',
                                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                  position: 'relative',
                                  textAlign: 'center',
                                  backgroundColor: '#f9f9f9',
                                }}
                                className="attachment-item"
                              >
                                <a
                                  href={attachmentUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  <div style={{ height: '90px', position: 'relative', padding: '10px' }}>
                                    {isImage ? (
                                      <img
                                        src={attachmentUrl}
                                        alt={attachment.original_name}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          height: '100%',
                                          backgroundColor: '#f1f1f1',
                                        }}
                                      >
                                        {isPdf && (
                                          <span
                                            className="mdi mdi-file-pdf"
                                            style={{
                                              fontSize: '48px',
                                              color: '#D50032',
                                            }}
                                          ></span>
                                        )}
                                        {isWord && (
                                          <span
                                            className="mdi mdi-file-word"
                                            style={{
                                              fontSize: '48px',
                                              color: '#3F51B5',
                                            }}
                                          ></span>
                                        )}
                                        {isText && (
                                          <span
                                            className="mdi mdi-file-document"
                                            style={{
                                              fontSize: '48px',
                                              color: '#4B8DF8',
                                            }}
                                          ></span>
                                        )}
                                        {isCsvOrExcel && (
                                          <span
                                            className="mdi mdi-file-excel"
                                            style={{
                                              fontSize: '48px',
                                              color: '#4CAF50',
                                            }}
                                          ></span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      background: '#f9f9f9',
                                      borderTop: '1px solid #ddd',
                                      padding: '5px',
                                      position: 'absolute',
                                      bottom: 0,
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: '12px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#333',
                                      }}
                                    >
                                      {attachment.original_name}
                                    </div>
                                    <div
                                      style={{
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor: '#FF5722',
                                        clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
                                      }}
                                    ></div>
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SentEmailRead;
