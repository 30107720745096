import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import {
    dcrypt,
    bcrypt,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select"
import moment from 'moment';
import { Tabs, Tab } from "react-bootstrap";



export default class MettingCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
            page_name: this.props.match.params.id ? "Edit Lead Form" : "Create Lead Form",
            from: this.props.from ? this.props.from : '',
            record: {
                name: '',
                description: '',
                permission: '',
                group_id: '',
                duration: '5',
                redirect_option: 'same',
                disclaimer: '',
                disclaimer_id: '',
            },

            groups: [],
            permissions: [],
            tag_id: [],
            disclaimers: [],
            tab: "1",
            tokens: [],
            warningAlert: false

        };
    }

    componentDidMount() {
        this.getFormData();
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/meeting/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        groups: response.data.groups,
                        disclaimers: response.data.disclaimers,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmit = async (event, values) => {
        values.redirect_option = this.state.record.redirect_option;
        fetch(`${ApiUrl}` + "add/clientForms", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.props.history.push('/lead/forms/edit/' + bcrypt(response.data.record.id));
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleGroup = (selectedGroup) => {

        this.setState(prevState => {
            let record = Object.assign({}, prevState.record);
            record.group_id = selectedGroup.group_id;
            return { record };
        })
    }
    handleDisclaimer = (selectedGroup) => {
      
        this.setState(prevState => {
            let record = Object.assign({}, prevState.record);
            record.disclaimer_id = selectedGroup.id;
            record.disclaimer = selectedGroup.body;
            return { record };
        })
    }

    handlePermission = (selectedPermission) => {

        var filter = [];
        if (selectedPermission) {
            selectedPermission.map(fil => {
                filter.push(fil.user_id);
            })
        }

        this.setState(prevState => {
            let record = Object.assign({}, prevState.record);
            record.permission = filter.toString();
            return { record };
        })

        this.setState({
            selectedPermission: selectedPermission
        })
    }

    toggle = (index) => {
        if (this.state.tab !== index) {
            this.setState({
                tab: index,
            });
        }
    };

    render() {
        return (
            <React.Fragment>


                <Helmet>
                    <title> {this.state.page_name} </title>
                </Helmet>
                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                className="btn-sm"
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
                {this.state.warningAlert ? (
                    <SweetAlert
                        title="Please fill all fields"
                        warning
                        onConfirm={() => {
                            this.setState({
                                warningAlert: false
                            })
                        }}
                    />
                ) : null}
                <Row>
                    <Col className="col-12">
                        <Card>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={this.state.tab == 1 ? "active" : ""}
                                        onClick={() => {
                                            this.toggle("1");
                                        }}
                                    >
                                        Form Information
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={this.state.tab == 2 ? "active" : ""}
                                        onClick={() => {
                                            this.toggle("2");
                                        }}
                                        disabled
                                    >
                                        Build From
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={this.state.tab == 3 ? "active" : ""}
                                        onClick={() => {
                                            this.toggle("3");
                                        }}
                                        disabled
                                    >
                                        Map Form
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <TabContent activeTab={this.state.tab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col md={6}>
                                                <ModalBody className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <AvField
                                                            name="name"
                                                            value={this.state.record.name}
                                                            label="Name"
                                                            className="form-control"
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-12 mb-3">
                                                        <label className="control-label"> Group </label>
                                                        <Select
                                                            getOptionLabel={option => option.title}
                                                            getOptionValue={option => option.group_id}
                                                            value={this.state.groups.filter(({ group_id }) => group_id === this.state.record.group_id)}
                                                            isMulti={false}
                                                            options={this.state.groups}
                                                            classNamePrefix="select2-selection"
                                                            name="groups_id"
                                                            onChange={(e) => {
                                                                this.handleGroup(e)
                                                            }}
                                                        />
                                                        <AvField required type="hidden" name="group_id" value={this.state.record.group_id} />
                                                    </div>

                                                    

                                                    <div className="col-md-12 mb-3">
                                                        <label className="control-label"> Assign to </label>
                                                        <Select
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.user_id}
                                                            isMulti={true}
                                                            value={this.state.selectedPermission}
                                                            options={this.state.permissions}
                                                            classNamePrefix="select2-selection"
                                                            onChange={(e) => {
                                                                this.handlePermission(e);
                                                            }}
                                                        />
                                                        <AvField
                                                            type="hidden"
                                                            name="permission"
                                                            value={this.state.record.permission}
                                                        />
                                                    </div>

                                                    <div className="col-md-12 mb-3 d-none">
                                                        <label> Form Redirect URL </label>
                                                        <div class="input-group">
                                                            <span class="input-group-addon">https://</span>
                                                            <AvField
                                                                name="redirct_url"
                                                                id="price"
                                                                type="text"
                                                                value={this.state.record.redirct_url}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3 d-none">
                                                        <label className="control-label">Redirect Option </label>
                                                        <div className="form-check form-check-inline ml-5">
                                                            <input checked={this.state.record.redirect_option == 'same' ? 'checked' : ''}
                                                                name={'redirect_option'}
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    this.setState(prevState => {
                                                                        let record = Object.assign({}, prevState.record);
                                                                        record.redirect_option = e.target.value;
                                                                        return { record };
                                                                    });
                                                                }}
                                                                className="form-check-input"
                                                                value={'same'} />
                                                            <label className="form-check-label text-transform-capitalize">Same Window</label>
                                                        </div>

                                                        <div className="form-check form-check-inline">
                                                            <input checked={this.state.record.redirect_option == 'new' ? 'checked' : ''}
                                                                name={'redirect_option'}
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    this.setState(prevState => {
                                                                        let record = Object.assign({}, prevState.record);
                                                                        record.redirect_option = e.target.value;
                                                                        return { record };
                                                                    });
                                                                }}
                                                                className="form-check-input" value={'new'} />
                                                            <label className="form-check-label text-transform-capitalize">New Window</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label className="control-label"> Disclaimer Template </label>
                                                        <Select
                                                            getOptionLabel={option => option.title}
                                                            getOptionValue={option => option.id}
                                                            value={this.state.disclaimers.filter(({ id }) => id === this.state.record.disclaimer_id)}
                                                            isMulti={false}
                                                            options={this.state.disclaimers}
                                                            classNamePrefix="select2-selection"
                                                            name="disclaimer_id"
                                                            required
                                                            onChange={(e) => {
                                                                this.handleDisclaimer(e)
                                                            }}
                                                        />
                                                        <AvField required type="hidden" name="disclaimer_id" value={this.state.record.disclaimer_id} />
                                                        
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                            <AvField
                                                            name="disclaimer"
                                                            value={this.state.record.disclaimer}
                                                            label="Disclaimer Text"
                                                            type="textarea"
                                                            className="form-control d-none"
                                                            placeholder="Enter Disclaimer Text"
                                                        />
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                            __html:  this.state.record.disclaimer,
                                                            }}
                                                        />
                                                       
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button
                                                        className="btn btn-primary waves-effect waves-light btn-sm"
                                                        type="submit"
                                                    >
                                                        Save & Next
                                                    </button>
                                                </ModalFooter>
                                            </Col>
                                            <Col md={6}></Col>

                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
