import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import Loader from "react-loader";
import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select"
import moment from 'moment';
import { Tabs, Tab } from "react-bootstrap";

export default class MettingCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
            page_name: this.props.match.params.id ? "Edit Calendar" : "Create Calendar",
            from: this.props.from ? this.props.from : '',
            loaded: true,
            record: {
                meeting_type: 'one-on-one',
                internal_name: '',
                event_title: '',
                location: '',
                description: '',
                permission: '',
                group_id: '',
                duration: '5',
                calendar_url: `${window.location.origin}/meet/${moment().toDate().getTime()}/${uInfo().name.replaceAll(' ', '_').toLowerCase()}`
            },
            scheduling: {
                title: 'Sample Calendar',
                WorkingDays: [
                    {
                        key: '1',
                        day: 'Mon',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '2',
                        day: 'Tue',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '3',
                        day: 'Wed',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '4',
                        day: 'Thu',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '5',
                        day: 'Fri',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'on'
                    },
                    {
                        key: '6',
                        day: 'Sat',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'off'
                    },
                    {
                        key: '7',
                        day: 'Sun',
                        start: '9:00 AM',
                        end: '6:00 PM',
                        available: 'off'
                    },

                ]
            },
            scheduling_forms: [
                {
                    key: 'first_name',
                    label: 'First Name',
                    alternative_text: '',
                    required: true,
                },
                {
                    key: 'last_name',
                    label: 'Last Name',
                    alternative_text: '',
                    required: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    alternative_text: '',
                    required: true,
                }
            ],

            automation: {
                confirmation_email: false,
                confirmation_sms: false,
                reminder: false,
                reminders: [
                    {
                        interval: '1',
                        type: 'day_before'
                    }
                ]

            },
            groups: [],
            permissions: [],
            tag_id: [],
            tab: "1",
            tokens: [],
            warningAlert: false

        };

        this.addField = this.addField.bind(this);
    }

    componentDidMount() {
        this.state.id != null && this.getData();
        this.getFormData();
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/meeting/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {


                    this.setState({
                        groups: response.data.groups,
                        permissions: response.data.coachPartners,
                        tokens: response.data.tokens.default_fields
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    getData = () => {
        fetch(`${ApiUrl}` + "get/edit/meeting", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id, coach_id: this.state.coach_id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                        selectedGroup: response.data.record.group_id,
                        group_id: response.data.record.group_id,
                        selectedPermission: response.data.record.permissions,
                        permission: response.data.record.permission,
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handleSubmit = async (event, errors, values) => {

        if (this.state.tab == '1') {
            this.toggle("2");
            return;
        } else if (this.state.tab == "2") {
            this.setState({ loaded: false });
            //     this.toggle("3");
            //     return;
            // } else if (this.state.tab == "3" && errors.length < 1) {
            values.schedule_settings = JSON.stringify(this.state.scheduling);
            values.schedule_form = JSON.stringify(this.state.scheduling_forms);
            values.automation_settings = JSON.stringify(this.state.automation);

            fetch(`${ApiUrl}` + "add/meeting", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify(values),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState({ loaded: true });
                    if (data.status === true) {
                        if (this.state.id == null) {
                            this.form && this.form.reset();
                        }
                        this.props.history.push('/meeting/list');

                        // setTimeout(
                        //     () => this.props.history.push('/meeting/list'),
                        //     1000
                        // );

                    } else {
                        toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {

            console.log('not fount');
            this.setState({
                warningAlert: true
            })

        }
    };


    handleGroup = (selectedGroup) => {

        this.setState(prevState => {
            let record = Object.assign({}, prevState.record);
            record.group_id = selectedGroup.group_id;
            return { record };
        })
    }

    handlePermission = (selectedPermission) => {

        var filter = [];
        if (selectedPermission) {
            selectedPermission.map(fil => {
                filter.push(fil.user_id);
            })
        }

        this.setState(prevState => {
            let record = Object.assign({}, prevState.record);
            record.permission = filter.toString();
            return { record };
        })

        this.setState({
            selectedPermission: selectedPermission
        })
    }

    toggle = (index) => {
        if (this.state.tab !== index) {
            this.setState({
                tab: index,
            });
        }
    };

    StartHours = () => {
        const items = [];
        new Array(24).fill().forEach((acc, index) => {
            items.push(moment({ hour: index }).format('h:mm A'));
            items.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
        });
        return items;
    }


    addField = (data) => {
        this.setState(prevState => ({
            scheduling_forms: [...prevState.scheduling_forms, { [`label`]: data.label, key: data.key, [`alternative_text`]: data.alternative_text, required: false }]
        }));
        this.setState({
            addMore: false
        })
    }

    removeReminder = (index) => {
        let automation = this.state.automation;
        automation.reminders.splice(index, 1);
        this.setState(automation)
    }

    removeOptionClick(k, e) {
        let scheduling_forms = [...this.state.scheduling_forms];
        scheduling_forms.splice(k, 1);
        this.setState({ scheduling_forms });
    }

    render() {
        return (
            <React.Fragment>


                <Helmet>
                    <title> {this.state.page_name} </title>
                </Helmet>
                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                className="btn-sm"
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
                {this.state.warningAlert ? (
                    <SweetAlert
                        title="Please fill all fields"
                        warning
                        onConfirm={() => {
                            this.setState({
                                warningAlert: false
                            })
                        }}
                    />
                ) : null}
                <Row>
                    <Col className="col-12">
                        <Card>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={this.state.tab == 1 ? "active" : ""}
                                        onClick={() => {
                                            this.toggle("1");
                                        }}
                                    >
                                        Overview
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={this.state.tab == 2 ? "active" : ""}
                                        onClick={() => {
                                            this.toggle("2");
                                        }}
                                    >
                                        Scheduling
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                        className={this.state.tab == 3 ? "active" : ""}
                                        onClick={() => {
                                            this.toggle("3");
                                        }}
                                    >
                                        Automation
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
                            <Loader loaded={this.state.loaded} className="spinner">
                                <AvForm
                                    onSubmit={this.handleSubmit}
                                    // model={this.state.record}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <TabContent activeTab={this.state.tab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col md={6}>
                                                    <ModalBody className="row">
                                                        <div className="col-md-12 mb-3  d-none">
                                                            <AvField
                                                                name="meeting_type"
                                                                value={this.state.record.meeting_type}
                                                                label="Calendar type"
                                                                className="form-control"
                                                                type="select"
                                                                disabled
                                                            >
                                                                <option value={'one-on-one'}>One-on-one</option>
                                                                <option value={'group'}>Group</option>
                                                                <option value={'round-robin'}>Round-robin</option>
                                                            </AvField>
                                                        </div>

                                                        <div className="col-md-12 mb-3">
                                                            <AvField
                                                                name="calendar_title"
                                                                value={this.state.record.internal_name}
                                                                label="Calendar Title"
                                                                className="form-control"
                                                                required
                                                            />
                                                        </div>

                                                        <div className="col-md-12 mb-3">
                                                            <AvField
                                                                name="event_title"
                                                                value={this.state.record.event_title}
                                                                label="Default Event title"
                                                                className="form-control"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mb-3">
                                                            <AvField
                                                                name="location"
                                                                value={this.state.record.location}
                                                                label="Location"
                                                                className="form-control"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mb-3">
                                                            <label className="control-label"> Group </label>
                                                            <Select
                                                                getOptionLabel={option => option.title}
                                                                getOptionValue={option => option.group_id}
                                                                // defaultValue={this.state.groups.filter(obj => this.state.groups.includes(2))}
                                                                value={this.state.groups.filter(({ group_id }) => group_id === this.state.record.group_id)}
                                                                isMulti={false}
                                                                options={this.state.groups}
                                                                classNamePrefix="select2-selection"
                                                                name="groups_id"
                                                                onChange={(e) => {
                                                                    this.handleGroup(e)
                                                                }}
                                                            />
                                                            <AvField required type="hidden" name="group_id" value={this.state.record.group_id} />
                                                        </div>

                                                        <div className="col-md-12 mb-3 d-none">
                                                            <label className="control-label"> Assign to </label>
                                                            <Select
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.user_id}
                                                                isMulti={true}
                                                                value={this.state.selectedPermission}
                                                                options={this.state.permissions}
                                                                classNamePrefix="select2-selection"
                                                                onChange={(e) => {
                                                                    this.handlePermission(e);
                                                                }}
                                                            />
                                                            <AvField
                                                                type="hidden"
                                                                name="permission"
                                                                value={this.state.record.permission}
                                                            />
                                                        </div>
                                                        <div className="col-md-12 mb-3">
                                                            <AvField
                                                                name="description"
                                                                value={this.state.record.description}
                                                                label="Description"
                                                                className="form-control"
                                                                type="textarea"
                                                            />
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light btn-sm"
                                                            type="submit"
                                                        >
                                                            Next
                                                        </button>
                                                    </ModalFooter>
                                                </Col>
                                                <Col md={6}></Col>

                                            </Row>

                                        </TabPane>
                                        <TabPane tabId={"2"}>
                                            <Row>
                                                <Col md={12}>
                                                    <ModalBody >
                                                        <div className="row">

                                                            <div className="col-md-12 mb-3">
                                                                <AvField
                                                                    name="calendar_url"
                                                                    value={this.state.record.calendar_url}
                                                                    // label="Scheduling page link"
                                                                    className="form-control"
                                                                    type="hidden"
                                                                    readOnly
                                                                />
                                                            </div>

                                                            <div className="col-md-12 mb-3">
                                                                <AvField
                                                                    name="duration"
                                                                    value={this.state.record.duration}
                                                                    label="Duration Time (In Minutes)"
                                                                    className="form-control"
                                                                    placeholder="Add minutes only e.g 5,10,15,30..."
                                                                    type="number"
                                                                    min="1"
                                                                    max="1440"
                                                                    required
                                                                />
                                                                {/* <AvField
                                                                    name="duration"
                                                                    value={this.state.record.duration}
                                                                    label="Duration Time"
                                                                    className="form-control"
                                                                    type="select"
                                                                >
                                                                    <option value={'5'}>5 minutes</option>
                                                                    <option value={'10'}>10 minutes</option>
                                                                    <option value={'15'}>15 minutes</option>
                                                                    <option value={'30'}>30 minutes</option>
                                                                    <option value={'60'}>60 minutes</option>
                                                                </AvField> */}
                                                            </div>


                                                            <div className="col-md-12 mb-3 d-none">
                                                                <AvField
                                                                    name="title"
                                                                    value={this.state.scheduling.title}
                                                                    label="Title"
                                                                    className="form-control"
                                                                    onChange={(e) =>
                                                                        this.setState(prevState => {
                                                                            let scheduling = Object.assign({}, prevState.scheduling);
                                                                            scheduling.title = e.target.value;
                                                                            return { scheduling };
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <Tabs defaultActiveKey="schedule" id="uncontrolled-tab-example" className="mb-3">
                                                            <Tab eventKey="schedule" title="Schedule">
                                                                {
                                                                    this.state.scheduling.WorkingDays.map((d, j) => {
                                                                        return (
                                                                            <div key={j} className="row mb-3">
                                                                                <div className="col-md-3">
                                                                                    <AvGroup>
                                                                                        <AvInput
                                                                                            placeholder="Enter business day"
                                                                                            className="form-control"
                                                                                            name={'day_' + d.key}
                                                                                            value={d.day}
                                                                                            readOnly
                                                                                            required />
                                                                                        <AvFeedback> Required Field *</AvFeedback>
                                                                                    </AvGroup>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <AvField type="select"
                                                                                        name={'available_' + d.key}
                                                                                        label=""
                                                                                        value={d.available}
                                                                                        onChange={(e) =>
                                                                                            this.setState(prevState => {
                                                                                                let WorkingDays = Object.assign({}, prevState.scheduling.WorkingDays);
                                                                                                WorkingDays[j].available = e.target.value;
                                                                                                return { WorkingDays };
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <option value="on">On</option>
                                                                                        <option value="off">Off</option>


                                                                                    </AvField>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <AvField type="select"
                                                                                        name={'start_time_' + d.key}
                                                                                        label=""
                                                                                        value={d.start}
                                                                                        onChange={(e) =>
                                                                                            this.setState(prevState => {
                                                                                                let WorkingDays = Object.assign({}, prevState.scheduling.WorkingDays);
                                                                                                WorkingDays[j].start = e.target.value;
                                                                                                return { WorkingDays };
                                                                                            })
                                                                                        }
                                                                                        disabled={d.available == 'off' ? true : false}
                                                                                    >
                                                                                        {
                                                                                            this.StartHours().map(hour => {
                                                                                                return (
                                                                                                    <option key={hour} value={hour}>{hour}</option>
                                                                                                )
                                                                                            })
                                                                                        }


                                                                                    </AvField>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <AvField type="select"
                                                                                        name={'end_time_' + d.key}
                                                                                        label=""
                                                                                        value={d.end}
                                                                                        onChange={(e) =>
                                                                                            this.setState(prevState => {
                                                                                                let WorkingDays = Object.assign({}, prevState.scheduling.WorkingDays);
                                                                                                WorkingDays[j].end = e.target.value;
                                                                                                return { WorkingDays };
                                                                                            })
                                                                                        }
                                                                                        disabled={d.available == 'off' ? true : false}
                                                                                    >
                                                                                        {
                                                                                            this.StartHours().map(hour => {
                                                                                                return (
                                                                                                    <option key={hour} value={hour}>{hour}</option>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </AvField>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </Tab>
                                                            <Tab eventKey="form" title="Form">
                                                                <div className="metting-details">
                                                                    <p>Ask prospects and customers a few qualifying questions as they are booking meetings with you.</p>
                                                                    {
                                                                        this.state.scheduling_forms.map((row, k) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="row mb-3" key={k}>

                                                                                        <div className="col-md-4">
                                                                                            <AvField
                                                                                                name={row.key}
                                                                                                placeholder={`${row.label}`}
                                                                                                className="form-control"
                                                                                                readOnly

                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <AvField
                                                                                                name={row.key}
                                                                                                value={row.alternative_text}
                                                                                                placeholder={"Enter alternative title"}
                                                                                                className="form-control"
                                                                                                onChange={(e) =>
                                                                                                    this.setState(prevState => {
                                                                                                        let scheduling_forms = Object.assign({}, prevState.scheduling_forms);
                                                                                                        scheduling_forms[k].alternative_text = e.target.value;
                                                                                                        return scheduling_forms;
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <AvField
                                                                                                name={row.key}
                                                                                                label={`Required`}
                                                                                                className="form-control mr-5"
                                                                                                type="checkbox"
                                                                                                value={row.required}
                                                                                                checked={row.required}
                                                                                                disabled={k <= 2 ? true : false}
                                                                                                onChange={(e) =>
                                                                                                    this.setState(prevState => {
                                                                                                        let scheduling_forms = Object.assign({}, prevState.scheduling_forms);
                                                                                                        scheduling_forms[k].required = e.target.checked;
                                                                                                        return scheduling_forms;
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        {
                                                                                            k > 2 && (

                                                                                                <div className="col-md-2">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-danger btn-sm"
                                                                                                        onClick={this.removeOptionClick.bind(this, k)}>
                                                                                                        <i className="ion ion-md-trash"> </i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                    <button type="button" className="btn btn-lignt p0 mb-3 btn-sm" onClick={() => this.setState({ addMore: true })}>Add other form field</button>
                                                                    {
                                                                        this.state.addMore && (
                                                                            <div className="col-md-12 mb-3 min-height-200" >
                                                                                <Select
                                                                                    getOptionLabel={(option) => option.label}
                                                                                    getOptionValue={(option) => option.key}
                                                                                    // value={options.filter(({ id }) => id === this.state.country_code)}
                                                                                    isMulti={false}
                                                                                    options={(
                                                                                        ids => this.state.tokens.filter(n => !ids.includes(n.key))
                                                                                    )(this.state.scheduling_forms.map(({ key }) => key))}
                                                                                    classNamePrefix="select2-selection"
                                                                                    name="country_id"
                                                                                    onChange={(e) => {
                                                                                        this.addField(e)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>

                                                            </Tab>

                                                        </Tabs>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light btn-sm"
                                                            type="button"
                                                            onClick={() => this.toggle("1")}
                                                        >
                                                            Back
                                                        </button>
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light pull-left btn-sm"
                                                            type="submit"
                                                        >
                                                            Next
                                                        </button>
                                                    </ModalFooter>
                                                </Col>
                                                <Col md={6}></Col>

                                            </Row>
                                        </TabPane>
                                        {/* <TabPane tabId={"3"} className="mt-3">
                                            <Row>
                                                <Col md={6}>
                                                    <ModalBody>
                                                        <div className="border py-3 px-3">
                                                            <div className="d-flex space-between">
                                                                <div className="email">
                                                                    <h3>Confirmation email</h3>
                                                                    <p>Send a confirmation email to attendees immediately after they schedule a meeting.</p>
                                                                </div>
                                                                <div className="toggle form-check form-switch form-switch-lg">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="confirmation_email"
                                                                        defaultChecked={this.state.automation.confirmation_email}
                                                                        onClick={e => {
                                                                            this.setState(prevState => {
                                                                                let automation = Object.assign({}, prevState.automation);
                                                                                automation.confirmation_email = e.target.checked;
                                                                                return { automation };
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className="border py-3 px-3">
                                                            <div className="d-flex space-between">
                                                                <div className="email">
                                                                    <h3>Sms Notification</h3>
                                                                    <p>Send a sms notification to attendees immediately after they schedule a meeting.</p>
                                                                </div>
                                                                <div className="toggle form-check form-switch form-switch-lg">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="confirmation_sms"
                                                                        defaultChecked={this.state.automation.confirmation_sms}
                                                                        onClick={e => {
                                                                            this.setState(prevState => {
                                                                                let automation = Object.assign({}, prevState.automation);
                                                                                automation.confirmation_sms = e.target.checked;
                                                                                return { automation };
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className="border py-3 px-3 mt-3">
                                                            <div className="d-flex space-between">
                                                                <div className="email">
                                                                    <h3>Pre-meeting reminder</h3>
                                                                    <p>Send an email or sms notification to attendees before a meeting starts.</p>
                                                                </div>
                                                                <div className="toggle form-check form-switch form-switch-lg">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="reminder"
                                                                        defaultChecked={this.state.automation.reminder}
                                                                        onClick={e => {
                                                                            this.setState(prevState => {
                                                                                let automation = Object.assign({}, prevState.automation);
                                                                                automation.reminder = e.target.checked;
                                                                                return { automation };
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {
                                                                this.state.automation.reminder && (
                                                                    <>
                                                                        {
                                                                            this.state.automation.reminders.map((row, r) => {
                                                                                return (
                                                                                    <div className="row mb-3" key={r}>

                                                                                        <div className="col-md-4">
                                                                                            <AvField
                                                                                                name="interval"
                                                                                                value={row.interval}
                                                                                                placeholder={`Enter Interval`}
                                                                                                className="form-control"
                                                                                                type="number"
                                                                                                min={0}
                                                                                                onChange={(e) =>
                                                                                                    this.setState(prevState => {
                                                                                                        let reminders = Object.assign({}, prevState.automation.reminders);
                                                                                                        reminders[r].interval = e.target.value.replace(/\D/g, '');
                                                                                                        return { reminders };
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <AvField
                                                                                                name="type"
                                                                                                value={row.type}
                                                                                                className="form-control"
                                                                                                type="select"
                                                                                                onChange={(e) =>
                                                                                                    this.setState(prevState => {
                                                                                                        let reminders = Object.assign({}, prevState.automation.reminders);
                                                                                                        reminders[r].type = e.target.value;
                                                                                                        return { reminders };
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                <option value={'day_before'}>day before</option>
                                                                                                <option value={'week_before'}>week before</option>
                                                                                                <option value={'hour_before'}>hour before</option>
                                                                                                <option value={'minute_before'}>minute before</option>
                                                                                            </AvField>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            {
                                                                                                r > 0 && (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-danger"
                                                                                                        onClick={this.removeReminder.bind(this, r)}>
                                                                                                        <i className="ion ion-md-trash"> </i>
                                                                                                    </button>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        <button type="button" className="btn btn-lignt p0 mt-3 mb-3" onClick={() => {
                                                                            let automation = this.state.automation;
                                                                            automation.reminders = [...automation.reminders, { interval: '1', type: 'day_before' }];
                                                                            this.setState({ automation })
                                                                        }}>
                                                                            Add other form field</button>
                                                                    </>
                                                                )
                                                            }

                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light"
                                                            type="button"
                                                            onClick={() => this.toggle("2")}
                                                        >
                                                            Back
                                                        </button>
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </button>
                                                    </ModalFooter>
                                                </Col>
                                                <Col md={6}></Col>
                                            </Row>
                                        </TabPane> */}
                                    </TabContent>
                                </AvForm>
                            </Loader>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
