import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  CardSubtitle,
} from "reactstrap";
import {
  dcrypt,
  bcrypt,
  isAllowed,
  uInfo,
  currentDateTime,
  uToken,
  convertDateFormat,
  NumberWithCommas,
} from "../../useToken";

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
  ApiUrl,
  ProductName,
  ToastAutoClose,
  logoLightPng,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "react-loader";
import { loadStripe } from "@stripe/stripe-js";

export default function AddonPurchase() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("cat"); //const { id } = useParams();
  const addonId = dcrypt(id);
  const [addonData, setAddonData] = useState({});
  const [packages, setPackages] = useState([]);
  const [addCardModal, setAddCardModal] = useState(false);

  const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState(0);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("");

  const [state, setState] = useState({
    // id: match.params.id ? dcrypt(match.params.id) : null,
    // page_id: query.get('page') ? query.get('page') : 0,
    // cat_id: query.get('cat') ? dcrypt(query.get('cat')) : null,
    page_name: "Invoice Preview",
    from: "", //from || "",
    record: {},
    clients: [],
    coachPartners: [],
    customFields: [{ items: "", quantity: "0", price: "0", total: "0" }],
    formData: {
      start_date: currentDateTime(),
      expire_date: "",
      type: "one_time",
      invoice_for: "Member",
      renewal_interval: "Weekly",
      items_data: [],
      email_subject: "",
      notes: "",
      confirmation_email: "",
      created_for_id: 0,
      activity: "0",
      invoice_id: 0,
      logo: "",
    },
    filter_value: "",
    createdFor: {},
    loaded: true,
    pay_now: "Yes",
    card_details: [],
    publishableKey: null,
    currency: "",
    currency_code: "",
  });

  const routeHistory = useHistory();

  const getAddonDetail = () => {
    fetch(`${ApiUrl}addons/detail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id: addonId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          setAddonData(data.data.record);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getPackages = () => {
    fetch(`${ApiUrl}` + "packages/list/Coach", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      //   body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setPackages(response.data.records);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getcarddetails = () => {
    // console.log('uToken()' , uToken());
    fetch(`${ApiUrl}user/getcarddetails`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          if (response.data.customer_id != "") {
            setState((prevState) => ({
              ...prevState,
              card_details: response.data.carddetails.data.data,
            }));
          }
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    getAddonDetail();
    getPackages();
    getcarddetails();
  }, []);

  const purchaseAddon = (event) => {
    setState((prevState) => ({
      ...prevState,
      loaded: false,
    }));
    event.preventDefault();

    const body = JSON.stringify({
      card_id: currentPaymentMethodId,
      addon_id: addonData.id,
    });

    fetch(`${ApiUrl}addons/purchase`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: body,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          swal({
            title: response.message,
            icon: "success",
          });

          // const tokenString = localStorage.getItem("authUser");
          // const userToken = JSON.parse(tokenString);
          // let purchased_addons = userToken.purchased_addons || "";
          // if (purchased_addons) {
          //   purchased_addons = `${purchased_addons},${addonData.id}`;
          // } else {
          //   purchased_addons = addonData.id;
          // }
          // userToken.purchased_addons = purchased_addons;

          const tokenString = localStorage.getItem("authUser");
          const userToken = JSON.parse(tokenString);
          let purchased_addons = userToken.purchased_addons || "";

          if (purchased_addons) {
            let addonsArray = String(purchased_addons).split(",").map(Number);
            if (!addonsArray.includes(addonData.id)) {
              addonsArray.push(addonData.id);
            }
            purchased_addons = addonsArray.join(",");
          } else {
            purchased_addons = addonData.id;
          }

          userToken.purchased_addons = purchased_addons;

          let active_purchased_addons = userToken.active_purchased_addons || "";
          if (active_purchased_addons) {
            active_purchased_addons = `${active_purchased_addons},${addonData.id}`;
          } else {
            active_purchased_addons = addonData.id;
          }
          userToken.active_purchased_addons = active_purchased_addons;

          if (addonData.id === 4) {
            userToken.coach_can_sell_trainings = "active_paid";
          }
          localStorage.setItem("authUser", JSON.stringify(userToken));
          routeHistory.goBack();
        } else {
          // swal({
          //   title: response.message,
          //   icon: "warning",
          // });

          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }

        setState((prevState) => ({
          ...prevState,
          loaded: true,
        }));
      })
      .catch((error) => {
        // swal({
        //   title: "An error occurred",
        //   icon: "error",
        // });

        toast("An error occurred", {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: "toast-custom-cls error",
        });
      });
  };
  const defaultUI = () => {
    const products = [
      {
        items: addonData.title,
        total: addonData.price,
        type: addonData.pricing_interval,
      },
    ];

    return products.map(
      (el, i) =>
        el.total > 0 && (
          <>
            <div className="border-top-2 border-bottom-2 px-1 py-1" key={i}>
              <div className="row">
                <div className="col-sm-6 col-md-6 d-flex align-items-center">
                  {el.items}
                </div>
                <div className="col-sm-6 col-md-6 d-flex align-items-center">
                  ${NumberWithCommas(Math.round(el.total))}
                  {/* {" - "}
                {el.type == "OneTime" ? "One Time" : "Monthly"} */}
                </div>
              </div>
            </div>
          </>
        )
    );
  };

  const addnewcard = (event, values) => {
    console.log("in add");
    event.preventDefault();

    const submitData = {
      cardnumber: values.cardnumber,
      cvc: values.cvc,
      expirationdateMM: values.expirationdateMM,
      expirationdateYYYY: values.expirationdateYYYY,
    };

    fetch(`${ApiUrl}add/coach/Paymentmethod`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify(submitData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setAddCardModal(false);
          setCurrentPaymentMethodId("");
          setCurrentPaymentMethod("");
          getcarddetails();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        swal({
          title: "An error occurred",
          icon: "error",
        });
      });
  };

  const stripePromise = state.publishableKey
    ? loadStripe(state.publishableKey)
    : null;

  return (
    <>
      <Helmet>
        <title>Purchase Add-on</title>
      </Helmet>

      <Row>
        <Col className="col-12">
          <Card>
            <AvForm onValidSubmit={purchaseAddon} className="needs-validation">
              <div className="row">
                <div className="col-md-8 col-sm-12 offset-2">
                  <Card id="print-data">
                    <CardBody>
                      <div className="invoice-title d-flex space-between">
                        <h3 className="mt-0">
                          <img src={logoLightPng} alt="logo" height="50" />
                        </h3>
                        <div className="font-size-16">
                          {/* <p>
                              {convertDateFormat(state.formData.start_date)}
                            </p> */}
                          {/* <p>
                              #IN-
                              {String(state.formData.invoice_id).padStart(
                                5,
                                "0"
                              )}
                            </p> */}
                          <p>
                            {addonData.pricing_interval == "OneTime"
                              ? "One Time"
                              : "Monthly"}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6" className="mt-4">
                          <div className="invoice-meta">
                            <p className="for">TO</p>

                            <div className="invoice-data">
                              <h6>{uInfo().name}</h6>
                              <p className="email">{uInfo().email}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xs="6" className="mt-4">
                          <div className="invoice-meta">
                            <p className="for">From</p>
                            <div className="invoice-data">
                              <h6>{uInfo().name}</h6>
                              <p className="email">{uInfo().email}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="row mt-4">
                        <div className="col-sm-6 col-md-6 mb-6">
                          <b>Add-on</b>
                        </div>
                        <div className="col-sm-6 col-md-6 mb-3">
                          <b>Amount</b>
                          <smal className="fw-light fst-italic">
                            {" "}
                            (
                            {addonData.pricing_interval == "OneTime"
                              ? "one-time payment"
                              : "monthly payment"}
                            )
                          </smal>
                        </div>
                      </div>

                      {defaultUI()}
                      <div className="row">
                        <div className="col-sm-6 col-md-6 mb-3"></div>
                        <div className="col-sm-6 col-md-6 mb-3 p-2">
                          <b>
                            {" "}
                            Total Amount Due: $
                            {NumberWithCommas(Math.round(addonData.price))}
                          </b>
                        </div>
                      </div>

                      <ModalBody className="row">
                        <CardTitle className="text-center">
                          <b>Purchase Add-on</b>
                        </CardTitle>
                        <Row
                          className={`${addonData.price < 1 ? "d-none" : ""}`}
                        >
                          <Col className="col-12 mb-3">
                            <label className="control-label">
                              Select Credit Card
                            </label>
                            <div className="card-list">
                              <Select
                                value={currentPaymentMethod}
                                getOptionLabel={(option) =>
                                  `**** ${option.last4} - ${option.exp_month}/${option.exp_year}`
                                }
                                getOptionValue={(option) => option.id}
                                isMulti={false}
                                options={state.card_details}
                                classNamePrefix="select2-selection"
                                name="card_ids"
                                onChange={(e) => {
                                  setCurrentPaymentMethod(e);
                                  setCurrentPaymentMethodId(e.id);
                                }}
                                required
                              />
                            </div>
                            <AvInput
                              type="hidden"
                              name="card_id"
                              value={currentPaymentMethodId}
                            />
                          </Col>
                          <Col lg="12">
                            <button
                              type="button"
                              className="btn btn-link p0 btn-sm"
                              onClick={() => setAddCardModal(true)}
                            >
                              <i className="fas fa-plus"></i> Add New Payment
                              Method
                            </button>
                          </Col>
                        </Row>
                      </ModalBody>
                      <Loader loaded={state.loaded} className="spinner">
                        <ModalFooter>
                          <Button
                            className="btn-sm"
                            type="button"
                            color="secondary"
                            onClick={() => routeHistory.goBack()}
                          >
                            Cancel
                          </Button>
                          <button
                            className="btn btn-primary waves-effect waves-light btn-sm"
                            type="submit"
                          >
                            Submit
                          </button>
                        </ModalFooter>
                      </Loader>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </AvForm>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={addCardModal}
        role="dialog"
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAddCardModal(false);
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setAddCardModal(false);
            }}
            className="text-transform-capitalize"
          >
            Add New Card
          </ModalHeader>

          <AvForm
            onValidSubmit={(event, values) => addnewcard(event, values)}
            className="needs-validation"
          >
            <ModalBody>
              <Row className="mt-3">
                <Col className="col-6">
                  <label className="control-label">Card Number</label>
                  <AvField
                    name="cardnumber"
                    className="form-control"
                    type="text"
                    placeholder="Enter Card Number"
                    required
                    maxLength={16}
                    validate={{
                      pattern: {
                        value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                        errorMessage: "Type only positive numbers",
                      },
                    }}
                  />
                </Col>
                <Col className="col-6">
                  <label className="control-label"> CVC</label>
                  <AvField
                    name="cvc"
                    className="form-control"
                    type="text"
                    placeholder="CVC"
                    required
                    maxLength={4}
                    validate={{
                      pattern: {
                        value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                        errorMessage: "Type only positive numbers",
                      },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="col-6">
                  <AvField
                    name="expirationdateMM"
                    label="MM"
                    className="form-control"
                    type="text"
                    placeholder="MM"
                    maxLength={2}
                    required
                    validate={{
                      pattern: {
                        value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                        errorMessage: "Type only positive numbers",
                      },
                    }}
                  />
                </Col>
                <Col className="col-6">
                  <AvField
                    name="expirationdateYYYY"
                    label="YYYY"
                    className="form-control"
                    type="text"
                    placeholder="YYYY"
                    required
                    maxLength={4}
                    validate={{
                      pattern: {
                        value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                        errorMessage: "Type only positive numbers",
                      },
                    }}
                  />
                </Col>
              </Row>
            </ModalBody>
            <Loader loaded={state.loaded} className="spinner">
              <ModalFooter>
                <Button
                  className="btn-sm"
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setAddCardModal(false);
                  }}
                >
                  Close
                </Button>
                <button
                  className="btn btn-primary waves-effect waves-light btn-sm"
                  type="submit"
                >
                  Save
                </button>
              </ModalFooter>
            </Loader>
          </AvForm>
        </div>
      </Modal>
    </>
  );
}
