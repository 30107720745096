
export function getCoachPartnerPages() {
    return [
        {
            "key": "crm", "label": "CRM",
            "sub_tabs": [
                { "key": "inbox", "label": "Inbox" },
                { 
                    "key": "leads", 
                    "label": "Leads",
                    "sub_items": [
                        { "key": "import_leads", "label": "Import Leads" },
                        { "key": "leads_quick_dialer", "label": "Quick Dialer" },
                        { "key": "lead_forms", "label": "Lead Forms" }
                    ]
                },
                { 
                    "key": "clients", 
                    "label": "Clients",
                    "sub_items": [
                      { "key": "clients_quick_dialer", "label": "Quick Dialer" } 
                    ]
                },
                { 
                    "key": "my_coaches", 
                    "label": "My Coaches",
                    "sub_items": [
                      { "key": "add_coach", "label": "Add Coach" } 
                    ]
                },
                { "key": "groups_and_tags", "label": "Groups & Tags" },
                { "key": "custom_fields", "label": "Custom Fields" },
            ]
        },
        {
            "key": "sales", "label": "Sales",
            "sub_tabs": [
                { "key": "pipeline", "label": "Pipeline" },
                { "key": "onboard_training", "label": "OnBoard Training" },
                { "key": "lead_forms", "label": "Lead Forms" },
                { "key": "invoices", "label": "Invoices" },
            ]
        },
        {
            "key": "campaign", 
            "label": "Campaign",
            "sub_tabs": [
                { "key": "campaign_list", "label": "Campaign List" },
                { "key": "call_status", "label": "Call Status" },
                { "key": "caller_group", "label": "Caller Group" },
                { 
                    "key": "template_library", 
                    "label": "Template Library",
                    "sub_items": [
                        { "key": "email_template", "label": "Email Template" },
                        { "key": "sms_template", "label": "SMS Template" },
                        { "key": "voice_template", "label": "Voice Template" },
                        { "key": "script_template", "label": "Script Template" }
                    ]
                },
                { "key": "unsubscribe_keywords", "label": "Unsubscribe Keywords" }
            ]
        },
        {
            "key": "coaching", 
            "label": "Coaching",
            "sub_tabs": [
                { 
                    "key": "assessment_list", 
                    "label": "Assessments",
                    "sub_items": [
                        { "key": "assessment_calculator", "label": "Assessment Calculator" },
                        { "key": "profit_center", "label": "Profit Center" }
                    ]
                },
                { 
                    "key": "trainings", 
                    "label": "Trainings",
                    "sub_items": [
                        { "key": "ask_ai", "label": "Ask AI" }
                    ]
                },
                { 
                    "key": "training_tags", 
                    "label": "Training Tags",
                    "sub_items": [
                        { "key": "user_tags", "label": "User Tags" }
                    ]
                },
                { "key": "training_group", "label": "Group Coaching" },
                { "key": "resources", "label": "Resource Center" },
                { "key": "training_products", "label": "Training Products" },
                { "key": "add_ons_library", "label": "Add-ons Library" },
            ]
        },
        {
            "key": "reports", "label": "Reports",
            "sub_tabs": [
                { "key": "reporting", "label": "Reporting" },
                { "key": "credit_log", "label": "Credit Log" },
            ]
        },
        {
            "key": "settings",
            "label": "Settings",
            "sub_tabs": [
                {
                    "key": "profile",
                    "label": "Profile",
                    "sub_items": [
                        { "key": "personal_phone_settings", "label": "Personal Phone Settings" },
                        { "key": "email_settings", "label": "Email Settings" },
                        { "key": "social_links", "label": "Social Links" },
                        { "key": "email_signature", "label": "Email Signature" },
                        { "key": "coach_photo", "label": "Coach Photo" },
                        { "key": "change_password", "label": "Change Password" }
                    ]
                },
                {
                    "key": "team",
                    "label": "Team",
                    "sub_items": [
                        { "key": "coach_partner", "label": "Add Coach Partner" },
                        { "key": "permission_library", "label": "Permission Library" }
                    ]
                },
                { "key": "calendar", "label": "Calendar" },
                {
                    "key": "notifications",
                    "label": "Notifications",
                    "sub_items": [
                        { "key": "notifications_trainings", "label": "Trainings" },
                        { "key": "notifications_tasks", "label": "Tasks" },
                        { "key": "notifications_calendar_events", "label": "Calendar Events" },
                        { "key": "notifications_action_steps", "label": "Action Steps" },
                        { "key": "notifications_templates", "label": "Templates" }
                    ]
                },
                { "key": "theme_settings", "label": "Theme Settings" },
                { "key": "api", "label": "API" },
            ]
        }        
    ];
}

export function getCoachPages() {
    return [
        {
            "key": "crm", "label": "CRM",
            "sub_tabs": [
                { "key": "inbox", "label": "Inbox" },
                { 
                    "key": "leads", 
                    "label": "Leads",
                    "sub_items": [
                        { "key": "import_leads", "label": "Import Leads" },
                        { "key": "leads_quick_dialer", "label": "Quick Dialer" },
                        { "key": "lead_forms", "label": "Lead Forms" }
                    ]
                },
                { 
                    "key": "clients", 
                    "label": "Clients",
                    "sub_items": [
                      { "key": "clients_quick_dialer", "label": "Quick Dialer" } 
                    ]
                },
                { 
                    "key": "my_coaches", 
                    "label": "My Coaches",
                    "sub_items": [
                      { "key": "add_coach", "label": "Add Coach" } 
                    ]
                },
                { "key": "groups_and_tags", "label": "Groups & Tags" },
                { "key": "custom_fields", "label": "Custom Fields" },
            ]
        },
        {
            "key": "sales", "label": "Sales",
            "sub_tabs": [
                { "key": "pipeline", "label": "Pipeline" },
                { "key": "onboard_training", "label": "OnBoard Training" },
                { "key": "invoices", "label": "Invoices" },
            ]
        },
        {
            "key": "campaign", 
            "label": "Campaign",
            "sub_tabs": [
                { "key": "campaign_list", "label": "Campaign List" },
                { "key": "call_status", "label": "Call Status" },
                { "key": "caller_group", "label": "Caller Group" },
                { 
                    "key": "template_library", 
                    "label": "Template Library",
                    "sub_items": [
                        { "key": "email_template", "label": "Email Template" },
                        { "key": "sms_template", "label": "SMS Template" },
                        { "key": "voice_template", "label": "Voice Template" },
                        { "key": "script_template", "label": "Script Template" }
                    ]
                },
                { "key": "unsubscribe_keywords", "label": "Unsubscribe Keywords" }
            ]
        },
        {
            "key": "coaching", 
            "label": "Coaching",
            "sub_tabs": [
                { 
                    "key": "assessment_list", 
                    "label": "Assessments",
                    "sub_items": [
                        { "key": "assessment_calculator", "label": "Assessment Calculator" },
                        { "key": "profit_center", "label": "Profit Center" }
                    ]
                },
                { 
                    "key": "trainings", 
                    "label": "Trainings",
                    "sub_items": [
                        { "key": "ask_ai", "label": "Ask AI" }
                    ]
                },
                { 
                    "key": "training_tags", 
                    "label": "Training Tags",
                    "sub_items": [
                        { "key": "user_tags", "label": "User Tags" }
                    ]
                },
                { "key": "training_group", "label": "Group Coaching" },
                { "key": "resources", "label": "Resource Center" },
            ]
        },
        {
            "key": "reports", "label": "Reports",
            "sub_tabs": [
                { "key": "reporting", "label": "Reporting" },
                { "key": "credit_log", "label": "Credit Log" },
            ]
        },
        {
            "key": "settings",
            "label": "Settings",
            "sub_tabs": [
                {
                    "key": "profile",
                    "label": "Profile",
                    "sub_items": [
                        { "key": "personal_phone_settings", "label": "Personal Phone Settings" },
                        { "key": "email_settings", "label": "Email Settings" },
                        { "key": "social_links", "label": "Social Links" },
                        { "key": "email_signature", "label": "Email Signature" },
                        { "key": "coach_photo", "label": "Coach Photo" },
                        { "key": "change_password", "label": "Change Password" }
                    ]
                },
                {
                    "key": "team",
                    "label": "Team",
                    "sub_items": [
                        { "key": "coach_partner", "label": "Add Coach Partner" },
                        { "key": "permission_library", "label": "Permission Library" }
                    ]
                },
                { "key": "settings_integration", "label": "Integration" },
                { "key": "credits", "label": "Credits" },
                { "key": "calendar", "label": "Calendar" },
                {
                    "key": "notifications",
                    "label": "Notifications",
                    "sub_items": [
                        { "key": "notifications_trainings", "label": "Trainings" },
                        { "key": "notifications_tasks", "label": "Tasks" },
                        { "key": "notifications_calendar_events", "label": "Calendar Events" },
                        { "key": "notifications_action_steps", "label": "Action Steps" },
                        { "key": "notifications_templates", "label": "Templates" }
                    ]
                },
                { "key": "theme_settings", "label": "Theme Settings" },
                {
                    "key": "phone_numbers",
                    "label": "Phone Numbers",
                    "sub_items": [
                        { "key": "my_numbers", "label": "My Numbers" },
                        { "key": "get_phone_numbers", "label": "Get Phone Number(s)" },
                        { "key": "deleted_phone_numbers", "label": "Deleted Phone Number(s)" },
                        { "key": "unlock_phone_flow", "label": "Unlock Phone Flow" },
                    ]
                },
                { "key": "billing", "label": "Billing" },
                { "key": "transaction_log", "label": "Transaction Log" },
                { "key": "payment", "label": "Payment" },
                {
                    "key": "global_constraint_setting",
                    "label": "Global Constraint Setting",
                    "sub_items": [
                        { "key": "constraints_settings", "label": "Constraints Settings" },
                        { "key": "call_recording", "label": "Call Recording" }
                    ]
                },
                { "key": "api", "label": "API" },
                {
                    "key": "website_builder",
                    "label": "Website Builder",
                    "sub_items": [
                        { "key": "blogs", "label": "Blogs" },
                        { "key": "landing_page_settings", "label": "Landing Page Settings" },
                        { "key": "book_download", "label": "Book Download" },
                        { "key": "blocked_ip", "label": "Blocked IP" }
                    ]
                }
            ]
        }        
    ];
}

export function getStaffPages() {
    return [
        {
            "key": "master_users", "label": "Users", "sub_tabs":
                [
                    { "key": "coaches", "label": "Coaches" },
                    { "key": "staff", "label": "Staff" },
                    { "key": "permission_library", "label": "Permission Library" },
                    { "key": "clone_coach_settings", "label": "Clone Coach Settings" },
                ]
        },
        {
            "key": "plans", "label": "Plans", "sub_tabs":
                [
                    { "key": "coach_packages", "label": "Coach Packages" },
                    { "key": "credit_packages", "label": "Credit Packages" },
                    { "key": "proposal_list", "label": "Proposals" },
                    { "key": "revenue_goals", "label": "Revenue Goals" },
                    { "key": "revenue_products", "label": "Revenue Products" },
                ]
        },
        {
            "key": "credits", "label": "Credits", "sub_tabs":
                [
                    { "key": "free_credit", "label": "Free Credit" },
                    { "key": "consume_criteria", "label": "Credit Consumption Criteria" },
                ]
        },
        {
            "key": "coaching", "label": "Coaching", "sub_tabs":
                [
                    { "key": "training", "label": "Training" },
                    { "key": "training_tags", "label": "Training Tags" },
                    { "key": "resources", "label": "Resources" },
                    { "key": "tracker_steps", "label": "Tracker Steps" },
                    { "key": "assessment_questions", "label": "Assessment Questions" },
                ]
        },
        {
            "key": "reports", "label": "Reports", "sub_tabs":
                [
                    { "key": "activity_log", "label": "Activity Log" },
                    { "key": "twillio_usagelog", "label": "Twilio Usage Log" },
                    { "key": "credit_consumption_log", "label": "Credit Consumption Log" },
                    { "key": "coach_login_history", "label": "Coach Login History" },
                    { "key": "coach_subscription_log", "label": "Coach Subscription Log" },
                    { "key": "coach_nonsubscription_log", "label": "Coach Nonsubscription Log" },
                    { "key": "invoices_lists", "label": "Invoice Log" },


                ]
        },
        {
            "key": "cms", "label": "CMS", "sub_tabs":
                [
                    { "key": "disclaimer", "label": "Disclaimer" },
                    { "key": "terms_of_service", "label": "Terms of Service" },
                    { "key": "privacy_policy", "label": "Privacy Policy" },
                    { "key": "default_template", "label": "Default Notification Template" },
                    { "key": "landing_page", "label": "Landing Page" },


                ]
        },
        {
            "key": "staff_setting", "label": "Setting", "sub_tabs":
                [
                    { "key": "settings_integration", "label": "Integration" },
                    { "key": "settings_email", "label": "Email" },
                    { "key": "settings_payment", "label": "Payment" },
                    { "key": "settings_domain", "label": "Domain" },
                    { "key": "system_forms", "label": "System Forms" },
                    { "key": "standard_profile_request", "label": "Standard Profile Request" },
                    { "key": "settings_cms_blogs", "label": "Blogs" },
                    { "key": "settings_cms_blogs", "label": "Testimonial" },
                    { "key": "settings_cms_blogs", "label": "FAQs" },

                ]
        },
    ];
}
