import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ListGroup from 'react-bootstrap/ListGroup';
import { Accordion } from "react-bootstrap";

export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_name: this.props.match.params.id
        ? "Edit Training Group"
        : "Create Training Group",
      currentuser: JSON.parse(localStorage.getItem("authUser")),
      record: {},
      training_for: props.trainingFor,
      trainingList: [],
      name: ""
    };
  }

  componentDidMount() {
    this.getWayUserTraining();
  }

  getWayUserTraining = () => {
    fetch(`${ApiUrl}` + "get/group/trainingCategory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: 0, user_id: 0, training_for: 3 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            trainingList: response.data.record
          });
          const setInputState = (el, state) => {
            if (state === 'indeterminate') {
              el.indeterminate = true
            } else {
              el.indeterminate = false
              el.checked = state
            }
          }

          const updateOwned = (el) => {
            if (el.hasAttribute('data-children')) {
              let state = el.checked
              el.getAttribute('data-children').split(' ').forEach(id => {
                let owned = document.getElementById(id)
                setInputState(owned, state)
                updateOwned(owned)
              })
            }
          }

          const updateOwner = (el) => {

            if (el.hasAttribute('data-parent')) {
              let owner = document.getElementById(el.getAttribute('data-parent'))
              let states = []
              let collectiveState
              owner.getAttribute('data-children').split(' ').every(id => {
                let owned = document.getElementById(id)
                let state = owned.indeterminate === true ? 'indeterminate' : owned.checked
                if (states.length > 0 && states.indexOf(state) === -1) {
                  collectiveState = 'indeterminate'
                  return false
                } else {
                  states.push(state)
                  return true
                }
              })
              collectiveState = collectiveState || states[0]
              setInputState(owner, collectiveState)
              updateOwner(owner)
            }
          }

          document.querySelectorAll('.nested-multiselect').forEach(multiselect => {
            multiselect.querySelectorAll('input[type="checkbox"][data-children], input[type="checkbox"][data-parent]').forEach(input => {
              input.addEventListener('change', event => {
                updateOwned(event.currentTarget)
                updateOwner(event.currentTarget)
              })
            })
          });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }
    submitData.append('name', this.state.name);
    fetch(`${ApiUrl}` + "add/traininggroup", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "Editing");
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          setTimeout(() => this.props.history.push("/traininggroup/list"), 1000);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <form method="post" onSubmit={this.handleSubmit}>
                <ModalBody className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      name="name"
                      value={this.state.name}
                      label="Name"
                      onChange={(e) => this.setState({ name: e.target.value })}
                      className="form-control"
                      required
                    />
                  </div>

                  <fieldset className="nested-multiselect-14">
                    {
                      this.state.trainingList.map((cat, i) => {
                        return (
                          <fieldset className="nested-multiselect">
                            <Accordion defaultActiveKey={parseInt(cat.cat_id)} className="border-0">
                              <Accordion.Item eventKey={i} className={`${i} --aa -- ${cat.cat_id} `}>
                                <Accordion.Header>
                                  <label id={`label-${cat.cat_id}`}>
                                    <input id={`${cat.cat_id}`}
                                      type="checkbox"
                                      name="categories"
                                      value={`${cat.cat_id}`}
                                      aria-owns={`subcategories-${cat.cat_id}`}
                                      data-children={cat.childeren} />
                                    <span> {cat.title}</span>
                                  </label>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {
                                    cat.sub_cat.map((sub, s) => {
                                      return (
                                        <Accordion defaultActiveKey={parseInt(sub.cat_id)} className="border-0">
                                          <Accordion.Item eventKey={s} className={`${s} --aa -- ${sub.cat_id} `}>
                                            <Accordion.Header>
                                              <label id={`label-${sub.cat_id}`}>
                                                <input id={`${sub.cat_id}`}
                                                  type="checkbox"
                                                  name="categories"
                                                  value={`${sub.cat_id}`}
                                                  aria-owns={`subcategories-${sub.cat_id}`}
                                                  data-parent={cat.cat_id}
                                                  data-children={sub.childeren} />
                                                <span> {sub.title}</span>
                                              </label>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              {
                                                sub.sub_cat.map((final, f) => {
                                                  return (
                                                    <Accordion defaultActiveKey={parseInt(final.cat_id)} className="border-0">
                                                      <Accordion.Item eventKey={f} className={`${f} --aa -- ${final.cat_id} `}>
                                                        <Accordion.Header>
                                                          <label id={`label-${final.cat_id}`}>
                                                            <input
                                                              id={`${final.cat_id}`}
                                                              type="checkbox"
                                                              name="category[]"
                                                              value={final.cat_id}
                                                              data-parent={sub.cat_id}
                                                              aria-owns={`subcategories-${final.cat_id}`}
                                                              data-children={final.childeren}
                                                            />
                                                            <span> {final.title}</span>
                                                          </label>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                          {
                                                            final.sub_cat.map((step, s) => {
                                                              return (
                                                                 // <Accordion defaultActiveKey={parseInt(step.step_id)} className="border-0">
                                                                //   <Accordion.Item eventKey={s} className={`${s} --aa -- ${step.step_id} `}>
                                                                //     <Accordion.Header>
                                                                      <label id={`label-s-${step.step_id}`} className="row">
                                                                        <input
                                                                          id={`s-${step.step_id}`}
                                                                          type="checkbox"
                                                                          name="subcategory[]"
                                                                          value={`${cat.cat_id}-${sub.cat_id}-${final.cat_id}-${step.step_id}`}
                                                                          data-parent={final.cat_id}

                                                                        />
                                                                        <span> {step.title}</span>
                                                                      </label>
                                                                    //     </Accordion.Header>
                                                                  //   </Accordion.Item>
                                                                // </Accordion>
                                                              )
                                                            })}
                                                        </Accordion.Body>
                                                      </Accordion.Item>
                                                    </Accordion>
                                                  )
                                                })
                                              }
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion >
                                      )
                                    })
                                  }
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion >
                          </fieldset>
                        );

                      })
                    }
                  </fieldset>

                  {/* <fieldset className="nested-multiselect-14">
                    {
                        this.state.trainingList.map((cat, i) => {
                            return (
                                <fieldset className="nested-multiselect">
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <label id={`label-${cat.cat_id}`}>
                                                <input id={`${cat.cat_id}`}
                                                    type="checkbox"
                                                    name="categories"
                                                    value={`${cat.cat_id}`}
                                                    aria-owns={`subcategories-${cat.cat_id}`}
                                                    // defaultChecked={cat.assign_checked}
                                                    data-children={cat.childeren} />
                                                <span> {cat.title}</span>
                                            </label>
                                            <fieldset id={`subcategories-${cat.cat_id}`}
                                                aria-label={`${cat.cat_id} subcategories`}>
                                                <ListGroup>
                                                    {
                                                        cat.sub_cat.map((sub, s) => {
                                                            return (
                                                                <ListGroup.Item>
                                                                    <label id={`label-${sub.cat_id}`}>
                                                                        <input id={`${sub.cat_id}`}
                                                                            type="checkbox"
                                                                            name="categories"
                                                                            value={`${sub.cat_id}`}
                                                                            aria-owns={`subcategories-${sub.cat_id}`}
                                                                            data-parent={cat.cat_id}
                                                                            // defaultChecked={sub.assign_checked}
                                                                            data-children={sub.childeren} />
                                                                        <span> {sub.title}</span>
                                                                    </label>
                                                                    <fieldset id={`subcategories-${sub.cat_id}`}
                                                                        aria-label={`${sub.cat_id} subcategories`}
                                                                    >
                                                                        <ListGroup>
                                                                            {
                                                                                sub.sub_cat.map((final, f) => {
                                                                                    return (
                                                                                        <ListGroup.Item>
                                                                                            <label id={`label-${final.cat_id}`}>
                                                                                                <input
                                                                                                    id={`${final.cat_id}`}
                                                                                                    type="checkbox"
                                                                                                    name="category[]"
                                                                                                    // defaultChecked={final.assign_checked}
                                                                                                    value={final.cat_id}
                                                                                                    data-parent={sub.cat_id}
                                                                                                    aria-owns={`subcategories-${final.cat_id}`}
                                                                                                    data-children={final.childeren}

                                                                                                />
                                                                                                <span> {final.title}</span>
                                                                                            </label>
                                                                                            <fieldset id={`subcategories-${final.cat_id}`}
                                                                                                aria-label={`${final.cat_id} subcategories`}
                                                                                            >
                                                                                                <ListGroup>
                                                                                                    {
                                                                                                        final.sub_cat.map((step, s) => {
                                                                                                            return (
                                                                                                                <ListGroup.Item>
                                                                                                                    <label id={`label-s-${step.step_id}`}>
                                                                                                                        <input
                                                                                                                            id={`s-${step.step_id}`}
                                                                                                                            type="checkbox"
                                                                                                                            name="subcategory[]"
                                                                                                                            // defaultChecked={step.assign_checked}
                                                                                                                            value={`${cat.cat_id}-${sub.cat_id}-${final.cat_id}-${step.step_id}`}
                                                                                                                            data-parent={final.cat_id}

                                                                                                                        />
                                                                                                                        <span> {step.title}</span>
                                                                                                                    </label>
                                                                                                                </ListGroup.Item>
                                                                                                            )
                                                                                                        })}
                                                                                                </ListGroup>
                                                                                            </fieldset>
                                                                                        </ListGroup.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ListGroup>

                                                                    </fieldset>
                                                                </ListGroup.Item>
                                                            )
                                                        })
                                                    }
                                                </ListGroup>

                                            </fieldset>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </fieldset>
                            );

                        })
                    }
                  </fieldset> */}
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>

                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
