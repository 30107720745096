import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  convertDateFormat,
  currentDateTime,
  dcrypt,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  logoLightPng,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_name: "",
      record: {},
      records: [],
      formData: {
        start_date: currentDateTime(),
      },
    };
  }

  componentDidMount() {
    if (this.state.id != null) {
      this.getproposaldata({ id: this.state.id });
    } else {
      this.getproposaldata("");
    }

    window.onafterprint = function () {
      window.location.reload();
    };
  }

  getproposaldata = (data) => {
    fetch(`${ApiUrl}` + "get/approved/proposal", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            records: response.data.records,
            page_name: response.data.records.title,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  Download() {
    const downloadcontent = document.getElementById("divToPrint");
    var filename = "Proposal-" + this.state.page_name + ".pdf";

    html2canvas(downloadcontent).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      doc.text("Hello world!", 1, 1);
      doc.save("two-by-four.pdf");
      //pdf.addImage(imgData, "JPEG", 15, 40, 180, 160);
      //pdf.save("download.pdf");
    });
  }
  PrintElem() {
    var printContents = document.getElementById("print-data").innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }

  render() {
    return (
      <React.Fragment>

        <ToastContainer />

        <Row>
          <Col sm={12} className="">
            <div className="pb-10 pull-right">
              {this.state.records.type != 'signup-proposal' && (
                <Button
                  type="button"
                  color="info"
                  onClick={() => this.PrintElem()}
                  className="mr-3 btn-sm"
                >
                  <i className="fas fa-download"> </i>
                </Button>
              )}
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
                className="mr-3 btn-sm"
              >
                Back
              </Button>

            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12" id="print-data">
            <Card>
              <CardBody>
                <div
                  id="divToPrint"
                  className="mt4"
                  style={{
                    backgroundColor: "#f5f5f5",
                    width: "210mm",
                    minHeight: "297mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "25px",
                  }}
                >
                  <div>
                    <div className="invoice-title d-flex space-between">
                      <h3 className="mt-0">
                        <img src={logoLightPng} alt="logo" height="24" />
                      </h3>
                      <div className="font-size-16">
                        <p>
                          {convertDateFormat(this.state.formData.start_date)}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="header" style={{ textAlign: "center" }}>
                      <h2>{this.state.records.title}</h2>
                    </div>
                    {
                      this.state.records.type == 'signup-proposal' ? (
                        <iframe id="iframepdf" height={'930'} width={'100%'} src={this.state.records.pdf}></iframe>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.records.body,
                          }}
                        />
                      )
                    }

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
