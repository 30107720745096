import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  Table,
  Button,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import Helmet from "react-helmet"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

import { Link, withRouter } from "react-router-dom"

import avatar from "../../../assets/images/users/user-4.jpg"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Logs from "../../Logs/Logs.js"
import { ApiUrl, ProductName } from "../../../config"
import { toast, Flip } from "react-toastify"
import { dcrypt, uToken } from "../../../useToken"
import LoginHistory from "../../../components/tables/LoginHistory"
import Group from "../../../pages/Coaches/Group/Group.js"
import swal from 'sweetalert';



// actions

//Import Action to copy breadcrumb items from local state to redux state

const TagDetails = props => {

  const [page_name] = useState("Tags");
  const [idx] = useState(dcrypt(props.match.params.id));
  const [info, setInfo] = useState({});

  const [trainings, setTranings] = useState([]);
  const [users, setUsers] = useState([]);
  


  useEffect(() => {
    console.log('UserProfile', props);
    getmeta();

  }, []);

  const getmeta = () => {
    fetch(`${ApiUrl}` + "get/training/meta", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ id: idx}),
       
    })
        .then((response) => response.json())
        .then((response) => {
            
            if (response.status === true) {
                setInfo(response.data.record);

              
                getAssociatedRecord(response.data.record.id, "", "trainings");
           
                getAssociatedUsers(response.data.record.id, "", "users");
            } else {
                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
}


const getAssociatedRecord = (id=null, queryString="", type = "trainings") => {
    fetch(`${ApiUrl}` + `get/associated/${type}` + queryString, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ id: id}),
       
    })
        .then((response) => response.json())
        .then((response) => {
            
            if (response.status === true) {

                setTranings(response.data.records);
            } 
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};

const getAssociatedUsers= (id=null, queryString="", type = "trainings") => {
    fetch(`${ApiUrl}` + `get/associated/${type}` + queryString, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ id: id}),
       
    })
        .then((response) => response.json())
        .then((response) => {
            
            if (response.status === true) {

                setUsers(response.data.records);
            } 
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};
 





  

  return (
    <React.Fragment className={'custom-table'}>
      <div className="UserProfileTable">
        <Helmet>
          <title>Profile </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{page_name}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => props.history.goBack()}
              >
                Back{" "}
              </Button>
            </div>{" "}
          </Col>{" "}
        </Row>

        <Row>

          <Col md={4}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Tags Information</CardTitle>
                <div className="table-responsive user-info">
                  <Table className="table table-bordered mb-0 border-0">
                    <tbody>
                      <tr style={{ background: '#041934', color: 'white', backgroundPosition: 'right center' }}>
                        <th>Key</th>
                        <th>Value</th>
                      </tr>
                      <tr>
                        <td>TagId</td>                        
                        <td>{info.id}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>{info.name}</td>
                      </tr>
                      
                      
                      <tr>
                        <td>Status</td>
                        <td>{ info.status == 1 ? "Active" : "Inactive"}</td>
                      </tr>
                      
                     
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

          </Col>

          
                <Col md={4}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Associated Trainings</CardTitle>
                    <div className="table-responsive user-info">
                      <Table className="table table-bordered mb-0 border-0">
                        <tbody>
                          <tr style={{ background: '#041934', color: 'white', backgroundPosition: 'right center' }}>
                            <th>Training Name</th>
                            <th>Type</th>
                          </tr>
                          { trainings.length > 0 && (trainings.map((row, i) => {
                return (
             
                          <tr>
                            <td>{row.title}</td>
                            <td>{row.type}</td>
                          </tr>   
                             )
                            })
                        )
                    }

                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
    
              </Col>

           
          <Col md={4}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Associated Users</CardTitle>
                <div className="table-responsive user-info 4">
                  <Table className="table table-bordered mb-0 border-0">
                    <tbody>
                      <tr style={{ background: '#041934', color: 'white', backgroundPosition: 'right center' }}>
                        <th>User Name</th>
                        <th>Type</th>
                      </tr>
                      
                      { users.length > 0 && (users.map((row, i) => {
                return (
             
                          <tr>
                            <td>{row.name}</td>
                            <td>{row.type}</td>
                          </tr>   
                             )
                            })
                        )
                    }
                    
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>

          </Col>

        </Row>
  


      </div>
    </React.Fragment>
  )
}

export default TagDetails;