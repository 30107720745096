import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Table,
} from "reactstrap";
import Loader from "react-loader";

import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import DropdownButton from "react-bootstrap/DropdownButton";
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";

export default class Billing extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <Link
                color="info"
                className="btn btn-primary btn-sm mb-1 mr-5"
                to={
                  "/wl/subscription/history/invoice/" +
                  bcrypt(record.subscription_id) +
                  "/" +
                  bcrypt(record.transaction_id) +
                  "/" +
                  bcrypt(record.user_id)
                }
              >
                <i className="mdi mdi-file-multiple"> Invoice </i>
              </Link>
            </Fragment>
          );
        },
      },
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },
      // {
      //   text: "Package",
      //   key: "package_name",
      //   sortable: false,
      // },

      // {
      //   text: "Package interval",
      //   key: "package_interval",
      //   sortable: false,
      // },
      {
        text: "Price",
        key: "price",
        sortable: false,
      },
      {
        text: "Type",
        key: "type",
        sortable: false,
      },

      // {
      //   text: "Payment method",
      //   key: "payment_method",
      //   sortable: false,
      // },
      {
        text: "Billing Date",
        key: "created_at",
        sortable: false,
      },
      // {
      //   text: "Start date",
      //   key: "start_date",
      //   sortable: false,
      // },
    
      // {
      //   text: "Last billing date",
      //   key: "last_billing_date",
      //   sortable: false,
      // },

      // {
      //   text: "Next Billing date",
      //   key: "expiry_date",
      //   sortable: false,
      // },

      {
        text: "Billing status",
        key: "billing_status",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.billing_status == "Active" && (
                <Badge bg="success"> {record.billing_status} </Badge>
              )}
              {record.billing_status == "Inactive" && (
                <Badge bg="danger"> {record.billing_status} </Badge>
              )}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 100,
      length_menu: false,
      show_filter: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.state = {
      sub_id: dcrypt(this.props.match.params.sub_id),
      user_id: dcrypt(this.props.match.params.user_id),
      package: [],
      info: [],
      history: [],
      totalTransactions: [],
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Billing",
      customDate: true,
      loader: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "", data) => {
    fetch(
      `${ApiUrl}` +
        "get/wl/subscription/history/" +
        this.state.sub_id +
        "/" +
        this.state.user_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            info: response.data.info,
            history: response.data.history,
            totalTransactions: response.data.totalTransactions,
            package: response.data.package_data,
            loader: true,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Row>
          <Col sm={6}></Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Loader loaded={this.state.loader} />
          <Col md={6} className="mb-0">
            <Card>
              <CardBody>
                <CardTitle className="h4"> User Info </CardTitle>
                <div className="table-responsive user-info">
                  <Table className="table table-bordered mb-0 border-0">
                    <tbody>
                      <tr>
                        <td> UserId </td> <td> {this.state.info.user_id} </td>
                      </tr>
                      <tr>
                        <td> Name </td> <td> {this.state.info.name} </td>
                      </tr>
                      <tr>
                        <td> Email </td> <td> {this.state.info.email} </td>
                      </tr>
                      <tr>
                        <td> Role </td>
                        <td>
                          {this.state.info.role
                            ? this.state.info.role.name
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td> Status </td> <td> {this.state.info.status} </td>
                      </tr>
                      <tr>
                        <td> Created At </td>
                        <td> {this.state.info.created} </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4">
                  {this.state.package.purpose == "addon"
                    ? "Addon Info"
                    : "Package Info"}
                </CardTitle>
                <div className="table-responsive user-info">
                  <Table className="table table-bordered mb-0 border-0">
                    <tbody>
                      <tr>
                        <td>
                          {this.state.package.purpose == "addon"
                            ? "Addon Name "
                            : "Package Name "}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.state.package.purpose == "addon"
                            ? this.state.package.title
                            : this.state.package.name}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          {this.state.package.purpose == "addon"
                            ? " Pricing Interval"
                            : "Package Interval"}{" "}
                        </td>
                        <td>
                          {" "}
                          {this.state.package.purpose == "addon"
                            ? this.state.package.pricing_interval
                            : this.state.package.package_interval}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          {this.state.package.purpose == "addon"
                            ? "Addon For"
                            : "Package For"}{" "}
                        </td>
                        <td> {this.state.package.package_for} </td>
                      </tr>
                      <tr>
                        <td> Recurring price </td>
                        <td> $ {this.state.package.price} </td>
                      </tr>

                      {this.state.package.purpose == "package" && (
                        <>
                          <tr>
                            <td> Setup Fee</td>
                            <td>
                              {" "}
                              ${" "}
                              {this.state.package.other_settings
                                ? this.state.package.setup_price
                                : 0}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td> Setup Fee Installment</td>
                            <td>
                              {" "}
                              {this.state.package.other_settings
                                ? this.state.package.setup_fee_installment
                                : 1}{" "}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pt-0">
                <ReactDatatable
                  key={this.state.id}
                  config={this.config}
                  records={this.state.history}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
