import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Tabs,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import PackagesSidebar from "../../Packages/packages-sidebar";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt, bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import swal from 'sweetalert';
export default class RevenueProducts extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      roles: [],
      total_pages: 0,
      record: {},
      productRecords: {},
      tab: "1",
      page_name: "Revenue Products",
      filter: {},
      customDate: true,
    };

    this.columns = [
      {
        text: "Product Id#",
        key: "product_id",
        sortable: true,
      },
      {
        text: "Title",
        key: "name",
        sortable: true,
      },
      {
        text: "Type",
        key: "type",
        sortable: true,
      },
      {
        text: "Price",
        key: "price",
        sortable: true,
      },
      {
        text: "Setup Fee",
        key: "setup_fee",
        sortable: true,
      },
      {
        text: "Created at",
        key: "created_at",
      },
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.status == "Active" && (
                <Badge bg="success">{record.status}</Badge>
              )}
              {record.status == "Inactive" && (
                <Badge bg="danger">{record.status}</Badge>
              )}
            </Fragment>
          );
        },
      },

      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-primary btn-sm mr-5"
                  to={"/settings/revenueProduct/edit/" + bcrypt(record.product_id)}
                >
                  <i className="mdi mdi-pencil"></i>
                </Link>
              )}
              {/* | &nbsp; */}
              {isAllowed(uInfo(), ["can_delete"]) && (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={this.deleteRecord.bind(this, record, index)}
                >
                  <i className="mdi mdi-delete"></i>
                </button>
              )}

            </Fragment>
          );
        },
      },
    ];


    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
    };
  }
  componentDidMount() {
    this.getProductData("", "");
  }


  handleSubmit = async (event, values) => {

    fetch(`${ApiUrl}` + "save/revneue/goals", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getProductData("", values);
  };
  getProductData = (queryString, data) => {
    fetch(`${ApiUrl}` + "revenueProduct/list" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            productRecords: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getProductData("", "");
  };
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getProductData("?" + queryString, this.state.filter);
  };
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/revenueProduct", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.product_id }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                this.getProductData("", "");
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col lg={2} className="pl-0">
            <PackagesSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Link
                    type="button"
                    color="info"
                    className="btn btn-info btn-sm"
                    to={"/settings/revenueProduct/add"}
                  >
                    <i className="mdi mdi-plus"> </i>
                    Create
                  </Link>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <Row>
              <Col xl="12" className="d-none">
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              name="date_range"
                              label="Date Filter"
                              onChange={(e) =>
                                e.target.value == "Custom Date"
                                  ? this.setState({
                                    customDate: false,
                                  })
                                  : this.setState({
                                    customDate: true,
                                  })
                              }
                            >
                              <option value=""> All Time </option>
                              <option value="Current Week">

                                Current Week
                              </option>
                              <option value="Last Week"> Last Week </option>
                              <option value="Current Month">

                                Current Month
                              </option>
                              <option value="Last Month"> Last Month </option>
                              <option value="Last 3 Months">

                                Last 3 Months
                              </option>
                              <option value="Last 6 Months">

                                Last 6 Months
                              </option>
                              <option value="Current Year">

                                Current Year
                              </option>
                              <option value="Last Year"> Last Year </option>
                              <option value="Custom Date"> Custom Date </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="start_date"
                              label="Start date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="end_date"
                              label="End date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              id="s"
                              name="proposal_status"
                              label="Proposal Status"
                            >
                              <option value={""}> Select Status </option>
                              <option value={"Active"}> Active </option>
                              <option value={"Inactive"}> Inactive </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <label className=""> {"Action"}</label>
                            <div className="button-items">
                              <Button
                                className="btn-sm"
                                color="primary"
                                type="submit"
                              >
                                Filter
                              </Button>
                              <Button
                                onClick={() => this.cancelFilter()}
                                outline
                                color="secondary"
                                className="waves-effect btn-sm"
                                type="button"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ReactDatatable
                      config={this.config}
                      records={this.state.productRecords}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
