import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
  convertDateFormat,
  NumberWithCommas,
} from "../../useToken";
import {
  ApiUrl,
  AttachementsUrl,
  ProductName,
  logoLightPng,
  ToastAutoClose,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import Loader from "react-loader";
import PrivateProposal from "../Authentication/PrivateProposal";
import PaymentComponent from "../../components/Custom/PaymentComponent";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { currencies } from "../../common/Common-Currency.js";
import AddonPurchase from "./AddonPurchase.js";

export default class PaymentForm extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.state = {
      addnewcardmodal: false,
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_id: query.get("page") ? query.get("page") : 0,
      cat_id: query.get("cat") ? dcrypt(query.get("cat")) : null,
      page_name: "Invoice Preview",
      from: this.props.from ? this.props.from : "",
      record: {},
      clients: [],

      coachPartners: [],
      customFields: [{ items: "", quantity: "0", price: "0", total: "0" }],
      formData: {
        start_date: currentDateTime(),
        expire_date: "",
        type: "one_time",
        invoice_for: "Member",
        renewal_interval: "Weekly",
        items_data: [],
        email_subject: "",
        notes: "",
        confirmation_email: "",
        created_for_id: 0,
        activity: "0",
        invoice_id: 0,
        logo: "",
      },
      filter_value: "",
      createdFor: {},
      loaded: true,
      pay_now: "Yes",
      cureent_payment_method_id: 0,
      cureent_payment_method: "",
      card_details: [],
      publishableKey: null,
      currency: "",
      currency_code: "",
    };
    this.childRef = React.createRef();
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    window.onafterprint = function () {
      window.location.reload();
    };
    this.isProposalAccepted("No");
    this.getcarddetails();
  }
  getcarddetails = () => {
    fetch(`${ApiUrl}` + "user/getcarddetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            card_details: response.data.carddetails.data.data,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  isProposalAccepted = (status) => {
    this.setState({
      pay_now: status,
    });
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.getInvoiceCurrency(response.data.record.currency_code);
          //console.log(response.data.record.invoice_for, 'invoice_forinvoice_for')
          this.setState({
            formData: response.data.record,
            customFields: JSON.parse(response.data.record.items_data),
            createdFor: response.data.record.createdFor,
            pay_now: response.data.record.proposal_id > 0 ? "No" : "Yes",
            publishableKey: response.data.record.stripe_key,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getInvoiceCurrency = (code) => {
    this.setState({ currency_code: code });
    let currencysymbol = currencies[code];
    if (currencysymbol) {
      this.setState({ currency: currencysymbol.symbol });
    }
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.invoice_id = this.state.id ? this.state.id : -1;
    }
    if (this.state.cat_id) {
      values.cat_id = this.state.cat_id ? this.state.cat_id : null;
    }

    if (this.childRef.current) {
      const result = await this.childRef.current.handlePayment(event);
      if (result.error == 1) {
        toast(result.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: "toast-custom-cls error",
        });
        return;
      } else {
        values.token = result.token;
        values.cardnumber = "";
        values.cvc = "";
        values.expirationdateMM = "";
        values.expirationdateYYYY = "";
      }
    }
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "pay/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          if (
            this.state.master_type == "addon" &&
            this.state.invoice_for == "Coach"
          ) {
            const tokenString = localStorage.getItem("authUser");
            const userToken = JSON.parse(tokenString);
            let purchased_addons = userToken.purchased_addons || "";

            if (purchased_addons) {
              let addonsArray = String(purchased_addons).split(",").map(Number);
              if (!addonsArray.includes(this.state.cat_id)) {
                addonsArray.push(this.state.cat_id);
              }
              purchased_addons = addonsArray.join(",");
            } else {
              purchased_addons = this.state.cat_id;
            }

            userToken.purchased_addons = purchased_addons;

            let active_purchased_addons =
              userToken.active_purchased_addons || "";
            if (active_purchased_addons) {
              active_purchased_addons = `${active_purchased_addons},${this.state.cat_id}`;
            } else {
              active_purchased_addons = this.state.cat_id;
            }
            userToken.active_purchased_addons = active_purchased_addons;

            if (this.state.cat_id === 4) {
              userToken.coach_can_sell_trainings = "active_paid";
            }
            localStorage.setItem("authUser", JSON.stringify(userToken));
          }

          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
          if (this.state.cat_id) {
            this.props.history.goBack();
          } else {
            this.props.history.push("/client/profile");
          }
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  addnewcard = async (event, values) => {
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "add/coach/Paymentmethod", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.setState({
            addnewcardmodal: false,
            cureent_payment_method_id: "",
            cureent_payment_method: "",
          });
          this.getcarddetails();
          this.form && this.form.reset();
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  defaultUI() {
    return this.state.customFields.map(
      (el, i) =>
        el.total > 0 && (
          // <div className="border px-1 py-1" key={i}>
          //   <div className="row">
          //     <div className="col-sm-6 col-md-6 mb-3">{el.items}</div>
          //     {/* <div className="col-sm-3 col-md-3 mb-3">{el.quantity}</div>
          //   <div className="col-sm-3 col-md-3 mb-3"> {el.price}</div> */}
          //     <div className="col-sm-6 col-md-6">{this.state.currency}{NumberWithCommas(Math.round(el.total))}</div>
          //   </div>
          // </div>

          <div className="border-top-2 border-bottom-2 px-1 py-1" key={i}>
            <div className="row">
              <div className="col-sm-6 col-md-6 d-flex align-items-center">
                {el.items}
              </div>
              <div className="col-sm-6 col-md-6 d-flex align-items-center">
                {this.state.currency}
                {NumberWithCommas(Math.round(el.total))}
              </div>
            </div>
          </div>
        )
    );
  }
  render() {
    const stripePromise = this.state.publishableKey
      ? loadStripe(this.state.publishableKey)
      : null;

    // if (
    //   this.state.formData.master_type == "addon" &&
    //   this.state.formData.invoice_for == "Coach"
    // ) {
    //   return <AddonPurchase />;
    // }

    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        {/* <div className="row">
          <div className="col-md-8 offset-2">
            <div className="pb-10 pull-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
                className="mr-3"
              >
                Back
              </Button>
            </div>
          </div>
        </div> */}
        <div className="ediditor-proposl-view">
          {this.state.formData.proposal_id > 0 && (
            <Row>
              <Col className="col-12 ediditor-proposl-view">
                <PrivateProposal
                  client_id={this.state.formData.created_for_id}
                  user_id={this.state.formData.coach_id}
                  isProposalAccepted={this.isProposalAccepted}
                  invoice_id={this.state.formData.invoice_id}
                  proposal_id={this.state.formData.proposal_id}
                  page_id={this.state.page_id}
                  currency={this.state.currency_code}
                />
              </Col>
            </Row>
          )}
          {this.state.pay_now == "Yes" && (
            <Row>
              <Col className="col-12">
                <Card>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <div className="row">
                      <div className="col-md-8 col-sm-12 offset-2">
                        <Card id="print-data">
                          <CardBody>
                            <div className="invoice-title d-flex space-between">
                              <h3 className="mt-0">
                                <img
                                  src={this.state.formData.logo}
                                  alt="logo"
                                  height="50"
                                />
                              </h3>
                              <div className="font-size-16">
                                <p>
                                  {convertDateFormat(
                                    this.state.formData.start_date
                                  )}
                                </p>
                                <p>
                                  #IN-
                                  {String(
                                    this.state.formData.invoice_id
                                  ).padStart(5, "0")}
                                </p>
                                <p>
                                  {" "}
                                  {this.state.formData.type.replace(
                                    "_",
                                    " "
                                  )}{" "}
                                  {this.state.formData.type == "recurring" &&
                                    this.state.formData.renewal_interval}
                                </p>
                              </div>
                            </div>
                            <hr />
                            <Row>
                              <Col xs="6" className="mt-4">
                                <div className="invoice-meta">
                                  <p className="for">To</p>
                                  {this.state.formData.invoice_for ==
                                  "Client" ? (
                                    <div className="invoice-data">
                                      <h6>
                                        {this.state.createdFor.first_name}{" "}
                                        {this.state.createdFor.last_name}
                                      </h6>
                                      <p className="address">
                                        {this.state.createdFor.address}
                                      </p>
                                      <p className="mobile">
                                        {this.state.createdFor.mobile}
                                      </p>
                                      <p className="email">
                                        {this.state.createdFor.email}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="invoice-data">
                                      <h6>{this.state.createdFor.name}</h6>
                                      <p className="address">
                                        {this.state.createdFor.address}
                                      </p>
                                      <p className="email">
                                        {this.state.createdFor.email}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs="6" className="mt-4">
                                <div className="invoice-meta">
                                  <p className="for">From</p>
                                  <div className="invoice-data">
                                    <h6>
                                      {this.state.formData?.createdBy?.name}
                                    </h6>
                                    <p className="email">
                                      {this.state.formData?.createdBy?.email}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="row mt-4">
                              <div className="col-sm-6 col-md-6 mb-6">
                                {" "}
                                <b>Product</b>{" "}
                              </div>
                              {/* <div className="col-sm-3 col-md-3 mb-3">Quantity</div>
                              <div className="col-sm-3 col-md-3 mb-3">
                                Unit Price($)
                              </div> */}
                              <div className="col-sm-6 col-md-6 mb-3">
                                {" "}
                                <b>Amount</b>
                              </div>
                            </div>
                            {this.defaultUI()}
                            <div className="row">
                              <div className="col-sm-6 col-md-6 mb-3"></div>

                              <div className="col-sm-6 col-md-6 mb-3">
                                <b>
                                  Total Amount Due: {this.state.currency}
                                  {NumberWithCommas(
                                    Math.round(this.state.formData.total_price)
                                  )}
                                </b>
                              </div>
                            </div>
                            <ModalBody className="row">
                              <CardTitle className="text-center">
                                <b>Pay Invoice</b>
                              </CardTitle>
                              {this.state.formData.invoice_for !=
                              "ConvertCoach" ? (
                                <Row
                                  className={`${
                                    this.state.formData.total_price < 1 &&
                                    "d-none"
                                  }`}
                                >
                                  <Col className={`col-12 mb-3 `}>
                                    <label className="control-label">
                                      Select Credit Card
                                    </label>

                                    <div className="card-list">
                                      <Select
                                        value={
                                          this.state.cureent_payment_method
                                        }
                                        getOptionLabel={(option) =>
                                          "****" +
                                          option.last4 +
                                          " - " +
                                          option.exp_month +
                                          "/" +
                                          option.exp_year
                                        }
                                        getOptionValue={(option) => option.id}
                                        isMulti={false}
                                        options={this.state.card_details}
                                        classNamePrefix="select2-selection"
                                        name="card_ids"
                                        onChange={(e) => {
                                          this.setState({
                                            cureent_payment_method_id: e.id,
                                            cureent_payment_method: e,
                                          });
                                        }}
                                        required
                                      />
                                    </div>
                                    <AvInput
                                      type="hidden"
                                      name="card_id"
                                      value={
                                        this.state.cureent_payment_method_id
                                      }
                                    />
                                  </Col>
                                  <Col lg="12">
                                    <button
                                      onClick={() =>
                                        this.setState({
                                          addnewcardmodal: true,
                                        })
                                      }
                                      type="button"
                                      className="btn btn-link p0 btn-sm"
                                    >
                                      <i className="fas fa-plus"></i> Add New
                                      Payment Method
                                    </button>
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  <div className="col-md-12 mb-3">
                                    {stripePromise && (
                                      <Elements stripe={stripePromise}>
                                        <PaymentComponent ref={this.childRef} />
                                      </Elements>
                                    )}
                                  </div>
                                  {/* <div className="col-md-6 mb-3">
                                    <label className="control-label">
                                      {" "}
                                      Card Number{" "}
                                    </label>
                                    <AvField
                                      name="cardnumber"
                                      className="form-control"
                                      type="number"
                                      Placeholder="Enter Card Number"
                                      value={this.state.record.cardnumber}
                                      required
                                    />
                                  </div> */}
                                  {/* <div className="col-md-6 mb-3">
                                    <label className="control-label"> CVC</label>
                                    <AvField
                                      name="cvc"
                                      className="form-control"
                                      type="number"
                                      Placeholder="CVC"
                                      value={this.state.record.cvc}
                                      required
                                    />
                                  </div> */}
                                  {/* <div className="col-md-6 mb-3">
                                    <AvField
                                      name="expirationdateMM"
                                      label="MM"
                                      className="form-control"
                                      type="number"
                                      Placeholder="MM"
                                      value={this.state.record.expirationdateMM}
                                      required
                                    />
                                  </div> */}
                                  {/* <div className="col-md-6 mb-3">
                                    <AvField
                                      name="expirationdateYYYY"
                                      label="YYYY"
                                      className="form-control"
                                      type="number"
                                      Placeholder="YYYY"
                                      value={this.state.record.expirationdateYYYY}
                                      required
                                    />
                                  </div> */}
                                  <div className="col-md-6 mb-3">
                                    <AvField
                                      name="address_line1"
                                      label="Billing Address"
                                      className="form-control"
                                      type="text"
                                      Placeholder="Billing Addresss"
                                      value={this.state.record.address_line1}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <AvField
                                      name="address_city"
                                      label="Billing City"
                                      className="form-control"
                                      type="text"
                                      Placeholder="Billing City"
                                      value={this.state.record.address_city}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <AvField
                                      name="address_state"
                                      label="Billing State"
                                      className="form-control"
                                      type="text"
                                      Placeholder="Billing State"
                                      value={this.state.record.address_state}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <AvField
                                      name="address_zip"
                                      label="Billing Zip"
                                      className="form-control"
                                      type="text"
                                      Placeholder="Billing Zip"
                                      value={this.state.record.address_zip}
                                    />
                                  </div>
                                </>
                              )}
                            </ModalBody>
                            <Loader
                              loaded={this.state.loaded}
                              className="spinner"
                            >
                              <ModalFooter>
                                <Button
                                  className="btn-sm"
                                  type="button"
                                  color="secondary"
                                  onClick={() => this.props.history.goBack()}
                                >
                                  Cancel
                                </Button>
                                <button
                                  className="btn btn-primary waves-effect waves-light btn-sm"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </ModalFooter>
                            </Loader>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </AvForm>
                </Card>
              </Col>
            </Row>
          )}
        </div>
        <Modal
          isOpen={this.state.addnewcardmodal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              addnewcardmodal: false,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  addnewcardmodal: false,
                });
              }}
              className="text-transform-capitalize"
            >
              Add New Card
            </ModalHeader>
            <AvForm
              onValidSubmit={this.addnewcard}
              className="needs-validation"
            >
              <ModalBody>
                <Row className="mt-3">
                  <Col className="col-6">
                    <label className="control-label">Card Number</label>
                    <AvField
                      name="cardnumber"
                      className="form-control"
                      type="text"
                      placeholder="Enter Card Number"
                      required
                      maxLength={16}
                      validate={{
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                  <Col className="col-6">
                    <label className="control-label"> CVC</label>
                    <AvField
                      name="cvc"
                      className="form-control"
                      type="text"
                      placeholder="CVC"
                      required
                      maxLength={4}
                      validate={{
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="col-6">
                    <AvField
                      name="expirationdateMM"
                      label="MM"
                      className="form-control"
                      type="text"
                      placeholder="MM"
                      maxLength={2}
                      required
                      validate={{
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                  <Col className="col-6">
                    <AvField
                      name="expirationdateYYYY"
                      label="YYYY"
                      className="form-control"
                      type="text"
                      placeholder="YYYY"
                      required
                      maxLength={4}
                      validate={{
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <Loader loaded={this.state.loaded} className="spinner">
                <ModalFooter>
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        addnewcardmodal: false,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Save
                  </button>
                </ModalFooter>
              </Loader>
            </AvForm>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
