import React, { Component, Fragment } from "react";
import Loader from "react-loader";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Table,
  CardHeader,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdownaction from "./Dropdown";
import Tooltip from "@material-ui/core/Tooltip";
//Import Action to copy breadcrumb items from local state to redux state
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
  NumberWithCommas,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  ProductName,
  ToastAutoClose,
  AttachementsUrl,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "./profile-sidebar";
import swal from "sweetalert";
import AddonsSubscription from "./AddonsSubscription";

export default class Billing extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "action",
        text: "Action",
        style: { overflow: "visible" },
        cell: (record) => {
          return (
            <Dropdownaction
              userid={record.user_id}
              props={record.subscription_id}
              trans_id={record.transaction_id}
            />
          );
        },
      },

      {
        text: "Package",
        key: "package_name",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.package_name} <sub> / Per {record.package_interval}</sub>
            </Fragment>
          );
        },
      },

      {
        text: "Payment",
        key: "payment_method",
        sortable: false,
      },
      {
        text: "Price",
        key: "price",
        sortable: false,
      },
      {
        text: "Setup Price",
        key: "setup_price",
        sortable: false,
      },
      {
        text: "Start date",
        key: "start_date",
        sortable: false,
      },
      
      {
        text: "Recent billing date",
        key: "last_billing_date",
        sortable: false,
      },
      {
        text: "Next Billing date",
        key: "expiry_date",
        sortable: false,
      },
      {
        text: "type",
        key: "subscription_type",
        sortable: false,
        cell: (record, index) => {
          if (record.subscription_type == "Paid") {
            return (
              <p key={index} className="badge bg-success">
                {record.subscription_type}
              </p>
            );
          } else if (record.subscription_type == "Unpaid") {
            return (
              <p key={index} className="badge bg-danger">
                {record.subscription_type}
              </p>
            );
          } else {
            return <p key={index}>{record.subscription_type}</p>;
          }
        },
      },
      // {
      //   text: "Billing For",
      //   key: "billing_for",
      //   sortable: false,
      // },
      // {
      //   text: "Cycle Completed",
      //   key: "cycle_completed",
      //   sortable: false,
      // },
      {
        text: "billing status",
        key: "billing_status",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.billing_status == "Active" && (
                <Badge bg="success"> {record.billing_status} </Badge>
              )}
              {record.billing_status == "Inactive" && (
                <Badge bg="danger"> {record.billing_status} </Badge>
              )}
            </Fragment>
          );
        },
      },
    ];
    this.approvedcolumns = [
      {
        text: "SR#",
        key: "sr",
      },
      {
        text: "Title",
        key: "title",
      },
      {
        text: "Ip Address",
        key: "ip_address",
      },
      {
        text: "Type",
        key: "p_type",
      },
      {
        text: "Dated",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <Link
                key={index}
                to={"/acceptedproposal/view/" + bcrypt(record.id)}
                className="waves-effect btn btn-light w-auto px-0 btn-sm"
              >
                <i className="fas fa-eye pr-5"> </i>
                View
              </Link>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 100,
      length_menu: [],
      show_filter: false,
      show_pagination: false,
      show_length_menu: false,
      show_info: false,

      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.approved_config = {
      key_column: "sr",
      page_size: 100,
      length_menu: false,
      show_filter: false,
      show_pagination: false,

      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };

    this.state = {
      index: { btnInfo1: false },
      records: [],
      approvedproposalrecords: [],
      approved_total_pages: {},
      roles: [],
      total_pages: 0,
      modal: false,
      updatemodal: false,
      record: {},
      filter: {},
      page_name: "Billing",
      customDate: true,
      activeTab: "1",
      packageactiveTab: "1",
      packages: [],
      payment_display: "none",
      loaded: true,
      addnewcardmodal: false,
      currentPackage: {
        package_data: {},
      },
      card_to_update: "",
      card_to_make_default: "",
      card_to_delete: "",
      carddetails: [],
      customerID: "",
      default_source: "",
      invoicedetails: [],
      licence_package_record: [],
      current_licence_package: "",
      licence_package_name: "",
      licence_package_id: "",
      user_settings: {},
      premium_licenses_log: [],
      cureent_payment_method_id: 0,
      cureent_payment_method: "",
    };
  }
  setBtnInfo1(index) {
    this.setState({
      index: { btnInfo1: true },
    });
  }

  componentDidMount() {
    this.getpackageData();
    this.getbillingData();
    this.getcarddetails();
    this.getinvoicedetails();
    this.getapprovedproposalrecords();
  }

  makedefault = async (id) => {
    this.setState({ loaded: false });
    this.setState({
      card_to_make_default: id,
    });

    fetch(`${ApiUrl}` + "make/card/default", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ card_to_make_default: id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.getcarddetails();
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  deletecard = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You want removed this card",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        this.setState({
          card_to_delete: id,
        });
        this.setState({ loaded: false });
        fetch(`${ApiUrl}` + "make/card/delete", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({ card_to_delete: id }),
        })
          .then((response) => response.json())
          //Then with the data from the response in JSON...
          .then((data) => {
            this.setState({ loaded: true });
            if (data.status === true) {
              this.getcarddetails();
            } else {
              toast(data.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          //Then with the error genereted...
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };
  addnewcard = async (event, values) => {
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "add/coach/newPaymentmethod", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.setState({
            addnewcardmodal: false,
            cureent_payment_method_id: "",
            cureent_payment_method: "",
          });
          this.getcarddetails();
          this.form && this.form.reset();
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  addcarddetails = async (event, values) => {
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "add/coach/Paymentmethod", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        this.setState({ loaded: true });
        if (response.status === true) {
          this.form && this.form.reset();
          this.getcarddetails();
          this.setState({
            modal: false,
          });
          this.setState({
            customerID: response.data.result.customer_id,
          });
          this.setState({ activeTab: "2" });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  updatecreditcard = async (event, values) => {
    values.card_id = this.state.card_to_update;
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "update/coach/Paymentmethod", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.form && this.form.reset();
          this.getcarddetails();
          this.setState({
            updatemodal: false,
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getcarddetails = () => {
    fetch(`${ApiUrl}` + "user/getcarddetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            customerID: response.customer_id,
            carddetails: response.data.carddetails.data.data,
            default_source:
              response.data.carddetails.customerdetails.default_source,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getinvoicedetails = () => {
    fetch(`${ApiUrl}` + "user/getinvoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            invoicedetails: response.data.invoicedetails,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  changeSubscription = (package_id, subscription_id) => {
    if (this.state.customerID == "") {
      swal({
        title: "Required Card!",
        text: "Please add your card details to upgrade to the selected package.",
        icon: "error",
      });
      this.setState({
        modal: true,
      });
      return;
    }
    swal({
      title: "Are you sure?",
      text: "You want to perform this action",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        this.setState({ loaded: false });
        fetch(`${ApiUrl}` + "user/change/subscription", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({
            package_id: package_id,
            current_subscription: subscription_id,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            this.setState({ loaded: true });
            if (response.status === true) {
              toast(response.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls success",
              });

              setTimeout(
                () => (window.location.href = "/settings/billing"),
                500
              );
            } else {
              toast(response.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    });
  };

  getpackageData = () => {
    fetch(`${ApiUrl}` + "user/subscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            currentPackage: response.data.current_subscription,
            packages: response.data.packages,
            user_settings: response.data.user_settings,
            premium_licenses_log: response.data.premium_licenses_log,
            licence_package_name:
              response.data.packages.length > 3
                ? response.data.packages[3].name
                : "",
            licence_package_record: response.data.premium_license,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getbillingData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/user/subscription/list", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getapprovedproposalrecords = () => {
    fetch(`${ApiUrl}` + "get/user/approved/proposal", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            approved_total_pages: response.data.total,
            approvedproposalrecords: response.data.records,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };
  handleSubmit = async (event, values) => {
    if (
      this.state.licence_package_id == "" ||
      this.state.licence_package_id == null
    ) {
      swal({
        title: "Required Fields!",
        text: "Select additional coach license package from list",
        icon: "warning",
      });
      return;
    }

    if (
      this.state.cureent_payment_method_id == "" ||
      this.state.cureent_payment_method_id == 0
    ) {
      swal({
        title: "Required Fields!",
        text: "To proceed with your payment, please select your card",
        icon: "warning",
      });
      return;
    }

    if (this.state.licence_package_id) {
      values.id = this.state.licence_package_id
        ? this.state.licence_package_id
        : -1;
    }

    // return;

    // if (this.state.currentPackage.package_id) {
    //   values.master_package_id = this.state.currentPackage.package_id
    //     ? this.state.currentPackage.package_id
    //     : -1;
    // }
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "purchase/additional/licence", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ loaded: true });
        if (data.status === true) {
          this.setState({
            current_licence_package: "",
            cureent_payment_method: "",
            cureent_payment_method_id: 0,
            licence_package_id: "",
          });
          this.getpackageData();
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls success",
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    let currentDescription = this.state.currentPackage.package_data.description
      ? JSON.parse(this.state.currentPackage.package_data.description)
      : "{}";
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Loader loaded={this.state.loaded} className="spinner"></Loader>
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <Card className="mt-2 mb-2">
              <CardBody className="py-0">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 1 ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "1" })}
                    >
                      UPGRADE PLAN
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 2 ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "2" })}
                    >
                      CARD
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 3 ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "3" })}
                    >
                      BILLING HISTORY
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 4 ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "4" })}
                    >
                      ACCEPTED PROPOSALS
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={this.state.activeTab == 5 ? "active" : ""}
                      onClick={() => this.setState({ activeTab: "5" })}
                    >
                      ADDONS SUBSCRIPTION
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </CardBody>
            </Card>
            <TabContent className="p-0" activeTab={this.state.activeTab}>
              <TabPane tabId={"1"}>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col>
                        <div className="card mb-5 mb-lg-0 my-2">
                          <div className="card-body pt-0">
                            <TabContent
                              className="p-0"
                              activeTab={this.state.packageactiveTab}
                            >
                              <TabPane tabId="1">
                                {this.state.currentPackage.package_data
                                  .package_type == "custom" ? (
                                  <Col md={12} key={1}>
                                    <div className="card mb-5 mb-lg-0 my-2 justify-content-center">
                                      <div className="card-body package">
                                        <h5 className="card-title text-muted text-uppercase text-center">
                                          {
                                            this.state.currentPackage
                                              .package_data.name
                                          }
                                        </h5>
                                        <h6 className="card-price text-center">
                                          $
                                          {
                                            this.state.currentPackage
                                              .package_data.price
                                          }
                                          <span className="period">
                                            /
                                            {
                                              this.state.currentPackage
                                                .package_data.package_interval
                                            }
                                          </span>
                                        </h6>
                                        <hr />
                                        <ul className="fa-ul">
                                          {currentDescription &&
                                            currentDescription.map(
                                              (desc, i) => {
                                                return (
                                                  <li
                                                    key={i}
                                                    className="border-bottom py-2"
                                                  >
                                                    <span className="fa-li">
                                                      <i className="fas fa-check"></i>
                                                    </span>
                                                    {desc.description_meta}
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>
                                        {this.state.currentPackage.package_data
                                          .setup_fee_installment > 0 && (
                                          <div
                                            class="alert alert-info"
                                            role="alert"
                                          >
                                            <h6 class="alert-heading">
                                              This package's setup price has
                                              been divided into{" "}
                                              {
                                                this.state.currentPackage
                                                  .package_data
                                                  .setup_fee_installment
                                              }{" "}
                                              installments, each costing $
                                              {NumberWithCommas(
                                                Math.round(
                                                  this.state.currentPackage
                                                    .package_data.setup_price /
                                                    this.state.currentPackage
                                                      .package_data
                                                      .setup_fee_installment
                                                )
                                              )}{" "}
                                              per month
                                            </h6>
                                            <hr />
                                            <p>
                                              After{" "}
                                              {
                                                this.state.currentPackage
                                                  .package_data
                                                  .setup_fee_installment
                                              }{" "}
                                              months, payment will be deducted
                                              based on the terms of your
                                              package, either on a monthly or
                                              yearly basis.
                                            </p>
                                          </div>
                                        )}
                                        <div className="d-grid">
                                          <button
                                            disabled={true}
                                            className="btn btn-primary text-uppercase btn-sm"
                                          >
                                            Current Package
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  <Row>
                                    {this.state.packages.map((row, i) => {
                                      if (row.package_interval == "Monthly") {
                                        let description = JSON.parse(
                                          row.description
                                        );

                                        return (
                                          <Col md={3} key={i}>
                                            <div className="card mb-5 mb-lg-0 my-2">
                                              <div className="card-body package">
                                                <h5 className="card-title text-muted text-uppercase text-center">
                                                  {row.name}
                                                </h5>
                                                <h6 className="card-price text-center">
                                                  ${row.price}
                                                  <span className="period">
                                                    /{row.package_interval}
                                                  </span>
                                                </h6>
                                                <hr />
                                                <ul className="fa-ul">
                                                  {description.map(
                                                    (desc, i) => {
                                                      return (
                                                        <li
                                                          key={i}
                                                          className="border-bottom py-2"
                                                        >
                                                          <span className="fa-li">
                                                            <i className="fas fa-check"></i>
                                                          </span>
                                                          {
                                                            desc.description_meta
                                                          }
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                                </ul>
                                                <div className="d-grid">
                                                  {Number(
                                                    this.state.currentPackage
                                                      .price
                                                  ) >= Number(row.price) &&
                                                    this.state.currentPackage
                                                      .payment_method ==
                                                      "stripe" && (
                                                      <button
                                                        onClick={() =>
                                                          this.changeSubscription(
                                                            row.package_id,
                                                            this.state
                                                              .currentPackage
                                                              .subscription_id
                                                          )
                                                        }
                                                        className="btn btn-primary text-uppercase btn-sm"
                                                        disabled
                                                      >
                                                        {this.state
                                                          .currentPackage
                                                          .package_data
                                                          .package_id ==
                                                        row.package_id
                                                          ? "Current Package"
                                                          : " -- "}
                                                      </button>
                                                    )}
                                                  {Number(
                                                    this.state.currentPackage
                                                      .price
                                                  ) < Number(row.price) &&
                                                    this.state.currentPackage
                                                      .payment_method ==
                                                      "stripe" && (
                                                      <button
                                                        onClick={() =>
                                                          this.changeSubscription(
                                                            row.package_id,
                                                            this.state
                                                              .currentPackage
                                                              .subscription_id
                                                          )
                                                        }
                                                        disabled={
                                                          this.state
                                                            .currentPackage
                                                            .package_data
                                                            .package_type ==
                                                          "custom"
                                                            ? true
                                                            : row.package_id <=
                                                              2
                                                            ? this.state
                                                                .currentPackage
                                                                .package_data
                                                                .package_id ==
                                                              row.package_id
                                                              ? true
                                                              : false
                                                            : true
                                                        }
                                                        className="btn btn-primary text-uppercase code-green border-0 btn-sm"
                                                      >
                                                        {this.state
                                                          .currentPackage
                                                          .package_data
                                                          .package_id ==
                                                        row.package_id
                                                          ? "Current Package"
                                                          : " --"}{" "}
                                                        <i className="fas fa-level-up-alt"></i>
                                                      </button>
                                                    )}
                                                  {this.state.currentPackage
                                                    .payment_method !==
                                                    "stripe" && (
                                                    <button
                                                      onClick={() =>
                                                        this.changeSubscription(
                                                          row.package_id,
                                                          this.state
                                                            .currentPackage
                                                            .subscription_id
                                                        )
                                                      }
                                                      disabled={
                                                        (Number(
                                                          this.state
                                                            .currentPackage
                                                            .price
                                                        ) > Number(row.price)
                                                          ? true
                                                          : false) ||
                                                        (row.package_id <= 2
                                                          ? false
                                                          : true) ||
                                                        (this.state
                                                          .currentPackage
                                                          .package_data
                                                          .package_id ==
                                                        row.package_id
                                                          ? true
                                                          : false)
                                                      }
                                                      className="btn btn-primary text-uppercase btn-sm"
                                                    >
                                                      {this.state.currentPackage
                                                        .package_data
                                                        .package_id ==
                                                        row.package_id &&
                                                        "Current Package"}

                                                      {Number(
                                                        this.state
                                                          .currentPackage.price
                                                      ) > Number(row.price) &&
                                                        this.state
                                                          .currentPackage
                                                          .package_data
                                                          .package_id !=
                                                          row.package_id &&
                                                        "--"}

                                                      {Number(
                                                        this.state
                                                          .currentPackage.price
                                                      ) < Number(row.price) &&
                                                        this.state
                                                          .currentPackage
                                                          .package_data
                                                          .package_id !=
                                                          row.package_id &&
                                                        "Upgrade"}

                                                      {Number(
                                                        this.state
                                                          .currentPackage.price
                                                      ) == Number(row.price) &&
                                                        this.state
                                                          .currentPackage
                                                          .package_data
                                                          .package_id !=
                                                          row.package_id &&
                                                        "--"}
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        );
                                      }
                                    })}
                                  </Row>
                                )}

                                {/* ADDITIONAL COACH LICENSES DETAIL */}
                                {((this.state.currentPackage.package_data
                                  .has_coach_license == "Yes" &&
                                  this.state.currentPackage.package_data
                                    .package_id == 4) ||
                                  this.state.premium_licenses_log.length >
                                    0) && (
                                  <Row className=" mt-3">
                                    <Col className="col-12">
                                      <Card>
                                        {this.state.currentPackage.package_data
                                          .has_coach_license == "Yes" &&
                                          this.state.currentPackage.package_data
                                            .package_id == 4 && (
                                            <CardTitle>
                                              {" "}
                                              <div className="page-title-box">
                                                <h4>
                                                  {" "}
                                                  Buy Additional Coach License{" "}
                                                </h4>
                                              </div>{" "}
                                            </CardTitle>
                                          )}
                                        <AvForm
                                          onValidSubmit={this.handleSubmit}
                                          ref={(c) => (this.form = c)}
                                          className="needs-validation"
                                        >
                                          <ModalBody>
                                            <Row>
                                              {this.state.currentPackage
                                                .package_data
                                                .has_coach_license == "Yes" &&
                                                this.state.currentPackage
                                                  .package_data.package_id ==
                                                  4 && (
                                                  <Col className="col-8">
                                                    <Row>
                                                      <Col className="col-12 mb-3">
                                                        <label className="control-label">
                                                          Select Package
                                                        </label>
                                                        <div className="package-list">
                                                          <Select
                                                            value={
                                                              this.state
                                                                .current_licence_package
                                                            }
                                                            getOptionLabel={(
                                                              option
                                                            ) =>
                                                              option.title +
                                                              " - License Limit:" +
                                                              option.licence_limit +
                                                              " - Per License Recurring Fee: $" +
                                                              option.price +
                                                              " - One Time Fee: $" +
                                                              option.setup_fee
                                                            }
                                                            getOptionValue={(
                                                              option
                                                            ) => option.id}
                                                            isMulti={false}
                                                            options={
                                                              this.state
                                                                .licence_package_record
                                                            }
                                                            classNamePrefix="select2-selection"
                                                            name="package_id"
                                                            onChange={(e) => {
                                                              this.setState({
                                                                licence_package_id:
                                                                  e.id,
                                                                current_licence_package:
                                                                  e,
                                                                payment_display:
                                                                  "block",
                                                              });
                                                            }}
                                                            required
                                                          />
                                                        </div>
                                                      </Col>
                                                    </Row>

                                                    <Row>
                                                      <Col className="col-12 mb-3">
                                                        <label className="control-label">
                                                          Select Card
                                                        </label>

                                                        <div className="card-list">
                                                          <Select
                                                            value={
                                                              this.state
                                                                .cureent_payment_method
                                                            }
                                                            getOptionLabel={(
                                                              option
                                                            ) =>
                                                              "****" +
                                                              option.last4 +
                                                              " - " +
                                                              option.exp_month +
                                                              "/" +
                                                              option.exp_year
                                                            }
                                                            getOptionValue={(
                                                              option
                                                            ) => option.id}
                                                            isMulti={false}
                                                            options={
                                                              this.state
                                                                .carddetails
                                                            }
                                                            classNamePrefix="select2-selection"
                                                            name="card_ids"
                                                            onChange={(e) => {
                                                              this.setState({
                                                                cureent_payment_method_id:
                                                                  e.id,
                                                                cureent_payment_method:
                                                                  e,
                                                              });
                                                            }}
                                                            required
                                                          />
                                                        </div>
                                                        <AvInput
                                                          type="hidden"
                                                          name="card_id"
                                                          value={
                                                            this.state
                                                              .cureent_payment_method_id
                                                          }
                                                        />
                                                      </Col>
                                                      <Col lg="12">
                                                        <button
                                                          onClick={() =>
                                                            this.setState({
                                                              addnewcardmodal: true,
                                                            })
                                                          }
                                                          type="button"
                                                          className="btn btn-link p0 btn-sm"
                                                        >
                                                          <i className="fas fa-plus"></i>{" "}
                                                          Add New Payment Method
                                                        </button>
                                                      </Col>
                                                    </Row>

                                                    {/* <Row className="mt-3">
                                                  <Col className="col-6">
                                                    <AvField
                                                      name="cardnumber"
                                                      label="Card Number"
                                                      className="form-control"
                                                      placeholder="Enter Card Number"
                                                      value=""
                                                      required={true}
                                                      maxLength={16}
                                                      validate={{
                                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                                      }}
                                                    />
                                                  </Col>
                                                  <Col className="col-6">
                                                    <label className="control-label"> CVC</label>
                                                    <AvField
                                                      name="cvc"
                                                      className="form-control"
                                                      placeholder="CVC"
                                                      value=""
                                                      required={true}
                                                      maxLength={4}
                                                      validate={{
                                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                                      }}
                                                    />
                                                  </Col>



                                                  <Col className="col-6">
                                                    <AvField
                                                      name="expirationdateMM"
                                                      label="MM"
                                                      className="form-control"
                                                      placeholder="MM"
                                                      value=""
                                                      required={true}
                                                      maxLength={2}
                                                      validate={{
                                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col className="col-6">
                                                    <AvField
                                                      name="expirationdateYYYY"
                                                      label="YYYY"
                                                      className="form-control"
                                                      placeholder="YYYY"
                                                      value=""
                                                      required={true}
                                                      maxLength={4}
                                                      validate={{
                                                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                                      }}
                                                    />
                                                  </Col>
                                                </Row> */}
                                                    <Row className="mt-3 w-100 justify-content-end mx-0">
                                                      <Col
                                                        lg={2}
                                                        className="px-0"
                                                      >
                                                        <button
                                                          className="btn btn-primary waves-effect waves-light float-end btn-sm"
                                                          type="submit"
                                                        >
                                                          Submit
                                                        </button>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                )}

                                              {/* DISPLYAING PURCHASED COACH LICENSES AND MY COACH ADDON DETAIL */}
                                              {/* <Col className="col-4 text-center"> */}
                                              <Col className={`col-4 text-center ${this.state.currentPackage.package_data.has_coach_license === "Yes" && this.state.currentPackage.package_data.package_id === 4 ? '' : 'offset-8'}`}>
                                                <div className="display-flex space-between LicenseDetails">
                                                  <div className="VariousCalls TotalRecord MyScheduleMain TaskManager card">
                                                    <div
                                                      className="card-body p-0"
                                                      style={{
                                                        minHeight: "auto",
                                                      }}
                                                    >
                                                      <section className="breakthrough clientProfileHeading ToolKit sub-nav-bg mb-0 text-center p-3">
                                                        <h5 className="mb-0">
                                                          Additional Coach
                                                          License Details
                                                        </h5>
                                                      </section>
                                                      <div className="RecentRecord MyTasks NewTasks mt-2 card-body">
                                                        <h6 className="text-center">
                                                          {" "}
                                                          <b>
                                                            Available Coach
                                                            License
                                                          </b>{" "}
                                                          :{" "}
                                                          {Number(
                                                            this.state
                                                              .user_settings
                                                              .remaining_coach
                                                          )}{" "}
                                                        </h6>
                                                        {/* <h6 className="text-center"> <b>Upcoming Invoice</b> : ${Number(this.state.currentPackage.price)} </h6> */}
                                                        <ul>
                                                          {this.state.premium_licenses_log.map(
                                                            (row, i) => {
                                                              return (
                                                                <li key={i}>
                                                                  <div className="TaskDescp row">
                                                                    <p>
                                                                      {" "}
                                                                      <b>
                                                                       {row.purpose == "license" ? 'Package Title:' : 'Addon Title:'} 
                                                                      </b>{" "}
                                                                      {
                                                                        row.title
                                                                      }
                                                                    </p>
                                                                    <p>
                                                                      {" "}
                                                                      <b>
                                                                        Type:
                                                                      </b>{" "}
                                                                      {row.type}
                                                                    </p>

                                                                    
                                                                    <p>
                                                                      <b>
                                                                        Setup
                                                                        Fee:
                                                                      </b>{" "}
                                                                      {
                                                                        row.purpose == "license" ? row.setup_fee : row.price
                                                                      }
                                                                    </p>

                                                                    {row.purpose == "license" && ( 
                                                                    <p>
                                                                      <b>
                                                                        Per
                                                                        License
                                                                        Recurring
                                                                        Fee:
                                                                      </b>{" "}
                                                                      {
                                                                        row.price
                                                                      }
                                                                    </p>)}
                                                                    <p>
                                                                      <b>
                                                                        License
                                                                        Limit:
                                                                      </b>{" "}
                                                                      {
                                                                        row.licence_limit
                                                                      }
                                                                    </p>
                                                                    <p>
                                                                      <b>
                                                                        Created
                                                                        At:
                                                                      </b>{" "}
                                                                      {
                                                                        row.created_at
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </ModalBody>
                                        </AvForm>
                                      </Card>
                                    </Col>
                                  </Row>
                                )}
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col sm={6}>
                            <Row>
                              <Col sm={6}>
                                <h4> Add/Update Card </h4>
                              </Col>
                              <Col sm={6}>
                                <span
                                  className="Add_card"
                                  style={{
                                    marginLeft: "125px",
                                    fontSize: "25px",
                                  }}
                                >
                                  {this.state.customerID != "" ? (
                                    <Tooltip title="Add">
                                      <i
                                        className="fas fa-plus-square cursor-pointer"
                                        onClick={() =>
                                          this.setState({
                                            addnewcardmodal: true,
                                          })
                                        }
                                      ></i>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Col>
                            </Row>
                            <div className="pt-20 table-responsive">
                              <Table className="table mb-0">
                                <tbody>
                                  {this.state.carddetails.map((row, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          {row.brand == "Visa" && (
                                            <img
                                              className="img-fluid"
                                              src={
                                                `${AttachementsUrl}` +
                                                "default/cardimages/card_a.jpg"
                                              }
                                              alt="Lexa"
                                            />
                                          )}
                                          {row.brand == "MasterCard" && (
                                            <img
                                              className="img-fluid"
                                              src={
                                                `${AttachementsUrl}` +
                                                "default/cardimages/card_b.jpg"
                                              }
                                              alt="Lexa"
                                            />
                                          )}
                                          {row.brand == "American Express" && (
                                            <img
                                              className="img-fluid"
                                              src={
                                                `${AttachementsUrl}` +
                                                "default/cardimages/card_c.jpg"
                                              }
                                              alt="Lexa"
                                            />
                                          )}
                                          {row.brand == "Discover" && (
                                            <img
                                              className="img-fluid"
                                              src={
                                                `${AttachementsUrl}` +
                                                "default/cardimages/card_d.jpg"
                                              }
                                              alt="Lexa"
                                            />
                                          )}
                                          ****{row.last4}
                                          &nbsp;
                                          <span style={{ float: "right" }}>
                                            {row.exp_month}/{row.exp_year}
                                          </span>
                                        </td>

                                        <td>
                                          <Tooltip title="Delete">
                                            <i
                                              className="fas fa-trash-alt pr-5 cursor-pointer"
                                              onClick={(e) =>
                                                this.deletecard(row.id)
                                              }
                                            ></i>
                                          </Tooltip>
                                          <Tooltip title="Update">
                                            <i
                                              className="fas fa-pencil-alt cursor-pointer"
                                              onClick={(e) =>
                                                this.setState({
                                                  updatemodal: true,
                                                  card_to_update: row.id,
                                                })
                                              }
                                            ></i>
                                          </Tooltip>
                                          &nbsp;
                                          {row.id !=
                                            this.state.default_source && (
                                            <Tooltip title="Make Default">
                                              <i
                                                className="fas fa-check-circle cursor-pointer"
                                                onClick={(e) =>
                                                  this.makedefault(row.id)
                                                }
                                              ></i>
                                            </Tooltip>
                                          )}
                                          {row.id ==
                                            this.state.default_source && (
                                            <Tooltip title="Default">
                                              <i
                                                style={{ color: "green" }}
                                                className="fas fa-check-circle default "
                                                disabled
                                              ></i>
                                            </Tooltip>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                          <Col sm={6}>
                            {this.state.invoicedetails != "" ? (
                              <h4>
                                Invoice
                                {this.state.currentPackage.package_data
                                  .setup_fee_installment > 0 && (
                                  <OverlayTrigger
                                    key={`auto-`}
                                    placement={"auto"}
                                    overlay={
                                      <Popover id={`popover-positioned-auto`}>
                                        <Popover.Header as="h3">
                                          Note
                                        </Popover.Header>
                                        <Popover.Body>
                                          <b>
                                            This package's setup price has been
                                            divided into{" "}
                                            {
                                              this.state.currentPackage
                                                .package_data
                                                .setup_fee_installment
                                            }{" "}
                                            installments, each costing $
                                            {NumberWithCommas(
                                              Math.round(
                                                this.state.currentPackage
                                                  .package_data.setup_price /
                                                  this.state.currentPackage
                                                    .package_data
                                                    .setup_fee_installment
                                              )
                                            )}{" "}
                                            per month
                                          </b>
                                          <br />
                                          <br />
                                          <p>
                                            After{" "}
                                            {
                                              this.state.currentPackage
                                                .package_data
                                                .setup_fee_installment
                                            }{" "}
                                            months, payment will be deducted
                                            based on the terms of your package,
                                            either on a monthly or yearly basis.
                                          </p>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <i className="fas fa-info pl-3"></i>
                                  </OverlayTrigger>
                                )}
                              </h4>
                            ) : (
                              <h4>No upcoming Invoice</h4>
                            )}

                            {this.state.invoicedetails != "" && (
                              <div className="pt-20 table-responsive">
                                <Table className="table mb-0">
                                  <tbody>
                                    <tr>
                                      <td>Package Interval</td>
                                      <td>
                                        $
                                        {
                                          this.state.currentPackage.package_data
                                            .price
                                        }
                                        /
                                        {
                                          this.state.currentPackage
                                            .package_interval
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Next Invoice: </td>
                                      <td>
                                        {this.state.invoicedetails.amount_due}
                                      </td>
                                    </tr>
                                    <tr>
                                      {this.state.invoicedetails.due_date !==
                                      null ? (
                                        <td>Invoice Date :</td>
                                      ) : (
                                        <td></td>
                                      )}

                                      <td>
                                        {this.state.invoicedetails.due_date}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.customerID == "" ? (
                              <button
                                onClick={() =>
                                  this.setState({
                                    modal: true,
                                  })
                                }
                                className="btn btn-primary btn-sm text-transform-capitalize btn-sm"
                              >
                                Add New Card
                              </button>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={"3"}>
                <Row className="mt-4">
                  <Col className="col-12">
                    <Card>
                    <CardTitle className="ms-3 fw-bold ">Package Subscription</CardTitle>
                      <CardBody  className="my-0 py-0">
                        <ReactDatatable
                          key={this.state.id}
                          config={this.config}
                          records={this.state.records}
                          columns={this.columns}
                          dynamic={true}
                          total_record={this.state.total_pages}
                        />
                      </CardBody>
                    </Card>
                  </Col>

                 
                  <Col className="col-12 mt-5">
                    <Card>
                      <CardTitle className="ms-3 fw-bold ">Addons Subscription</CardTitle>
                      <CardBody className="my-0 py-0">
                        <AddonsSubscription />
                      </CardBody>
                    </Card>
                  </Col>
                
                </Row>
              </TabPane>
              <TabPane tabId={"4"}>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <ReactDatatable
                          key={this.state.id}
                          config={this.approved_config}
                          records={this.state.approvedproposalrecords}
                          columns={this.approvedcolumns}
                          dynamic={true}
                          total_record={this.state.approved_total_pages}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={"5"}>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <AddonsSubscription />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.modal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              modal: !this.state.modal,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  modal: !this.state.modal,
                });
              }}
              className="text-transform-capitalize"
            >
              Add Payment Method
            </ModalHeader>
            <AvForm
              onValidSubmit={this.addcarddetails}
              className="needs-validation"
            >
              <ModalBody>
                <Row className="mt-3">
                  <Col className="col-6">
                    <label className="control-label">Card Number</label>
                    <AvField
                      name="cardnumber"
                      className="form-control"
                      type="number"
                      Placeholder="Enter Card Number"
                      value=""
                      required
                    />
                  </Col>
                  <Col className="col-6">
                    <label className="control-label"> CVC</label>
                    <AvField
                      name="cvc"
                      className="form-control"
                      type="number"
                      Placeholder="CVC"
                      value=""
                      required
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="col-6">
                    <AvField
                      name="expirationdateMM"
                      label="MM"
                      className="form-control"
                      type="number"
                      Placeholder="MM"
                      value=""
                      required
                    />
                  </Col>
                  <Col className="col-6">
                    <AvField
                      name="expirationdateYYYY"
                      label="YYYY"
                      className="form-control"
                      type="number"
                      Placeholder="YYYY"
                      value=""
                      required
                    />
                  </Col>
                </Row>
              </ModalBody>
              <Loader loaded={this.state.loaded} className="spinner">
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        modal: false,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Save
                  </button>
                </ModalFooter>
              </Loader>
            </AvForm>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.updatemodal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              updatemodal: false,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  updatemodal: false,
                });
              }}
              className="text-transform-capitalize"
            >
              Update Payment Method
            </ModalHeader>
            <AvForm
              onValidSubmit={this.updatecreditcard}
              className="needs-validation"
            >
              <ModalBody>
                <Row className="mt-3">
                  <Col className="col-12">
                    <label className="control-label">Card Holder Name</label>
                    <AvField
                      name="cardname"
                      className="form-control"
                      type="text"
                      Placeholder="Enter Card Holder Name"
                      value=""
                      required
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="col-6">
                    <AvField
                      name="expirationdateMM"
                      label="MM"
                      className="form-control"
                      type="text"
                      placeholder="MM"
                      maxLength={2}
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                      required
                    />
                  </Col>
                  <Col className="col-6">
                    <AvField
                      name="expirationdateYYYY"
                      label="YYYY"
                      className="form-control"
                      type="text"
                      placeholder="YYYY"
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                      maxLength={4}
                      required
                    />
                  </Col>
                </Row>
              </ModalBody>
              <Loader loaded={this.state.loaded} className="spinner">
                <ModalFooter>
                  <Button
                  className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        updatemodal: false,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Save
                  </button>
                </ModalFooter>
              </Loader>
            </AvForm>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.addnewcardmodal}
          role="dialog"
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            this.setState({
              addnewcardmodal: false,
            });
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                this.setState({
                  addnewcardmodal: false,
                });
              }}
              className="text-transform-capitalize"
            >
              Add New Card
            </ModalHeader>
            <AvForm
              onValidSubmit={this.addnewcard}
              className="needs-validation"
            >
              <ModalBody>
                <Row className="mt-3">
                  <Col className="col-6">
                    <label className="control-label">Card Number</label>
                    <AvField
                      name="cardnumber"
                      className="form-control"
                      type="text"
                      placeholder="Enter Card Number"
                      required
                      maxLength={16}
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                  <Col className="col-6">
                    <label className="control-label"> CVC</label>
                    <AvField
                      name="cvc"
                      className="form-control"
                      type="text"
                      placeholder="CVC"
                      required
                      maxLength={4}
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="col-6">
                    <AvField
                      name="expirationdateMM"
                      label="MM"
                      className="form-control"
                      type="text"
                      placeholder="MM"
                      maxLength={2}
                      required
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                  <Col className="col-6">
                    <AvField
                      name="expirationdateYYYY"
                      label="YYYY"
                      className="form-control"
                      type="text"
                      placeholder="YYYY"
                      required
                      maxLength={4}
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: {
                          value: "^([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                          errorMessage: "Type only positive numbers",
                        },
                      }}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <Loader loaded={this.state.loaded} className="spinner">
                <ModalFooter>
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        addnewcardmodal: false,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Save
                  </button>
                </ModalFooter>
              </Loader>
            </AvForm>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
