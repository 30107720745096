import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import AsyncSelect from 'react-select/async';

export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            clients: [],
            clients_id: 0,
            permissions: [],
            permission: [],
            emails: [],
            phoneNumbers: [],
            email_reminder: false,
            sms_reminder: false,
            compannyId: this.props.compannyId ? this.props.compannyId : '',
            pipelines: [],
            prev_status_id: this.props.prev_status_id ? this.props.prev_status_id : null,

        };
    }

    componentDidMount() {
        this.getFormData();
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/pipeline/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ type: 'training' })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        permissions: response.data.coachPartners,
                        pipelines: response.data.pipelines
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    handleSubmit = async (event, values) => {
        if (this.state.compannyId != '') {
            values.client_id = this.state.compannyId;
        }
        values.type = 'training';
        values.prev_status_id = this.state.prev_status_id;
        fetch(`${ApiUrl}` + "add/onboard/status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, 'Editing');
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    this.props.cancelSlider();
                    //  toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handlePipeline = (selectedPipeline) => {

        var filter = [];
        if (selectedPipeline) {
            selectedPipeline.map(fil => {
                filter.push(fil.pipeline_id);
            })
        }

        this.setState({
            pipline_ids: filter.toString(),
            selectedPipeline: selectedPipeline
        })
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">
                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="title"
                                            value={this.state.record.title}
                                            label="Title"
                                            className="form-control"
                                            required
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="control-label">Training</label>
                                        <Select
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.pipeline_id}
                                            isMulti={false}
                                            value={this.state.selectedPipeline}
                                            options={this.state.pipelines}
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Training"
                                            onChange={(e) => {
                                                this.setState({
                                                    pipline_ids: e.pipeline_id
                                                })
                                            }}
                                        />
                                        <AvField required type="hidden" name="pipeline_id" value={this.state.pipline_ids} />


                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="color_code"
                                            value={this.state.record.color_code?? '#000'}
                                            label="Status Color"
                                            className="form-control"
                                            required
                                            type="color"
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="status"
                                            value={this.state.record.status}
                                            label="Status"
                                            className="form-control"
                                            id="rs"
                                            required
                                            type="select"
                                        >
                                            <option value={''}>Select Status</option>
                                            <option value={'Active'}>Active</option>
                                            <option value={'Inactive'}>Inactive</option>
                                            {/* <option value={'Hidden'}>Hidden</option> */}

                                        </AvField>
                                    </div>


                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        className="btn btn-primary waves-effect waves-light btn-sm"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
