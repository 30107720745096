import React, { useContext, useRef, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import WayBookContext from "./WaybookContext";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { bcrypt, dcrypt, uInfo, uToken , includedInPlanAddon , hasPurchasedAddon } from "../../useToken";
import { ApiUrl } from "../../config";
import swal from "sweetalert";
import { toast, ToastContainer, Flip } from "react-toastify";
export default function WaySideBarCoach(props) {
  const params = React.useContext(WayBookContext);

  const [isOpen, setIsOpen] = useState(
    "sub" + params.category + "-" + params.subject
  );
  const [isDocOpen, setIsDocOpen] = useState(
    "doc" + params.category + "-" + params.subject + "-" + params.documents
  );

  const [activeKey, setActiveKey] = useState(params.category);

  const [defaultIndex] = useState(params.category);
  const [lists, setLists] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { search } = useLocation();
  const url_params = new URLSearchParams(search);
  const is_coach_section = url_params.get("sec") ? "Yes" : "No";
  const [coursePaid, setCoursePaid] = useState(
    uInfo().type === "Coach"
      ? JSON.parse(localStorage.getItem("course_free_paid"))
      : false
  );
  const [canEditCocah, setCanEditCocah] = useState(
    uInfo().type === "Coach"
      ? JSON.parse(localStorage.getItem("can_edit_coach"))
      : false
  );
  const [checkboxStates, setCheckboxStates] = useState({});
  const toggleOpen = (id) => () =>
    setIsOpen((prevOpen) => (prevOpen === id ? null : id));
  const toggleDocOpen = (id) => () =>
    setIsDocOpen((isDocOpen) => (isDocOpen === id ? null : id));
  const Subject = ({
    cat_id,
    index,
    subindex,
    content,
    subject,
    percentage,
    isOpen,
    isDocOpen,
    toggle,
    toggleDoc,
    status,
    image,
    user_id,
    training_for,
    tag_training,
    master_id,
    badge_image,
    badge_description = "",
    disabled,
  }) => {
    return (
      <div
        key={cat_id}
        className="subject"
        onDragStart={(e) => dragStart(e, subindex)}
        onDragEnter={(e) => dragEnter(e, subindex)}
        onDragEnd={(e) => dropTwo(e, index)}
        draggable={params.can_edit_coach}
      >
        <div className={`${isOpen ? "active" : ""} pb-1 ProgressBarHide 2`}>
          <div className="step-content p-0 pt-2 pe-2">
            <div className=" d-flex justify-content-between">
              <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <Link
                      className={`${disabled ? "" : ""}`}
                      disabled={""}
                      to={`/trainings/${bcrypt(master_id)}/${bcrypt(
                        cat_id
                      )}?sec=Y29hY2g=`}
                    >
                      {subject}
                    </Link>
                  </span>
                  {uInfo().type &&
                    uInfo().type !== "Coach" &&
                    params.subject &&
                    params.subject == subindex && (
                      <>
                        <span className="PercentageShow"> {percentage}%</span>
                      </>
                    )}
                </div>
                {uInfo().type &&
                  uInfo().type !== "Coach" &&
                  params.subject &&
                  params.subject_i == subindex && (
                    <>
                      <div className="progress w-100">
                        <div
                          role="progressbar"
                          class="progress-bar"
                          aria-valuemin={percentage}
                          aria-valuemax="100"
                          aria-valuenow={percentage}
                          style={{ width: `${percentage}%` }}
                        ></div>
                      </div>
                    </>
                  )}
              </div>
              <div className="d-flex">
                {params.can_edit_coach && (
                  <div className="subject-action">
                    {status !== "Publish" && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            This module is unpublished
                          </Tooltip>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>
                    )}

                    <Dropdown
                      onSelect={(e) =>
                        params.subjectOrDocumentAction(
                          "Subject",
                          cat_id,
                          subject,
                          e,
                          training_for,
                          tag_training,
                          image,
                          user_id,
                          "left",
                          master_id,
                          badge_image,
                          badge_description
                        )
                      }
                    >
                      <Dropdown.Toggle variant="link">
                        <i className="fas fa-ellipsis-h 1"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {status === "Publish" ? (
                          <Dropdown.Item eventKey="Draft">
                            Switch to Unpublish
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item eventKey="Publish">
                            Switch to Publish
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                        <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                        <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                        <Dropdown.Item eventKey="AddDocument">
                          Add Lesson
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Collapse isOpen={isOpen} className="content-way">
          {content}
        </Collapse>
      </div>
    );
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    e.preventDefault();

    if (!params.can_edit_coach) {
      e.preventDefault();
    }
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const dropTwo = (e, i) => {
    const copyListItems = [...lists[i].sub_cat];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    let newState = [...lists];
    newState[i].sub_cat = copyListItems;
    setLists(newState);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getWayBookLists();
    };
    fetchData();
  }, []);
  useEffect(() => {
    // Initialize checkbox states based on the initial list
    const initialStates = {};
    lists.forEach((item) => {
      initialStates[item.cat_id] = item.course_free_paid === "paid"; // true or false
    });
    setCheckboxStates(initialStates);
  }, [lists]);
  const getWayBookLists = async () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/waybook/category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ can_edit: canEditCocah, is_coach_section: "Yes" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setLists(response.data.record);
          params.setWayBookCoachList(response.data.record);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
        setLoaded(true);
      })
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const handleCheckboxChange = (checked, catId) => {
    // Update local state immediately
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [catId]: checked, // Set the state for the specific checkbox
    }));

    // Call your handler for further processing
    onChangeCoursePaidHandler(checked, catId);

    setLists((prevCourses) =>
      prevCourses.map((course) =>
        course.cat_id === catId
          ? {
              ...course,
              course_free_paid: checked ? "paid" : "free",
            }
          : course
      )
    );

    if (checked) {
      setActiveKey(catId);
    }
  };

  const handleAccordionToggle = (catId) => {
    setActiveKey((prev) => (prev === catId ? null : catId));
  };

  const onChangeCoursePaidHandler = async (e, cat_id) => {
    if (cat_id) {
      let bodyParams = {
        cat_id: cat_id,
        course_free_paid: e == true ? "paid" : "free",
      };
      try {
        fetch(`${ApiUrl}get/waybook/coach/course_free_paid/${cat_id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify(bodyParams),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
            } else {
              // setCheckboxStates((prevStates) => ({
              //   ...prevStates,
              //   [cat_id]: false,
              // }));

              // setCheckboxStates((prevStates) => {
              //   const newState = { ...prevStates };
              //   delete newState[cat_id];
              //   return newState;
              // });
              toast(response.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            }
          })
          .catch((error) => {
            setLoaded(true);

            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error deleting question:", error);
      }
    }
  };

  // const handlePriceChange = async (cat_id, value) => {
  //   if (cat_id) {
  //     if (value >= 1) {
  //       let bodyParams = {
  //         cat_id: cat_id,
  //         course_paid_price: value,
  //       };
  //       try {
  //         const response = await fetch(
  //           `${ApiUrl}get/waybook/coach/course_free_paid_price/${cat_id}`,
  //           {
  //             method: "POST",
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ` + uToken(),
  //             },
  //             body: JSON.stringify(bodyParams),
  //           }
  //         );
  //         // const responseData = await response.json();
  //         // await getWayBookLists();
  //         //console.log(responseData); // Optional: Log response data for debugging
  //         if (response.ok) {
  //           const responseData = await response.json();

  //           setLists((prevList) =>
  //             prevList.map((list) =>
  //               list.cat_id === cat_id
  //                 ? { ...list, course_paid_price: value }
  //                 : list
  //             )
  //           );

  //           console.log(responseData);
  //         } else {
  //           toast("Failed to update the price. Please try again.", {
  //             hideProgressBar: true,
  //             position: "top-center",
  //             transition: Flip,
  //             className: "toast-custom-cls error",
  //           });
  //         }
  //       } catch (error) {
  //         console.error("Error deleting question:", error);
  //       }
  //     } else {
  //       toast("Price should be greater than 0.", {
  //         hideProgressBar: true,
  //         position: "top-center",
  //         transition: Flip,
  //         className: "toast-custom-cls error",
  //       });
  //     }
  //   }
  // };

  const handlePriceChange = (cat_id, value) => {
    if (cat_id) {
      if (value >= 1) {
        let bodyParams = {
          cat_id: cat_id,
          course_paid_price: value,
        };

        try {
          fetch(`${ApiUrl}get/waybook/coach/course_free_paid_price/${cat_id}`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(bodyParams),
          })
            .then((response) => response.json())
            .then((response) => {
              // Updating the state for the list
              setLists((prevList) =>
                prevList.map((list) =>
                  list.cat_id === cat_id
                    ? { ...list, course_paid_price: value }
                    : list
                )
              );

              // if (response.data && response.data.course_free_paid) {
              //   setCheckboxStates((prevStates) => ({
              //     ...prevStates,
              //     [cat_id]:
              //       response.data.course_free_paid === "paid" ? true : false,
              //   }));
              // } else {
              //   setCheckboxStates((prevStates) => ({
              //     ...prevStates,
              //     [cat_id]: false,
              //   }));
              // }
            })
            .catch((error) => {
              console.error("Error updating price:", error);
              toast(error.message, {
                hideProgressBar: true,
                position: "top-center",
                transition: Flip,
                className: "toast-custom-cls error",
              });
            });
        } catch (error) {
          console.error("Error deleting question:", error);
        }
      } else {
        toast("Price should be greater than 0.", {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: "toast-custom-cls error",
        });
      }
    } else {
      toast("Category ID is missing. Please try again.", {
        hideProgressBar: true,
        position: "top-center",
        transition: Flip,
        className: "toast-custom-cls error",
      });
    }
  };

  return (
    <div className="subjects side-change-dropdown CourseArea">
      <Accordion
        // defaultActiveKey={
        //   defaultIndex && is_coach_section === "Yes" ? [defaultIndex] : null
        // }
        activeKey={
          activeKey && uInfo().type === "Coach" ? `${activeKey}` : null
        }
        alwaysOpen
        flush
        className="WayBookCate coaches"
      >
        {lists.map((item, i) => (
          <Accordion.Item
            disabled={item.disabled}
            key={item.cat_id}
            className={`${item.disabled ? "" : ""} mainCate coaches`}
            eventKey={`${item.cat_id}`}
          >
            <div className="CourseTitle">
              <Accordion.Header
                className={`${params.category == item.cat_id ? "active" : ""}`}
                onClick={() => handleAccordionToggle(item.cat_id)}
              >
                <Link
                  disabled={item.disabled}
                  to={`/trainings/${bcrypt(item.cat_id)}?sec=Y29hY2g=`}
                  className={`${item.disabled ? "" : ""}`}
                >
                  <>
                    <div className="d-flex WayBookDropDown CourseToggle py-1 justify-content-between pe-0 ps-1">
                      <div className="course-title">{item.title}</div>
                    </div>
                  </>

                  {!params.can_edit_coach && item.onboard_training === 1 && (
                    <i
                      title="Onboard Training"
                      className="fas fa-chalkboard-teacher ml-5"
                    ></i>
                  )}
                </Link>
              </Accordion.Header>
              {params.can_edit_coach && (
                <div className="category-action">
                  <div className="d-flex align-items-center">
                    {canEditCocah && (
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="form-check form-switch py-0 d-flex px-0">
                            <label className="mb-0 me-2">Free</label>
                            <input
                              type="checkbox"
                              className="form-check-input ms-0"
                              id={`customSwitch-${item.cat_id}`}
                              // checked={item.course_free_paid == 'paid' ? true : false}
                              checked={checkboxStates[item.cat_id] || false}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e.target.checked,
                                  item.cat_id
                                )
                              }
                            />
                            <label className="mb-0 ms-2 me-1">Paid</label>
                          </div>
                        </div>
                      </>
                    )}

                    {item.status !== "Publish" && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            This course is unpublished
                          </Tooltip>
                        }
                      >
                        <i className="fas fa-info-circle"></i>
                      </OverlayTrigger>
                    )}

                    <Dropdown
                      onSelect={(e) =>
                        params.subjectOrDocumentAction(
                          "Category",
                          item.cat_id,
                          item.title,
                          e,
                          item.training_for,
                          item.tag_training,
                          "",
                          "",
                          "left",
                          item.master_id,
                          item.badge_image,
                          item.badge_description,
                          item.coaching_points,
                          item.course_description
                        )
                      }
                    >
                      <Dropdown.Toggle variant="link">
                        <i className="fas fa-ellipsis-h 2"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {item.status === "Publish" ? (
                          <Dropdown.Item eventKey="Draft">
                            Switch to Unpublish
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item eventKey="Publish">
                            Switch to Publish
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                        <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                        <Dropdown.Item eventKey="AddSubject">
                          Add Module
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
              {/* {!canEditCocah && uInfo().coach_can_sell_trainings === "active_paid" && ( */}
              {!canEditCocah &&
                (hasPurchasedAddon(4) || includedInPlanAddon(4)) && (
                  <>
                    <div
                      className={`PriceCourse pe-2 inside ${item.course_free_paid}`}
                    >
                      {item.course_free_paid === "paid"
                        ? `Price: $${Number(
                            item.course_paid_price
                          ).toLocaleString()}`
                        : item.course_free_paid === "free"
                        ? "Free"
                        : ""}
                    </div>
                  </>
                )}
            </div>
            <Accordion.Body className="p-0">
              {/* {item.course_free_paid == "paid" && canEditCocah && uInfo().coach_can_sell_trainings === "active_paid" && ( */}
              {item.course_free_paid == "paid" &&
                canEditCocah &&
                (hasPurchasedAddon(4) || includedInPlanAddon(4)) && (
                  <>
                    <div className="CoursePriceSetup d-flex align-items-center justify-content-end mt-2">
                      <label className="mb-0 me-2">Course Price($)</label>
                      <input
                        type="number"
                        className="form-control"
                        name="course_paid_price"
                        min={1}
                        defaultValue={item.course_paid_price}
                        onChange={(e) =>
                          handlePriceChange(item.cat_id, e.target.value)
                        }
                        placeholder="$"
                      />
                    </div>
                  </>
                )}
              {item.sub_cat.map((sub_item, k) => (
                <Subject
                  key={"sub" + item.cat_id + "-" + sub_item.cat_id}
                  cat_id={sub_item.cat_id}
                  index={item.cat_id}
                  subindex={sub_item.cat_id}
                  content={
                    <ListGroup key={"list" + sub_item.cat_id} variant="flush">
                      {sub_item.sub_cat.map((sub, m) => (
                        <ListGroup.Item disabled={false} key={sub.cat_id}>
                          <div
                            className={`${
                              params.documents == sub.cat_id ? "active" : ""
                            } d-flex align-items-center justify-content-between ps-2`}
                          >
                            <Link
                              id={sub.cat_id}
                              to={`/${
                                sub.first_step_type == "quiz"
                                  ? "trainingsreview"
                                  : "trainings"
                              }/${bcrypt(item.cat_id)}/${bcrypt(
                                sub_item.cat_id
                              )}/${bcrypt(sub.cat_id)}${
                                sub.first_step_link ? sub.first_step_link : ""
                              }&sec=Y29hY2g=`}
                            >
                              {sub.title}
                            </Link>

                            <div className="d-flex">
                              {params.can_edit_coach ? (
                                <div className="document-action d-flex">
                                  {sub.status !== "Publish" && (
                                    <>
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id="button-tooltip">
                                            This lesson is unpublished
                                          </Tooltip>
                                        }
                                      >
                                        <i className="fas fa-info-circle"></i>
                                      </OverlayTrigger>
                                    </>
                                  )}

                                  <Dropdown
                                    onSelect={(eventKey) => {
                                      if (eventKey === "AddStep") {
                                        params.subjectOrDocumentAction(
                                          "Step",
                                          sub.cat_id,
                                          sub.title,
                                          "AddStep",
                                          "",
                                          "",
                                          "",
                                          "",
                                          "left",
                                          sub.cat_id
                                        );
                                      } else if (eventKey === "AddStepquiz") {
                                        params.subjectOrDocumentAction(
                                          "Step",
                                          sub.cat_id,
                                          sub.title,
                                          "AddStepquiz",
                                          "",
                                          "",
                                          "",
                                          "",
                                          "left",
                                          sub.cat_id
                                        );
                                      } else {
                                        params.subjectOrDocumentAction(
                                          "Document",
                                          sub.cat_id,
                                          sub.title,
                                          eventKey,
                                          sub.training_for,
                                          sub.tag_training,
                                          "",
                                          "",
                                          "left",
                                          sub.master_id
                                        );
                                      }
                                    }}
                                  >
                                    <Dropdown.Toggle variant="link">
                                      <i className="fas fa-ellipsis-h 3"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {sub.status === "Publish" ? (
                                        <Dropdown.Item eventKey="Draft">
                                          Switch to Unpublish
                                        </Dropdown.Item>
                                      ) : (
                                        <Dropdown.Item eventKey="Publish">
                                          Switch to Publish
                                        </Dropdown.Item>
                                      )}
                                      <Dropdown.Item eventKey="Update">
                                        Rename
                                      </Dropdown.Item>
                                      <Dropdown.Item eventKey="Move">
                                        Move
                                      </Dropdown.Item>
                                      <Dropdown.Item eventKey="Delete">
                                        Delete
                                      </Dropdown.Item>
                                      <Dropdown.Item eventKey="AddStep">
                                        Add Training
                                      </Dropdown.Item>
                                      <Dropdown.Item eventKey="AddStepquiz">
                                        Add Quiz
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : (
                                <div className="document-action d-flex">
                                  <OverlayTrigger
                                    placement="auto"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        {sub.percentage}% completed
                                      </Tooltip>
                                    }
                                  >
                                    <div className="dummy-box"></div>
                                  </OverlayTrigger>
                                </div>
                              )}
                              <button
                                disabled={false}
                                className={`${
                                  sub.disabled ? "" : ""
                                } btn btn-link p-0`}
                                onClick={toggleDocOpen(
                                  "doc" + i + "-" + k + "-" + m
                                )}
                              >
                                {isDocOpen === "doc" + i + "-" + k + "-" + m ? (
                                  <i className="fas fas fa-angle-up" />
                                ) : (
                                  <i className="fas fas fa-angle-down" />
                                )}
                              </button>
                            </div>
                          </div>

                          <Collapse
                            isOpen={
                              isDocOpen ==
                              "doc" +
                                item.cat_id +
                                "-" +
                                sub_item.cat_id +
                                "-" +
                                sub.cat_id
                            }
                            className="content-way"
                          >
                            <ListGroup
                              key={"step_list" + sub.cat_id}
                              className="step-lists content-page-documents-list-item highlight-parent"
                            >
                              {sub.sub_steps &&
                                sub.sub_steps.map((step, n) => (
                                  <ListGroup.Item
                                    key={step.step_id}
                                    className={`${
                                      params.step == step.step_id
                                        ? "active"
                                        : ""
                                    } ${step.disabled ? "" : ""} highlight`}
                                  >
                                    <div className="d-flex justify-content-between gap-1 AddBorder">
                                      <div className="steps-list d-flex align-items-center">
                                        <i className="fas fa-circle"></i>
                                        {step.type === "quiz" ? (
                                          <Link
                                            id={step.step_id}
                                            className={`${
                                              step.disabled ? "" : ""
                                            }`}
                                            to={`/trainingsreview/${bcrypt(
                                              item.cat_id
                                            )}/${bcrypt(
                                              sub_item.cat_id
                                            )}/${bcrypt(sub.cat_id)}/${bcrypt(
                                              step.step_id
                                            )}/?step=${bcrypt(
                                              step.step_id
                                            )}&sec=Y29hY2g=`}
                                          >
                                            {step.title}

                                            {step.status === "Draft" && (
                                              <div className="text-danger">
                                                <small>unpublished</small>
                                              </div>
                                            )}
                                          </Link>
                                        ) : (
                                          <>
                                            <Link
                                              id={step.step_id}
                                              className={`${
                                                step.disabled
                                                  ? "upcoming-step"
                                                  : "upcoming-step"
                                              }`}
                                              to={`/trainings/${bcrypt(
                                                item.cat_id
                                              )}/${bcrypt(
                                                sub_item.cat_id
                                              )}/${bcrypt(sub.cat_id)}/${bcrypt(
                                                step.step_id
                                              )}/?step=${bcrypt(
                                                step.step_id
                                              )}&sec=Y29hY2g=`}
                                            >
                                              {step.title}

                                              {step.status === "Draft" && (
                                                <div className="text-danger">
                                                  <small>unpublished</small>
                                                </div>
                                              )}
                                            </Link>
                                          </>
                                        )}
                                      </div>
                                      <div className="step-action">
                                        {params.can_edit_coach ? (
                                          <Dropdown
                                            onSelect={(e) =>
                                              params.stepMethodAction(
                                                "Step",
                                                step.step_id,
                                                step.title,
                                                e,
                                                step.training_for,
                                                step.tag_training,
                                                step.cat_id
                                              )
                                            }
                                          >
                                            <Dropdown.Toggle
                                              variant="link"
                                              id="dropdown-basic"
                                            >
                                              <i className="fas fa-ellipsis-h 4"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              {step.status === "Publish" ? (
                                                <>
                                                  <Dropdown.Item eventKey="Draft">
                                                    Switch to Unpublish
                                                  </Dropdown.Item>
                                                  <Dropdown.Item eventKey="Retake">
                                                    Require Retake
                                                  </Dropdown.Item>
                                                  <Dropdown.Item eventKey="Notification">
                                                    Training Notification
                                                  </Dropdown.Item>
                                                </>
                                              ) : (
                                                <Dropdown.Item eventKey="Publish">
                                                  Switch to Publish
                                                </Dropdown.Item>
                                              )}
                                              <Dropdown.Item eventKey="Update">
                                                Rename
                                              </Dropdown.Item>
                                              <Dropdown.Item eventKey="Move">
                                                Move
                                              </Dropdown.Item>
                                              <Dropdown.Item eventKey="Delete">
                                                Delete
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          uInfo().user_id !== 2 && (
                                            <div className="mark-option">
                                              {step.log_id ? (
                                                <OverlayTrigger
                                                  placement="auto"
                                                  overlay={
                                                    <Tooltip id="button-tooltip">
                                                      You've completed this
                                                      training
                                                    </Tooltip>
                                                  }
                                                >
                                                  <i className="fas fa-check-circle"></i>
                                                </OverlayTrigger>
                                              ) : (
                                                <OverlayTrigger
                                                  placement="auto"
                                                  overlay={
                                                    <Tooltip id="button-tooltip">
                                                      You've not completed this
                                                      training
                                                    </Tooltip>
                                                  }
                                                >
                                                  <i className="fas fa-circle"></i>
                                                </OverlayTrigger>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </ListGroup.Item>
                                ))}
                            </ListGroup>
                          </Collapse>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  }
                  subject={sub_item.title}
                  isOpen={
                    isOpen === "sub" + item.cat_id + "-" + sub_item.cat_id
                  }
                  isDocOpen={
                    isDocOpen === "doc" + item.cat_id + "-" + sub_item.cat_id
                  }
                  toggle={toggleOpen(
                    "sub" + item.cat_id + "-" + sub_item.cat_id
                  )}
                  toggleDoc={toggleDocOpen}
                  status={sub_item.status}
                  image={sub_item.image}
                  user_id={sub_item.user_id}
                  percentage={sub_item.percentage}
                  training_for={sub_item.training_for}
                  tag_training={sub_item.tag_training}
                  master_id={item.cat_id}
                  disabled={sub_item.disabled}
                  badge_image={sub_item.badge_image}
                  badge_description={sub_item.badge_description}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
