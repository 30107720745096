import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Alert } from "reactstrap";
import swal from "sweetalert";
import Helmet from "react-helmet";
import Loader from "react-loader";
import {
  convertDateFormat,
  currentDate,
  dcrypt,
  uInfo,
  uToken,
  bcrypt,
  NumberWithCommas,
  hasPurchasedAddon,
  includedInPlanAddon,
} from "../../useToken";
import { ApiUrl, logoLightPng } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { currencies } from "../../common/Common-Currency.js";
export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      selectedProposaId: null,
      invoice_type: this.props.match.params.type
        ? this.props.match.params.type
        : null,
      page_name: this.props.match.params.id ? "Edit Invoice" : "Create Invoice",
      from: this.props.from ? this.props.from : "",
      record: {},
      clients: [],
      coachPartners: [],
      holidays: {},
      managers: [],
      group_id: [],
      tag_id: [],
      emails: [],
      phoneNumbers: [],
      permission: [],
      productProposals: [],
      clientAssessments: [],
      has_payment_method: 0,
      country_code: [],
      currency_arr: [],
      currency_code: "$",
      currency_code_invoices: "USD",
      currency: "",
      user: "",
      customFields: [
        { items: "", quantity: "0", price: "0", total: "0", readOnly: "0" },
      ],
      formData: {
        start_date: currentDate(),
        expire_date: "",
        type: "one_time",
        payment_method: "stripe",
        invoice_for: "Client",
        renewal_interval: "Monthly",
        items_data: [],
        email_subject: "",
        notes: "",
        confirmation_email: "",
        created_for_id: 0,
        activity: "0",
        master_type: "coaching",
        product_id: "0",
        training_product_id: "0",
        way_training_cat_id: "0",
        package_id: "",
        search_client: "",
        invoice_currency_code: "USD",
      },
      setUpPermission: {
        permission: 0,
        remaining_coach: 0,
        packages: [],
      },
      traningProducts: [],
      revenueProducts: [],
      createdFor: {},
      hasProposal: 0,
      loaded: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.id != null) {
      this.getFormData({ filter_client: this.state.id });
    } else {
      this.getFormData("");
    }

    // if (this.state.invoice_type == 'coach') {
    this.makeCoachPackagesPermission();
    // }

    window.onafterprint = function () {
      window.location.reload();
    };

    // this.getUserobjfromlocal();
  }

  findSymbol = (code) => {
    let currencycode = currencies[code];
    console.log(currencycode.symbol);
    return currencycode.symbol;
  };
  getAssessments = (client_id) => {
    fetch(`${ApiUrl}` + "get/client/assessments/" + client_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            clientAssessments: response.data.record,
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getProposal = (id, type = "0") => {
    fetch(`${ApiUrl}` + "getProduct/proposals/" + id + "/" + type, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            productProposals: response.data.record,
            hasProposal: response.data.hasProposal,
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  makeCoachPackagesPermission = () => {
    fetch(`${ApiUrl}` + "make/coach/packages/permission", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            setUpPermission: response.data,
            customFields: [],
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getFormData = (data) => {
    fetch(`${ApiUrl}` + "get/invoice/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.id == null) {
            this.setState({
              clients: response.data.clients,
              coachPartners: response.data.coachPartners,
            });
          } else {
            this.setState({
              createdFor: response.data.client,
            });
            this.setState((prevState) => {
              let formData = Object.assign({}, prevState.formData);
              formData.created_for_id = this.state.id;
              formData.invoice_for = "Client";
              formData.activity = "1";
              return { formData };
            });
          }
          this.setState({
            revenueProducts: response.data.revenue_products,
            traningProducts: response.data.tranings_product,
            has_payment_method: response.data.has_payment_method,
            logo: response.data.logo,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  Submit = () => {
    fetch(`${ApiUrl}` + "add/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(this.state.formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (this.state.id != null) {
            this.props.history.goBack();
          } else {
            this.props.history.push("/invoice/list");
          }
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }

        // this.setState({
        //     loaded: true,
        // })
      })
      //Then with the error genereted...
      .catch((error) => {
        // this.setState({
        //     loaded: true,
        // })
        console.error("Error:", error);
      });
  };
  handleSubmit = async (event) => {
    console.log(this.state.formData, "this.state.formData");
    event.preventDefault();
    if (
      (this.state.formData.product_id == "" ||
        this.state.formData.product_id == "0") &&
      (this.state.formData.package_id == "" ||
        this.state.formData.package_id == "0") &&
      (this.state.formData.training_product_id == "" ||
        this.state.formData.training_product_id == "0") &&
      (this.state.formData.way_training_cat_id == "" ||
        this.state.formData.way_training_cat_id == "0")
    ) {
      swal({
        title: "Required Fields",
        text: "Select Package or Product",
        icon: "warning",
      });

      return;
    }

    if (
      this.state.formData.created_for_id == "" ||
      this.state.formData.created_for_id == 0
    ) {
      swal({
        title: "Required Fields",
        text: "Select To(Client)",
        icon: "warning",
      });

      return;
    } else {
      let sum = this.state.customFields.reduce(function (prev, current) {
        return prev + +current.total;
      }, 0);

      if (sum < 1 && this.state.formData.master_type == "ConvertCoach") {
        swal({
          title: "The total price must be greater than 1.",
          text: "Select Price",
          icon: "warning",
        });

        return;
      }

      // this.setState({
      //     loaded: false,
      // })
      this.setState(
        (prevState) => {
          let formData = Object.assign({}, prevState.formData);
          formData.items_data = this.state.customFields;
          formData.total_price = sum;
          return { formData };
        },
        () => this.Submit()
      );
      return;
    }
  };

  defaultUI() {
    return this.state.customFields.map((el, i) => (
      <div className="p-3 mb-0 bg-light pb-0" key={i}>
        <div className="row">
          <div className="col-sm-6 col-md-6 mb-3">
            <div className="form-group">
              {/* readOnly={el.readOnly == '1' ? true : false} */}
              <input
                value={el.items}
                name="items"
                required="required"
                type="text"
                className="form-control"
                onChange={this.handleChange.bind(this, i, "none")}
              />
            </div>
          </div>
          <div className="col-sm-3 col-md-3 mb-2 d-none">
            <div className="form-group">
              <input
                pattern="\d+"
                value={el.quantity}
                readOnly={el.readOnly == "1" ? true : false}
                name="quantity"
                required="required"
                type="number"
                min={0}
                className="form-control"
                onChange={this.handleChange.bind(this, i)}
              />
            </div>
          </div>
          <div className="col-sm-3 col-md-3 mb-2 d-none">
            <div className="form-group">
              <input
                pattern="\d+"
                name="price"
                readOnly={el.readOnly == "1" && i < 1 ? true : false}
                value={el.price}
                required="required"
                type="number"
                min={0}
                className="form-control"
                onChange={this.handleChange.bind(this, i)}
              />
            </div>
          </div>
          {el.for_setup == 1 && (
            <>
              <div className="col-sm-2 col-md-2">
                <div className="form-group">
                  {/* <input pattern="\d+" name="price" readOnly={el.readOnly == '1' && i < 1 ? true : false} value={el.price} required="required" type="number" min={0}
                                        className="form-control" onChange={this.handleChange.bind(this, i)} /> */}

                  <select
                    name="setup_fee_installment"
                    className="form-control"
                    value={el.setup_fee_installment}
                    disabled={
                      el.readOnly == "1" && i < 1
                        ? true
                        : false || el.setup_fee_installmentreadOnly == 1
                        ? true
                        : false
                    }
                    onChange={this.handleChange.bind(
                      this,
                      i,
                      el.for_setup == 1 ? "divide" : "none"
                    )}
                  >
                    <option value={"0"}> 0 </option>
                    <option value={"1"}> 1 </option>
                    <option value={"2"}> 2 </option>
                    <option value={"3"}> 3 </option>
                    <option value={"4"}> 4 </option>
                    <option value={"5"}> 5 </option>
                    <option value={"6"}> 6 </option>
                    <option value={"7"}> 7 </option>
                    <option value={"8"}> 8 </option>
                    <option value={"9"}> 9 </option>
                    <option value={"10"}> 10 </option>
                  </select>
                </div>
              </div>

              <div className="col-sm-2 col-md-2">
                <div className="form-group d-flex align-items-center gap-2">
                  {/* <i className="fas fa-dollar-sign"></i>1 */}
                  <div class="group-addnon">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          class="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          {this.state.currency_code}
                        </span>
                      </div>
                      <div class="form-group">
                        <input
                          pattern="\d+"
                          name="installment_fee"
                          value={el.installment_fee}
                          onChange={this.handleChange.bind(
                            this,
                            i,
                            el.for_setup == 1 ? "divide" : "none"
                          )}
                          required="required"
                          type="number"
                          min={0}
                          className="form-control px-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {el.for_info == 1 && (
            <div className="col-sm-4 col-md-4">
              <div className="form-group d-flex align-items-center gap-2">
                {/* <i className="fas fa-dollar-sign"></i>2 */}

                <div class="group-addnon w-100">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        id="validationTooltipUsernamePrepend"
                      >
                        {this.state.currency_code}
                      </span>
                    </div>
                    <div class="form-group">
                      <input
                        pattern="\d+"
                        name="p_price"
                        readOnly={true}
                        value={el.price}
                        required="required"
                        type="number"
                        min={0}
                        className="form-control px-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {el.for_info == 1 && (
                <note className={"text-info"}>
                  After successfully paying installment setup fee, the package
                  charge will be deducted
                </note>
              )}
            </div>
          )}
          <div
            className={` ${
              el.for_info == 1 || el.for_setup
                ? "col-sm-2 col-md-2"
                : "col-sm-6 col-md-6"
            }  `}
          >
            <div className="invoice grid-style">
              <div className="form-group d-flex align-items-center gap-2">
                {/* <i className="fas fa-dollar-sign"></i>3 */}
                <div class="group-addnon">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        id="validationTooltipUsernamePrepend"
                      >
                        {this.state.currency_code}
                      </span>
                    </div>
                    <div class="form-group">
                      {/* //toFixed(2) remove from vlue  */}
                      <input
                        name="total"
                        readOnly={
                          (el.readOnly == "1" && i < 1) || el.for_setup == 1
                            ? true
                            : false
                        }
                        value={el.total > 0 ? Math.round(el.total) : el.total}
                        onChange={this.handleChange.bind(
                          this,
                          i,
                          el.for_setup == 1 ? "divide" : "none"
                        )}
                        required="required"
                        type="number"
                        min={0}
                        step={"any"}
                        className="form-control px-1 as"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {i != 0 && (
                <button
                  type="button"
                  className={`btn btn-danger do-not-print ${
                    el.for_setup == 1 && "d-none"
                  }`}
                  onClick={this.removeClick.bind(this, i)}
                >
                  <i className="ion ion-md-trash"> </i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    ));
  }

  addClick() {
    this.setState((prevState) => ({
      customFields: [
        ...prevState.customFields,
        { items: "", quantity: "0", price: "0", total: "0", readOnly: "0" },
      ],
    }));
  }

  handleChange(i, type, e) {
    const { name, value } = e.target;
    console.log(name, value, i, type, "name, value , i , type");
    let customFields = [...this.state.customFields];
    if (name === "quantity" || name === "price") {
      customFields[i][name] = value.replace(/\D/g, "");
      customFields[i]["total"] =
        customFields[i].quantity * customFields[i].price;
    } else {
      customFields[i][name] = value;
    }

    // console.log(type , i , name);

    if (type == "divide") {
      var installment_fee = customFields[i]["installment_fee"];
      var setup_fee_installment = customFields[i]["setup_fee_installment"];

      if (installment_fee == 0) {
        customFields[1]["setup_fee_installment"] = 0;
        customFields[1]["setup_fee_installmentreadOnly"] = 1;
        var price = customFields[0]["price"] ?? 0;
        customFields[0]["total"] = price;
        customFields[1]["total"] = 0;
      }

      if (setup_fee_installment == 0 && installment_fee != 0) {
        var price = customFields[0]["price"] ?? 0;
        let final = Number(installment_fee);
        customFields[0]["total"] = price;
        customFields[1]["total"] = Math.round(final).toFixed(2);
      }

      if (installment_fee > 0 && setup_fee_installment > 0) {
        customFields[0]["total"] = 0;
        customFields[1]["total"] = Math.round(
          installment_fee / setup_fee_installment
        ).toFixed(2);
      }

      if (installment_fee > 0) {
        customFields[i]["setup_fee_installmentreadOnly"] = 0;
      }
    }

    // if (type == 'divide') {
    //     var installment_fee = customFields[i]['installment_fee'];
    //     var setup_fee_installment = customFields[i]['setup_fee_installment'];
    //     if (installment_fee == 0) {
    //         customFields[i]['setup_fee_installment'] = 0;
    //         customFields[i]['setup_fee_installmentreadOnly'] = 1;
    //     } else {
    //         customFields[i]['setup_fee_installmentreadOnly'] = 0;
    //     }

    //     if (installment_fee > 0 && setup_fee_installment > 0) {
    //         customFields[0]['total'] = 0;
    //         customFields[i]['total'] = Math.round(installment_fee / setup_fee_installment).toFixed(2);

    //     } else {

    //         console.log(installment_fee, 'customFields[i]', setup_fee_installment)
    //         if (installment_fee == 0 && setup_fee_installment == 0) {
    //             var price = customFields[0]['price'] ?? 0;
    //             customFields[0]['total'] = price;
    //             customFields[1]['total'] = 0;
    //         }

    //         if (setup_fee_installment == 0) {

    //         }

    //         if (installment_fee == 0 && setup_fee_installment < 1) {
    //             var price = customFields[0]['price'] ?? 0;
    //             customFields[0]['total'] = price;
    //         }

    //     }

    // }

    this.setState({ customFields });
  }

  removeClick(i) {
    let customFields = [...this.state.customFields];
    console.log(customFields);
    customFields.splice(i, 1);
    this.setState({ customFields });
  }

  PrintElem() {
    var printContents = document.getElementById("print-data").innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    // document.body.innerHTML = originalContents;
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Loader loaded={this.state.loaded}>
          <form method="POST" onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={8} className="">
                <div className="pb-10 pull-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                    className="mr-3 btn-sm"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    color="info"
                    onClick={() => this.PrintElem()}
                    className="mr-3 btn-sm"
                  >
                    Print
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="8" xl="8" xs="12" id="print-data">
                <Card>
                  <CardBody>
                    <div className="invoice-title d-flex space-between">
                      <h3 className="mt-0">
                        <img src={this.state.logo} alt="logo" height="50" />
                      </h3>
                      <div className="font-size-16">
                        <p>
                          {convertDateFormat(this.state.formData.start_date)}
                        </p>
                        <p>
                          {this.state.formData.type.replace("_", " ")}{" "}
                          {this.state.formData.type == "recurring" &&
                            this.state.formData.renewal_interval}
                        </p>
                      </div>
                    </div>
                    <hr className="m-0 my-2" />
                    <Row>
                      <Col xs="6" className="mt-0">
                        <div className="invoice-meta">
                          <p className="for">TO</p>
                          {this.state.formData.invoice_for == "Client" ? (
                            <div className="invoice-data">
                              <h5>
                                {this.state.createdFor.first_name}{" "}
                                {this.state.createdFor.last_name}
                              </h5>
                              <p className="address">
                                {this.state.createdFor.address}
                              </p>
                              <p className="mobile">
                                {this.state.createdFor.mobile}
                              </p>
                              <p className="email">
                                {this.state.createdFor.email}
                              </p>
                            </div>
                          ) : (
                            <div className="invoice-data">
                              <h6>{this.state.createdFor.name}</h6>
                              <p className="address">
                                {this.state.createdFor.address}
                              </p>
                              <p className="email">
                                {this.state.createdFor.email}
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col xs="6" className="mt-0">
                        <div className="invoice-meta">
                          <p className="for">From</p>
                          <div className="invoice-data">
                            <h5>{uInfo().name}</h5>
                            {/* <p className="address">
                                                            I-8 Markez Islamabad
                                                            Islamabad, 47000
                                                            4085027559
                                                        </p> */}
                            {/* <p className="mobile">+12515819043</p> */}
                            <p className="email">{uInfo().email}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="row mt-4">
                      <div className="col-sm-6 col-md-6 mb-2">
                        Product Description
                      </div>
                      {/* <div className="col-sm-3 col-md-3 mb-3">Quantity</div>
                                            <div className="col-sm-3 col-md-3 mb-3">Unit Price($)</div> */}
                      {/* <div className="col-sm-6 col-md-6 mb-2">Total</div> */}
                    </div>
                    {this.defaultUI()}
                    <div className="row border-top mx-0">
                      <div className="col-sm-3 col-md-3 mb-3 do-not-print">
                        {this.state.invoice_type != "coach" && (
                          <button
                            type="button"
                            className="btn btn-success d-none btn-sm"
                            onClick={() => this.addClick()}
                          >
                            Add Item
                          </button>
                        )}
                      </div>
                      <div className="col-sm-3 col-md-3 mb-3"></div>
                      <div className="col-sm-3 col-md-3 mb-3"></div>
                      <div className="col-sm-3 col-md-3 mb-3 pe-0">
                        <div className="InvoiceTotal">
                          Total: {this.state.currency_code}
                          {NumberWithCommas(
                            Math.round(
                              this.state.customFields.reduce(function (
                                prev,
                                current
                              ) {
                                return prev + +current.total;
                              },
                              0)
                            ).toFixed(2)
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group d-none">
                      <label>Note</label>
                      <textarea
                        className="form-control"
                        value={this.state.formData.notes}
                        name="notes"
                        placeholder="Enter notes"
                        onChange={(e) =>
                          this.setState((prevState) => {
                            let formData = Object.assign(
                              {},
                              prevState.formData
                            );
                            formData.notes = e.target.value;
                            return { formData };
                          })
                        }
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4" xl="4" xs="12" className="mb-3">
                <Card>
                  <CardBody>
                    <div className="invoice-setting">
                      <h6>Invoice Date</h6>
                      <div className="form-group row m-1 mb-2">
                        <input
                          onChange={(e) => {
                            this.setState((prevState) => {
                              let formData = Object.assign(
                                {},
                                prevState.formData
                              );
                              formData.start_date = e.target.value;
                              return { formData };
                            });
                          }}
                          name="start_date"
                          value={this.state.formData.start_date}
                          className="form-control"
                          type="date"
                          required
                        />
                      </div>
                      <h6>Currency</h6>
                      <div className="form-group row m-1 mb-2">
                        <select
                          className="form-control"
                          name="currency_code"
                          onChange={(e) => {
                            this.setState((prevState) => {
                              let formData = Object.assign(
                                {},
                                prevState.formData
                              );
                              formData.invoice_currency_code = e.target.value;
                              return { formData };
                            });

                            let currencycode = currencies[e.target.value];
                            console.log(currencycode.code);
                            this.setState({
                              currency_code: currencycode.symbol,
                              currency_code_invoices: currencycode.code,
                            });
                          }}
                        >
                          {Object.keys(currencies).map((item, i) => {
                            return (
                              <option
                                value={item}
                                className="form-check pt-3"
                                key={i}
                              >
                                {currencies[item].symbol +
                                  "- (" +
                                  currencies[item].name_plural +
                                  ") (" +
                                  currencies[item].code +
                                  ")"}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {this.state.id == null && (
                        <div className="form-group mb-2">
                          <label>Invoice For</label>
                          <br />
                          {["Client", "ConvertCoach"].map((row, i) => {
                            return (
                              <div
                                key={i}
                                className="form-check form-check-inline"
                              >
                                <input
                                  disabled
                                  checked={
                                    this.state.formData.invoice_for == row
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.invoice_for = e.target.value;
                                      formData.created_for_id = 0;

                                      return { formData };
                                    });
                                    this.setState({
                                      createdFor: {},
                                    });
                                  }}
                                  name={"invoice_for"}
                                  type="radio"
                                  className="form-check-input"
                                  value={row}
                                />
                                <label className="form-check-label">{`${row}`}</label>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {this.state.formData.invoice_for == "Client" ||
                      this.state.formData.invoice_for == "ConvertCoach" ? (
                        <>
                          {this.state.id == null && (
                            <div className="form-group mb-2 position-relative">
                              <input
                                onChange={(e) =>
                                  this.getFormData({
                                    filter_value: e.target.value,
                                  })
                                }
                                placeholder="Search clients"
                                name="searh"
                                type={"text"}
                                className={"form-control"}
                              />
                              {this.state.clients.length > 0 && (
                                <ul className="InvoiceClientList">
                                  {this.state.clients.map((row, i) => {
                                    return (
                                      <li
                                        onClick={() => {
                                          this.setState((prevState) => {
                                            let formData = Object.assign(
                                              {},
                                              prevState.formData
                                            );
                                            formData.created_for_id =
                                              row.client_id;
                                            formData.search_client =
                                              row.first_name +
                                              " " +
                                              row.last_name;
                                            return { formData };
                                          });
                                          this.setState({
                                            createdFor: row,
                                          });
                                          this.getFormData({
                                            filter_value: "",
                                          });
                                        }}
                                        className="form-check"
                                        key={i}
                                      >
                                        <p className="form-check-label">{`${row.first_name} ${row.last_name}`}</p>
                                        <p className="form-check-label">{`${row.email}`}</p>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          )}

                          {this.state.id != null && (
                            <div className="form-group mb-2">
                              <input
                                placeholder="Enter Email(s) Separated By Comma"
                                name="for"
                                readOnly
                                type={"text"}
                                value={`Client# ${this.state.createdFor.client_id} ${this.state.createdFor.first_name} ${this.state.createdFor.last_name}`}
                                className={"form-control"}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-group mb-2">
                          <select
                            className="form-control"
                            name="invoice_for"
                            onChange={(e) => {
                              let user_id = e.target.value;
                              if (user_id != "") {
                                this.setState((prevState) => {
                                  let formData = Object.assign(
                                    {},
                                    prevState.formData
                                  );
                                  formData.created_for_id = user_id;
                                  return { formData };
                                });
                                const row = this.state.coachPartners.filter(
                                  (item) => item.user_id == user_id
                                );
                                this.setState({
                                  createdFor: row[0],
                                });
                              }
                            }}
                          >
                            <option value="">Select Coach Partner</option>
                            {this.state.coachPartners.map((row, i) => {
                              return (
                                <option
                                  value={row.user_id}
                                  className="form-check pt-3"
                                  key={i}
                                >
                                  {`${row.name} (${row.email})`}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}

                      {this.state.invoice_type == "coach" ? (
                        <>
                          <select
                            className="form-control mb-2"
                            required
                            name="package_id"
                            onChange={(e) => {
                              let package_id = e.target.value;
                              if (package_id != "") {
                                const row =
                                  this.state.setUpPermission.packages.filter(
                                    (item) => item.package_id == package_id
                                  );

                                let itemsArray = [
                                  {
                                    items: `${row[0].name} | ${row[0].sub_title}`,
                                    quantity: "1",
                                    price: `${row[0].price}`,
                                    total: `0`,
                                    readOnly: "1",
                                    for_info: 1,
                                  },
                                  {
                                    items: `Monthly setup fee, and duration of installment `,
                                    quantity: "1",
                                    setup_fee_installment: `${row[0].setup_fee_installment}`,
                                    price: `${row[0].setup_fee}`,
                                    installment_fee: `${row[0].setup_fee}`,
                                    total: `${
                                      row[0].setup_fee_installment > 0
                                        ? row[0].setup_fee /
                                          row[0].setup_fee_installment
                                        : row[0].setup_fee +
                                          Number(row[0].price)
                                    }`,
                                    readOnly: "1",
                                    for_setup: 1,
                                    setup_fee_installmentreadOnly: 0,
                                  },
                                ];

                                this.setState({
                                  customFields: itemsArray,
                                });

                                this.setState((prevState) => {
                                  let formData = Object.assign(
                                    {},
                                    prevState.formData
                                  );
                                  formData.type = "recurring";
                                  formData.invoice_for = "ConvertCoach";
                                  formData.master_type = "package";
                                  formData.package_id = package_id;
                                  formData.renewal_interval =
                                    row[0].package_interval;
                                  return { formData };
                                });

                                this.getProposal(package_id, "package");
                              } else {
                                this.setState((prevState) => {
                                  let formData = Object.assign(
                                    {},
                                    prevState.formData
                                  );
                                  formData.type = "recurring";
                                  formData.renewal_interval = "Monthly";
                                  return { formData };
                                });
                              }
                            }}
                          >
                            <option value="">Select package</option>
                            {this.state.setUpPermission.packages.map(
                              (row, i) => {
                                // if (row.has_coach_license == 'No') {
                                return (
                                  <option
                                    value={row.package_id}
                                    className="form-check pt-3"
                                    key={i}
                                  >
                                    {`${row.name} | (${row.sub_title}) | ($${row.price})`}
                                  </option>
                                );
                                // }
                              }
                            )}
                          </select>
                        </>
                      ) : (
                        <>
                          <div className="form-group mb-2">
                            <label>Product Type</label>
                            <br />

                            <div className="form-check form-check-inline">
                              <input
                                checked={
                                  this.state.formData.master_type == "coaching"
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setState((prevState) => {
                                    let formData = Object.assign(
                                      {},
                                      prevState.formData
                                    );
                                    formData.master_type = e.target.value;
                                    formData.product_id = "0";
                                    formData.package_id = "0";
                                    formData.invoice_for = "Client";

                                    return { formData };
                                  });
                                  this.setState({
                                    customFields: [
                                      {
                                        items: "",
                                        quantity: "0",
                                        price: "0",
                                        total: "0",
                                        readOnly: "0",
                                      },
                                    ],
                                  });
                                  this.getProposal(0);
                                }}
                                name={"master_type"}
                                type="radio"
                                className="form-check-input"
                                value={"coaching"}
                              />
                              <label className="form-check-label text-transform-capitalize">
                                Coaching
                              </label>
                            </div>

                            <div className="form-check form-check-inline">
                              <input
                                checked={
                                  this.state.formData.master_type ==
                                  "e-learning"
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setState((prevState) => {
                                    let formData = Object.assign(
                                      {},
                                      prevState.formData
                                    );
                                    formData.master_type = e.target.value;
                                    formData.product_id = "0";
                                    formData.package_id = "0";
                                    formData.invoice_for = "Client";
                                    return { formData };
                                  });
                                  this.setState({
                                    customFields: [
                                      {
                                        items: "",
                                        quantity: "0",
                                        price: "0",
                                        total: "0",
                                        readOnly: "0",
                                      },
                                    ],
                                  });
                                  this.getProposal(0);
                                }}
                                name={"master_type"}
                                type="radio"
                                className="form-check-input"
                                value={"e-learning"}
                              />
                              <label className="form-check-label text-transform-capitalize">
                                Academy
                              </label>
                            </div>

                            {this.state.setUpPermission.permission >= 1 &&
                              this.state.setUpPermission.remaining_coach >
                                0 && (
                                <div className="form-check form-check-inline">
                                  <input
                                    checked={
                                      this.state.formData.master_type ==
                                      "ConvertCoach"
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.setState((prevState) => {
                                        let formData = Object.assign(
                                          {},
                                          prevState.formData
                                        );
                                        formData.master_type = "ConvertCoach";
                                        formData.invoice_for = "ConvertCoach";
                                        formData.product_id = "0";
                                        formData.package_id = "0";
                                        return { formData };
                                      });

                                      this.setState({
                                        customFields: [],
                                      });
                                      this.getProposal(0);
                                    }}
                                    name={"master_type"}
                                    type="radio"
                                    className="form-check-input"
                                    value={"coaching"}
                                  />
                                  <label className="form-check-label text-transform-capitalize">
                                    New Coach
                                  </label>
                                </div>
                              )}

                            {/* {uInfo().coach_can_sell_trainings === 'active_paid' && ( */}
                            {(hasPurchasedAddon(4) ||
                              includedInPlanAddon(4)) && (
                              <div className="form-check form-check-inline">
                                <input
                                  checked={
                                    this.state.formData.master_type ==
                                    "trainingProduct"
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.master_type = "trainingProduct";
                                      formData.invoice_for = "Client";
                                      formData.product_id = "0";
                                      formData.package_id = "0";
                                      return { formData };
                                    });

                                    this.setState({
                                      customFields: [],
                                    });
                                    // this.getProposal(0);
                                  }}
                                  name={"master_type"}
                                  type="radio"
                                  className="form-check-input"
                                  value={"coaching"}
                                />
                                <label className="form-check-label text-transform-capitalize">
                                  My Training
                                </label>
                              </div>
                            )}

                            {/* {
                                                                ['coaching', 'e-learning', 'new-coach'].map((row, i) => {
                                                                    return (
                                                                        <div key={i} className="form-check form-check-inline">
                                                                            <input checked={this.state.formData.master_type == row ? 'checked' : ''}
                                                                                onChange={(e) => {
                                                                                    this.setState(prevState => {
                                                                                        let formData = Object.assign({}, prevState.formData);
                                                                                        formData.master_type = e.target.value;
                                                                                        formData.product_id = '0';
                                                                                        return { formData };
                                                                                    });
                                                                                    this.setState({
                                                                                        customFields: [{ items: "", quantity: "0", price: "0", total: "0", readOnly: "0" }],
                                                                                    })
                                                                                }} name={'master_type'} type="radio"
                                                                                className="form-check-input" value={row} />
                                                                            <label className="form-check-label text-transform-capitalize">{`${row}`}</label>
                                                                        </div>
                                                                    );
                                                                })
                                                            } */}
                          </div>

                          {/* <div className="form-group mb-2">
                                                            {
                                                                this.state.formData.master_type == 'coaching' || this.state.formData.master_type == 'e-learning' ?
                                                                    <select required className="form-control" name="product_id"
                                                                        onChange={(e) => {
                                                                            let product_id = e.target.value;
                                                                            if (product_id != '0') {
                                                                                const row = this.state.revenueProducts.filter(item => item.product_id == product_id);

                                                                                let itemsArray = [
                                                                                    { items: `${row[0].name}`, quantity: "1", price: `${row[0].price}`, total: `${row[0].price}`, readOnly: '0' },
                                                                                    { items: `One time Setup Fee`, quantity: "1", price: `${row[0].setup_fee}`, total: `${row[0].setup_fee}`, readOnly: '0' },
                                                                                ];

                                                                                this.setState({
                                                                                    customFields: itemsArray
                                                                                })

                                                                                this.setState(prevState => {
                                                                                    let formData = Object.assign({}, prevState.formData);
                                                                                    formData.product_id = product_id;
                                                                                    formData.invoice_for = 'Client';
                                                                                    formData.type = row[0].payment_type;
                                                                                    formData.renewal_interval = row[0].product_interval;
                                                                                    formData.notes = row[0].description;
                                                                                    return { formData };
                                                                                });
                                                                                this.setState({
                                                                                    invoice_type: "product",

                                                                                })
                                                                                this.getProposal(product_id, this.state.formData.master_type)
                                                                            } else {
                                                                                this.setState(prevState => {
                                                                                    let formData = Object.assign({}, prevState.formData);
                                                                                    formData.product_id = '0';
                                                                                    formData.notes = '';
                                                                                    formData.sub_proposal_type = '';
                                                                                    return { formData };
                                                                                });
                                                                                this.setState({
                                                                                    customFields: [{ items: "", quantity: "0", price: "0", total: "0", readOnly: "0" }],
                                                                                    productProposals: [],
                                                                                    hasProposal: 0
                                                                                })
                                                                            }
                                                                        }} >
                                                                        <option value='0'>Select Product</option>
                                                                        {
                                                                            this.state.revenueProducts.map((row, i) => {
                                                                                if (row.type == this.state.formData.master_type) {
                                                                                    return (
                                                                                        <option value={row.product_id}
                                                                                            className="form-check pt-3" key={'pro' + row.product_id}>
                                                                                            {`${row.name} | ($${row.price})`}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            })
                                                                        }
                                                                    </select>
                                                                    :
                                                                    <select className="form-control mb-2" required name="package_id"
                                                                        onChange={(e) => {
                                                                            let package_id = e.target.value;
                                                                            if (package_id != '') {
                                                                                const row = this.state.setUpPermission.packages.filter(item => item.package_id == package_id);

                                                                                var sub_price = row[0].setup_fee_installment == 0 ? row[0].price : 0;

                                                                                let itemsArray = [
                                                                                    {
                                                                                        items: `${row[0].name} | ${row[0].sub_title}`, quantity: "1", price: `${row[0].price}`,
                                                                                        total: `${sub_price}`, readOnly: '1', for_info: 1
                                                                                    },
                                                                                    {
                                                                                        items: `Monthly setup fee, and duration of installment `, quantity: "1",
                                                                                        setup_fee_installment: `${row[0].setup_fee_installment}`, price: `${row[0].setup_fee}`,
                                                                                        installment_fee: `${row[0].setup_fee}`,
                                                                                        total: `${row[0].setup_fee_installment > 0 ? row[0].setup_fee / row[0].setup_fee_installment : Number(row[0].setup_fee)}`,
                                                                                        readOnly: '1', for_setup: 1
                                                                                    },

                                                                                    // { items: `One time Setup Fee`, quantity: "1", price: `${row[0].setup_fee}`, total: `${row[0].setup_fee}`, readOnly: '1' },
                                                                                ];

                                                                                this.setState({
                                                                                    customFields: itemsArray
                                                                                })

                                                                                this.setState(prevState => {
                                                                                    let formData = Object.assign({}, prevState.formData);
                                                                                    formData.type = 'recurring';

                                                                                    formData.invoice_for = 'ConvertCoach';
                                                                                    formData.package_id = package_id;
                                                                                    formData.renewal_interval = row[0].package_interval;
                                                                                    return { formData };
                                                                                });

                                                                                this.getProposal(package_id, 'package');
                                                                                this.setState({
                                                                                    invoice_type: "ConvertCoach"
                                                                                })
                                                                            } else {
                                                                                this.setState(prevState => {
                                                                                    let formData = Object.assign({}, prevState.formData);
                                                                                    formData.type = 'recurring';
                                                                                    formData.renewal_interval = 'Monthly';
                                                                                    return { formData };
                                                                                });
                                                                            }
                                                                        }} >
                                                                        <option value=''>Select package</option>
                                                                        {
                                                                            this.state.setUpPermission.packages.map((row, i) => {
                                                                                // if (row.has_coach_license == 'No') {
                                                                                return (
                                                                                    <option value={row.package_id}
                                                                                        className="form-check pt-3" key={'pac' + row.package_id}>
                                                                                        {`${row.name} | (${row.sub_title}) | ($${row.price})`}
                                                                                    </option>
                                                                                );
                                                                                // }
                                                                            })
                                                                        }
                                                                    </select>
                                                            }
                                                        </div> */}
                          <div className="form-group mb-2">
                            {this.state.formData.master_type == "coaching" ||
                            this.state.formData.master_type == "e-learning" ? (
                              <select
                                required
                                className="form-control"
                                name="product_id"
                                onChange={(e) => {
                                  let product_id = e.target.value;
                                  if (product_id != "0") {
                                    const row =
                                      this.state.revenueProducts.filter(
                                        (item) => item.product_id == product_id
                                      );

                                    let itemsArray = [
                                      {
                                        items: `${row[0].name}`,
                                        quantity: "1",
                                        price: `${row[0].price}`,
                                        total: `${row[0].price}`,
                                        readOnly: "0",
                                      },
                                      {
                                        items: `One time Setup Fee`,
                                        quantity: "1",
                                        price: `${row[0].setup_fee}`,
                                        total: `${row[0].setup_fee}`,
                                        readOnly: "0",
                                      },
                                    ];

                                    this.setState({
                                      customFields: itemsArray,
                                    });

                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.product_id = product_id;
                                      formData.invoice_for = "Client";
                                      formData.type = row[0].payment_type;
                                      formData.renewal_interval =
                                        row[0].product_interval;
                                      formData.notes = row[0].description;
                                      return { formData };
                                    });
                                    this.setState({
                                      invoice_type: "product",
                                    });
                                    this.getProposal(
                                      product_id,
                                      this.state.formData.master_type
                                    );
                                  } else {
                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.product_id = "0";
                                      formData.notes = "";
                                      formData.sub_proposal_type = "";
                                      return { formData };
                                    });
                                    this.setState({
                                      customFields: [
                                        {
                                          items: "",
                                          quantity: "0",
                                          price: "0",
                                          total: "0",
                                          readOnly: "0",
                                        },
                                      ],
                                      productProposals: [],
                                      hasProposal: 0,
                                    });
                                  }
                                }}
                              >
                                <option value="0">Select Product</option>
                                {this.state.revenueProducts.map((row, i) => {
                                  if (
                                    row.type == this.state.formData.master_type
                                  ) {
                                    return (
                                      <option
                                        value={row.product_id}
                                        className="form-check pt-3"
                                        key={"pro" + row.product_id}
                                      >
                                        {`${row.name} | ($${row.price})`}
                                      </option>
                                    );
                                  }
                                })}
                              </select>
                            ) : // ) : uInfo().coach_can_sell_trainings ===
                            //     "active_paid" &&
                            //   this.state.formData.master_type ==
                            //     "trainingProduct" ? (
                            (hasPurchasedAddon(4) || includedInPlanAddon(4)) &&
                              this.state.formData.master_type ==
                                "trainingProduct" ? (
                              <select
                                required
                                className="form-control"
                                name="way_training_cat_id"
                                onChange={(e) => {
                                  let way_training_cat_id = e.target.value;

                                  if (way_training_cat_id != "0") {
                                    const row =
                                      this.state.traningProducts.filter(
                                        (item) =>
                                          item.cat_id == way_training_cat_id
                                      );
                                    //console.log(row[0].course_paid_price ?? 0)
                                    let itemsArray = [
                                      {
                                        items: `${row[0].title}`,
                                        quantity: "1",
                                        price: `${
                                          row[0].course_free_paid === "free"
                                            ? 0
                                            : row[0].course_paid_price ?? 0
                                        }`,
                                        total: `${
                                          row[0].course_free_paid === "free"
                                            ? 0
                                            : row[0].course_paid_price ?? 0
                                        }`,
                                        readOnly: "0",
                                      },
                                    ];
                                    let currencycode = currencies["USD"];

                                    this.setState({
                                      customFields: itemsArray,
                                      currency_code: currencycode.symbol,
                                      currency_code_invoices: currencycode.code,
                                    });

                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.way_training_cat_id =
                                        way_training_cat_id;
                                      formData.invoice_for = "Client";
                                      formData.invoice_currency_code =
                                        currencycode.code;
                                      // formData.type = row[0].payment_type;
                                      // formData.renewal_interval = row[0].product_interval;
                                      // formData.notes = row[0].description;
                                      return { formData };
                                    });
                                    this.setState({
                                      invoice_type: "product",
                                    });
                                    //this.getProposal(training_product_id, this.state.formData.master_type)
                                  } else {
                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.way_training_cat_id = "0";
                                      formData.notes = "";
                                      formData.sub_proposal_type = "";
                                      return { formData };
                                    });
                                    this.setState({
                                      customFields: [
                                        {
                                          items: "",
                                          quantity: "0",
                                          price: "0",
                                          total: "0",
                                          readOnly: "0",
                                        },
                                      ],
                                      productProposals: [],
                                      hasProposal: 0,
                                    });
                                  }
                                }}
                              >
                                <option value="0">
                                  Select My Training Course
                                </option>
                                {this.state.traningProducts.map((row, i) => {
                                  let currencycode = currencies["USD"];
                                  return (
                                    <option
                                      value={row.cat_id}
                                      className="form-check pt-3"
                                      key={"pro" + row.cat_id}
                                    >
                                      {`${row.title} | ( ${
                                        row.course_free_paid === "paid"
                                          ? `${currencycode.symbol}${
                                              row.course_paid_price ?? 0
                                            }`
                                          : "Free"
                                      } )`}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <select
                                className="form-control mb-2"
                                required
                                name="package_id"
                                onChange={(e) => {
                                  let package_id = e.target.value;
                                  if (package_id != "") {
                                    const row =
                                      this.state.setUpPermission.packages.filter(
                                        (item) => item.package_id == package_id
                                      );

                                    var sub_price =
                                      row[0].setup_fee_installment == 0
                                        ? row[0].price
                                        : 0;

                                    let itemsArray = [
                                      {
                                        items: `${row[0].name} | ${row[0].sub_title}`,
                                        quantity: "1",
                                        price: `${row[0].price}`,
                                        total: `${sub_price}`,
                                        readOnly: "1",
                                        for_info: 1,
                                      },
                                      {
                                        items: `Monthly setup fee, and duration of installment `,
                                        quantity: "1",
                                        setup_fee_installment: `${row[0].setup_fee_installment}`,
                                        price: `${row[0].setup_fee}`,
                                        installment_fee: `${row[0].setup_fee}`,
                                        total: `${
                                          row[0].setup_fee_installment > 0
                                            ? row[0].setup_fee /
                                              row[0].setup_fee_installment
                                            : Number(row[0].setup_fee)
                                        }`,
                                        readOnly: "1",
                                        for_setup: 1,
                                      },
                                    ];

                                    this.setState({
                                      customFields: itemsArray,
                                    });

                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.type = "recurring";

                                      formData.invoice_for = "ConvertCoach";
                                      formData.package_id = package_id;
                                      formData.renewal_interval =
                                        row[0].package_interval;
                                      return { formData };
                                    });

                                    this.getProposal(package_id, "package");
                                    this.setState({
                                      invoice_type: "ConvertCoach",
                                    });
                                  } else {
                                    this.setState((prevState) => {
                                      let formData = Object.assign(
                                        {},
                                        prevState.formData
                                      );
                                      formData.type = "recurring";
                                      formData.renewal_interval = "Monthly";
                                      return { formData };
                                    });
                                  }
                                }}
                              >
                                <option value="">Select package</option>
                                {this.state.setUpPermission.packages.map(
                                  (row, i) => {
                                    return (
                                      <option
                                        value={row.package_id}
                                        className="form-check pt-3"
                                        key={"pac" + row.package_id}
                                      >
                                        {`${row.name} | (${row.sub_title}) | ($${row.price})`}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            )}
                          </div>

                          {this.state.productProposals &&
                            this.state.productProposals.length > 0 && (
                              <div className="form-group mb-2">
                                <label> Select Proposal </label>
                                {/* commit due to preview before sumbit  {this.state.selectedProposaId && (
                                                                    <span className="pull-right">
                                                                

                                                                        <Link
                                                                            to={
                                                                                '/proposal/' + bcrypt(this.state.selectedProposaId)
                                                                            }
                                                                            target="_blank"
                                                                            className="btn btn-stripe p-0"
                                                                        >
                                                                            <i title="Preview Proposal" className="fas fa-eye px-2 mr-2 cursor-pointer"></i>
                                                                        </Link>
                                                                    </span>
                                                                )} */}
                                <select
                                  className="form-control"
                                  name="proposal_id"
                                  onChange={(e) => {
                                    let proposal_id = e.target.value;
                                    if (proposal_id != "0") {
                                      const row =
                                        this.state.productProposals.filter(
                                          (item) =>
                                            item.proposal_id == proposal_id
                                        );
                                      if (
                                        row[0].sub_type == "Proposal" &&
                                        row[0].type == "Coaching"
                                      ) {
                                        let itemsArray = [
                                          {
                                            items: `${row[0].name}`,
                                            quantity: "1",
                                            price: `${row[0].price}`,
                                            total: `${row[0].price}`,
                                            readOnly: "0",
                                          },
                                          {
                                            items: `One time Setup Fee`,
                                            quantity: "1",
                                            price: `${row[0].setup_price}`,
                                            total: `${row[0].setup_price}`,
                                            readOnly: "0",
                                          },
                                        ];

                                        this.setState({
                                          customFields: itemsArray,
                                          selectedProposaId: proposal_id,
                                        });

                                        this.setState((prevState) => {
                                          let formData = Object.assign(
                                            {},
                                            prevState.formData
                                          );
                                          formData.proposal_id = proposal_id;
                                          formData.sub_proposal_type =
                                            "Proposal";
                                          return { formData };
                                        });
                                      } else {
                                        var sub_price =
                                          row[0].setup_fee_installment == 0
                                            ? row[0].price
                                            : 0;
                                        const newData =
                                          this.state.customFields.map(
                                            (item, i) => {
                                              if (i === 0) {
                                                return {
                                                  ...item,
                                                  price: `${row[0].price}`,
                                                  total: `${sub_price}`,
                                                };
                                              }

                                              if (i === 1) {
                                                return {
                                                  ...item,
                                                  price: `${row[0].setup_price}`,
                                                  setup_fee_installment: `${row[0].setup_fee_installment}`,
                                                  installment_fee: `${row[0].setup_price}`,
                                                  total: `${
                                                    row[0]
                                                      .setup_fee_installment > 0
                                                      ? Math.round(
                                                          row[0].setup_price /
                                                            row[0]
                                                              .setup_fee_installment
                                                        )
                                                      : row[0].setup_price
                                                  }`,
                                                  setup_fee_installmentreadOnly: 0,
                                                };
                                              }
                                              return item;
                                            }
                                          );

                                        this.setState({
                                          customFields: newData,
                                        });

                                        this.setState((prevState) => {
                                          let formData = Object.assign(
                                            {},
                                            prevState.formData
                                          );
                                          formData.proposal_id = proposal_id;
                                          formData.sub_proposal_type =
                                            "Proposal";
                                          return { formData };
                                        });
                                      }
                                      if (
                                        row[0].sub_type == "AssessmentProposal"
                                      ) {
                                        this.setState({
                                          selectedProposaId: proposal_id,
                                        });

                                        this.getAssessments(
                                          this.state.formData.created_for_id
                                        );
                                        this.setState((prevState) => {
                                          let formData = Object.assign(
                                            {},
                                            prevState.formData
                                          );
                                          formData.sub_proposal_type =
                                            "AssessmentProposal";
                                          formData.proposal_id = proposal_id;
                                          return { formData };
                                        });
                                      }
                                    } else {
                                      this.setState((prevState) => {
                                        let formData = Object.assign(
                                          {},
                                          prevState.formData
                                        );
                                        formData.proposal_id = "0";
                                        formData.sub_proposal_type = "";
                                        return { formData };
                                      });
                                      // this.setState({
                                      //     selectedProposaId: null,
                                      //     customFields: [{ items: "", quantity: "0", price: "0", total: "0", readOnly: "0" }]
                                      // })
                                    }
                                  }}
                                >
                                  <option value="0"> Select Proposal</option>

                                  {this.state.hasProposal == 1 && (
                                    <optgroup label="Default Proposal">
                                      {this.state.productProposals &&
                                        this.state.productProposals.map(
                                          (row, i) => {
                                            if (row.sub_type == "Proposal") {
                                              return (
                                                <option
                                                  value={row.proposal_id}
                                                  className="form-check pt-3"
                                                  key={i}
                                                >
                                                  {`${row.name} | (${row.price}) ---`}
                                                </option>
                                              );
                                            }
                                          }
                                        )}
                                    </optgroup>
                                  )}

                                  {this.state.formData.master_type ==
                                    "coaching" && (
                                    <optgroup label="Assessment Proposal">
                                      {this.state.productProposals &&
                                        this.state.productProposals.map(
                                          (row, i) => {
                                            if (
                                              row.sub_type ==
                                              "AssessmentProposal"
                                            ) {
                                              return (
                                                <option
                                                  value={row.proposal_id}
                                                  className="form-check pt-3"
                                                  key={i}
                                                >
                                                  {`${row.name} | (${row.price})`}
                                                </option>
                                              );
                                            }
                                          }
                                        )}
                                    </optgroup>
                                  )}
                                </select>
                              </div>
                            )}

                          {this.state.formData.sub_proposal_type ==
                            "AssessmentProposal" && (
                            <>
                              {this.state.clientAssessments &&
                              this.state.clientAssessments.length > 0 ? (
                                <div className="form-group mb-2">
                                  <label> Assessment </label>
                                  <select
                                    className="form-control"
                                    name="assessment_id"
                                    onChange={(e) => {
                                      let assesments_id = e.target.value;
                                      this.setState((prevState) => {
                                        let formData = Object.assign(
                                          {},
                                          prevState.formData
                                        );
                                        formData.assessment_id = assesments_id;
                                        return { formData };
                                      });
                                    }}
                                  >
                                    <option value="0">
                                      {" "}
                                      Select Assessment
                                    </option>

                                    {this.state.clientAssessments &&
                                      this.state.clientAssessments.map(
                                        (row, i) => {
                                          return (
                                            <option
                                              value={row.assessment_id}
                                              className="form-check pt-3"
                                              key={i}
                                            >
                                              {`${row.name} | Assessment# (${row.assessment_id})`}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              ) : (
                                <p className="text-danger">
                                  No assessment found
                                </p>
                              )}
                            </>
                          )}
                        </>
                      )}

                      <div className="form-group mb-2">
                        {[
                          { label: "One Time", value: "one_time" },
                          { label: "Recurring", value: "recurring" },
                        ].map((row, i) => {
                          return (
                            <div
                              key={i}
                              className="form-check form-check-inline"
                            >
                              <input
                                checked={
                                  this.state.formData.type == row.value
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setState((prevState) => {
                                    let formData = Object.assign(
                                      {},
                                      prevState.formData
                                    );
                                    formData.type = e.target.value;
                                    return { formData };
                                  });
                                }}
                                name={"type"}
                                type="radio"
                                disabled={
                                  this.state.invoice_type == "ConvertCoach" ||
                                  this.state.invoice_type == "coach" ||
                                  this.state.invoice_type == "product"
                                    ? true
                                    : false
                                }
                                className="form-check-input"
                                value={row.value}
                              />
                              <label className="form-check-label">{`${row.label}`}</label>
                            </div>
                          );
                        })}
                      </div>
                      {this.state.formData.type == "recurring" &&
                        [
                          { label: "Monthly", value: "Monthly" },
                          { label: "Quarterly", value: "Quarterly" },
                          { label: "Yearly", value: "Yearly" },
                        ].map((row, i) => {
                          return (
                            <div
                              key={i}
                              className="form-check form-check-inline"
                            >
                              <input
                                checked={
                                  this.state.formData.renewal_interval ==
                                  row.value
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setState((prevState) => {
                                    let formData = Object.assign(
                                      {},
                                      prevState.formData
                                    );
                                    formData.renewal_interval = e.target.value;
                                    return { formData };
                                  });
                                }}
                                name={"renewal_interval"}
                                type="radio"
                                disabled={
                                  this.state.invoice_type == "ConvertCoach" ||
                                  this.state.invoice_type == "coach" ||
                                  this.state.invoice_type == "product"
                                    ? true
                                    : false
                                }
                                className="form-check-input"
                                value={row.value}
                              />
                              <label className="form-check-label">{`${row.label}`}</label>
                            </div>
                          );
                        })}
                    </div>

                    {this.state.formData.master_type == "ConvertCoach" && (
                      <div className="form-group mt-3">
                        <label>Payment method</label>
                        <br />
                        {[
                          { label: "Stripe", value: "stripe" },
                          { label: "Manual", value: "manual" },
                        ].map((row, i) => {
                          return (
                            <div
                              key={i}
                              className="form-check form-check-inline"
                            >
                              <input
                                checked={
                                  this.state.formData.payment_method ==
                                  row.value
                                    ? "checked"
                                    : ""
                                }
                                onChange={(e) => {
                                  this.setState((prevState) => {
                                    let formData = Object.assign(
                                      {},
                                      prevState.formData
                                    );
                                    formData.payment_method = e.target.value;
                                    return { formData };
                                  });
                                }}
                                name={"payment_method"}
                                type="radio"
                                className="form-check-input"
                                value={row.value}
                              />
                              <label className="form-check-label">{`${row.label}`}</label>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* Ignore the check of proposl id at time product_id */}
                    {this.state.formData?.master_type == "coaching" &&
                      this.state.formData?.sub_proposal_type ==
                        "AssessmentProposal" &&
                      this.state.formData?.proposal_id == "2" &&
                      this.state.formData?.assessment_id > 0 && (
                        <Alert color="info" className="mt-3">
                          Click the eye icon{" "}
                          <Link
                            to={`/proposal/${bcrypt(
                              this.state.formData?.proposal_id
                            )}?c=${bcrypt(
                              this.state.formData?.created_for_id
                            )}&a=${bcrypt(
                              this.state.formData?.assessment_id
                            )}&u=${bcrypt(uInfo().user_id)}&t=p&curr=${bcrypt(
                              this.state.currency_code_invoices
                            )}`}
                            target="_blank"
                            className="btn btn-stripe p-0 btn-sm"
                          >
                            <i
                              title="Preview Proposal"
                              className="fas fa-eye px-2 mr-2 cursor-pointer"
                            ></i>
                          </Link>
                          to preview results
                        </Alert>
                      )}
                  </CardBody>
                </Card>
              </Col>

              {this.state.has_payment_method == "0" && (
                <Col lg="12">
                  <Alert color="warning">
                    You have not added a payment method.{" "}
                    <Link to={`/settings/payment`} className="alert-link pl-2">
                      Click here
                    </Link>{" "}
                    to enter your payment method.
                  </Alert>
                </Col>
              )}
            </Row>
          </form>
        </Loader>
      </React.Fragment>
    );
  }
}
