import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    ModalFooter,
} from "reactstrap";
import Helmet from "react-helmet";
import {
    dcrypt,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Froala from "../../Froala";

export default class TrackerCategoriesEdit extends Component {

    constructor(props) {
        super(props);
        console.log(dcrypt(this.props.match.params.id))
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            record: {},
            page_name: "Edit Action Steps Category",
            loader: true,
            categoryfilter: {}
        };
    }

    componentDidMount() {
        this.getData(this.props.match.params.id);
    }

    getData = (id) => {
        console.log(`${ApiUrl}` + "get/edit/tracker/category/" + dcrypt(id))

        fetch(`${ApiUrl}` + "get/edit/tracker/category/" + dcrypt(id), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        formData.append('id', this.state.id ? this.state.id : -1);
        this.setState({ loader: false });
        fetch(`${ApiUrl}` + "update/category/tracker", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: formData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.props.history.goBack();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });

            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <ErrorBoundary>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>
                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                className="btn-sm"
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <form onSubmit={this.handleSubmit} method="post"
                                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                    <div className="form-group mb-3">
                                        <label>Category name </label>
                                        <input defaultValue={this.state.record.cat_name} className="form-control" name="cat_name" required />
                                    </div>
                                    <ModalFooter className="border-0 py-0">
                                        <Button
                                            className="btn-sm"
                                            type="button"
                                            color="secondary"
                                            onClick={() => this.props.history.goBack()}
                                            disabled={this.state.loader ? false : true}
                                        >
                                            Cancel
                                        </Button>
                                        <button
                                            className="btn btn-primary waves-effect waves-light btn-sm"
                                            type="submit"
                                            disabled={this.state.loader ? false : true}
                                        >
                                            Submit
                                        </button>
                                    </ModalFooter>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ErrorBoundary>
        );
    }
}
