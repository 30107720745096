import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state
import UrlImageDownloader from "react-url-image-downloader";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Froala from "../../../Froala";
export default class smsTemplatesAddEdit extends Component {
  constructor(props) {
    super(props);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      permissions: [],
      user_id: obj.user_id,
      record: {
        title: "",
        subject: "",
        body: "",
        type: "SMS",
      },
      selected_file: "",
      page_name: this.props.match.params.id
        ? "Edit Script Template"
        : "Create Script Template",
      customfields: [],

      field: "",
      appendContents: "",
      editor: "",
      smstokens: [],
      default_fields: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.state.id != null && this.getData();

    this.getcoachdata();
  }
  handleBody = async (event, values) => {
    this.setState({
        script_body: values,
    });
  }
  changeHandler = (e) => {
    let selected_file = [];
    var reader = new FileReader();
    reader.onload = () => {
      selected_file.push(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState({
      selected_file: selected_file,
    });
  };

  getcoachdata = () => {
    fetch(`${ApiUrl}` + "get/coachdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            default_fields: this.parsecustomfields(
              response.data.record.default_fields
            ),
            permissions: response.data.coachpartners,
          });

          var custom_fields = this.parsecustomfields(
            response.data.record.custom_fields
          );
          custom_fields.map((field) => {
            this.setState({
              customfields: [...this.state.customfields, field],
            });
          });
          this.setState({
            smstokens: [
              {
                label: "Default Fields",
                options: this.state.default_fields,
              },
              {
                label: "Custom Fields",
                options: this.state.customfields,
              },
            ],
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  parsecustomfields = (data) => {
    return data.map((customfield) => {
      return { label: customfield.label, value: customfield.key };
    });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/editscripttemplate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            editor: response.data.record.body,
            selectedPermission: response.data.permissions,
            // inputList: JSON.parse(response.data.record.description)
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    values.body = this.state.script_body;
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }

    fetch(`${ApiUrl}` + "edit/scripttemplate", {
      method: "POST",
      contentType: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      files: true,
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
            this.setState({
              editor: "",
            });
          }
          // this.getData();
          this.props.history.goBack();
          /// toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  changeEditor = (field) => {
    this.setState({
      field: field,
    });

    this.appendcontent(field.value);
  };
  appendcontent = (value) => {
    this.setState({
      appendContents: "{" + value + "}",
    });
  };

  handleChange = (e) => {
    this.setState({
      editor: e,
    });
  };

  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState({
      permission: filter.toString(),
      selectedPermission: selectedPermission,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {" "}
            {this.state.page_name}
            Tables{" "}
          </title>{" "}
        </Helmet>{" "}
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>{" "}
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {" "}
                  {ProductName}{" "}
                </li>{" "}
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>{" "}
                </li>{" "}
              </ol>{" "}
            </div>{" "}
          </Col>{" "}
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back{" "}
              </Button>{" "}
            </div>{" "}
          </Col>{" "}
        </Row>{" "}
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
                enctype="multipart/form-data"
              >
                <ModalBody>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      value={this.state.record.name}
                      label="Name"
                      className="form-control"
                      required
                    />
                  </div>{" "}
                  {/* <div className="mb-3">
                                                    <label> Type </label>
                                                    <AvRadioGroup
                                                      value={this.state.record.type}
                                                      inline
                                                      name="type"
                                                      onChange={(e) => this.onChangeTypeAction(e.target.value)}
                                                    >
                                                      <AvRadio label="Outbound" value="1" />
                                                      <AvRadio label="Inbound" value="2" />
                                                    </AvRadioGroup>
                                                  </div> */}{" "}
                  {/* <div className="row mb-3" >

                    <div className="col-md-8">
                      <div className="MainTZone SendSMS Two">

                        <Select getOptionLabel={
                          (option) => option.label
                        }
                          getOptionValue={
                            (option) => option.value
                          }
                          isMulti={false}
                          options={this.state.smstokens}
                          classNamePrefix="select form-control"
                          style={
                            { position: "absolute" }
                          }
                          className={"is-touched is-dirty av-valid"}
                          name="field"
                          onChange={
                            (e) => {
                              this.setState({
                                selectedToken: `${e.value}`
                              })
                            }
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <button
                        onClick={() => {
                          this.appendcontent(this.state.selectedToken);
                        }}
                        type="button"
                        className="btn btn-primary"
                      >
                        Insert Token
                      </button>
                    </div>
                  </div> */}
                  <div className="mb-3">
                    {/* <SunEditor
                      setOptions={{
                        height: 400,
                        buttonList: [
                          ["undo", "redo", "font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "removeFormat",
                          ],
                          [
                            "fontColor",
                            "hiliteColor",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "table",
                          ],
                          [
                            "link",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                          ],
                        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                        // plugins: [font] set plugins, all plugins are set by default
                        // Other option
                      }}
                      setContents={this.state.record.body}
                      onChange={this.handleChange}
                      appendContents={this.state.appendContents}
                    />

                    <AvField
                      name="body"
                      value={this.state.editor}
                      type="hidden"
                      required
                    /> */}
                    <Froala btnId={'edit-script-content'} content={this.state.record.body}  handleSubmit={this.handleBody} use_token={'users'} />
                  </div>{" "}
                  {/* <div className="mb-3">
                    <AvGroup>
                      <label> Token </label>{" "}
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isMulti={false}
                        options={this.state.smstokens}
                        classNamePrefix="select form-control"
                        style={{ position: "absolute" }}
                        className={"is-touched is-dirty av-valid"}
                        name="field"
                        onChange={(e) => {
                          this.changeEditor(e);
                        }}
                      />{" "}
                    </AvGroup>{" "}
                  </div>{" "} */}
                  <div className="col-md-12 mb-3 d-none">
                    <label className="control-label"> Assign to </label>{" "}
                    <Select
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.user_id}
                      isMulti={true}
                      value={this.state.selectedPermission}
                      options={this.state.permissions}
                      classNamePrefix="select form-control"
                      style={{ position: "absolute" }}
                      className={"is-touched is-dirty av-valid"}
                      onChange={(e) => {
                        this.handlePermission(e);
                      }}
                    />{" "}
                    <AvField
                      type="hidden"
                      name="permission"
                      value={this.state.permission}
                    />{" "}
                  </div>{" "}
                </ModalBody>{" "}
                <ModalFooter>
                  <Button
                    className="btn-sm"
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel{" "}
                  </Button>{" "}
                  <button
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    type="submit"
                    id="edit-script-content"
                  >
                    Submit{" "}
                  </button>{" "}
                </ModalFooter>{" "}
              </AvForm>{" "}
            </Card>{" "}
          </Col>{" "}
        </Row>{" "}
        {/* /////////////////////////////////////////////// */}{" "}
      </React.Fragment>
    );
  }
}
