import React, { useState, useEffect } from "react";
import { ApiUrl, AttachementsUrl } from "../../config";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Link, useHistory } from "react-router-dom";

const Badges = (props) => {
  const [id, setId] = useState(
    props.id ? props.id : props.match.params.id ? props.match.params.id : null
  );
  const [records, setRecords] = useState([]);
  const [certification, setCertification] = useState({});
  const [defaultBadge, setDefaultBadge] = useState({});

  const [total_badge, setTotalBadges] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    fetch(`${ApiUrl}get/module/badges`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id, status: "1" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records);
          setTotalBadges(response.data.total);

          const shownBadge = response.data.records.find(
            (badge) => badge.shown_web === true
          );

          if (shownBadge) {
            setDefaultBadge(shownBadge);
          } else if (response.data.records.length > 0) {
            setDefaultBadge(response.data.records[0]);
          }

          // const certificationsNotZero = response.data.records.filter(
          //   (record) => record.certification_id != 0
          // );

          // const certificateOnWeb = certificationsNotZero.find(
          //   (record) => record.shown_on_web === 1
          // );

          // if (certificateOnWeb) {
          //   setCertification(certificateOnWeb);
          // } else {
          //   if (certificationsNotZero.length > 0) {
          //     const lowestRankRecord = certificationsNotZero.reduce(
          //       (minRankRecord, currentRecord) => {
          //         return currentRecord.rank < minRankRecord.rank
          //           ? currentRecord
          //           : minRankRecord;
          //       }
          //     );

          //     setCertification(lowestRankRecord);
          //   } else {
          //     const shownBadge = response.data.records.find(
          //       (badge) => badge.shown_on_web === 1
          //     );
          //     if (shownBadge) {
          //       setCertification(shownBadge);
          //     } else if (response.data.records.length > 0) {
          //       setCertification(response.data.records[0]);
          //     }
          //   }
          // }

          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {!loading && records.length > 0 ? (
        <>
          {/* NEWLY WORKING CODE SNIPPET AFTER CERTIFICATION CHANGES */}
          {defaultBadge && Object.keys(defaultBadge).length > 0 && (
            <div className="mb-4" style={{ textAlign: "center" }}>
              <div className="SliderContent Dashboard">
                {defaultBadge.certification_id != 0 ? (
                  <h5 className="ChangeColor">{defaultBadge.title}</h5>
                ) : (
                  <>
                    <Link
                      to={`/trainings/${defaultBadge.module_encoded_link}`}
                    >
                      <h5 className="ChangeColor">
                        {defaultBadge.module_title}
                      </h5>
                    </Link>
                    <h5>{defaultBadge.course_title}</h5>
                  </>
                )}
              </div>
              <div
                className="BadgesLogo certification_logo"
                style={{ textAlign: "center" }}
              >
                {defaultBadge.certification_id != 0 ? (
                  <img
                    className="d-block"
                    src={`${AttachementsUrl}user_${defaultBadge.wl_admin}/training_badges/${defaultBadge.image}`}
                    alt={defaultBadge.title}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.openModal();
                    }}
                  />
                ) : (
                  <img
                    className="d-block"
                    src={`${AttachementsUrl}user_${defaultBadge.wl_admin}/training/${defaultBadge.image}`}
                    alt={defaultBadge.module_title}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.openModal();
                    }}
                  />
                )}
              </div>
            </div>
          )}

          {/* PREVIOUSLY WORKING CODE SNIPPET BEFORE CERTIFICATION CHANGES */}
          {/* <Carousel> */}
          {/* {records.map((record, index) => (
              <Carousel.Item key={index}>
                <div style={{ textAlign: "center" }}>
                  <div className="SliderContent Dashboard">
                    <Link to={`/trainings/${record.module_encoded_link}`}>
                      <h5 className="ChangeColor">{record.module_title}</h5>
                    </Link>
                    <h5>{record.course_title}</h5>
                  </div>
                  <div className="BadgesLogo">
                    <img
                      className="d-block"
                      src={`${AttachementsUrl}user_${record.wl_admin}/training/${record.image}`}
                      alt={record.module_title}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.openModal();
                      }}
                    />
                  </div>
                </div>
              </Carousel.Item>
            ))} */}
          {/* </Carousel> */}
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              props.openModal();
            }}
          >
            <h4>Total Badges Earned: {total_badge}</h4>
          </div>
        </>
      ) : (
        <img
          src="https://ws.profitcoach.app/crmdoc/default/images/certified-logo.png"
          alt="Icon"
          onClick={() => {
            props.openModal();
          }}
        ></img>
      )}
    </>
  );
};

export default Badges;
